import DepartmentMonitorSampleConfigForm from '@pages/DepartmentMonitorSampleConfigForm';
import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import Empty from '@/assets/images/empty_bg.png';
import SettingIcon from '@/assets/images/icon/setting_icon.png';

const EmptyBg = styled.img`
  width: 250px;
  height: 194px;
`;

const EmptyLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  margin-top: 10px;
`;

const EmptyButton = styled.button`
  width: 140px;
  height: 44px;
  background: #2e5ef2;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 24px;
`;

const DepartmentMonitorSampleConfig = (props) => {
  const {
    routes: {
      params: { monitorTaskId },
      query: { period, monitorTaskStatus, grade },
    },
  } = props;
  const [monitorTask, setMonitorTask] = useState();

  const loadByMonitorTask = useCallback(async () => {
    try {
      const response =
        await vsf?.services?.ExtractConfigDtoController_getByMonitorTaskId_553a8d?.(
          {
            monitorTaskId,
          },
        );
      setMonitorTask(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [monitorTaskId]);

  useEffect(() => {
    loadByMonitorTask();
  }, [loadByMonitorTask]);

  return (
    <Page
      title={monitorTask ? '抽样配置' : ''}
      style={{
        width: '100%',
        height: '100%',
        overflowX: 'hidden',
        overflowY: 'scroll',
        marginLeft: 24,
        background: '#fff',
      }}
      className="sample_config_form_view"
    >
      {monitorTask ? (
        <DepartmentMonitorSampleConfigForm
          monitorTaskId={monitorTaskId}
          monitorTask={monitorTask}
          period={period}
          monitorTaskStatus={monitorTaskStatus}
          grade={grade}
        />
      ) : (
        <div
          style={{
            width: '100%',
            height: '100%',
            marginTop: '164px',
          }}
        >
          <div className="col aic">
            <EmptyBg src={Empty} />
            <EmptyLabel>抽取参数未配置，请先前往“规则配置”</EmptyLabel>
            <EmptyButton className="aic jcc" onClick={() => setMonitorTask({})}>
              <img
                src={SettingIcon}
                style={{
                  width: 16,
                  height: 16,
                  marginRight: 6,
                }}
              />
              规则配置
            </EmptyButton>
          </div>
        </div>
      )}
    </Page>
  );
};
export default definePage(DepartmentMonitorSampleConfig);
