import './index.less';

import vsf, { renderRoutes, RouteConfig } from '@vs/vsf-boot';
import { Avatar, Dropdown, Layout, Select } from '@vs/vsf-kit';
import cn from 'classnames';
import React, { useEffect, useState } from 'react';

import DefaultHeadPhoto from '@/assets/images/defaultHeadPhoto.png';
import BottomIcon from '@/assets/images/icon/bottom_icon.png';
import { PLATFORM, TABS_MENU_KEY } from '@/config';
import { navigateToWithPlatform, usePlatformInfo } from '@/utils';

const { Sider } = Layout;

type ContainerProps = {
  route: RouteConfig;
  location: Location;
};

const TABS_MENU = [
  {
    label: '抽样检测',
    key: TABS_MENU_KEY.Sampling,
  },
  {
    label: '成绩管理',
    key: TABS_MENU_KEY.AchievementManage,
  },
  {
    label: '权限管理',
    key: TABS_MENU_KEY.PermissionManage,
  },
];

const Container: React.FC<ContainerProps> = (props: any) => {
  const {
    route,
    routes: {
      params: { pathname },
    },
  } = props;
  const [activeKey, setActiveKey] = useState(TABS_MENU_KEY.Sampling);
  const [dropDownOpen, setDropDownOpen] = useState(false);
  const [dropDownOpenOrg, setDropDownOpenOrg] = useState(false);
  const [orgList, setOrgList] = useState<any>([]);

  const [platformInfo] = usePlatformInfo();

  const [currentPlatform, setCurrentPlatform] = useState<
    number | undefined | null
  >();
  const userName = localStorage.getItem('userName') ?? '';

  useEffect(() => {
    if (platformInfo) {
      setCurrentPlatform(platformInfo?.platformId);
    }
    loadOrgList();
  }, [platformInfo]);

  const loadOrgList = () => {
    try {
      const response = JSON.parse(
        localStorage.getItem('sample-org-list') ?? '[]',
      );
      if (response) {
        setOrgList(response);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelOrgChange = (value) => {
    setCurrentPlatform(value);

    if (value && value !== -1) {
      localStorage.setItem(
        'platformInfo',
        JSON.stringify({
          platform: 'school',
          platformId: value,
        }),
      );
    } else {
      localStorage.setItem(
        'platformInfo',
        JSON.stringify({
          platform: 'department',
          platformId: -1,
        }),
      );
    }
    setActiveKey(TABS_MENU_KEY.Sampling);
    if (pathname?.includes('monitorTaskList')) {
      props?.history?.replace(
        `/${PLATFORM[platformInfo?.platform]}/${value}/monitorTaskList`,
      );
    } else {
      navigateToWithPlatform(`/${value}/monitorTaskList`);
    }
  };

  const handelItemClick = (key) => {
    setActiveKey(key);
    vsf.stores.user?.setActiveTabsKey(key);

    if (key === TABS_MENU_KEY.Sampling) {
      navigateToWithPlatform(`/${platformInfo?.platformId}/monitorTaskList`);
    } else if (key === TABS_MENU_KEY.AchievementManage) {
      navigateToWithPlatform(`/${platformInfo?.platformId}/grades`);
    } else {
      navigateToWithPlatform(`/${platformInfo?.platformId}/priviledge`);
    }
  };

  const renderTabs = () => {
    return (
      <div className="vsf-layout-header-tabs aic">
        {TABS_MENU.map((item) => (
          <div
            key={item.key}
            className={cn('vsf-layout-header-tabs-item aic jcc', {
              ['vsf-layout-header-tabs-item-active']: activeKey === item.key,
              ['vsf-layout-header-tabs-item-normal']: activeKey !== item.key,
            })}
            onClick={() => handelItemClick(item.key)}
          >
            {item.label}
            {activeKey === item.key && (
              <div className="vsf-layout-header-tabs-item-line" />
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div className="vsf-layout col aic">
      <div className="vsf-layout-header aic jcc">
        <div
          className="aic jcsb"
          style={{
            width: '65%',
            minWidth: '1000px',
          }}
        >
          <div>{renderTabs()}</div>
          <div className="aic">
            {/* <Select
              value={currentPlatform ?? ''}
              className="vsf-layout-header-select"
              dataSource={orgList ?? []}
              onChange={handelOrgChange}
            /> */}

            <div className="vsf-layout-header-drop aic">
              <Dropdown
                open={dropDownOpenOrg}
                menu={{
                  items: [
                    {
                      label: '切换组织',
                      key: 'exit',
                      onClick: () => {
                        try {
                          vsf?.navigateTo('/useInstitution');
                          localStorage.removeItem('userName');
                        } catch (error) {
                          console.error(error);
                        }
                      },
                    },
                  ],
                }}
              >
                <div
                  className="orgName aic"
                  onClick={() => setDropDownOpenOrg(!dropDownOpenOrg)}
                >
                  {
                    orgList?.find((item) => item?.value === currentPlatform)
                      ?.label
                  }
                  <img
                    className="vsf-layout-header-drop-icon"
                    src={BottomIcon}
                  />
                </div>
              </Dropdown>
            </div>
            <div className="vsf-layout-header-drop aic">
              <Avatar src={<img src={DefaultHeadPhoto} alt="avatar" />} />
              <div
                className="orgName aic"
                onClick={() => setDropDownOpen(!dropDownOpen)}
              >
                {userName}
              </div>
              {/* <Dropdown
                open={dropDownOpen}
                menu={{
                  items: [
                    {
                      label: '退出登录',
                      key: 'exit',
                      onClick: () => {
                        try {
                          vsf?.navigateTo('/useInstitution');
                          localStorage.removeItem('userName');
                        } catch (error) {
                          console.error(error);
                        }
                      },
                    },
                  ],
                }}
              >
                <div
                  className="vsf-layout-header-drop-name aic"
                  onClick={() => setDropDownOpen(!dropDownOpen)}
                >
                  {userName}
                  <img
                    className="vsf-layout-header-drop-icon"
                    src={BottomIcon}
                  />
                </div>
              </Dropdown> */}
            </div>
          </div>
        </div>
      </div>
      <div className="vsf-layout-content">
        {/* <Sider
          className="vsf-layout-sider"
          theme="light"
          collapsible
          collapsed={collapsed}
          onCollapse={(value) => setCollapsed(value)}
        >
          <div>侧边信息</div>
        </Sider> */}
        <div className="vsf-layout-main">
          {renderRoutes(route.routes, route.redirect)}
        </div>
      </div>
    </div>
  );
};

export default Container;
