import vsf, { definePage } from '@vs/vsf-boot';
import {
  Col,
  message,
  Row,
  Section,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
  withRef,
} from '@vs/vsf-kit';
import React, {
  useCallback,
  useEffect,
  useImperativeHandle,
  useState,
} from 'react';

import { CustomFileUpload } from '@/components';
import { labelColWidth } from '@/components/TreeMenu/utils';
import { extractTables } from '@/utils/xlsx';
const DepartmentMonitorAddStudentForm = React.forwardRef((props, ref) => {
  const { taskIdIs } = props;

  const [excelList, setExcelList] = useState();
  const [addType, setAddType] = useState('import');
  const [formState, setFormState] = useState({
    addType,
  });
  const [orgList, setOrgList] = useState([]);

  useImperativeHandle(ref, () => {
    return {
      getValues: () => {
        return new Promise((resolve, reject) => {
          vsf.refs.DepartmentMonitorAddStudentInnerForm?.validateFields()
            .then((res) => {
              resolve({
                ...formState,
                list: excelList,
              });
            })
            .catch((e) => reject(e));
        });
      },
    };
  });

  const loadOrgList = useCallback(async () => {
    try {
      const response =
        await vsf?.services?.TaskOrgDtoController_queryAllByTaskOrgList_788d4b?.(
          {
            qto: {
              taskIdIs,
            },
          },
        );
      if (response && response.code === 200) {
        setOrgList(
          (response?.data?.result ?? []).map((item) => ({
            label: item.org?.orgName ?? '',
            value: item?.org?.orgName ?? '',
          })),
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [taskIdIs]);

  useEffect(() => {
    loadOrgList();
  }, [loadOrgList]);

  return (
    <Section title="添加学生">
      <VSControlledForm
        vsid="21920"
        id="DepartmentMonitorAddStudentInnerForm"
        value={{
          grade: props.grade,
          ...formState,
        }}
        onChange={(_value) => {
          setFormState(_value);
          props.onChange?.({
            ..._value,
            list: excelList,
          });
        }}
      >
        <VSFormLayout key="0" columnCount={1} labelCol={labelColWidth[4]}>
          <VSFormItem
            name={'addType'}
            label="添加方式"
            valueType="radio"
            dataSource={[
              {
                label: '表格导入',
                value: 'import',
              },
              {
                label: '手动添加',
                value: 'add',
              },
            ]}
            fieldProps={{
              value: addType,
              defaultValue: addType,
              onChange: (value) => {
                setAddType(value);
              },
            }}
          />
          {addType === 'import' ? (
            <VSFormItem
              name={['excel']}
              valueType="custom"
              label="添加名单"
              transform={() => ({
                list: excelList,
              })}
            >
              <CustomFileUpload
                type="file"
                width={376}
                template={{
                  url: `/student_template.xlsx`,
                  name: '学生信息录入模板-其他学科',
                }}
                uploadProps={{
                  accept: '.xls,.xlsx',
                  customRequest: async (e) => {
                    try {
                      const response = await extractTables(
                        e.file,
                        {
                          schoolId: '*schoolId',
                          orgName: '*学校名称',
                          period: '*学段',
                          year: '*学年',
                          classes: '*班级',
                          studentName: '*姓名',
                          sex: '*性别',
                          studentStatusNum: '*学籍辅号',
                          idCard: '证件编号',
                        },
                        [
                          'schoolId',
                          'orgName',
                          'period',
                          'year',
                          'classes',
                          'studentName',
                          'sex',
                          'studentStatusNum',
                        ],
                      );
                      console.log(response, 'response====');
                      if (response) {
                        if (
                          response?.[0]?.data &&
                          response?.[0]?.data?.length > 0
                        ) {
                          const _data = response?.[0]?.data?.map((item) => ({
                            ...item,
                            studentStatusNum:
                              item?.studentStatusNum?.indexOf('0') === 0
                                ? item?.studentStatusNum?.substr(1)
                                : item?.studentStatusNum,
                          }));

                          setExcelList(_data);
                          props?.onChange?.({
                            ...formState,
                            list: _data,
                          });
                          setTimeout(() => {
                            e.onProgress({ percent: 100 });
                            e.onSuccess(
                              {
                                name: e.file.name,
                                status: 'done',
                                path: URL.createObjectURL(e.file),
                              },
                              e.file,
                            );
                          }, 1);
                        } else {
                          message.error('表格中的没有填入学生数据或数据不完整');
                        }
                      }
                    } catch (error) {
                      console.error(error);
                      message.error('解析失败，请检查文档格式');
                    }
                  },
                }}
              />
            </VSFormItem>
          ) : (
            <VSFormLayout columnCount={1} labelCol={labelColWidth[4]}>
              <VSFormItem
                name={['orgName']}
                label="学校名称"
                valueType="select"
                required={false}
                rules={[
                  {
                    required: true,
                    message: '请填写学校名称',
                  },
                ]}
                dataSource={orgList}
              />
              <VSFormItem
                name={['grade']}
                label="年级"
                valueType="select"
                readonly
                dataSource={[
                  { label: '一年级', value: 'FIRST_GRADE' },
                  { label: '二年级', value: 'SECOND_GRADE' },
                  { label: '三年级', value: 'THIRD_GRADE' },
                  { label: '四年级', value: 'FOURTH_GRADE' },
                  { label: '五年级', value: 'FIFTH_GRADE' },
                  { label: '六年级', value: 'SIXTH_GRADE' },
                  { label: '七年级', value: 'SEVENTH_GRADE' },
                  { label: '八年级', value: 'EIGHTH_GRADE' },
                  { label: '九年级', value: 'NINE_GRADE' },
                ]}
                fieldProps={{}}
              />
              <VSFormItem
                name={['classes']}
                label="班级"
                valueType="text"
                required={false}
                rules={[
                  {
                    required: true,
                    message: '请填写班级',
                  },
                  {
                    message: '班级长度不合法',
                    type: 'string',
                    min: 0,
                    max: 1024,
                  },
                ]}
                fieldProps={{}}
              />
              <VSFormItem
                name={['name']}
                label="姓名"
                valueType="text"
                required={false}
                rules={[
                  {
                    required: true,
                    message: '请填写学生姓名',
                  },
                  {
                    message: '学生姓名长度不合法',
                    type: 'string',
                    min: 0,
                    max: 64,
                  },
                ]}
                fieldProps={{}}
              />
              <VSFormItem
                name={['sex']}
                label="性别"
                valueType="select"
                dataSource={[
                  { label: '男', value: 'MAN' },
                  { label: '女', value: 'WOMAN' },
                ]}
                fieldProps={{}}
                required={false}
                rules={[
                  {
                    required: true,
                    message: '请选择性别',
                  },
                ]}
              />
              <VSFormItem
                name={['studentStatusNum']}
                label="学籍辅号"
                valueType="text"
                required={false}
                rules={[
                  {
                    required: true,
                    message: '请填写学籍辅号',
                  },
                  {
                    message: '学籍辅号长度不合法',
                    type: 'string',
                    min: 0,
                    max: 64,
                  },
                ]}
                fieldProps={{}}
              />
            </VSFormLayout>
          )}
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
});
DepartmentMonitorAddStudentForm.displayName = 'DepartmentMonitorAddStudentForm';
export default withRef()(DepartmentMonitorAddStudentForm);
