import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Popconfirm,
  Section,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import { ChangeStatusEnum, ChangeStatusEnumValue } from '@/config';
import { navigateToWithPlatform, usePlatformInfo } from '@/utils';

const CheckButton = styled.div`
  width: 80px;
  height: 30px;
  background: #eaeefd;
  border-radius: 6px;
  border: 1px solid #c9d6ff;
  font-size: 14px;
  font-weight: 400;
  color: #2e5ef2;
  cursor: pointer;
`;

const RejectButton = styled.div`
  width: 80px;
  height: 30px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #dddddd;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
`;

const DefaultText = styled.div`
  width: 9px;
  height: 20px;
  font-size: 14px;
  font-family:
    PingFangSC-Regular,
    PingFang SC;
  font-weight: 400;
  color: #333333;
  line-height: 20px;
`;

const UnConfirmedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #fe9908;
  margin-right: 8px;
  border-radius: 50%;
`;

const ChangeUnAuditedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #ffcf54;
  margin-right: 8px;
  border-radius: 50%;
`;

const CreateUnAuditedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #12bc82;
  margin-right: 8px;
  border-radius: 50%;
`;

const Confirmed = styled.div`
  width: 5px;
  height: 5px;
  background: #aaaaaa;
  margin-right: 8px;
  border-radius: 50%;
`;

const REJECTED = styled.div`
  width: 5px;
  height: 5px;
  background: #ff381e;
  margin-right: 8px;
  border-radius: 50%;
`;

const DepartmentMonitorInfoCheckSchoolTable = (props) => {
  const { taskOrgIdIs, period, grade, monitorTaskStatus, taskIdIs } = props;

  const [platformInfo] = usePlatformInfo();
  const backCurrentRecord = useRef();
  const [refresh, setRefresh] = useState(false);

  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf?.services?.StudentDtoController_pageStudent_2d0072?.({
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList, // 排序结果
            taskOrgIdIs,
            ...params.search, // 搜索结果
            ...params.filters, // 过滤器结果
            ...(params?.extra ?? {}), // 传入的额外参数
            refresh: undefined,
          },
          ext: params?.ext,
        });
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [taskOrgIdIs],
  );

  return (
    <Section className="monitorStatus">
      <VSPromiseTable
        editable={{ editType: 'single', columnProps: { hideInTable: true } }}
        pagination={{
          defaultPageSize: 10,
          showTotal: () => <></>,
        }}
        searchConfig={{
          filterType: 'inline',
          optionButtons: 'search',
          searchText: '查询',
        }}
        extraParams={{ refresh }}
        onFetch={onFetch}
      >
        <VSTableColumn
          width={180}
          ellipsis
          dataIndex={['studentStatusNum']}
          title="学籍辅号"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '学籍辅号长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['name']}
          title="姓名"
          valueType="text"
          search
          order={-29}
          columnKey={'nameLike'}
          formItemProps={{
            label: '学籍号或姓名',
            rules: [],
          }}
          fieldProps={{
            width: 110,
            placeholder: '请输入',
          }}
        />
        <VSTableColumn
          dataIndex={['grade']}
          title="年级"
          valueType="select"
          dataSource={[
            { label: '一年级', value: 'FIRST_GRADE' },
            { label: '二年级', value: 'SECOND_GRADE' },
            { label: '三年级', value: 'THIRD_GRADE' },
            { label: '四年级', value: 'FOURTH_GRADE' },
            { label: '五年级', value: 'FIFTH_GRADE' },
            { label: '六年级', value: 'SIXTH_GRADE' },
            { label: '七年级', value: 'SEVENTH_GRADE' },
            { label: '八年级', value: 'EIGHTH_GRADE' },
            { label: '九年级', value: 'NINE_GRADE' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          width={180}
          dataIndex={['classes']}
          title="班级"
          valueType="text"
          search
          order={-29}
          columnKey={'classesLike'}
          formItemProps={{
            rules: [
              { message: '班级长度不合法', type: 'string', min: 0, max: 64 },
            ],
          }}
          fieldProps={{
            width: 110,
            placeholder: '请输入',
          }}
        />
        <VSTableColumn
          dataIndex={['sex']}
          title="性别"
          valueType="select"
          dataSource={[
            { label: '男', value: 'MAN' },
            { label: '女', value: 'WOMAN' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          width={140}
          dataIndex={['changeStatus']}
          title="状态"
          valueType="select"
          search
          order={-30}
          columnKey={'changeStatusIs'}
          fieldProps={{}}
          dataSource={[
            { label: '已确认', value: 'CONFIRMED' },
            { label: '已拒绝', value: 'REJECTED' },
            { label: '信息变更待审核', value: 'CHANGE_UNAUDITED' },
            { label: '待接收学校审核', value: 'SCHOOL_UNAUDITED' },
            { label: '新增待审核', value: 'CREATE_UNAUDITED' },
            { label: '待确认', value: 'UNCONFIRMED' },
          ]}
          render={(doms, record) => {
            return (
              <div className="aic">
                {record?.changeStatus === ChangeStatusEnum.UNCONFIRMED && (
                  <UnConfirmedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.REJECTED && (
                  <REJECTED />
                )}
                {record?.changeStatus === ChangeStatusEnum.CHANGE_UNAUDITED && (
                  <ChangeUnAuditedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.SCHOOL_UNAUDITED && (
                  <ChangeUnAuditedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.CREATE_UNAUDITED && (
                  <CreateUnAuditedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.CONFIRMED && (
                  <Confirmed />
                )}
                {doms}
              </div>
            );
          }}
        />
        <VSTableColumn title="操作" valueType="option" name={'action'}>
          <VSTableAction
            show={(record) =>
              record?.changeStatus === ChangeStatusEnum.CHANGE_UNAUDITED
            }
            onClick={() => {
              const _query = `period=${period}&grade=${grade}&monitorTaskStatus=${monitorTaskStatus}`;

              navigateToWithPlatform(
                `/${platformInfo?.platformId}/monitor/${taskIdIs}/infoChangeCheck?${_query}`,
              );
            }}
          >
            <CheckButton className="aic jcc">前往审核</CheckButton>
          </VSTableAction>
          <VSTableAction
            show={(record) =>
              record?.changeStatus === ChangeStatusEnum.CONFIRMED
            }
            onClick={(_, record) => {
              backCurrentRecord.current = record;
            }}
          >
            <Popconfirm
              title="确定退回该学生吗？"
              icon=""
              onConfirm={async () => {
                try {
                  const response =
                    await vsf?.services?.StudentBOController_departmentStudentChangeBack_c38732?.(
                      {
                        studentId: backCurrentRecord.current?.id,
                      },
                    );
                  if (response && response.code === 200) {
                    message.success('已退回');
                    setRefresh(!refresh);
                    return true;
                  }
                  return false;
                } catch (error) {
                  console.log(error);
                }
              }}
            >
              <RejectButton className="aic jcc">退回</RejectButton>
            </Popconfirm>
          </VSTableAction>
          <VSTableAction
            show={(record) =>
              record.changeStatus !== ChangeStatusEnum.CONFIRMED &&
              record.changeStatus !== ChangeStatusEnum.CHANGE_UNAUDITED
            }
          >
            <DefaultText>-</DefaultText>
          </VSTableAction>
        </VSTableColumn>
      </VSPromiseTable>
    </Section>
  );
};
export default definePage(DepartmentMonitorInfoCheckSchoolTable);
