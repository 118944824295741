import '@/pages/TreeMenu.less';

import ReferenceGradesTable from '@pages/ReferenceGradesTable';
import vsf, { definePage } from '@vs/vsf-boot';
import { Column, Columns, Layout, Page, Placeholder } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';

import TreeMenu from '@/components/TreeMenu';
import { buildTree } from '@/components/TreeMenu/utils';
import { navigateToWithPlatform } from '@/utils';

const { Sider } = Layout;

const dataRelationFieldNames = {
  id: 'key',
  parentId: 'parentId',
};

const ReferenceGrades = (props) => {
  const { subject } = props;

  const [scoreMenuIdIs, setScoreMenuIdIs] = useState();
  const [treeData, setTreeData] = useState([]);
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState();

  const getDefaultSelectNode = (_treeData) => {
    try {
      const getChildren = (node) => {
        if (node?.children) {
          return getChildren(node?.children?.[0]);
        }
        return node;
      };
      const buildTreeData = buildTree(
        _treeData ?? [],
        null,
        dataRelationFieldNames,
      );
      const firstChildren = getChildren(buildTreeData?.[0]);
      if (firstChildren) {
        // handelTreeSelect(firstChildren);
        return firstChildren;
      }
      return undefined;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  };

  const fetchGetAllScoreMenu = useCallback(async () => {
    try {
      const response =
        await vsf.services?.ScoreMenuDtoController_getAllScoreMenu_ef5851?.({
          status: '0',
        });
      if (response && response.code) {
        const _tempList = (response.data ?? [])?.map((item) => ({
          key: item.id,
          title: item.name,
          parentId: !item.parentId ? null : item.parentId,
        }));
        setTreeData(_tempList);

        const _defaultSelectNode = getDefaultSelectNode(_tempList);
        setDefaultSelectedKeys(_defaultSelectNode);
        setScoreMenuIdIs(_defaultSelectNode.key);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchGetAllScoreMenu();
  }, [fetchGetAllScoreMenu]);

  const renderCustomerTitle = (node) => {
    return (
      <div className="treenode-item-normal aic jcsb">
        <div className="ell" style={{ width: '100%' }}>
          {node.title}
        </div>
      </div>
    );
  };

  const handelTreeSelect = (node) => {
    if (node?.key) {
      setScoreMenuIdIs(node.key);
    }
  };

  return (
    <div
      className="aic"
      style={{
        width: '960px',
        height: 680,
        border: '1px solid #DDDDDD',
        borderRadius: 10,
        overflowY: 'hidden',
      }}
    >
      <Sider
        style={{
          width: 200,
          height: 680,
          flex: 'none',
          marginTop: 15,
          marginLeft: 15,
          borderRight: '1px solid #DDDDDD',
          overflow: 'scroll',
        }}
        className="vsf-layout-sec-sider"
        theme="light"
        collapsible
      >
        {defaultSelectedKeys && (
          <TreeMenu
            treeProps={{
              titleRender: renderCustomerTitle,
              defaultExpandedKeys: defaultSelectedKeys
                ? [defaultSelectedKeys?.key]
                : [],
              defaultSelectedKeys: defaultSelectedKeys
                ? [defaultSelectedKeys?.key]
                : [],
            }}
            search={{
              status: false,
            }}
            dataRelationFieldNames={dataRelationFieldNames}
            treeData={treeData}
            onSelect={handelTreeSelect}
          />
        )}
      </Sider>
      <div
        style={{
          boxSizing: 'border-box',
          paddingLeft: 20,
          alignItems: 'flex-start',
          height: '100%',
        }}
      >
        <ReferenceGradesTable
          scoreMenuIdIs={scoreMenuIdIs}
          subject={subject}
          onUseGrade={props?.onUseGrade}
        />
      </div>
    </div>
  );
};
export default definePage(ReferenceGrades);
