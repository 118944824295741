import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Section,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { SampleStudentEnum, SexEnum } from '@/config';
const ConfirmButton = styled.div`
  width: 80px;
  height: 30px;
  background: #eaeefd;
  border-radius: 6px;
  border: 1px solid #c9d6ff;

  font-size: 14px;
  font-weight: 400;
  color: #2e5ef2;
  cursor: pointer;
`;
const UnConfirmedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #fe9908;
  margin-right: 8px;
  border-radius: 50%;
`;

const UNAUDITEDAic = styled.div`
  width: 5px;
  height: 5px;
  background: #fe9908;
  margin-right: 8px;
  border-radius: 50%;
`;

const PASSEDAic = styled.div`
  width: 5px;
  height: 5px;
  background: #12bc82;
  margin-right: 8px;
  border-radius: 50%;
`;
const REJECTEDdAic = styled.div`
  width: 5px;
  height: 5px;
  background: #ff3322;
  margin-right: 8px;
  border-radius: 50%;
`;

const Confirmed = styled.div`
  width: 5px;
  height: 5px;
  background: #aaaaaa;
  margin-right: 8px;
  border-radius: 50%;
`;

const SchoolMonitorReplacementTable = (props) => {
  const {
    extractRoundIdIs,
    extractRosterIdIn,
    onRefreshStudentNum,
    manDisable,
    woManDisable,
    studentDisable,
    distinguishSex,
  } = props;
  const onFetch = useCallback(
    async (params) => {
      if (extractRoundIdIs) {
        const res =
          await vsf?.services?.ChangeRosterStudentDtoController_pageByChangeRosterStudent_b2657c?.(
            {
              qto: {
                from: params.pagination?.from ?? 0,
                size: params.pagination?.size ?? 20,
                orderList: params.orderList, // 排序结果
                extractRoundIdIs,
                extractRosterIdIn,
                ...params.search, // 搜索结果
                ...params.filters, // 过滤器结果
                ...(params?.extra ?? {}), // 传入的额外参数
              },
              ext: params?.ext,
            },
          );
        return {
          data: res?.data?.result ?? res?.data ?? [],
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
    },
    [extractRosterIdIn, extractRoundIdIs],
  );

  const getDisableStatus = (record) => {
    if (!distinguishSex) {
      return studentDisable;
    }
    if (record?.sex === SexEnum.MAN) {
      return manDisable;
    }
    if (record?.sex === SexEnum.WOMAN) {
      return woManDisable;
    }
  };

  return (
    <div className="monitor__container">
      <Section>
        <VSPromiseTable
          pagination={{
            defaultPageSize: 10,
            showTotal: () => <></>,
          }}
          editable={{ editType: 'single', columnProps: { hideInTable: true } }}
          vsid="60264"
          searchConfig={{
            filterType: 'inline',
            optionButtons: ['search'],
            searchText: '查询',
            collapsed: false,
            collapseRender: () => null,
          }}
          onFetch={onFetch}
        >
          <VSTableColumn
            dataIndex={['studentStatusNum']}
            title="学籍辅号"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '学籍辅号长度不合法',
                  type: 'string',
                  min: 0,
                  max: 64,
                },
              ],
            }}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['name']}
            title="姓名"
            valueType="text"
            search
            order={-29}
            columnKey={'nameLike'}
            formItemProps={{
              rules: [
                { message: '姓名长度不合法', type: 'string', min: 0, max: 64 },
              ],
            }}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['grade']}
            title="年级"
            valueType="select"
            dataSource={[
              { label: '一年级', value: 'FIRST_GRADE' },
              { label: '二年级', value: 'SECOND_GRADE' },
              { label: '三年级', value: 'THIRD_GRADE' },
              { label: '四年级', value: 'FOURTH_GRADE' },
              { label: '五年级', value: 'FIFTH_GRADE' },
              { label: '六年级', value: 'SIXTH_GRADE' },
              { label: '七年级', value: 'SEVENTH_GRADE' },
              { label: '八年级', value: 'EIGHTH_GRADE' },
              { label: '九年级', value: 'NINE_GRADE' },
            ]}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['classes']}
            title="班级"
            valueType="text"
            search
            order={-30}
            columnKey={'classesLike'}
            formItemProps={{
              rules: [
                {
                  message: '班级长度不合法',
                  type: 'string',
                  min: 0,
                  max: 1024,
                },
              ],
            }}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['sex']}
            title="性别"
            valueType="select"
            dataSource={[
              { label: '男', value: 'MAN' },
              { label: '女', value: 'WOMAN' },
            ]}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['status']}
            title="状态"
            valueType="select"
            search
            order={-31}
            columnKey={'statusIs'}
            dataSource={[
              { label: '待确认', value: 'UNCONFIRMED' },
              { label: '已确认', value: 'CONFIRMED' },
              { label: '替换待审核', value: 'UNAUDITED' },
              { label: '审核通过', value: 'PASSED' },
              { label: '审核拒绝', value: 'REJECTED' },
            ]}
            fieldProps={{}}
            render={(doms, record) => {
              return (
                <div className="aic">
                  {record?.status === SampleStudentEnum.UNCONFIRMED && (
                    <UnConfirmedAic />
                  )}
                  {record?.status === SampleStudentEnum.UNAUDITED && (
                    <UNAUDITEDAic />
                  )}
                  {record?.status === SampleStudentEnum.PASSED && <PASSEDAic />}
                  {record?.status === SampleStudentEnum.REJECTED && (
                    <REJECTEDdAic />
                  )}
                  {record?.status === SampleStudentEnum.CONFIRMED && (
                    <Confirmed />
                  )}
                  {doms}
                </div>
              );
            }}
          />
          <VSTableColumn title="操作" name={'action'} valueType="option">
            <VSTableAction
              disabled={getDisableStatus}
              disableLoading
              show={(record) => record?.status === 'UNCONFIRMED'}
              linkPage={{
                updateTable: true,
                updateType: 'reload',
                onLinkPageValidate: async (_, __, record) => {
                  try {
                    const response =
                      await vsf?.services?.SampleStudentController_passChangeRosterStudent_d9bb71?.(
                        {
                          btoParam: {
                            id: record?.id,
                          },
                        },
                      );
                    if (response && response.code === 200) {
                      message.success('确认成功');
                      onRefreshStudentNum?.();
                      return true;
                    }
                    return false;
                  } catch (error) {
                    console.error(error);
                    return false;
                  }
                },
              }}
            >
              {(record) => {
                return (
                  <ConfirmButton
                    className="aic jcc"
                    style={{
                      opacity: getDisableStatus(record) ? 0.5 : 1,
                    }}
                  >
                    确认
                  </ConfirmButton>
                );
              }}
            </VSTableAction>

            <VSTableAction show={(record) => record?.status === 'CONFIRMED'}>
              <span>-</span>
            </VSTableAction>
          </VSTableColumn>
        </VSPromiseTable>
      </Section>
    </div>
  );
};
export default definePage(SchoolMonitorReplacementTable);
