import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Section,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import TableEmptyBg from '@/assets/images/table_empty.png';
import { SampleStudentEnum, SexEnum } from '@/config';

import SchoolMonitorStudentConfirmAskForLeaveForm from './SchoolMonitorStudentConfirmAskForLeaveForm';
import SchoolMonitorStudentConfirmChangeForm from './SchoolMonitorStudentConfirmChangeForm';

const ConfirmButton = styled.div`
  width: 80px;
  height: 30px;
  background: #eaeefd;
  border-radius: 6px;
  border: 1px solid #c9d6ff;
  font-size: 14px;
  font-weight: 400;
  color: #2e5ef2;
  cursor: pointer;
`;

const ChangeButton = styled.div`
  width: 80px;
  height: 30px;
  background: #ffebe7;
  border-radius: 6px;
  border: 1px solid #ffd4cc;

  font-size: 14px;
  font-weight: 400;
  color: #ff3d11;
  cursor: pointer;
`;

const RevertButton = styled.div`
  width: 80px;
  height: 30px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #dddddd;

  font-size: 14px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
`;

const TableEmpty = styled.div`
  width: 250px;
  height: 194px;
`;
const TableEmptyLabel = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #999999;
`;

const UnConfirmedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #fe9908;
  margin-right: 8px;
  border-radius: 50%;
`;

const UNAUDITEDAic = styled.div`
  width: 5px;
  height: 5px;
  background: #fe9908;
  margin-right: 8px;
  border-radius: 50%;
`;

const PASSEDAic = styled.div`
  width: 5px;
  height: 5px;
  background: #12bc82;
  margin-right: 8px;
  border-radius: 50%;
`;
const REJECTEDdAic = styled.div`
  width: 5px;
  height: 5px;
  background: #ff3322;
  margin-right: 8px;
  border-radius: 50%;
`;

const Confirmed = styled.div`
  width: 5px;
  height: 5px;
  background: #aaaaaa;
  margin-right: 8px;
  border-radius: 50%;
`;

const SchoolMonitorStudentConfirmTable = (props) => {
  const {
    extractRosterIdIn,
    extractRoundIdIs,
    pubTime,
    onRefreshStudentNum,
    manDisable,
    woManDisable,
    studentDisable,
    distinguishSex,
    extractType,
  } = props;

  const onFetch = useCallback(
    async (params) => {
      if (extractRosterIdIn?.length && extractRoundIdIs) {
        const res =
          await vsf?.services?.SampleStudentDtoController_pageBySampleStudentSchool_96e1ad?.(
            {
              qto: {
                from: params.pagination?.from ?? 0,
                size: params.pagination?.size ?? 20,
                orderList: params.orderList, // 排序结果
                extractRosterIdIn,
                extractRoundIdIs,
                ...params.search, // 搜索结果
                ...params.filters, // 过滤器结果
                ...(params?.extra ?? {}), // 传入的额外参数
              },
              ext: params?.ext,
            },
          );
        return {
          data: res?.data?.result ?? res?.data ?? [],
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
    },
    [extractRosterIdIn, extractRoundIdIs],
  );

  const getClassesDisableStatus = (record) => {
    return studentDisable;
  };

  const getDisableStatus = (record) => {
    if (!distinguishSex) {
      return studentDisable;
    }
    if (record?.studentId?.sex === SexEnum.MAN) {
      return manDisable;
    }
    if (record?.studentId?.sex === SexEnum.WOMAN) {
      return woManDisable;
    }
  };

  return (
    <div className="monitor__container">
      <Section>
        <VSPromiseTable
          pagination={{
            defaultPageSize: 10,
            showTotal: () => <></>,
          }}
          editable={{ editType: 'single', columnProps: { hideInTable: true } }}
          vsid="09341"
          onFetch={onFetch}
          searchConfig={{
            searchText: '查询',
            optionButtons: ['search'],
            filterType: 'inline',
          }}
          tableRender={(_, defaultDom) => {
            if (dayjs().diff(dayjs(pubTime)) > 0) {
              return defaultDom;
            }
            return (
              <div
                style={{
                  width: '100%',
                  minHeight: 700,
                  background: '#fff',
                  paddingLeft: '20px',
                }}
                className="aic jcc"
              >
                <div className="col aic">
                  <TableEmpty>
                    <img
                      src={TableEmptyBg}
                      style={{ width: '100%', height: '100%' }}
                    />
                  </TableEmpty>
                  {pubTime ? (
                    <TableEmptyLabel>
                      请于 <span style={{ color: '#FF3D11' }}>{pubTime}</span>{' '}
                      查看本次学生名单
                    </TableEmptyLabel>
                  ) : (
                    <TableEmptyLabel>学生名单还未发布</TableEmptyLabel>
                  )}
                </div>
              </div>
            );
          }}
        >
          <VSTableColumn
            dataIndex={['studentId', 'studentStatusNum']}
            title="学籍辅号"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '学籍辅号长度不合法',
                  type: 'string',
                  min: 0,
                  max: 64,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['studentId', 'name']}
            title="姓名"
            valueType="text"
            search
            order={-1}
            columnKey={'nameLike'}
            formItemProps={{
              rules: [
                {
                  message: '姓名长度不合法',
                  type: 'string',
                  min: 0,
                  max: 64,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['studentId', 'grade']}
            title="年级"
            valueType="select"
            dataSource={[
              { label: '一年级', value: 'FIRST_GRADE' },
              { label: '二年级', value: 'SECOND_GRADE' },
              { label: '三年级', value: 'THIRD_GRADE' },
              { label: '四年级', value: 'FOURTH_GRADE' },
              { label: '五年级', value: 'FIFTH_GRADE' },
              { label: '六年级', value: 'SIXTH_GRADE' },
              { label: '七年级', value: 'SEVENTH_GRADE' },
              { label: '八年级', value: 'EIGHTH_GRADE' },
              { label: '九年级', value: 'NINE_GRADE' },
            ]}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['studentId', 'classes']}
            title="班级"
            valueType="text"
            search
            order={-2}
            columnKey={'classesLike'}
            formItemProps={{
              rules: [
                { message: '班级长度不合法', type: 'string', min: 0, max: 64 },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['studentId', 'sex']}
            title="性别"
            valueType="select"
            dataSource={[
              { label: '男', value: 'MAN' },
              { label: '女', value: 'WOMAN' },
            ]}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['status']}
            columnKey={'statusIs'}
            search
            title="状态"
            valueType="select"
            dataSource={
              extractType === 'STUDENT'
                ? [
                    { label: '已确认', value: 'CONFIRMED' },
                    { label: '已拒绝', value: 'REJECTED' },
                    { label: '待确认', value: 'UNCONFIRMED' },
                    { label: '替换待审核', value: 'UNAUDITED' },
                    { label: '已通过', value: 'PASSED' },
                  ]
                : [
                    { label: '已确认', value: 'CONFIRMED' },
                    { label: '待确认', value: 'UNCONFIRMED' },
                    { label: '请假', value: 'ASK_FOR_LEAVE' },
                  ]
            }
            fieldProps={{}}
            render={(doms, record) => {
              return (
                <div className="aic">
                  {(record?.status === SampleStudentEnum.UNCONFIRMED ||
                    record?.status === SampleStudentEnum.ASK_FOR_LEAVE) && (
                    <UnConfirmedAic />
                  )}
                  {record?.status === SampleStudentEnum.UNAUDITED && (
                    <UNAUDITEDAic />
                  )}
                  {record?.status === SampleStudentEnum.PASSED && <PASSEDAic />}
                  {record?.status === SampleStudentEnum.REJECTED && (
                    <REJECTEDdAic />
                  )}
                  {record?.status === SampleStudentEnum.CONFIRMED && (
                    <Confirmed />
                  )}
                  {doms}
                </div>
              );
            }}
          />
          <VSTableColumn
            title="操作"
            name={'action'}
            valueType="option"
            width={200}
          >
            <VSTableAction
              disabled={getDisableStatus}
              disableLoading
              show={(record) => record?.status === 'UNCONFIRMED'}
              linkPage={{
                updateTable: true,
                updateType: 'reload',
                onLinkPageValidate: async (_, __, record) => {
                  try {
                    const response =
                      await vsf?.services?.SampleStudentController_passSampleStudent_bac871?.(
                        {
                          btoParam: {
                            id: record?.id,
                          },
                        },
                      );
                    if (response && response.code === 200) {
                      message.success('确认成功');
                      onRefreshStudentNum?.();
                      return true;
                    }
                    return false;
                  } catch (error) {
                    console.error(error);
                  }
                  return false;
                },
              }}
            >
              {(record) => {
                return (
                  <ConfirmButton
                    className="aic jcc"
                    style={{
                      opacity: getDisableStatus(record) ? 0.5 : 1,
                    }}
                  >
                    确认
                  </ConfirmButton>
                );
              }}
            </VSTableAction>
            <VSTableAction
              disabled={getClassesDisableStatus}
              disableLoading
              show={(record) =>
                extractType === 'CLASSES' && record?.status === 'UNCONFIRMED'
              }
              linkPage={{
                updateTable: true,
                updateType: 'reload',
                modalProps: {
                  width: 500,
                  title: '学生请假申请',
                },
                linkPage: () => <SchoolMonitorStudentConfirmAskForLeaveForm />,
                onLinkPageValidate: async (_, __, record) => {
                  try {
                    const fromParam =
                      await vsf?.refs?.SchoolMonitorStudentConfirmAskForLeaveForm?.validateFieldsReturnFormatValue();
                    const response =
                      await vsf?.services?.SampleStudentController_askForLeaveSchool_24cac5?.(
                        {
                          btoParam: {
                            ...fromParam,
                            id: record?.id,
                          },
                        },
                      );
                    if (response && response.code === 200) {
                      message.success('申请成功');
                      onRefreshStudentNum?.();
                      return true;
                    }
                    return false;
                  } catch (error) {
                    console.error(error);
                    return false;
                  }
                },
              }}
            >
              {(record) => {
                return (
                  <ChangeButton
                    className="aic jcc"
                    style={{
                      opacity: getClassesDisableStatus(record) ? 0.5 : 1,
                    }}
                  >
                    请假
                  </ChangeButton>
                );
              }}
            </VSTableAction>
            <VSTableAction
              disableLoading
              show={(record) =>
                record?.status === 'ASK_FOR_LEAVE' && extractType === 'CLASSES'
              }
              linkPage={{
                updateTable: false,
                modalProps: {
                  width: 500,
                  title: '查看请假原因',
                  hideFooter: true,
                },
                linkPage: () => (
                  <SchoolMonitorStudentConfirmAskForLeaveForm disabledForm />
                ),
              }}
            >
              {(record) => {
                return (
                  <ChangeButton className="aic jcc">请假原因</ChangeButton>
                );
              }}
            </VSTableAction>
            <VSTableAction
              disableLoading
              show={(record) =>
                record?.status === 'ASK_FOR_LEAVE' && extractType === 'CLASSES'
              }
              linkPage={{
                updateTable: true,
                updateType: 'reload',
                onLinkPageValidate: async (_, __, record) => {
                  try {
                    const response =
                      await vsf?.services?.SampleStudentController_revokeAskForLeaveSchool_0ecd96?.(
                        {
                          sampleStudentId: record?.id,
                        },
                      );
                    if (response && response.code === 200) {
                      message.success('撤销成功');
                      onRefreshStudentNum?.();
                      return true;
                    }
                    return false;
                  } catch (error) {
                    console.error(error);
                  }
                },
              }}
            >
              {(record) => {
                return (
                  <RevertButton className="aic jcc">撤销请假</RevertButton>
                );
              }}
            </VSTableAction>
            <VSTableAction
              disabled={getDisableStatus}
              disableLoading
              show={(record) =>
                record?.status === 'UNCONFIRMED' && extractType === 'STUDENT'
              }
              linkPage={{
                updateTable: true,
                updateType: 'reload',
                modalProps: {
                  width: 500,
                  title: '申请替换',
                },
                linkPage: () => <SchoolMonitorStudentConfirmChangeForm />,
                onLinkPageValidate: async (_, __, record) => {
                  try {
                    const fromParam =
                      await vsf?.refs?.SchoolMonitorStudentConfirmChangeForm?.validateFieldsReturnFormatValue();
                    const response =
                      await vsf?.services?.SampleStudentController_applicationChangeSampleStudent_789864?.(
                        {
                          btoParam: {
                            ...fromParam,
                            sampleStudentId: record?.id,
                          },
                        },
                      );
                    if (response && response.code === 200) {
                      message.success('申请成功');
                      onRefreshStudentNum?.();
                      return true;
                    }
                    return false;
                  } catch (error) {
                    console.error(error);
                    return false;
                  }
                },
              }}
            >
              {(record) => {
                return (
                  <ChangeButton
                    style={{
                      opacity: getDisableStatus(record) ? 0.5 : 1,
                    }}
                    className="aic jcc"
                  >
                    申请替换
                  </ChangeButton>
                );
              }}
            </VSTableAction>

            <VSTableAction
              disableLoading
              show={(record) => record?.status === 'UNAUDITED'}
              linkPage={{
                updateTable: true,
                updateType: 'reload',
                onLinkPageValidate: async (_, __, record) => {
                  try {
                    const response =
                      await vsf?.services?.SampleStudentController_revokeChangeSampleStudent_7542d9?.(
                        {
                          sampleStudentId: record?.id,
                        },
                      );
                    if (response && response.code === 200) {
                      message.success('撤销成功');
                      onRefreshStudentNum?.();
                      return true;
                    }
                    return false;
                  } catch (error) {
                    console.error(error);
                  }
                },
              }}
            >
              <RevertButton className="aic jcc">撤销申请</RevertButton>
            </VSTableAction>
            <VSTableAction
              show={(record) =>
                record?.status === 'CONFIRMED' || record?.status === 'PASSED'
              }
            >
              <span>-</span>
            </VSTableAction>
          </VSTableColumn>
        </VSPromiseTable>
      </Section>
    </div>
  );
};
export default definePage(SchoolMonitorStudentConfirmTable);
