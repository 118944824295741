import vsf, { definePage } from '@vs/vsf-boot';
import {
  Modal,
  Section,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { SampleStudentEnum } from '@/config';
const RejectResonView = styled.div`
  width: 452px;
  height: 160px;
  background: #fafafa;
  border-radius: 6px;
  border: 1px solid #eeeeee;
  overflow: scroll;

  font-size: 14px;
  font-weight: 400;
  color: #333333;
  box-sizing: border-box;
  padding: 8px 12px;
`;

const UnConfirmedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #fe9908;
  margin-right: 8px;
  border-radius: 50%;
`;

const UNAUDITEDAic = styled.div`
  width: 5px;
  height: 5px;
  background: #fe9908;
  margin-right: 8px;
  border-radius: 50%;
`;

const PASSEDAic = styled.div`
  width: 5px;
  height: 5px;
  background: #12bc82;
  margin-right: 8px;
  border-radius: 50%;
`;
const REJECTEDdAic = styled.div`
  width: 5px;
  height: 5px;
  background: #ff3322;
  margin-right: 8px;
  border-radius: 50%;
`;

const Confirmed = styled.div`
  width: 5px;
  height: 5px;
  background: #aaaaaa;
  margin-right: 8px;
  border-radius: 50%;
`;

const SchoolMonitorReplacementHistoryTable = (props) => {
  const { extractRoundIdIs, rosterIdIn, taskOrgIdIs } = props;
  const [rejectVisible, setRejectVisible] = useState(false);
  const [rejectReason, setRejectReson] = useState('');

  const onFetch = useCallback(
    async (params) => {
      if (extractRoundIdIs && rosterIdIn && taskOrgIdIs) {
        const res =
          await vsf?.services?.ChangeSampleStudentPageDtoController_pageByChangeSampleStudentSchool_3fda96?.(
            {
              qto: {
                from: params.pagination?.from ?? 0,
                size: params.pagination?.size ?? 20,
                orderList: params.orderList, // 排序结果
                extractRoundIdIs,
                rosterIdIn,
                taskOrgIdIs,
                ...params.search, // 搜索结果
                ...params.filters, // 过滤器结果
                ...(params?.extra ?? {}), // 传入的额外参数
              },
              ext: params?.ext,
            },
          );
        return {
          data: res?.data?.result ?? res?.data ?? [],
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
    },
    [extractRoundIdIs, rosterIdIn, taskOrgIdIs],
  );

  return (
    <div className="monitor__container">
      <Section>
        <VSPromiseTable
          pagination={{
            defaultPageSize: 10,
            showTotal: () => <></>,
          }}
          editable={{ editType: 'single', columnProps: { hideInTable: true } }}
          vsid="69831"
          onFetch={onFetch}
          searchConfig={{
            filterType: 'inline',
            optionButtons: ['search'],
            searchText: '查询',
          }}
        >
          <VSTableColumn
            dataIndex={['sampleStudent', 'studentId', 'studentStatusNum']}
            title="学籍辅号"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '学籍辅号长度不合法',
                  type: 'string',
                  min: 0,
                  max: 64,
                },
              ],
            }}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['sampleStudent', 'studentId', 'name']}
            title="姓名"
            valueType="text"
            search
            order={-20}
            columnKey={'nameLike'}
            formItemProps={{
              rules: [
                {
                  message: '学生姓名长度不合法',
                  type: 'string',
                  min: 0,
                  max: 64,
                },
              ],
            }}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['sampleStudent', 'studentId', 'grade']}
            title="年级"
            valueType="select"
            dataSource={[
              { label: '一年级', value: 'FIRST_GRADE' },
              { label: '二年级', value: 'SECOND_GRADE' },
              { label: '三年级', value: 'THIRD_GRADE' },
              { label: '四年级', value: 'FOURTH_GRADE' },
              { label: '五年级', value: 'FIFTH_GRADE' },
              { label: '六年级', value: 'SIXTH_GRADE' },
              { label: '七年级', value: 'SEVENTH_GRADE' },
              { label: '八年级', value: 'EIGHTH_GRADE' },
              { label: '九年级', value: 'NINE_GRADE' },
            ]}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['sampleStudent', 'studentId', 'classes']}
            title="班级"
            valueType="text"
            search
            order={-21}
            columnKey={'classesLike'}
            formItemProps={{
              rules: [
                { message: '班级长度不合法', type: 'string', min: 0, max: 64 },
              ],
            }}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['sampleStudent', 'studentId', 'sex']}
            title="性别"
            valueType="select"
            dataSource={[
              { label: '男', value: 'MAN' },
              { label: '女', value: 'WOMAN' },
            ]}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['changeStatus']}
            title="状态"
            valueType="select"
            search
            order={-31}
            columnKey={'changeStatusIs'}
            dataSource={[
              { label: '待确认', value: 'UNCONFIRMED' },
              { label: '已确认', value: 'CONFIRMED' },
              { label: '替换待审核', value: 'UNAUDITED' },
              { label: '审核通过', value: 'PASSED' },
              { label: '审核拒绝', value: 'REJECTED' },
            ]}
            fieldProps={{}}
            render={(doms, record) => {
              return (
                <div className="aic">
                  {record?.changeStatus === SampleStudentEnum.UNCONFIRMED && (
                    <UnConfirmedAic />
                  )}
                  {record?.changeStatus === SampleStudentEnum.UNAUDITED && (
                    <UNAUDITEDAic />
                  )}
                  {record?.changeStatus === SampleStudentEnum.PASSED && (
                    <PASSEDAic />
                  )}
                  {record?.changeStatus === SampleStudentEnum.REJECTED && (
                    <REJECTEDdAic />
                  )}
                  {record?.changeStatus === SampleStudentEnum.CONFIRMED && (
                    <Confirmed />
                  )}
                  {doms}
                </div>
              );
            }}
          />
          <VSTableColumn title="拒绝原因" name={'action'} valueType="option">
            <VSTableAction
              show={(record) => record?.changeStatus === 'REJECTED'}
              onClick={(_, record) => {
                setRejectReson(record?.rejectReason);
                setRejectVisible(true);
              }}
            >
              查看
            </VSTableAction>
            <VSTableAction
              show={(record) => record?.changeStatus !== 'REJECTED'}
            >
              <span>-</span>
            </VSTableAction>
          </VSTableColumn>
        </VSPromiseTable>

        <Modal
          open={rejectVisible}
          title="拒绝原因"
          onCancel={() => setRejectVisible(false)}
          footer={false}
        >
          <RejectResonView>{rejectReason ?? ''}</RejectResonView>
        </Modal>
      </Section>
    </div>
  );
};
export default definePage(SchoolMonitorReplacementHistoryTable);
