import 'dayjs/locale/zh-cn';

import vsf, { definePage } from '@vs/vsf-boot';
import {
  DatePicker,
  message,
  Page,
  Section,
  TimePicker,
  VSPromiseTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import { Col, Modal, Row } from 'antd';
import locale from 'antd/es/date-picker/locale/zh_CN';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import SettingIcon from '@/assets/images/icon/setting_orange_icon.png';
import { SubjectEnumValue } from '@/config';
import ChinaNum from '@/pages/RandomSampleList/ChinaNum';
import { navigateToWithPlatform, usePlatformInfo } from '@/utils';

const TabsView = styled.div`
  width: 100%;
  height: 54px;
  border-top: 1px solid #eeeeee;
`;

const TabsViewItem = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-right: 30px;
  position: relative;
  cursor: pointer;
`;

const TabsViewItemLine = styled.div`
  width: 94px;
  height: 6px;
  background: linear-gradient(90deg, #ffc736 0%, #ffffff 100%);
  position: absolute;
  left: 0;
  bottom: 0;
`;

const SettingIconImg = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 6px;
`;
const AddStudentText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #ff3d11;
`;

const CancelText = styled.div`
  width: 29px;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #2e5ef2;
  margin-right: 28px;
  cursor: pointer;
`;

const PublishButton = styled.div`
  width: 84px;
  height: 36px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #2e5ef2;
  font-size: 14px;
  font-weight: 500;
  color: #2e5ef2;
  cursor: pointer;
`;

const AllManageButton = styled.div`
  width: 84px;
  height: 36px;
  background: #2e5ef2;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
`;

const TimeTextView = styled.div`
  width: 87px;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  position: relative;
`;

const TimeTextViewLine = styled.div`
  width: 86px;
  height: 6px;
  background: linear-gradient(90deg, #ffc736 0%, #ffffff 100%);
  position: absolute;
  left: 0;
  bottom: 0;
`;

const ModalItemView = styled.div`
  width: 452px;
  height: 180px;
  background: #fafafa;
  border-radius: 6px;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  padding: 16px;
  margin-bottom: 20px;
`;

const ModalItemViewTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #333333;
`;

const ModalItemViewTitleAic = styled.div`
  width: 6px;
  height: 6px;
  background: #2e5ef2;
  margin-right: 8px;
  border-radius: 50%;
`;

const subjects = [
  { name: '语文', subject: 'CHINESE' },
  { name: '数学', subject: 'MATHEMATICS' },
  { name: '英语', subject: 'ENGLISH' },
  { name: '体育', subject: 'PHYSICAL' },
  { name: '音乐', subject: 'MUSIC' },
  { name: '美术', subject: 'ART' },
  { name: '科学', subject: 'SCIENCE' },
  { name: '社会', subject: 'SOCIOLOGY' },
];

function convertDataStructure(data) {
  const result = [];
  for (const key in data) {
    const innerData = data[key];
    for (const innerKey in innerData) {
      const item = innerData[innerKey];
      result.push({ id: item?.id, extractTotal: item.extractTotal });
    }
  }
  return result;
}

function removeDuplicatesById(data) {
  const resultMap = {};

  for (const item of data) {
    resultMap[item.id] = item;
  }

  return Object.values(resultMap);
}

const DepartmentMonitorSampleConfigTable = (props) => {
  const {
    routes: {
      params: { monitorTaskId },
      query: { period, grade, monitorTaskStatus },
    },
  } = props;
  const [monitorTask, setMonitorTask] = useState();
  const [extractRoundIdIs, setExtractRoundIdIs] = useState();
  const [subject, setSubject] = useState();
  const [isManage, setIsManage] = useState(false);
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [refresh, setRefresh] = useState(false);
  const updateList = useRef([]);
  const listData = useRef();
  const [isPublish, setIsPublish] = useState(false);

  const [publishModalVisible, setPublishModalVisible] = useState(false);

  const [platformInfo] = usePlatformInfo();

  const loadByMonitorTask = useCallback(async () => {
    try {
      const response =
        await vsf?.services?.ExtractConfigDtoController_getByMonitorTaskId_553a8d?.(
          {
            monitorTaskId,
          },
        );
      if (response.data && response.data?.extractRoundDtoList?.[0]) {
        setSubject(
          response.data && response.data?.extractRoundDtoList?.[0]?.subject,
        );
        setExtractRoundIdIs(
          response.data && response.data?.extractRoundDtoList?.[0]?.id,
        );
      }
      setMonitorTask(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [monitorTaskId]);

  useEffect(() => {
    loadByMonitorTask();
  }, [loadByMonitorTask]);

  const fromData = (data) => {
    return data?.map((row) => {
      const { extractRosterOpenDtoList, ...rest } = row;
      const map = extractRosterOpenDtoList?.reduce((map, item) => {
        const { sex, level, ...other } = item;
        const key = sex || 'ALL';
        const sexData = map[key];
        if (monitorTask?.extractType === 'STUDENT') {
          if (sexData) {
            sexData[level] = other;
          } else {
            map[key] = {
              [level]: other,
            };
          }
        } else {
          map[key] = {
            CLASSES: other,
          };
        }
        return map;
      }, {});
      return {
        ...rest,
        ...map,
      };
    });
  };

  const onFetch = useCallback(
    async (params) => {
      if (extractRoundIdIs) {
        const res =
          await vsf?.services?.TaskOrgOpenDtoController_pageTaskExtractRoster_265837?.(
            {
              qto: {
                from: params.pagination?.from ?? 0,
                size: params.pagination?.size ?? 20,
                orderList: params.orderList, // 排序结果
                taskIdIs: monitorTaskId,
                ...params.search, // 搜索结果
                ...params.filters, // 过滤器结果
                ...(params?.extra ?? {}), // 传入的额外参数
                refresh: undefined,
              },
              ext: params?.ext,
            },
          );
        const _data = fromData(res?.data?.result ?? res?.data ?? []);
        listData.current = _data;
        return {
          data: _data,
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [extractRoundIdIs, monitorTaskId],
  );

  const handelExtractRoundClick = (record) => {
    setExtractRoundIdIs(record?.id);
    setSubject(
      monitorTask?.extractRoundDtoList?.find((item) => item.id === record?.id)
        ?.subject,
    );
  };

  const formatRenderArray = (data) => {
    let arr = [];
    if (data) {
      if (subject === 'PHYSICAL') {
        arr = [
          data?.filter((item) => item?.level === 'A'),
          data?.filter((item) => item?.level === 'B'),
          data?.filter((item) => item?.level === 'C'),
        ];
      } else {
        arr = [
          data?.filter((item) => item?.level === 'A'),
          data?.filter((item) => item?.level === 'B'),
          data?.filter((item) => item?.level === 'C'),
          data?.filter((item) => item?.level === 'D'),
        ];
      }
      return arr;
    }
    return [];
  };
  const handelBatchCancel = () => {
    setIsManage(false);
  };

  const handelBatchClick = async () => {
    if (
      monitorTask?.pubStatus !== 'PUBLISHED' &&
      !isPublish &&
      monitorTask?.extractType === 'STUDENT'
    ) {
      if (isManage) {
        try {
          const response =
            await vsf?.services?.ExtractRosterBOController_updateListExtractRoster_37141a?.(
              {
                list: updateList.current,
              },
            );
          if (response && response.code === 200) {
            message.success('调整成功');
            setRefresh(!refresh);
            return true;
          }
          return false;
        } catch (error) {
          console.log(error);
          return false;
        }
      } else {
        setIsManage(true);
      }
    }
  };

  const handelPublishClick = () => {
    if (monitorTask?.pubStatus !== 'PUBLISHED' && !isPublish) {
      setPublishModalVisible(true);
    }
  };

  const handelDateChange = (e, _idx) => {
    if (monitorTask?.extractRoundDtoList?.length > 1) {
      setMonitorTask({
        ...monitorTask,
        extractRoundDtoList: monitorTask?.extractRoundDtoList?.map(
          (item, index) => ({
            ...item,
            pubTimeDate:
              index === _idx
                ? dayjs(e).format('YYYY-MM-DD')
                : item?.pubTimeDate,
          }),
        ),
      });
    } else {
      setDate(dayjs(e).format('YYYY-MM-DD'));
    }
  };
  const handelTimeChange = (e, _idx) => {
    if (monitorTask?.extractRoundDtoList?.length > 1) {
      setMonitorTask({
        ...monitorTask,
        extractRoundDtoList: monitorTask?.extractRoundDtoList?.map(
          (item, index) => ({
            ...item,
            pubTimeTime: index === _idx ? e : item?.pubTimeTime,
          }),
        ),
      });
    } else {
      setTime(e);
    }
  };

  const handelPublishSubmit = async () => {
    try {
      const response =
        await vsf?.services?.ExtractConfigBOController_setPubTimeByExtractRound_3e04f6?.(
          {
            list:
              monitorTask?.extractRoundDtoList?.length > 1
                ? monitorTask?.extractRoundDtoList?.map((item) => ({
                    id: item?.id,
                    pubTime: `${item?.pubTimeDate} ${item?.pubTimeTime}`,
                  }))
                : monitorTask?.extractRoundDtoList?.map((item) => ({
                    id: item?.id,
                    pubTime: `${date} ${time}`,
                  })),
            configId: monitorTask?.id,
          },
        );
      if (response && response.code === 200) {
        message.success('设置成功');
        setIsPublish(true);
        setRefresh(!refresh);
        setPublishModalVisible(false);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  };

  const handelSettingClick = () => {
    const _query = `period=${period}&grade=${grade}&monitorTaskStatus=${monitorTaskStatus}`;
    navigateToWithPlatform(
      `/${platformInfo?.platformId}/monitor/${monitorTaskId}/sampleConfigDetail?${_query}`,
    );
  };

  const fakeItem = (title, children) => {
    return (
      <Row style={{ marginBottom: 24, minHeight: 32 }}>
        <Col span={3} style={{ display: 'flex', justifyContent: 'end' }}>
          {`${title}：`}
        </Col>
        <Col>{children}</Col>
      </Row>
    );
  };

  const handelRowUpdate = async (e) => {
    try {
      if (!isManage) {
        const response =
          await vsf?.services?.ExtractRosterBOController_updateListExtractRoster_37141a?.(
            {
              list: updateList.current,
            },
          );
        if (response && response.code === 200) {
          message.success('调整成功');
          setRefresh(!refresh);
          return e;
        }
        return false;
      }
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  const getClassesColumns = () => {
    return [
      <VSTableColumn
        width={70}
        key={`studentTotal`}
        dataIndex={['ALL', 'CLASSES', 'studentTotal']}
        title={`学生数`}
        valueType="digit"
        fieldProps={{
          min: 0,
        }}
        align="center"
        preview
        className={'man_th'}
      />,
      <VSTableColumn
        width={70}
        key={`extractTotal`}
        dataIndex={['ALL', 'CLASSES', 'extractTotal']}
        title={`抽样数`}
        valueType="digit"
        fieldProps={{
          min: 0,
        }}
        align="center"
        preview
        className={'man_th'}
      />,
      <VSTableColumn
        width={70}
        key={`extractPercentage`}
        dataIndex={['ALL', 'CLASSES', 'extractPercentage']}
        title={`占比`}
        valueType="digit"
        fieldProps={{
          min: 0,
        }}
        align="center"
        preview
        className={'man_th'}
      />,
    ];
  };

  const getDynamicColumns = () => {
    if (!monitorTask) {
      return [];
    }
    const subjectItem = subjects.find((s) => s.subject === subject);
    if (!subjectItem) {
      return [];
    }
    const distinguishSex = monitorTask.distinguishSex;
    const sexes = distinguishSex ? ['MAN', 'WOMAN'] : ['ALL'];
    const levels =
      subjectItem.subject === 'PHYSICAL'
        ? ['A', 'B', 'C']
        : ['A', 'B', 'C', 'D'];

    const sexData = sexes.reduce((data, sex) => {
      const subColumns = levels.reduce((columns, level) => {
        columns.push(
          //TODO: 修正字段为render
          <VSTableColumn
            width={70}
            key={`${sex}-${level}-studentTotal`}
            dataIndex={[sex, level, 'studentTotal']}
            title={`${subjectItem.name}${level}`}
            valueType="digit"
            fieldProps={{
              min: 0,
            }}
            align="center"
            preview
            className={sex === 'MAN' ? 'man_th' : 'woman_th'}
          />,
        );
        columns.push(
          <VSTableColumn
            width={70}
            key={`${sex}-${level}-studentTotal`}
            dataIndex={[sex, level, 'extractTotal']}
            title="抽样数"
            valueType="digit"
            align="center"
            fieldProps={{
              min: 0,
            }}
            className={sex === 'MAN' ? 'man_th' : 'woman_th'}
            render={(dom) => {
              return (
                <div
                  style={{
                    background: sex === 'MAN' ? '#EAEEFD' : '#FFEBE7',
                  }}
                  className="custom_table_cell_background aic jcc"
                >
                  {dom}
                </div>
              );
            }}
          />,
        );
        columns.push(
          <VSTableColumn
            width={70}
            key={`${sex}-${level}-extractPercentage`}
            dataIndex={[sex, level, 'extractPercentage']}
            title="占比"
            valueType="text"
            align="center"
            preview
            className={sex === 'MAN' ? 'man_th' : 'woman_th'}
          />,
        );
        return columns;
      }, []);
      data[sex] = subColumns;
      return data;
    }, {});

    if (distinguishSex) {
      return [
        <VSTableColumn
          width={70}
          title="男生"
          key="MAN"
          preview
          className="man_th"
        >
          {sexData.MAN}
        </VSTableColumn>,
        <VSTableColumn
          width={70}
          title="女生"
          key="WOMAN"
          preview
          className="woman_th"
        >
          {sexData.WOMAN}
        </VSTableColumn>,
      ];
    }
    return sexData.ALL;
  };

  return (
    <Page>
      <Section>
        {/* 保存接口没有调 query-paged-by-task-extract-roster-qto*/}
        <VSPromiseTable
          pagination={{
            defaultPageSize: 10,
            showTotal: () => <></>,
          }}
          className={
            monitorTask?.pubStatus === 'PUBLISHED' || isPublish
              ? 'custom_config_table'
              : ''
          }
          scroll={{
            x: 1200,
          }}
          editable={{
            editType: isManage ? 'multiple' : 'single',
            editText: '调整',
            onCanDelete: () => false,
            columnProps: {
              fixed: 'right',
              width: 80,
              hideInTable:
                isManage ||
                monitorTask?.pubStatus === 'PUBLISHED' ||
                isPublish ||
                monitorTask?.extractType === 'CLASSES',
            },
            onFieldChange: (k, v, r) => {
              const _key = Number(k);
              const currentListData = listData.current?.find(
                (item) => item.id === Number(_key),
              );
              const recordList = convertDataStructure({
                MAN: currentListData?.MAN,
                WOMAN: currentListData?.WOMAN,
                ALL: currentListData?.ALL,
              });
              const _list = convertDataStructure({
                MAN: r?.MAN,
                WOMAN: r?.WOMAN,
                ALL: r?.ALL,
              });
              const _recordList = recordList?.map((item, _idx) => ({
                id: item?.id,
                extractTotal: _list?.[_idx]?.extractTotal,
              }));
              if (isManage) {
                updateList.current = removeDuplicatesById([
                  ...updateList.current,
                  ..._recordList,
                ]);
              } else {
                updateList.current = _recordList;
              }
            },
          }}
          onUpdate={handelRowUpdate}
          vsid="88369"
          extraParams={{ extractRoundIdIs, refresh }}
          onFetch={onFetch}
          searchConfig={{
            filterType: 'inline',
            optionButtons: 'search',
            searchText: '查询',
          }}
          searchFormRender={(_, doms, option) => {
            return (
              <div className="aic" style={{ alignItems: 'flex-start' }}>
                {doms}
                <div className="aic">
                  {isManage ? (
                    <CancelText onClick={handelBatchCancel}>取消</CancelText>
                  ) : (
                    <div
                      className="aic"
                      style={{ width: 90, cursor: 'pointer' }}
                      onClick={handelSettingClick}
                    >
                      <SettingIconImg src={SettingIcon} />
                      <AddStudentText>规则配置</AddStudentText>
                    </div>
                  )}
                  {!isManage && (
                    <PublishButton
                      className="aic jcc"
                      onClick={handelPublishClick}
                      style={{
                        opacity:
                          monitorTask?.pubStatus === 'PUBLISHED' || isPublish
                            ? 0.5
                            : 1,
                      }}
                    >
                      发布名单
                    </PublishButton>
                  )}
                  <span style={{ width: 14 }} />
                  <AllManageButton
                    className="aic jcc"
                    onClick={handelBatchClick}
                    style={{
                      opacity:
                        monitorTask?.pubStatus === 'PUBLISHED' ||
                        isPublish ||
                        monitorTask?.extractType === 'CLASSES'
                          ? 0.5
                          : 1,
                    }}
                  >
                    {isManage ? '保存' : '批量调整'}
                  </AllManageButton>
                </div>
              </div>
            );
          }}
          tableExtraRender={() => {
            return monitorTask?.extractRoundDtoList?.length > 1 ? (
              <TabsView className="aic">
                {monitorTask?.extractRoundDtoList?.map((item, _idx) => (
                  <TabsViewItem
                    key={item.id}
                    className="aic"
                    style={{
                      color:
                        item.id === extractRoundIdIs ? '#2E5EF2' : '#666666',
                    }}
                    onClick={() => handelExtractRoundClick(item)}
                  >
                    <span style={{ position: 'relative', zIndex: 99 }}>
                      第{ChinaNum[_idx]}轮({SubjectEnumValue[item?.subject]})
                    </span>
                    <TabsViewItemLine />
                  </TabsViewItem>
                ))}
              </TabsView>
            ) : null;
          }}
        >
          <VSTableColumn
            width={104}
            dataIndex={['org', 'id']}
            title="学校识别码"
            valueType="digit"
            fieldProps={{}}
            preview
          />
          <VSTableColumn
            width={164}
            dataIndex={['org', 'orgName']}
            title="学校名称"
            valueType="text"
            search
            order={-7}
            columnKey={'orgNameLike'}
            fieldProps={{
              width: 110,
              placeholder: '请输入',
            }}
            hideInTable
          />
          <VSTableColumn
            width={164}
            dataIndex={['org', 'orgName']}
            title="学校名称"
            valueType="text"
            search
            order={-7}
            columnKey={'orgNameLike'}
            fieldProps={{
              width: 110,
              placeholder: '请输入',
            }}
            preview
            hideInSearch
          />
          <VSTableColumn
            width={80}
            dataIndex={['studentTotal']}
            title="学生总数"
            valueType="text"
            order={-7}
            preview
          />
          <VSTableColumn
            width={80}
            dataIndex={['extractTotal']}
            title="总抽样数"
            valueType="text"
            order={-7}
            preview
          />
          <VSTableColumn
            width={80}
            dataIndex={['extractPercentage']}
            title="抽样占比"
            valueType="text"
            order={-7}
            render={(_, data) => {
              return (
                <div style={{ color: data?.studentTotal == 0 ? 'red' : '' }}>
                  {data?.extractPercentage ?? 0}
                </div>
              );
            }}
            preview
          />
          {monitorTask?.extractType === 'STUDENT'
            ? getDynamicColumns()
            : getClassesColumns()}
        </VSPromiseTable>
      </Section>
      <Modal
        open={publishModalVisible}
        width={500}
        title="发布设置"
        onCancel={() => setPublishModalVisible(false)}
        onOk={handelPublishSubmit}
      >
        <TimeTextView>
          <span style={{ position: 'relative', zIndex: 99 }}>设置定时发布</span>
          <TimeTextViewLine />
        </TimeTextView>
        <div style={{ height: 20 }} />
        {monitorTask?.extractRoundDtoList?.length > 1 ? (
          monitorTask?.extractRoundDtoList?.map((item, _idx) => (
            <ModalItemView key={_idx}>
              <ModalItemViewTitle className="aic">
                <ModalItemViewTitleAic />
                <span> 第{ChinaNum[_idx]}轮</span>
              </ModalItemViewTitle>
              <div style={{ height: 15 }} />
              {fakeItem(
                '日期',
                <DatePicker
                  placeholder="请选择发布日期"
                  onChange={(e) => handelDateChange(e, _idx)}
                  locale={locale}
                  width={360}
                />,
              )}
              {fakeItem(
                '时间',
                <TimePicker
                  placeholder="请选择发布时间"
                  onChange={(e) => handelTimeChange(e, _idx)}
                  locale={locale}
                  width={360}
                />,
              )}
            </ModalItemView>
          ))
        ) : (
          <>
            {fakeItem(
              '日期',
              <DatePicker
                placeholder="请选择发布日期"
                onChange={handelDateChange}
                locale={locale}
                width={360}
              />,
            )}
            {fakeItem(
              '时间',
              <TimePicker
                placeholder="请选择发布时间"
                onChange={handelTimeChange}
                locale={locale}
                width={360}
              />,
            )}
          </>
        )}
      </Modal>
    </Page>
  );
};
export default definePage(DepartmentMonitorSampleConfigTable);
