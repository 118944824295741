import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSPromiseTable,
  VSTableAddition,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback } from 'react';
const DepartmentOrgManage = (props) => {
  /**
   * 获取请求参数
   * @param {OrgVoOrganizationInfoEntranceWebVo} org
   * @returns {object}
   */
  const getParam = (org) => {
    if (!org) {
      return;
    }
    return { orgIdIs: org.id };
  };

  const onFetch = useCallback(async (params) => {
    const res = await vsf?.services?.OrgUserDtoController_listOrgUser_dfdf19?.({
      qto: {
        from: params.pagination?.from ?? 0,
        size: params.pagination?.size ?? 20,
        orderList: params.orderList, // 排序结果
        ...params.search, // 搜索结果
        ...params.filters, // 过滤器结果
        ...(params?.extra ?? {}),
      },
    });
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  return (
    <Section title="">
      <VSPromiseTable
        cardProps={{
          bodyStyle: {
            padding: 0,
          },
        }}
        editable={{
          editType: 'single',
          deleteConfirmProps: {
            title: '确定删除吗？',
            icon: <></>,
          },
          onCanEdit: () => false,
          saveText: '确定',
          columnProps: {
            align: 'center',
          },
        }}
        pagination={false}
        onRecord={async () => {
          return {
            id: Math.random(),
            orgId: props.value?.id,
          };
        }}
        onAdd={async (params) => {
          const { id, ...rest } = params;
          const res =
            await vsf?.services?.OrgUserBOController_createOrgUser_c08c43?.({
              btoParam: { ...(rest ?? {}) },
            });
          return res?.data;
        }}
        onFetch={onFetch}
        onUpdate={async (params) => {
          const res =
            await vsf?.services?.OrgUserBOController_updateOrgUser_31ed17?.({
              btoParam: { ...(params ?? {}) },
            });
          return res?.data;
        }}
        onRemove={async (params) => {
          const res =
            await vsf?.services?.OrgUserBOController_deleteOrgUser_afecd0?.({
              btoParam: { ...(params ?? {}) },
            });
          return res?.code === 200;
        }}
        extraParams={getParam(props.value)}
        scroll={{
          y: 500,
        }}
      >
        <VSTableColumn
          dataIndex={['phone']}
          title="手机号"
          align="center"
          valueType="text"
          formItemProps={{
            rules: [
              {
                required: true,
                message: '请输入手机号',
              },
              {
                message: '手机号格式错误',
                type: 'string',
                pattern: /^(?:\+?86)?1[3-9]\d{9}$/,
              },
            ],
          }}
          fieldProps={{
            placeholder: '请输入',
          }}
        />

        <VSTableColumn
          dataIndex={['name']}
          title="姓名"
          align="center"
          valueType="text"
          formItemProps={{
            rules: [
              {
                required: true,
                message: '请输入姓名',
              },
              {
                message: '用户姓名长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{
            placeholder: '请输入',
          }}
        />

        <VSTableAddition children="添加" position="bottom" />
      </VSPromiseTable>
    </Section>
  );
};
export default definePage(DepartmentOrgManage);
