import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { SubjectEnumValue } from '@/config';

import DepartmentMonitorSampleStudentReplacementTable from './DepartmentMonitorSampleStudentReplacementTable';
import DepartmentMonitorSampleStudentTable from './DepartmentMonitorSampleStudentTable';
import ChinaNum from './RandomSampleList/ChinaNum';

const SubjectTabsView = styled.div`
  width: 100%;
  height: 56px;
  border-bottom: 1px solid #eeeeee;
  flex: none;
`;

const SubjectTabsViewItem = styled.div`
  min-width: 94px;
  height: 22px;
  font-size: 16px;
  font-weight: bold;
  color: #2e5ef2;
  position: relative;
  cursor: pointer;
  z-index: 99;
  margin-right: 30px;
`;

const SubjectTabsViewItemLine = styled.div`
  width: 94px;
  height: 6px;
  background: linear-gradient(90deg, #ffc736 0%, #ffffff 100%);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
`;

const DepartmentMonitorSampleStudent = (props) => {
  const {
    routes: {
      params: { taskIdIs },
      query: { period, grade, monitorTaskStatus },
    },
  } = props;

  const [monitorTask, setMonitorTask] = useState();
  const [activeId, setActiveId] = useState();

  const loadMonitorTask = useCallback(async () => {
    try {
      const response =
        await vsf?.services?.ExtractConfigDtoController_getByMonitorTaskId_553a8d?.(
          {
            monitorTaskId: taskIdIs,
          },
        );
      if (response.data) {
        setMonitorTask(response.data);
        setActiveId(response.data?.extractRoundDtoList?.[0]?.id);
      }
    } catch (error) {
      console.error(error);
    }
  }, [taskIdIs]);

  useEffect(() => {
    loadMonitorTask();
  }, [loadMonitorTask]);

  return (
    <Page>
      {monitorTask?.extractRoundDtoList && (
        <SubjectTabsView className="aic">
          {monitorTask?.extractRoundDtoList?.map((item, _idx) => (
            <SubjectTabsViewItem
              key={item.id}
              style={{
                color: item?.id === activeId ? '#2E5EF2' : '#666666',
              }}
              onClick={() => setActiveId(item?.id)}
            >
              <span>
                第{ChinaNum[_idx]}轮（{SubjectEnumValue[item?.subject]}）
              </span>
              {item?.id === activeId && <SubjectTabsViewItemLine />}
            </SubjectTabsViewItem>
          ))}
        </SubjectTabsView>
      )}
      <DepartmentMonitorSampleStudentTable
        monitorTask={monitorTask}
        taskIdIs={taskIdIs}
        extractRoundIdIs={activeId}
        monitorTaskStatus={monitorTaskStatus}
        grade={grade}
        period={period}
      />
    </Page>
  );
};

export default definePage(DepartmentMonitorSampleStudent);
