import vsf, { definePage } from '@vs/vsf-boot';
import { Section, VSForm, VSFormItem } from '@vs/vsf-kit';
import React from 'react';
const DepartmentOrgEdit = (props) => {
  const fromData = (data) => {
    if (!data) {
      return data;
    }
    const { county, city, province, ...rest } = data;
    return { ...rest, address: { province, city, district: county } };
  };

  const toData = (data) => {
    if (!data) {
      return data;
    }
    const { address, ...rest } = data;
    return {
      ...rest,
      city: address?.city,
      province: address?.province,
      county: address?.district,
    };
  };

  return (
    <Section title="">
      <VSForm
        layout="horizontal"
        id="DepartmentOrgForm"
        initialValues={fromData(props.value)}
        onChange={(_value) => {
          props.onChange?.(toData(_value));
        }}
        labelCol={{
          span: 5,
        }}
      >
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
          fieldProps={{}}
        />

        <VSFormItem
          name={['orgName']}
          label="机构名称"
          valueType="text"
          required={false}
          rules={[
            {
              required: true,
              message: '请输入机构名称',
            },
            { message: '机构名称长度不合法', type: 'string', min: 0, max: 64 },
          ]}
          fieldProps={{
            placeholder: '请输入机构名称',
          }}
        />

        <VSFormItem
          name={['schoolId']}
          label="schoolId"
          valueType="text"
          required={false}
          rules={[
            {
              required: true,
              message: '请输入schoolId',
            },
          ]}
          fieldProps={{
            placeholder: '请输入schoolId',
          }}
        />

        <VSFormItem
          name={['orgPeriodDtoList']}
          label="学段"
          valueType="checkbox"
          required={false}
          rules={[
            {
              required: true,
              message: '请选择学段',
            },
          ]}
          fieldProps={{
            dataSource: [
              { period: 'PRIMARY_SCHOOL', name: '小学' },
              { period: 'MIDDLE_SCHOOL', name: '初中' },
            ],
            fieldNames: { value: 'period', label: 'name' },
          }}
        />

        <VSFormItem
          name={['address']}
          label="行政区域"
          valueType="address"
          required={false}
          rules={[
            {
              required: false,
              message: '请选择行政区域',
            },
            {
              validator: (_, value) => {
                return new Promise((resolve, reject) => {
                  if (value?.city && value?.province && value?.district) {
                    resolve();
                  } else {
                    reject();
                  }
                });
              },
              message: '请选择行政区域',
            },
          ]}
          fieldProps={{
            placeholder: {
              cascader: '请选择行政区域',
            },
          }}
        />

        <VSFormItem
          name={['orgLevel']}
          label="机构层级"
          valueType="select"
          required={false}
          rules={[
            {
              required: true,
              message: '请选择机构层级',
            },
          ]}
          dataSource={[
            { label: '集团校', value: 'GROUP_SCHOOL' },
            { label: '主校', value: 'MAIN_CAMPUS' },
            { label: '分校', value: 'BRANCH_CAMPUS' },
            { label: '省级', value: 'PROVINCE_SCHOOL' },
            { label: '市级', value: 'CITY_SCHOOL' },
            { label: '区级', value: 'COUNTY_SCHOOL' },
          ]}
          fieldProps={{
            placeholder: '请选择机构层级',
          }}
        />
      </VSForm>
    </Section>
  );
};
export default definePage(DepartmentOrgEdit);
