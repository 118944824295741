import { createServices } from '@vs/vsf-boot';
export default createServices({
  ScoreMenuBOController_createScoreMenu_54937b: {
    method: 'post',
    url: '/api/score-info/create-score-menu',
    parameterFomatter: (data?: {
      btoParam: UpdateScoreMenuBtoScoreInfoServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: ScoreMenuVoScoreInfoEntranceWebVo) =>
      data,
  },
  ScoreTaskBOController_createScoreTask_919277: {
    method: 'post',
    url: '/api/score-info/create-score-task',
    parameterFomatter: (data?: {
      btoParam: CreateScoreTaskBtoScoreInfoServiceBto;
      list: ScoreInfoEoScoreInfoPersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: ScoreTaskVoScoreInfoEntranceWebVo) =>
      data,
  },
  ScoreTaskBOController_deleteScoreTask_db388b: {
    method: 'post',
    url: '/api/score-info/delete-score-task',
    parameterFomatter: (data?: {
      btoParam: DeleteScoreTaskBtoScoreInfoServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  ScoreMenuDtoController_getByParentId_edf522: {
    method: 'post',
    url: '/api/score-info/get-by-parent-id',
    parameterFomatter: (data?: { parentId: number }) => data,
    responseFormatter: (_, __, data?: ScoreMenuVoScoreInfoEntranceWebVo[]) =>
      data,
  },
  ScoreAuditPubDtoController_getScoreAudit_fe74f7: {
    method: 'post',
    url: '/api/score-info/get-score-audit',
    parameterFomatter: (data?: { scoreTaskId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: ScoreAuditPubVoScoreInfoEntranceWebVo[],
    ) => data,
  },
  ScoreAuditDtoController_getScoreAuditById_1bfe6b: {
    method: 'post',
    url: '/api/score-info/get-score-audit-by-id',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (_, __, data?: ScoreAuditVoScoreInfoEntranceWebVo) =>
      data,
  },
  ScoreInfoPubDtoController_pageOrgScoreInfo_a0e81c: {
    method: 'post',
    url: '/api/score-info/page-org-score-info',
    parameterFomatter: (data?: {
      qto: OrgScoreInfoQtoScoreInfoPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ScoreInfoPubDtoScoreInfoManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ScoreInfoPubDtoController_pageScoreInfo_a2ea04: {
    method: 'post',
    url: '/api/score-info/page-score-info',
    parameterFomatter: (data?: {
      qto: ScoreInfoQtoScoreInfoPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ScoreInfoPubDtoScoreInfoManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ScoreAuditSchoolDtoController_pageScoreAuditSchool_a1d7d6: {
    method: 'post',
    url: '/api/score-info/page-score-audit-school',
    parameterFomatter: (data?: {
      qto: ScoreAuditSchoolQtoScoreInfoPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ScoreAuditSchoolDtoScoreInfoManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ScoreTaskDtoController_pageScoreTask_09fa6d: {
    method: 'post',
    url: '/api/score-info/page-score-task',
    parameterFomatter: (data?: { qto: ScoreTaskQtoScoreInfoPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ScoreTaskDtoScoreInfoManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ScoreAuditBOController_updateScoreAudit_23e3e8: {
    method: 'post',
    url: '/api/score-info/update-score-audit',
    parameterFomatter: (data?: {
      btoParam: UpdateScoreAuditBtoScoreInfoServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: ScoreAuditPubVoScoreInfoEntranceWebVo) =>
      data,
  },
  ScoreMenuDtoController_getAllScoreMenu_ef5851: {
    method: 'post',
    url: '/api/score-info/get-all-score-menu',
    parameterFomatter: (data?: { status: string }) => data,
    responseFormatter: (_, __, data?: ScoreMenuVoScoreInfoEntranceWebVo[]) =>
      data,
  },
  ScoreAuditBOController_revokeScoreAudit_396d2f: {
    method: 'post',
    url: '/api/score-info/revoke-score-audit',
    parameterFomatter: (data?: {
      btoParam: UpdateScoreAuditBtoScoreInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ScoreAuditSchoolVoScoreInfoEntranceWebVo,
    ) => data,
  },
  ScoreInfoPubDtoController_getScoreInfoPub_702bf9: {
    method: 'post',
    url: '/api/score-info/get-score-info-pub',
    parameterFomatter: (data?: { scoreTaskIdI: number }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  ScoreTaskDtoController_pageScoreTaskBySubject_835f98: {
    method: 'post',
    url: '/api/score-info/page-score-task-by-subject',
    parameterFomatter: (data?: {
      qto: ScoreTaskSubjectQtoScoreInfoPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ScoreTaskVoScoreInfoEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ScoreMenuBOController_updateScoreMenu_715c89: {
    method: 'post',
    url: '/api/score-info/update-score-menu',
    parameterFomatter: (data?: {
      btoParam: UpdateScoreMenuBtoScoreInfoServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: ScoreMenuVoScoreInfoEntranceWebVo) =>
      data,
  },
  ScoreMenuBOController_deleteScoreMenus_39277f: {
    method: 'post',
    url: '/api/score-info/delete-score-menus',
    parameterFomatter: (data?: {
      btoParam: DeleteScoreMenuBtoScoreInfoServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ScoreMenuVoScoreInfoEntranceWebVo) =>
      data,
  },
  ScoreInfoBOController_dupSubmitScoreInfo_e5b03a: {
    method: 'post',
    url: '/api/score_info-entrance-web/dup-submit-score-info',
    parameterFomatter: (data?: {
      list: PhysicalScoreInfoEoScoreInfoPersistEo[];
      scoreTaskId: number;
      orgId: number;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ScoreInfoBOController_excelScoreInfo_3b823f: {
    method: 'post',
    url: '/api/score_info-entrance-web/excelScoreInfo',
    parameterFomatter: (data?: {
      file: MultipartFile;
      scoretaskId: number;
      isP_e: boolean;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ScoreInfoBOController_submitScoreInfo_c2f086: {
    method: 'post',
    url: '/api/score_info-entrance-web/submit-score-info',
    parameterFomatter: (data?: {
      list: PhysicalScoreInfoEoScoreInfoPersistEo[];
      scoreTaskId: number;
      orgId: number;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ChangeSampleStudentPageDtoController_pageByChangeSampleStudentSchool_3fda96: {
    method: 'post',
    url: '/api/sample-student/page-by-change-sample-student-school',
    parameterFomatter: (data?: {
      qto: ChangeSampleStudentSchoolQtoSampleStudentPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ChangeSampleStudentPageVoSampleStudentEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ChangeStatisticsStudentDtoController_pageByChangeStatisticsStudent_dc46d0: {
    method: 'post',
    url: '/api/sample-student/page-by-change-statistics-student',
    parameterFomatter: (data?: {
      qto: ChangeStatisticsStudentQtoSampleStudentPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ChangeStatisticsStudentVoSampleStudentEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  SampleStudentDtoController_pageBySampleStudentSchool_96e1ad: {
    method: 'post',
    url: '/api/sample-student/page-by-sample-student-school',
    parameterFomatter: (data?: {
      qto: SampleStudentSchoolQtoSampleStudentPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: SampleStudentVoSampleStudentEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  SampleStudentController_passSampleStudent_bac871: {
    method: 'post',
    url: '/api/sample-student/pass-sample-student',
    parameterFomatter: (data?: {
      btoParam: UpdateSampleStudentBtoSampleStudentServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: SampleStudentVoSampleStudentEntranceWebVo,
    ) => data,
  },
  SampleStudentController_applicationChangeSampleStudent_789864: {
    method: 'post',
    url: '/api/sample-student/application-change-sample-student',
    parameterFomatter: (data?: {
      btoParam: CreateChangeSampleStudentBtoSampleStudentServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: SampleStudentVoSampleStudentEntranceWebVo,
    ) => data,
  },
  ChangeRosterStudentDtoController_pageByChangeRosterStudent_b2657c: {
    method: 'post',
    url: '/api/sample-student/page-by-change-roster-student',
    parameterFomatter: (data?: {
      qto: ChangeRosterStudentQtoSampleStudentPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ChangeRosterStudentVoSampleStudentEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  SampleStudentController_revokeChangeSampleStudent_7542d9: {
    method: 'post',
    url: '/api/sample-student/revoke-change-sample-student',
    parameterFomatter: (data?: { sampleStudentId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: SampleStudentVoSampleStudentEntranceWebVo,
    ) => data,
  },
  ChangeSampleStudentPageDtoController_pageByChangeSampleStudent_f7c5b9: {
    method: 'post',
    url: '/api/sample-student/page-by-change-sample-student',
    parameterFomatter: (data?: {
      qto: ChangeSampleStudentPageQtoSampleStudentPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: ChangeSampleStudentPageVoSampleStudentEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  SampleStudentController_passChangeSampleStudent_1b05a8: {
    method: 'post',
    url: '/api/sample-student/pass-change-sample-student',
    parameterFomatter: (data?: {
      btoParam: UpdateChangeSampleStudentBtoSampleStudentServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChangeSampleStudentPageVoSampleStudentEntranceWebVo,
    ) => data,
  },
  SampleStudentController_rejectChangeSampleStudent_949cad: {
    method: 'post',
    url: '/api/sample-student/reject-change-sample-student',
    parameterFomatter: (data?: {
      btoParam: UpdateChangeSampleStudentBtoSampleStudentServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChangeSampleStudentPageVoSampleStudentEntranceWebVo,
    ) => data,
  },
  SampleStudentDtoController_pageBySampleStudent_5aa199: {
    method: 'post',
    url: '/api/sample-student/page-by-sample-student',
    parameterFomatter: (data?: {
      qto: SampleStudentQtoSampleStudentPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: SampleStudentVoSampleStudentEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ChangeStatisticsStudentDtoController_customGrantNum_5728cb: {
    method: 'post',
    url: '/api/sample-student/custom-grant-num',
    parameterFomatter: (data?: {
      btoParam: UpdateChangeStatisticsStudentBtoSampleStudentServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChangeStatisticsStudentVoSampleStudentEntranceWebVo[],
    ) => data,
  },
  ChangeStatisticsStudentDtoController_grantChangeStudent_96d88f: {
    method: 'post',
    url: '/api/sample-student/grant-change-student',
    parameterFomatter: (data?: { configId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  ChangeStatisticsStudentDtoController_setGrantNum_2547c3: {
    method: 'post',
    url: '/api/sample-student/set-grant-num',
    parameterFomatter: (data?: {
      byEnum: GrantByEnum;
      configId: number;
      methodEnum: MethodEnum;
      count: number;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  SampleStudentController_passChangeRosterStudent_d9bb71: {
    method: 'post',
    url: '/api/sample-student/pass-change-roster-student',
    parameterFomatter: (data?: {
      btoParam: UpdateChangeRosterStudentBtoSampleStudentServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ChangeRosterStudentVoSampleStudentEntranceWebVo,
    ) => data,
  },
  ChangeStatisticsStudentDtoController_grantExtraRoster_9fdb15: {
    method: 'post',
    url: '/api/sample-student/grant-extra-roster',
    parameterFomatter: (data?: {
      rosterId: number;
      configId: number;
      count: number;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  StudentChangeAuditDtoController_pageByStudentChangeRecordSchool_8593fb: {
    method: 'post',
    url: '/api/monitor-student/page-by-student-change-record-school',
    parameterFomatter: (data?: {
      qto: StudentChangeRecordSchoolQtoMonitorStudentPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StudentChangeAuditVoMonitorStudentEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  StudentChangeAuditDtoController_pageByStudentChangeAuditSchool_106733: {
    method: 'post',
    url: '/api/monitor-student/page-student-change-audit-school',
    parameterFomatter: (data?: {
      qto: StudentChangeAuditSchoolQtoMonitorStudentPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StudentChangeAuditVoMonitorStudentEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  StudentDtoController_pageByStudentSchool_2f24a8: {
    method: 'post',
    url: '/api/monitor-student/page-by-student-school',
    parameterFomatter: (data?: {
      qto: StudentSchoolQtoMonitorStudentPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StudentVoMonitorStudentEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  StudentChangeAuditBOController_applicationStudentChangeAudit_8f3c87: {
    method: 'post',
    url: '/api/monitor-student/application-student-change-audit',
    parameterFomatter: (data?: {
      btoParam: CreateStudentChangeAuditBtoMonitorStudentServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StudentChangeAuditVoMonitorStudentEntranceWebVo,
    ) => data,
  },
  StudentChangeAuditBOController_deleteStudentAudit_dfd4d9: {
    method: 'post',
    url: '/api/monitor-student/delete-student-audit',
    parameterFomatter: (data?: {
      btoParam: CreateStudentChangeAuditBtoMonitorStudentServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StudentChangeAuditVoMonitorStudentEntranceWebVo,
    ) => data,
  },
  TaskOrgDtoController_queryAllByTaskOrgList_788d4b: {
    method: 'post',
    url: '/api/monitor-student/query-all-by-task-org-list',
    parameterFomatter: (data?: {
      qto: TaskOrgListQtoSimpleMonitorStudentPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: TaskOrgDtoMonitorStudentManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  TaskOrgDtoController_pageTaskOrg_b053ba: {
    method: 'post',
    url: '/api/monitor-student/page-task-org',
    parameterFomatter: (data?: {
      qto: TaskOrgQtoMonitorStudentPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: TaskOrgDtoMonitorStudentManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  StudentDtoController_pageStudent_2d0072: {
    method: 'post',
    url: '/api/monitor-student/page-student',
    parameterFomatter: (data?: {
      qto: StudentQtoMonitorStudentPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StudentVoMonitorStudentEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  TaskOrgBOController_deleteTaskOrg_9794a3: {
    method: 'post',
    url: '/api/monitor-student/delete-task-org',
    parameterFomatter: (data?: {
      btoParam: DeleteTaskOrgBtoMonitorStudentServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  TaskOrgBOController_pubListTaskOrg_7bb6ee: {
    method: 'post',
    url: '/api/monitor-student/pub-list-task-org',
    parameterFomatter: (data?: { taskId: number; orgIds: number[] }) => data,
    responseFormatter: (_, __, data?: TaskOrgVoMonitorStudentEntranceWebVo[]) =>
      data,
  },
  TaskOrgBOController_deleteListTaskOrg_d81010: {
    method: 'post',
    url: '/api/monitor-student/delete-list-task-org',
    parameterFomatter: (data?: {
      btoParam: DeleteTaskOrgBtoMonitorStudentServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  TaskOrgDtoController_getUnpulished_09fe37: {
    method: 'post',
    url: '/api/monitor-student/get-unpulished',
    parameterFomatter: (data?: { taskId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  StudentChangeAuditDtoController_pageByStudentChangeAudit_982cf0: {
    method: 'post',
    url: '/api/monitor-student/page-student-change-audit',
    parameterFomatter: (data?: {
      qto: StudentChangeAuditQtoMonitorStudentPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: StudentChangeAuditVoMonitorStudentEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  StudentBOController_passStudent_14b964: {
    method: 'post',
    url: '/api/monitor-student/pass-student',
    parameterFomatter: (data?: {
      btoParam: UpdateStudentBtoMonitorStudentServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: StudentVoMonitorStudentEntranceWebVo) =>
      data,
  },
  StudentBOController_passStudentList_2d818c: {
    method: 'post',
    url: '/api/monitor-student/pass-student-list',
    parameterFomatter: (data?: {
      btoParam: UpdateStudentBtoMonitorStudentServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: StudentVoMonitorStudentEntranceWebVo[]) =>
      data,
  },
  StudentChangeAuditBOController_passStudentChangeAudit_8b3b8b: {
    method: 'post',
    url: '/api/monitor-student/pass-student-change-audit',
    parameterFomatter: (data?: {
      btoParam: RejectOrPassStudentChangeAuditBtoMonitorStudentServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StudentChangeAuditVoMonitorStudentEntranceWebVo,
    ) => data,
  },
  StudentChangeAuditBOController_passStudentChangeAuditBySchool_260cc7: {
    method: 'post',
    url: '/api/monitor-student/pass-student-change-audit-by-school',
    parameterFomatter: (data?: {
      btoParam: RejectOrPassStudentChangeAuditBtoMonitorStudentServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StudentChangeAuditVoMonitorStudentEntranceWebVo,
    ) => data,
  },
  StudentChangeAuditBOController_createStudentChangeAudit_f507e3: {
    method: 'post',
    url: '/api/monitor-student/create-student-change-audit',
    parameterFomatter: (data?: {
      btoParam: CreateStudentChangeAuditBtoMonitorStudentServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StudentChangeAuditVoMonitorStudentEntranceWebVo,
    ) => data,
  },
  TaskOrgBOController_pubAllTaskOrg_ed08fd: {
    method: 'post',
    url: '/api/monitor-student/pub-all-task-org',
    parameterFomatter: (data?: { taskId: number }) => data,
    responseFormatter: (_, __, data?: TaskOrgVoMonitorStudentEntranceWebVo[]) =>
      data,
  },
  StudentBOController_createAllStudent_b77e74: {
    method: 'post',
    url: '/api/monitor-student/create-all-student',
    parameterFomatter: (data?: {
      studentEos: StudentEoMonitorStudentPersistEo[];
      taskId: number;
      period: PeriodEnum;
      grade: GradeEnum;
    }) => data,
    responseFormatter: (_, __, data?: StudentVoMonitorStudentEntranceWebVo[]) =>
      data,
  },
  StudentChangeAuditBOController_applicationStudentChangeAuditBatch_081337: {
    method: 'post',
    url: '/api/monitor-student/application-student-change-audit-batch',
    parameterFomatter: (data?: {
      list: CreateStudentChangeAuditBtoMonitorStudentServiceBto[];
      extApiParam: {
        taskOrgId: number;
        orgId: number;
        studentStatusNum: string;
        name: string;
        grade: GradeEnum;
        classes: string;
        sex: SexEnum;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StudentChangeAuditVoMonitorStudentEntranceWebVo[],
    ) => data,
  },
  StudentBOController_passAllStudent_da3f03: {
    method: 'post',
    url: '/api/monitor-student/pass-all-student',
    parameterFomatter: (data?: { taskId: number; orgId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  StudentChangeAuditBOController_rejectStudentChangeAuditBySchool_3702e4: {
    method: 'post',
    url: '/api/monitor-student/reject-student-change-audit-by-school',
    parameterFomatter: (data?: {
      btoParam: RejectOrPassStudentChangeAuditBtoMonitorStudentServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StudentChangeAuditVoMonitorStudentEntranceWebVo,
    ) => data,
  },
  StudentChangeAuditBOController_revokeAuditSchool_aac950: {
    method: 'post',
    url: '/api/monitor-student/revoke-audit-school',
    parameterFomatter: (data?: {
      studentChangeAuditId: number;
      orgId: number;
    }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  StudentBOController_createStudent_1807ce: {
    method: 'post',
    url: '/api/monitor-student/create-student',
    parameterFomatter: (data?: {
      bto: CreateStudentEoMonitorStudentPersistEo;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: StudentVoMonitorStudentEntranceWebVo) =>
      data,
  },
  MonitorStudentController_pageMonitorTaskSchool_aebbfa: {
    method: 'post',
    url: '/api/monitor-student/page-monitor-task-school',
    parameterFomatter: (data?: {
      qto: TaskOrgSchoolQtoMonitorStudentPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: TaskOrgSchoolVoMonitorStudentEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  StudentChangeAuditBOController_revokeAudit_352013: {
    method: 'post',
    url: '/api/monitor-student/revoke-audit',
    parameterFomatter: (data?: { studentChangeAuditId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  StudentChangeAuditBOController_revokeApplication_ebd66d: {
    method: 'post',
    url: '/api/monitor-student/revoke-application',
    parameterFomatter: (data?: { studentId: number }) => data,
    responseFormatter: (_, __, data?: boolean) => data,
  },
  StudentChangeAuditBOController_deleteStudentAuditBatch_3384bc: {
    method: 'post',
    url: '/api/monitor-student/delete-student-audit-batch',
    parameterFomatter: (data?: {
      list: CreateStudentChangeAuditBtoMonitorStudentServiceBto[];
      extApiParam: {
        taskOrgId: number;
        orgId: number;
        studentStatusNum: string;
        name: string;
        grade: GradeEnum;
        classes: string;
        sex: SexEnum;
      };
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StudentChangeAuditVoMonitorStudentEntranceWebVo[],
    ) => data,
  },
  TaskOrgOpenDtoController_pageTaskExtractRoster_265837: {
    method: 'post',
    url: '/api/monitor-student/query-paged-by-task-extract-roster-qto',
    parameterFomatter: (data?: {
      qto: TaskExtractRosterQtoMonitorStudentPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: TaskOrgOpenVoMonitorStudentEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  StudentBOController_departmentStudentChangeBack_c38732: {
    method: 'post',
    url: '/api/monitor-student/department-student-change-back',
    parameterFomatter: (data?: { studentId: number }) => data,
    responseFormatter: (_, __, data?: StudentVoMonitorStudentEntranceWebVo) =>
      data,
  },
  StudentChangeAuditBOController_passStudentChangeAuditBatch_5eb7aa: {
    method: 'post',
    url: '/api/monitor-student/pass-student-change-audit-batch',
    parameterFomatter: (data?: {
      list: RejectOrPassStudentChangeAuditBtoMonitorStudentServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StudentChangeAuditVoMonitorStudentEntranceWebVo[],
    ) => data,
  },
  StudentChangeAuditBOController_rejectStudentChangeAuditBatch_f4dc2e: {
    method: 'post',
    url: '/api/monitor-student/reject-student-change-audit-batch',
    parameterFomatter: (data?: {
      list: RejectOrPassStudentChangeAuditBtoMonitorStudentServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StudentChangeAuditVoMonitorStudentEntranceWebVo[],
    ) => data,
  },
  StudentChangeAuditBOController_rejectStudentChangeAuditBySchoolBatch_b6acd1: {
    method: 'post',
    url: '/api/monitor-student/reject-student-change-audit-by-school-batch',
    parameterFomatter: (data?: {
      list: RejectOrPassStudentChangeAuditBtoMonitorStudentServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StudentChangeAuditVoMonitorStudentEntranceWebVo[],
    ) => data,
  },
  StudentChangeAuditBOController_passStudentChangeAuditBySchoolBatch_a5eb7e: {
    method: 'post',
    url: '/api/monitor-student/pass-student-change-audit-by-school-batch',
    parameterFomatter: (data?: {
      list: RejectOrPassStudentChangeAuditBtoMonitorStudentServiceBto[];
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: StudentChangeAuditVoMonitorStudentEntranceWebVo[],
    ) => data,
  },
  TaskOrgOpenDtoController_getSampleSchool_20bd00: {
    method: 'post',
    url: '/api/monitor-student/get-sample-school',
    parameterFomatter: (data?: {
      taskExtractRosterOssQtoSimple: TaskExtractRosterOssQtoSimpleMonitorStudentPersistQto;
    }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  OrgUserDtoController_pageOrgUser_83b9c0: {
    method: 'post',
    url: '/api/organization_info-entrance-web/pageOrgUser',
    parameterFomatter: (data?: {
      qto: PageOrgUserQtoOrganizationInfoPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OrgUserVoOrganizationInfoEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrgDtoController_pageOrg_b9b838: {
    method: 'post',
    url: '/api/organization_info-entrance-web/pageOrg',
    parameterFomatter: (data?: { qto: PageOrgQtoOrganizationInfoPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OrgVoOrganizationInfoEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrgBOController_excelOrg_ad492b: {
    method: 'post',
    url: '/api/organization_info-entrance-web/excelOrg',
    parameterFomatter: (data?: {
      orgEoList: OrgEoOrganizationInfoPersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: OrgVoOrganizationInfoEntranceWebVo[]) =>
      data,
  },
  OrgUserDtoController_listOrgUser_dfdf19: {
    method: 'post',
    url: '/api/organization_info-entrance-web/listOrgUser',
    parameterFomatter: (data?: { qto: OrgUserQtoOrganizationInfoPersistQto }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OrgUserVoOrganizationInfoEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrgUserBOController_createOrgUser_c08c43: {
    method: 'post',
    url: '/api/organization-info/create-org-user',
    parameterFomatter: (data?: {
      btoParam: CreateOrgUserBtoOrganizationInfoServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: OrgUserVoOrganizationInfoEntranceWebVo) =>
      data,
  },
  AdminUserDtoController_pageAdminUser_8a4210: {
    method: 'post',
    url: '/api/organization-info/adminuser/pageAdminUser',
    parameterFomatter: (data?: {
      qto: PageAdminUserQtoOrganizationInfoPersistQto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: AdminUserVoOrganizationInfoEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  AdminUserBOController_createAdminUser_66b7e3: {
    method: 'post',
    url: '/api/organization-info/create-admin-user',
    parameterFomatter: (data?: {
      btoParam: CreateAdminUserBtoOrganizationInfoServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: AdminUserVoOrganizationInfoEntranceWebVo,
    ) => data,
  },
  AdminUserBOController_deleteAdminUser_a526a2: {
    method: 'post',
    url: '/api/organization-info/delete-admin-user',
    parameterFomatter: (data?: {
      btoParam: DeleteAdminUserBtoOrganizationInfoServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  AdminUserBOController_updateAdminUser_d08f40: {
    method: 'post',
    url: '/api/organization-info/update-admin-user',
    parameterFomatter: (data?: {
      btoParam: UpdateAdminUserBtoOrganizationInfoServiceBto;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: AdminUserVoOrganizationInfoEntranceWebVo,
    ) => data,
  },
  OrgDtoController_listAllOrg_84e432: {
    method: 'post',
    url: '/api/organization-info/list-all-org',
    parameterFomatter: (data?: {
      qto: ListOrgQtoSimpleOrganizationInfoPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: OrgDtoOrganizationInfoManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  OrgUserBOController_deleteOrgUser_afecd0: {
    method: 'post',
    url: '/api/organization-info/delete-org-user',
    parameterFomatter: (data?: {
      btoParam: DeleteOrgUserBtoOrganizationInfoServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: number) => data,
  },
  OrgUserBOController_updateOrgUser_31ed17: {
    method: 'post',
    url: '/api/organization-info/update-org-user',
    parameterFomatter: (data?: {
      btoParam: UpdateOrgUserBtoOrganizationInfoServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: OrgUserVoOrganizationInfoEntranceWebVo) =>
      data,
  },
  OrgBOController_createOrg_05bcd2: {
    method: 'post',
    url: '/api/organization-info/create-org',
    parameterFomatter: (data?: {
      btoParam: CreateOrgBtoOrganizationInfoServiceBto;
      orgUserEoList: OrgUserEoOrganizationInfoPersistEo[];
    }) => data,
    responseFormatter: (_, __, data?: OrgVoOrganizationInfoEntranceWebVo) =>
      data,
  },
  OrgBOController_updateOrg_c5d949: {
    method: 'post',
    url: '/api/organization-info/update-org',
    parameterFomatter: (data?: {
      btoParam: UpdateOrgBtoOrganizationInfoServiceBto;
    }) => data,
    responseFormatter: (_, __, data?: OrgVoOrganizationInfoEntranceWebVo) =>
      data,
  },
  MonitorMenuDtoController_getAllMonitorMenu_86b783: {
    method: 'post',
    url: '/api/monitor-task/get-all-monitor-menu',
    parameterFomatter: (data?: { status: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: MonitorMenuVoMonitorTaskEntranceWebVo[],
    ) => data,
  },
  MonitorTaskDtoController_pageMonitorTask_0d681d: {
    method: 'post',
    url: '/api/monitor-task/query-page-monitor-task',
    parameterFomatter: (data?: {
      qto: MonitorTaskQtoMonitorTaskPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: MonitorTaskDtoMonitorTaskManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  MonitorTaskBOController_endMonitorTask_1b03c3: {
    method: 'post',
    url: '/api/monitor-task/end-monitor-task',
    parameterFomatter: (data?: {
      btoParam: UpdateMonitorTaskBtoMonitorTaskServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: MonitorTaskVoMonitorTaskEntranceWebVo) =>
      data,
  },
  MonitorTaskBOController_createMonitorTask_47dafc: {
    method: 'post',
    url: '/api/monitor-task/create-monitor-task',
    parameterFomatter: (data?: {
      btoParam: CreateMonitorTaskBtoMonitorTaskServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: MonitorTaskVoMonitorTaskEntranceWebVo) =>
      data,
  },
  MonitorTaskBOController_updateMonitorTask_12ea30: {
    method: 'post',
    url: '/api/monitor-task/update-monitor-task',
    parameterFomatter: (data?: {
      btoParam: UpdateMonitorTaskBtoMonitorTaskServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: MonitorTaskVoMonitorTaskEntranceWebVo) =>
      data,
  },
  MonitorTaskBOController_startMonitorTask_b804f7: {
    method: 'post',
    url: '/api/monitor-task/start-monitor-task',
    parameterFomatter: (data?: {
      btoParam: UpdateMonitorTaskBtoMonitorTaskServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: MonitorTaskVoMonitorTaskEntranceWebVo) =>
      data,
  },
  MonitorMenuBOController_createMonitorMenu_e7b194: {
    method: 'post',
    url: '/api/monitor-task/create-monitor-menu',
    parameterFomatter: (data?: {
      btoParam: CreateMonitorMenuBtoMonitorTaskServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: MonitorMenuVoMonitorTaskEntranceWebVo) =>
      data,
  },
  MonitorTaskController_getMonitorTaskSubject_02ee42: {
    method: 'post',
    url: '/api/monitor-task/get-monitor-task-subject',
    parameterFomatter: (data?: { monitorTaskId: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MonitorTaskSubjectVoMonitorTaskEntranceWebVo[],
    ) => data,
  },
  MonitorTaskController_getMonitorTaskDetail_12984e: {
    method: 'post',
    url: '/api/monitor-task/get-monitor-task-detail',
    parameterFomatter: (data?: { id: number }) => data,
    responseFormatter: (
      _,
      __,
      data?: MonitorTaskDetailVoMonitorTaskEntranceWebVo,
    ) => data,
  },
  MonitorMenuBOController_deleteMonitorMenu_95c22a: {
    method: 'post',
    url: '/api/monitor-task/delete-monitor-menu',
    parameterFomatter: (data?: {
      btoParam: DeleteMonitorMenuBtoMonitorTaskServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: MonitorMenuVoMonitorTaskEntranceWebVo) =>
      data,
  },
  MonitorMenuBOController_updateMonitorMenu_db4be5: {
    method: 'post',
    url: '/api/monitor-task/update-monitor-menu',
    parameterFomatter: (data?: {
      btoParam: UpdateMonitorMenuBtoMonitorTaskServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: MonitorMenuVoMonitorTaskEntranceWebVo) =>
      data,
  },
  SampleResultDtoController_getSampleResult_11277b: {
    method: 'post',
    url: '/api/sample-result/get-sample-result',
    parameterFomatter: (data?: { extractRoundId: number; taskId: number }) =>
      data,
    responseFormatter: (_, __, data?: string) => data,
  },
  SampleResultDtoController_getSampleSchoolResult_b2e42a: {
    method: 'post',
    url: '/api/sample-result/get-sample-school-result',
    parameterFomatter: (data?: {
      extractRoundId: number;
      orgId: number;
      taskId: number;
    }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  SampleResultDtoController_pageBySampleResult_ab4e7d: {
    method: 'post',
    url: '/api/sample-result/page-by-sample-result',
    parameterFomatter: (data?: {
      qto: SampleResultQtoSampleResultPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: SampleResultVoSampleResultEntranceWebVo[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  SampleResultDtoController_pageBySampleResultSchool_9da42c: {
    method: 'post',
    url: '/api/sample-result/page-by-sample-result-school',
    parameterFomatter: (data?: {
      qto: SampleResultSchoolQtoSampleResultPersistQto;
      ext: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: {
        count: number;
        result: SampleResultDtoSampleResultManagerDto[];
        from: number;
        size: number;
        scrollId: string;
        hasMore: boolean;
      },
    ) => data,
  },
  ExtractConfigBOController_createExtractConfig_9bc2cb: {
    method: 'post',
    url: '/api/sample-rule/create-extract-config',
    parameterFomatter: (data?: {
      btoParam: CreateExtractConfigBtoSampleRuleServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ExtractConfigVoSampleRuleEntranceWebVo) =>
      data,
  },
  ExtractConfigDtoController_getByMonitorTaskId_553a8d: {
    method: 'post',
    url: '/api/sample-rule/get-by-monitor-task-id',
    parameterFomatter: (data?: { monitorTaskId: number }) => data,
    responseFormatter: (_, __, data?: ExtractConfigVoSampleRuleEntranceWebVo) =>
      data,
  },
  SampleRuleController_getConfigRosterByMonitorTaskId_c29dad: {
    method: 'post',
    url: '/api/sample-rule/get-config-roster-by-monitor-task-id',
    parameterFomatter: (data?: { monitorTaskId: number; orgId: number }) =>
      data,
    responseFormatter: (
      _,
      __,
      data?: ExtractConfigRosterVoSampleRuleEntranceWebVo,
    ) => data,
  },
  ExtractConfigBOController_setPubTimeByExtractRound_3e04f6: {
    method: 'post',
    url: '/api/sample-rule/set-pub-time-by-extract-round',
    parameterFomatter: (data?: {
      list: PubExtractRoundEoSampleRulePersistEo[];
      extApiParam: any;
      configId: number;
    }) => data,
    responseFormatter: (_, __, data?: ExtractConfigVoSampleRuleEntranceWebVo) =>
      data,
  },
  ExtractConfigBOController_updateExtractConfig_748c2d: {
    method: 'post',
    url: '/api/sample-rule/update-extract-config',
    parameterFomatter: (data?: {
      btoParam: UpdateExtractConfigBtoSampleRuleServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (_, __, data?: ExtractConfigVoSampleRuleEntranceWebVo) =>
      data,
  },
  ExtractRosterBOController_updateListExtractRoster_37141a: {
    method: 'post',
    url: '/api/sample-rule/update-list-extract-roster',
    parameterFomatter: (data?: {
      list: UpdateExtractRosterBtoSampleRuleServiceBto[];
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExtractRosterOpenVoSampleRuleEntranceWebVo[],
    ) => data,
  },
  ExtractRosterBOController_updateExtractRoster_2255f2: {
    method: 'post',
    url: '/api/sample-rule/update-extract-roster',
    parameterFomatter: (data?: {
      btoParam: UpdateExtractRosterBtoSampleRuleServiceBto;
      extApiParam: any;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: ExtractRosterOpenVoSampleRuleEntranceWebVo,
    ) => data,
  },
  ExtractRosterBOController_studentExtractPercentage_7efd94: {
    method: 'post',
    url: '/api/sample-rule/student-extract-percentage',
    parameterFomatter: (data?: {
      configId: number;
      roundId: number;
      level: string;
    }) => data,
    responseFormatter: (
      _,
      __,
      data?: DisplayStudentConfirmedPercentageEoSampleRulePersistEo,
    ) => data,
  },
  OssController_uploadAnnex_6e8a1a: {
    method: 'post',
    url: '/api/external-api/upload-annex',
    parameterFomatter: (data?: { fileName: string }) => data,
    responseFormatter: (_, __, data?: string) => data,
  },
  OssController_getOssPolicy_e26cdd: {
    method: 'post',
    url: '/api/external-api/get-oss-policy',
    parameterFomatter: (data?: { suffixName: string; fileName: string }) =>
      data,
    responseFormatter: (_, __, data?: OssPolicyEoExternalApiPersistEo) => data,
  },
  OssController_getOssPolicys_0d5321: {
    method: 'post',
    url: '/api/external-api/get-oss-policys',
    parameterFomatter: (data?: { suffixName: string; fileName: string }) =>
      data,
    responseFormatter: (_, __, data?: OssPolicyEoExternalApiPersistEo) => data,
  },
  DingtalkLoginController_dingLogin_9108fe: {
    method: 'post',
    url: '/api/sample-xuetong-user-account-entrance-web/ding-login',
    parameterFomatter: (data?: { code: string }) => data,
    responseFormatter: (
      _,
      __,
      data?: OrgDingtalkInfoVoComVsUserAccountEntranceWebVo,
    ) => data,
  },
});
