import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Modal,
  Progress,
  Section,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import { Button } from 'antd';
import { cloneDeep, create } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import AddIcon from '@/assets/images/icon/add_icon.png';
import WarningIcon from '@/assets/images/warning_icon.png';
import { TaskOrgStatusEnumValue } from '@/config';
import { navigateToWithPlatform, usePlatformInfo } from '@/utils';

import ActionSplit from './ActionSplit';
import DepartmentMonitorAddStudentForm from './DepartmentMonitorAddStudentForm';

const AddIconImg = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 6px;
`;

const AddStudentText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #ff3d11;
`;

const AllPublishButton = styled.div`
  width: 84px;
  height: 36px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #2e5ef2;
  font-size: 14px;
  font-weight: 500;
  color: #2e5ef2;
  cursor: pointer;
`;

const AllPublishButtonDisable = styled.div`
  width: 84px;
  height: 36px;
  background: #ffffff;
  border-radius: 6px;
  opacity: 0.3;
  border: 1px solid #2e5ef2;
  font-size: 14px;
  font-weight: 500;
  color: #2e5ef2;
  cursor: pointer;
`;

const AllManageButton = styled.div`
  width: 84px;
  height: 36px;
  background: #2e5ef2;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
`;

const DeleteModalTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-top: 15px;
`;

const DeleteModalLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #666666;
  margin-top: 4px;
`;

const DeleteModalCancelButton = styled.div`
  width: 110px;
  height: 44px;
  background: #eaeefd;
  border-radius: 6px;
  border: 1px solid #c9d6ff;
  font-size: 16px;
  font-weight: 500;
  color: #2e5ef2;
  cursor: pointer;
  margin-right: 20px;
`;

const DeleteModalConfirmButton = styled.div`
  width: 110px;
  height: 44px;
  background: #2e5ef2;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  margin-left: 20px;
`;

const BatchDeleteButton = styled.div`
  width: 84px;
  height: 36px;
  background: rgba(255, 61, 17, 0.1);
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #fa6400;
  line-height: 20px;
  cursor: pointer;
`;

const CancelText = styled.div`
  width: 29px;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #2e5ef2;
  margin-right: 28px;
  cursor: pointer;
`;

const DepartmentMonitorInfoCheckTable = (props) => {
  const { taskIdIs, monitorTaskStatus, period, grade, createStatus } = props;

  const [formVisible, setFormVisible] = useState(false);
  const [deleteModalVisible, setDeleteModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState();
  const [refresh, setRefresh] = useState();
  const [isManage, setIsManage] = useState(false);
  const [publishVisible, setPublishVisible] = useState(false);
  const [isAllPublish, setIsAllPublish] = useState(false);
  const [platformInfo] = usePlatformInfo();
  const [data, setData] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [progressData, setProgressData] = useState(0);

  const selectedRows = useRef([]);

  const fetchUnpulished = useCallback(async () => {
    try {
      const response =
        await vsf?.services?.TaskOrgDtoController_getUnpulished_09fe37?.({
          taskId: taskIdIs,
        });
      if (response && response.code === 200) {
        setIsAllPublish(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [taskIdIs]);

  useEffect(() => {
    fetchUnpulished();
  }, [fetchUnpulished]);

  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf?.services?.TaskOrgDtoController_pageTaskOrg_b053ba?.({
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList, // 排序结果
            taskIdIs,
            ...params.search, // 搜索结果
            ...params.filters, // 过滤器结果
            ...(params?.extra ?? {}), // 传入的额外参数
            refresh: undefined,
          },
          ext: params?.ext,
        });
      const _data = res?.data?.result ?? res?.data ?? [];
      setData(_data);
      return {
        data: _data,
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [taskIdIs],
  );

  const handelShowStudent = (_, record) => {
    const _query = `period=${period}&grade=${grade}&monitorTaskStatus=${monitorTaskStatus}`;
    navigateToWithPlatform(
      `/${platformInfo?.platformId}/monitor/${record?.taskId}/school/${
        record?.id
      }?title=${record?.org?.orgName}&isEmpty=${
        record?.studentTotal <= 0
      }&${_query}`,
    );
  };

  const handelDeleteClick = (_, record) => {
    setCurrentItem(record);
    setDeleteModalVisible(true);
  };

  const handelDeleteConfirm = async () => {
    try {
      if (isManage && selectedRows.current?.length <= 0) {
        return message.error('请先选择学校！');
      }
      const response = isManage
        ? await vsf?.services?.TaskOrgBOController_deleteListTaskOrg_d81010?.({
            btoParam: selectedRows.current?.map((item) => ({ id: item?.id })),
          })
        : await vsf?.services?.TaskOrgBOController_deleteTaskOrg_9794a3?.({
            btoParam: {
              id: currentItem?.id,
            },
          });
      if (response && response.code === 200) {
        selectedRows.current = [];
        message.success('删除成功');
        setDeleteModalVisible(false);
        setTimeout(() => {
          setRefresh(!refresh);
        }, 500);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelAllPublish = async () => {
    try {
      const response =
        await vsf.services?.TaskOrgBOController_pubAllTaskOrg_ed08fd?.({
          taskId: taskIdIs,
        });
      if (response && response.code === 200) {
        message.success('发布成功！');
        setIsAllPublish(false);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  };

  const handelSelectRowChange = (rows) => {
    selectedRows.current = rows?.filter((item) => item);
  };

  const handelBatchClick = () => {
    if (data?.length) {
      if (isManage) {
        setPublishVisible(true);
      } else {
        setIsManage(true);
      }
    }
  };

  const handelBatchCancel = () => {
    setIsManage(false);
  };

  const handelBatchPublish = async () => {
    try {
      if (isManage && selectedRows.current?.length <= 0) {
        return message.error('请先选择学校！');
      }
      const response =
        await vsf?.services?.TaskOrgBOController_pubListTaskOrg_7bb6ee?.({
          taskId: taskIdIs,
          orgIds: selectedRows.current?.map((item) => item?.org?.id),
        });
      if (response && response.code === 200) {
        message.success('全部发布成功！');
        setRefresh(!refresh);
        setIsManage(false);
        setPublishVisible(false);
        return true;
      }
      return;
    } catch (error) {
      console.error(error);
    }
  };

  const handelSubmit = async () => {
    try {
      const params =
        await vsf.refs.DepartmentMonitorAddStudentForm?.getValues();

      if (Object.keys(params).length !== 0) {
        const LimitLength = 1000;
        if (
          params?.addType === 'import' &&
          params?.list?.length > LimitLength
        ) {
          setBtnLoading(true);
          const forLength = Math.ceil(params?.list?.length / LimitLength);
          const responseList = [];
          for (let i = 0; i <= forLength; i++) {
            const _list = cloneDeep(params?.list)?.slice(
              i * LimitLength,
              i * LimitLength + LimitLength,
            );
            const _response =
              await vsf?.services?.StudentBOController_createAllStudent_b77e74?.(
                {
                  studentEos: _list,
                  taskId: taskIdIs,
                  period,
                  grade,
                },
              );
            responseList?.push(_response);
            setProgressData((i + 1) * 10);
            if (responseList?.length === forLength) {
              message.success('添加成功');
              setFormVisible(false);
              setRefresh(!refresh);
              setBtnLoading(false);
              return true;
            }
          }
        } else {
          const response =
            params?.addType === 'add'
              ? await vsf?.services?.StudentBOController_createStudent_1807ce?.(
                  {
                    bto: {
                      ...params,
                      taskId: taskIdIs,
                      addType: undefined,
                    },
                  },
                )
              : await vsf?.services?.StudentBOController_createAllStudent_b77e74?.(
                  {
                    studentEos: params?.list,
                    taskId: taskIdIs,
                    period,
                    grade,
                  },
                );
          if (response && response.code === 200) {
            message.success('添加成功');
            setFormVisible(false);
            setRefresh(!refresh);
            setBtnLoading(false);
            return true;
          }
          return false;
        }
      } else {
        vsf.showToast('请导入名单', 'error');
      }
    } catch (error) {
      console.error(error);
      return false;
    }
  };

  console.log(createStatus, 'createStatus');
  console.log(typeof createStatus);

  return (
    <Section>
      <VSPromiseTable
        pagination={{
          defaultPageSize: 9,
          showTotal: () => <></>,
        }}
        editable={{ editType: 'single', columnProps: { hideInTable: true } }}
        searchConfig={{
          filterType: 'inline',
          optionButtons: 'search',
          searchButtonProps: {
            children: '查询',
          },
        }}
        actionSpaceProps={{
          split: <ActionSplit />,
        }}
        rowSelection={
          isManage
            ? {
                selectType: 'multiple',
                onSelectedRowsChange: handelSelectRowChange,
              }
            : null
        }
        extraParams={{ refresh }}
        onFetch={onFetch}
        searchFormRender={(_, doms, option) => {
          return (
            <div className="aic" style={{ alignItems: 'flex-start' }}>
              {doms}
              <div
                className="aic"
                style={
                  monitorTaskStatus === 'END'
                    ? {
                        display: 'none',
                      }
                    : {}
                }
              >
                {isManage ? (
                  <CancelText onClick={handelBatchCancel}>取消</CancelText>
                ) : (
                  <div
                    className="aic"
                    style={{
                      width: 90,
                      // opacity: data?.length ? 1 : 0.5,
                      opacity: data?.length && isAllPublish ? 1 : 0.5,
                      cursor: 'pointer',
                    }}
                  >
                    <AddIconImg src={AddIcon} />
                    <AddStudentText
                      onClick={() => {
                        if (data?.length && isAllPublish) {
                          setFormVisible(true);
                        }
                      }}
                    >
                      添加学生
                    </AddStudentText>
                  </div>
                )}
                {isManage ? (
                  <BatchDeleteButton
                    className="aic jcc"
                    onClick={() => setDeleteModalVisible(true)}
                  >
                    批量删除
                  </BatchDeleteButton>
                ) : isAllPublish ? (
                  <AllPublishButton
                    className="aic jcc"
                    onClick={handelAllPublish}
                  >
                    全部发布
                  </AllPublishButton>
                ) : (
                  <AllPublishButtonDisable className="aic jcc">
                    全部发布
                  </AllPublishButtonDisable>
                )}
                <span style={{ width: 14 }} />
                <AllManageButton
                  className="aic jcc"
                  onClick={handelBatchClick}
                  style={{
                    opacity: data?.length ? 1 : 0.5,
                  }}
                >
                  {isManage ? '批量发布' : '批量管理'}
                </AllManageButton>
              </div>
            </div>
          );
        }}
      >
        <VSTableColumn
          dataIndex={['org', 'id']}
          title="学校码"
          valueType="digit"
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['org', 'orgName']}
          title="学校名称"
          valueType="text"
          search
          ellipsis
          order={-22}
          columnKey={'orgNameLike'}
          fieldProps={{
            placeholder: '请输入',
            width: 150,
          }}
        />
        <VSTableColumn
          dataIndex={['classesAmount']}
          title="班级总数"
          valueType="digit"
        />
        <VSTableColumn
          dataIndex={['studentTotal']}
          title="学生总数"
          valueType="digit"
        />
        <VSTableColumn
          dataIndex={['boyTotal']}
          title="男生"
          valueType="digit"
        />
        <VSTableColumn
          dataIndex={['girlTotal']}
          title="女生"
          valueType="digit"
        />
        {createStatus && createStatus === 'true' && (
          <VSTableColumn
            dataIndex={['status']}
            title="状态"
            valueType="text"
            render={(_, record) => TaskOrgStatusEnumValue[record?.status]}
          />
        )}
        <VSTableColumn dataIndex={['action']} title="操作" valueType="option">
          <VSTableAction disabled={isManage} onClick={handelShowStudent}>
            查看学生
          </VSTableAction>
          <VSTableAction
            disabled={isManage}
            show={() => monitorTaskStatus !== 'END'}
            onClick={handelDeleteClick}
          >
            删除
          </VSTableAction>
        </VSTableColumn>
      </VSPromiseTable>
      <Modal
        open={formVisible}
        destroyOnClose
        onOk={handelSubmit}
        onCancel={() => setFormVisible(false)}
        cancelText="取消"
        okText="确定"
        okButtonProps={{
          loading: btnLoading,
        }}
      >
        <DepartmentMonitorAddStudentForm
          id="DepartmentMonitorAddStudentForm"
          grade={grade}
          taskIdIs={taskIdIs}
        />
        {btnLoading && <Progress percent={progressData} />}
      </Modal>
      <Modal
        destroyOnClose
        open={deleteModalVisible}
        footer={false}
        width={460}
        onCancel={() => setDeleteModalVisible(false)}
      >
        <div style={{ height: 20 }} />
        <div className="col aic " style={{ width: '100%', height: '100%' }}>
          <img src={WarningIcon} style={{ width: 80, height: 90 }} />
          <DeleteModalTitle>
            {isManage
              ? '确定批量删除学校吗？'
              : `确定删除“${currentItem?.org?.orgName ?? ''}”吗？`}
          </DeleteModalTitle>
          <DeleteModalLabel>
            删除后，导入该校的学生信息都将清空
          </DeleteModalLabel>
          <div className="aic" style={{ marginTop: 30, marginBottom: 23 }}>
            <DeleteModalCancelButton
              className="aic jcc"
              onClick={() => setDeleteModalVisible(false)}
            >
              取消
            </DeleteModalCancelButton>
            <DeleteModalConfirmButton
              className="aic jcc"
              onClick={handelDeleteConfirm}
            >
              确定
            </DeleteModalConfirmButton>
          </div>
        </div>
      </Modal>

      <Modal
        destroyOnClose
        open={publishVisible}
        footer={false}
        width={460}
        onCancel={() => setPublishVisible(false)}
      >
        <div style={{ height: 20 }} />
        <div className="col aic " style={{ width: '100%', height: '100%' }}>
          <img src={WarningIcon} style={{ width: 80, height: 90 }} />
          <DeleteModalTitle>确定全部发布吗？</DeleteModalTitle>
          <div className="aic" style={{ marginTop: 30, marginBottom: 23 }}>
            <DeleteModalCancelButton
              className="aic jcc"
              onClick={() => setPublishVisible(false)}
            >
              取消
            </DeleteModalCancelButton>
            <DeleteModalConfirmButton
              className="aic jcc"
              onClick={handelBatchPublish}
            >
              确定
            </DeleteModalConfirmButton>
          </div>
        </div>
      </Modal>
    </Section>
  );
};
export default definePage(DepartmentMonitorInfoCheckTable);
