import vsf, { definePage } from '@vs/vsf-boot';
import {
  Col,
  RadioGroup,
  Row,
  Section,
  Todo,
  VSControlledForm,
  VSFormItem,
  VSTable,
  VSTableAddition,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useState } from 'react';

import { CustomFileUpload } from '@/components';
import getOssPolicy from '@/utils/getOssPolicy';
import { extractTables } from '@/utils/xlsx';

const orgLevels = [
  { label: '集团校', value: 'GROUP_SCHOOL' },
  { label: '主校', value: 'MAIN_CAMPUS' },
  { label: '分校', value: 'BRANCH_CAMPUS' },
  { label: '省级', value: 'PROVINCE_SCHOOL' },
  { label: '市级', value: 'CITY_SCHOOL' },
  { label: '区级', value: 'COUNTY_SCHOOL' },
];

const periods = [
  { period: 'PRIMARY_SCHOOL', name: '小学' },
  { period: 'MIDDLE_SCHOOL', name: '初中' },
];

const DepartmentOrgAdd = (props) => {
  /**
   * 数据填入方式
   * @type {string}
   */
  const [type, setType] = useState('excel');
  /**
   * excel导入机构
   * @type {any}
   */
  const [excel, setExcel] = useState();
  /**
   * manual手动填入的机构
   * @type {any}
   */
  const [manual, setManual] = useState();
  /**
   * 导出数据
   * @param {string} type
   * @param {any} excel
   * @returns {any}
   */
  const exportData = ({ type, excel, manual }) => {
    if (type === 'excel') {
      return excel;
    }
    if (type === 'manual') {
      return toData(manual);
    }
    return undefined;
  };
  /**
   * 是否为手动添加
   * @param {string} type
   * @returns {boolean}
   */
  const isManual = (type) => {
    return type !== 'excel';
  };
  /**
   * 是否为自动导入
   * @param {string} type
   * @returns {boolean}
   */
  const isExcel = (type) => {
    return type === 'excel';
  };

  const toData = (data) => {
    if (!data) {
      return data;
    }
    const { address, users, ...rest } = data;
    return {
      ...rest,
      city: address?.city,
      province: address?.province,
      county: address?.district,
      users: (users || [])?.map((user) => {
        const { id, ...rest } = user;
        return rest;
      }),
    };
  };

  const labelSpan = 4;

  const fakeItem = (title, children) => {
    return (
      <Row style={{ marginBottom: 24, minHeight: 32 }}>
        <Col
          span={labelSpan}
          style={{ display: 'flex', justifyContent: 'end' }}
        >
          {`${title}：`}
        </Col>
        <Col>{children}</Col>
      </Row>
    );
  };

  return (
    <Section title="">
      {fakeItem(
        '添加方式',
        <RadioGroup
          dataSource={[
            { label: '表格导入', value: 'excel' },
            { label: '手动添加', value: 'manual' },
          ]}
          value={type}
          onChange={(_value) => {
            setType(_value);
            props.onChange?.(exportData({ type: _value, excel, manual }));
          }}
        />,
      )}
      {isExcel(type) &&
        fakeItem(
          '添加机构',
          <CustomFileUpload
            template={{
              name: '机构导入模板',
              url: '/org_template.xlsx',
            }}
            width={376}
            type="file"
            // getOssPolicy={getOssPolicy}
            onChange={(value) => {
              if (!value) {
                setExcel(undefined);
                props.onChange?.(
                  exportData({ type, excel: undefined, manual }),
                );
              }
            }}
            uploadProps={{
              accept: '.xls,.xlsx',
              customRequest: async (e) => {
                try {
                  const response = await extractTables(
                    e.file,
                    {
                      orgName: '*机构名称',
                      schoolId: '*schoolId',
                      province: '所属省份（如：浙江省）',
                      city: '所属市级（如：杭州市）',
                      county: '所属区/县（如：西湖区）',
                      orgLevel: '*机构类型（省、市、区、集团校、学校、校区）',
                      period1: '*学段1',
                      period2: '学段2',
                      name1: '*管理人员1姓名',
                      phone1: '*管理人员1手机号',
                      name2: '管理人员2姓名',
                      phone2: '管理人员2手机号',
                      name3: '管理人员3姓名',
                      phone3: '管理人员3手机号',
                    },
                    [
                      'orgName',
                      'schoolId',
                      'orgLevel',
                      'period1',
                      'name1',
                      'phone1',
                    ],
                  );
                  if (response) {
                    const data = response[0]?.data;
                    if (data && data.length > 0) {
                      let userCount = 0;
                      const excelValues = data.map((d) => {
                        const periodSet = new Set();
                        if (d.period1) {
                          periodSet.add(d.period1);
                        }
                        if (d.period2) {
                          periodSet.add(d.period2);
                        }
                        const userArray = [];
                        if (d.name1 && d.phone1) {
                          userArray.push({
                            name: d.name1,
                            phone: d.phone1,
                          });
                        }
                        if (d.name2 && d.phone2) {
                          userArray.push({
                            name: d.name2,
                            phone: d.phone2,
                          });
                        }
                        if (d.name3 && d.phone3) {
                          userArray.push({
                            name: d.name3,
                            phone: d.phone3,
                          });
                        }
                        const ret = {
                          schoolId: d.schoolId,
                          orgName: d.orgName,
                          orgLevel: orgLevels.find(
                            (level) => level.label === d.orgLevel,
                          )?.value,
                          province: d.province,
                          city: d.city,
                          county: d.county,
                          periodList: Array.from(periodSet)
                            .map((period) =>
                              periods.find((p) => p.name === period),
                            )
                            .filter((p) => !!p),
                          orgUserList: userArray,
                        };
                        userCount += userArray.length;
                        return ret;
                      });
                      setExcel(excelValues);
                      props.onChange?.(
                        exportData({ type, excel: excelValues, manual }),
                      );
                      e.onProgress({ percent: 100 });
                      e.onSuccess(
                        {
                          name: e.file.name,
                          status: 'done',
                          path: URL.createObjectURL(e.file),
                        },
                        e.file,
                      );
                      console.log(excelValues, 'excelValues======');
                      vsf.showToast(
                        `已成功导入${excelValues.length}个下属机构及${userCount}名管理人员`,
                        'success',
                      );
                    } else {
                      vsf.showToast(
                        '表格中的没有填入机构数据或数据不完整',
                        'error',
                      );
                    }
                  }
                } catch (error) {
                  console.error(error);
                  vsf.showToast('解析失败，请检查文档格式', 'error');
                }
              },
            }}
          />,
        )}
      {isManual(type) && (
        <VSControlledForm
          layout="horizontal"
          id="DepartmentOrgAddForm"
          labelCol={{
            span: labelSpan,
          }}
          onChange={(_value) => {
            setManual(_value);
            props.onChange?.(exportData({ type, excel, manual: _value }));
          }}
        >
          <VSFormItem
            name={['orgName']}
            label="机构名称"
            valueType="text"
            required={false}
            rules={[
              {
                required: true,
                message: '请输入机构名称',
              },
              {
                message: '机构名称长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ]}
            fieldProps={{
              placeholder: '请输入机构名称',
            }}
          />

          <VSFormItem
            name={['schoolId']}
            label="schoolId"
            valueType="text"
            required={false}
            rules={[
              {
                required: true,
                message: '请输入schoolId',
              },
            ]}
            fieldProps={{
              placeholder: '请输入schoolId',
            }}
          />

          <VSFormItem
            name={['orgPeriodDtoList']}
            label="学段"
            valueType="checkbox"
            required={false}
            fieldProps={{
              dataSource: periods,
              fieldNames: { value: 'period', label: 'name' },
            }}
            rules={[
              {
                required: true,
                message: '请选择学段',
              },
            ]}
          />

          <VSFormItem
            name={['address']}
            label="行政区域"
            valueType="address"
            required={false}
            rules={[
              {
                validator: (_, value) => {
                  return new Promise((resolve, reject) => {
                    if (value?.city && value?.province && value?.district) {
                      resolve();
                    } else {
                      reject();
                    }
                  });
                },
                message: '请选择行政区域',
              },
            ]}
            fieldProps={{
              placeholder: {
                cascader: '请选择行政区域',
              },
            }}
          />

          <VSFormItem
            name={['orgLevel']}
            label="机构层级"
            valueType="select"
            required={false}
            dataSource={orgLevels}
            rules={[
              {
                required: true,
                message: '请选择机构层级',
              },
            ]}
            fieldProps={{
              placeholder: '请选择机构层级',
            }}
          />

          <VSFormItem
            name={['orgUserList']}
            label="管理人员"
            required={false}
            rules={[
              {
                required: true,
                message: '请添加管理人员',
              },
            ]}
            valueType="custom"
          >
            <VSTable
              cardProps={{
                bodyStyle: {
                  padding: 0,
                },
              }}
              editable={{
                editType: 'single',
                deleteConfirmProps: { title: '确定删除吗？', icon: <></> },
                onCanEdit: () => false,
                saveText: '确定',
                columnProps: {
                  align: 'center',
                },
              }}
              pagination={false}
              onRecord={async () => {
                return {
                  id: Math.random(),
                };
              }}
              scroll={{
                y: 500,
              }}
            >
              <VSTableColumn
                dataIndex={['phone']}
                title="手机号"
                valueType="text"
                formItemProps={{
                  rules: [
                    {
                      required: true,
                      message: '请输入手机号',
                    },
                    {
                      message: '手机号格式错误',
                      type: 'string',
                      pattern: /^(?:\+?86)?1[3-9]\d{9}$/,
                    },
                  ],
                }}
                fieldProps={{
                  placeholder: '请输入',
                }}
              />

              <VSTableColumn
                dataIndex={['name']}
                title="姓名"
                valueType="text"
                formItemProps={{
                  rules: [
                    {
                      required: true,
                      message: '请输入姓名',
                    },
                    {
                      message: '用户姓名长度不合法',
                      type: 'string',
                      min: 0,
                      max: 64,
                    },
                  ],
                }}
                fieldProps={{
                  placeholder: '请输入',
                }}
              />

              <VSTableAddition children="添加" position="bottom" />
            </VSTable>
          </VSFormItem>
        </VSControlledForm>
      )}
    </Section>
  );
};
export default definePage(DepartmentOrgAdd);
