import vsf, { definePage } from '@vs/vsf-boot';
import {
  Col,
  message,
  Row,
  Section,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import React, { useState } from 'react';

import CustomFileUpload from '@/components/CustomFileUpload';
import { GradeEnumValue, PeriodEnumValue, SubjectEnumValue } from '@/config';
import { birdayFormatter } from '@/utils';
import getOssPolicy from '@/utils/getOssPolicy';
import { extractTables } from '@/utils/xlsx';
const SchoolGadesSubmitForm = (props) => {
  const { value } = props;
  return (
    <Section title="提交">
      <VSControlledForm
        id="SchoolGadesSubmitForm"
        layout="horizontal"
        vsid="34751"
        onSubmit={async (params) => {
          const res =
            await vsf?.services?.ScoreInfoBOController_submitScoreInfo_c2f086?.(
              {
                list: params?.list,
                scoreTaskId: params?.scoreTaskId,
                orgId: params?.orgId,
              },
            );
          return params; // 直接返回提交的数据
        }}
      >
        <div style={{ height: 30 }} />
        <VSFormItem label="学校名称" valueType="custom">
          {value?.scoreTask?.name}
        </VSFormItem>
        <VSFormItem label="学年" valueType="custom">
          {value?.scoreTask?.year}
        </VSFormItem>
        <VSFormItem label="学段" valueType="custom">
          {PeriodEnumValue[value?.scoreTask?.period]}
        </VSFormItem>
        <VSFormItem label="年级" valueType="custom">
          {GradeEnumValue[value?.scoreTask?.grade]}
        </VSFormItem>
        <VSFormItem label="学科" valueType="custom">
          {SubjectEnumValue[value?.scoreTask?.subject]}
        </VSFormItem>
      </VSControlledForm>
      <CustomFileUpload
        type="file"
        template={{
          url: `/school_grade_import_physical.xlsx`,
          name: '学科成绩录入模板-体育',
        }}
        uploadProps={{
          accept: '.xls,.xlsx',
          customRequest: async (e) => {
            try {
              const response = await extractTables(
                e.file,
                {
                  orgName: '*学校名称',
                  period: '*学段',
                  year: '*学年',
                  classes: '*班级',
                  studentName: '*姓名',
                  idCard: '身份证号',
                  sex: '*性别',
                  birthday: '出生日期',
                  nation: '民族',
                  nativePlace: '籍贯',
                  studentStatusNum: '*学籍辅号',
                  score: '*总分',
                },
                [
                  'orgName',
                  'period',
                  'year',
                  'classes',
                  'studentName',
                  'sex',
                  'studentStatusNum',
                  'score',
                ],
              );
              if (response) {
                if (response[0]?.data && response[0]?.data?.length > 0) {
                  const _data = response?.[0]?.data?.map((item) => ({
                    ...item,
                    studentStatusNum:
                      item?.studentStatusNum?.indexOf('0') === 0
                        ? item?.studentStatusNum?.substr(1)
                        : item?.studentStatusNum,
                  }));

                  props?.onChange?.({
                    ...value,
                    list: _data,
                  });
                  setTimeout(() => {
                    e.onProgress({ percent: 100 });
                    e.onSuccess(
                      {
                        name: e.file.name,
                        status: 'done',
                        path: URL.createObjectURL(e.file),
                      },
                      e.file,
                    );
                  }, 1);
                } else {
                  message.error('表格中的没有填入学生数据或数据不完整');
                }
              }
            } catch (error) {
              console.error(error);
              message.error('解析失败，请检查文档格式');
            }
          },
        }}
      ></CustomFileUpload>
    </Section>
  );
};
export default definePage(SchoolGadesSubmitForm);
