import SchoolMonitorStudentConfirmTable from '@pages/SchoolMonitorStudentConfirmTable';
import vsf, { definePage } from '@vs/vsf-boot';
import { Page, Section } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { SubjectEnumValue } from '@/config';
import { usePlatformInfo } from '@/utils';

import DepartmentMonitorFinalListTable from './DepartmentMonitorFinalListTable';
import ChinaNum from './RandomSampleList/ChinaNum';

const SubjectView = styled.div`
  width: 100%;
  height: 56px;
  background: #ecedf0;
  border-radius: 6px 6px 0px 0px;
`;

const SubjectViewItem = styled.div`
  width: 146px;
  height: 56px;
  background: #ffffff;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  cursor: pointer;
`;

const Line = styled.div`
  width: 100%;
  height: 3px;
  background: #2e5ef2;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px 12px 0 0;
`;

const SubjectTabsView = styled.div`
  flex: 1;
  height: 63px;
  box-sizing: border-box;
  padding-top: 14px;
  box-sizing: border-box;
  padding-left: 20px;
`;

const SubjectTabsViewItem = styled.div`
  height: 100%;
  font-size: 16px;
  font-weight: 500;
  color: #999999;
  position: relative;
  margin-right: 30px;
`;

const SubjectTabsViewItemLine = styled.div`
  width: 24px;
  height: 3px;
  background: #2e5ef2;
  border-radius: 2px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
`;

const CountLabel = styled.div`
  font-size: 14px;
  font-family:
    PingFangSC-Regular,
    PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-right: 19px;
`;

const DepartmentMonitorFinalList = (props) => {
  const {
    routes: {
      params: { taskIdIs },
    },
  } = props;

  const [monitorTask, setMonitorTask] = useState();
  const [extractRoundIdIs, setExtractRoundIdIs] = useState();
  const [tabsId, setTabsId] = useState();

  const [platformInfo] = usePlatformInfo();

  const loadByMonitorTask = useCallback(async () => {
    try {
      const response =
        await vsf?.services?.SampleRuleController_getConfigRosterByMonitorTaskId_c29dad?.(
          {
            monitorTaskId: taskIdIs,
          },
        );
      if (response && response.code === 200) {
        if (response.data && response.data?.extractRoundDtoList?.[0]) {
          setExtractRoundIdIs(
            response.data && response.data?.extractRoundDtoList?.[0]?.id,
          );

          if (response.data?.distinguishSex) {
            setTabsId(
              (
                response.data?.extractRoundDtoList?.[0]
                  ?.extractRosterOpenDtoList ?? []
              )?.filter((item) => item.sex === 'MAN')?.[0]?.id,
            );
          } else {
            setTabsId(
              (response.data?.extractRoundDtoList?.[0]
                ?.extractRosterOpenDtoList ?? [])?.[0]?.id,
            );
          }
        }
        setMonitorTask(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [taskIdIs]);

  useEffect(() => {
    loadByMonitorTask();
  }, [loadByMonitorTask]);

  const getTabsList = () => {
    const arr = [];
    monitorTask?.extractRoundDtoList
      ?.find((item) => item.id === extractRoundIdIs)
      ?.extractRosterOpenDtoList?.forEach((item) => {
        if (!arr.find((_v) => _v.level === item.level)) {
          arr?.push(item);
        }
      });

    return arr;
  };

  const getExtractRosterIdIn = () => {
    const currentTabs = monitorTask?.extractRoundDtoList
      ?.find((item) => item.id === extractRoundIdIs)
      ?.extractRosterOpenDtoList?.find((item) => item?.id === tabsId);

    const ids = monitorTask?.extractRoundDtoList
      ?.find((item) => item.id === extractRoundIdIs)
      ?.extractRosterOpenDtoList?.filter(
        (item) => item.level === currentTabs?.level,
      )
      ?.map((item) => item.id);
    return ids;
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        borderRadius: 8,
        overflowY: 'scroll',
        boxSizing: 'border-box',
        paddingLeft: 24,
      }}
      className="col"
    >
      {monitorTask?.extractRoundDtoList && (
        <>
          <SubjectView className="aic">
            {monitorTask?.extractRoundDtoList?.map((item, _idx) => (
              <SubjectViewItem
                key={item?.id}
                className="aic jcc"
                style={{
                  background:
                    extractRoundIdIs === item?.id ? '#FFFFFF' : '#ECEDF0',
                }}
                onClick={() => {
                  setExtractRoundIdIs(item?.id);
                }}
              >
                {extractRoundIdIs === item?.id && <Line />}
                <span
                  style={{
                    color:
                      extractRoundIdIs === item?.id ? '#2E5EF2' : '#999999',
                  }}
                >
                  第{ChinaNum[_idx]}轮（{SubjectEnumValue[item?.subject]}）
                </span>
              </SubjectViewItem>
            ))}
          </SubjectView>
          <div style={{ height: 25, background: '#fff' }} />
        </>
      )}
      <div style={{ background: '#FFF' }}>
        <DepartmentMonitorFinalListTable
          taskIdIs={taskIdIs}
          extractRoundIdIs={extractRoundIdIs}
        />
      </div>
    </div>
  );
};
export default definePage(DepartmentMonitorFinalList);
