import DepartmentAccountEdit from '@pages/DepartmentAccountEdit';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Icon,
  Section,
  Text,
  VSPromiseTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';

import ActionSplit from './ActionSplit';

const SchoolAccountList = (props) => {
  const [refresh, setRefresh] = useState(false);
  /**
   * 机构id参数函数转换
   * @param {string} id
   * @returns {object}
   */
  const id2Param = (id) => {
    return { orgIdIs: id };
  };

  const onFetch = useCallback(async (params) => {
    const res = await vsf?.services?.OrgUserDtoController_pageOrgUser_83b9c0?.({
      qto: {
        from: params.pagination?.from ?? 0,
        size: params.pagination?.size ?? 20,
        orderList: params.orderList, // 排序结果

        ...params.search, // 搜索结果
        ...params.filters, // 过滤器结果
        ...(params?.extra ?? {}), // 传入的额外参数
      },
    });
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  return (
    <Section title="" className="department_account_list">
      <VSPromiseTable
        onRemove={async (params) => {
          const res =
            await vsf?.services?.OrgUserBOController_deleteOrgUser_afecd0?.({
              btoParam: params,
            });
          return res?.code === 200;
        }}
        onFetch={onFetch}
        extraParams={{ refresh, ...id2Param(props?.id) }}
        addLinkPage={{
          linkPage: () => <DepartmentAccountEdit nameIndex="name" />,
          onLinkPageValidate: async (params) => {
            try {
              await vsf.refs.DepartmentAccountForm?.validateFieldsReturnFormatValue();
              const res =
                await vsf?.services?.OrgUserBOController_createOrgUser_c08c43?.(
                  {
                    btoParam: {
                      ...params,
                      orgId: props.id,
                    },
                  },
                );
              return res?.data;
            } catch (e) {
              return false;
            }
          },
          modalProps: { title: '添加账号', width: 500 },
        }}
        updateLinkPage={{
          linkPage: () => <DepartmentAccountEdit nameIndex="name" />,
          onLinkPageValidate: async (params) => {
            try {
              await vsf.refs.DepartmentAccountForm?.validateFieldsReturnFormatValue();
              const res =
                await vsf?.services?.OrgUserBOController_updateOrgUser_31ed17?.(
                  {
                    btoParam: {
                      ...params,
                      orgId: props.id,
                    },
                  },
                );
              return res?.data;
            } catch (e) {
              return false;
            }
          },
          modalProps: { title: '编辑账号', width: 500 },
        }}
        editable={{
          editType: 'single',
          deleteConfirmProps: { title: '确定删除该账号吗？', icon: <></> },
          columnProps: {
            width: 150,
          },
        }}
        actionSpaceProps={{
          split: <ActionSplit />,
        }}
        pagination={{
          defaultPageSize: 10,
          showTotal: () => <></>,
        }}
        form={{
          style: {
            justifyContent: 'end',
          },
        }}
        searchConfig={{
          filterType: 'inline',
          // autoSearch: true,
          optionButtons: [],
          extraAdditionButton: true,
          addition: { children: '添加账号', position: 'top' },
        }}
        searchFormRender={(_, dom) => {
          return (
            <div style={{ display: 'flex' }}>
              <Text flex={1} fontSize={19}>
                账号管理
              </Text>
              {dom}
            </div>
          );
        }}
        debounceTime={200}
      >
        <VSTableColumn
          dataIndex={['phone']}
          title="账号"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                pattern: /^(?:\+?86)?1[3-9]\d{9}$/,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['name']}
          title="姓名"
          valueType="text"
          search
          order={-1}
          columnKey={'nameLike'}
          formItemProps={{
            rules: [
              {
                message: '用户姓名长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
            label: '',
          }}
          fieldProps={{
            // addonAfter: <Icon type="SearchOutlined" />,
            placeholder: '搜索姓名、手机号',
            suffix: (
              <div
                onClick={() => {
                  setRefresh(!refresh);
                }}
              >
                <Icon type="SearchOutlined" />
              </div>
            ),
          }}
        />

        <VSTableColumn
          dataIndex={['createdAt']}
          title="创建时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />
      </VSPromiseTable>
    </Section>
  );
};
export default definePage(SchoolAccountList);
