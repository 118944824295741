import { isEmpty } from 'lodash';
import { read } from 'xlsx';

import { findSheet, findTable, readTable } from './table-helper';

export type ExcelTable<T extends Record<string, any>> = {
  name: string;
  data: T[];
};

export const extractTables = <T extends Record<string, any>>(
  excelFile: File,
  fileNames: Record<string, string>,
  required?: (keyof T)[] | boolean,
) => {
  return new Promise<ExcelTable<T>[]>((resolve, reject) => {
    const reader = new FileReader();
    reader.onload = (e) => {
      const data = e.target?.result;
      if (data) {
        try {
          const workbook = read(data, { type: 'binary' });
          const tables = Object.keys(workbook.Sheets).map((key) => {
            const { sheet, range } = findSheet(workbook, key);
            const { columns, firstRow } = findTable(sheet!, range!, fileNames);
            const data = readTable<Partial<T>>(sheet!, range!, columns, 1);
            return {
              name: key,
              data: data.filter((row) => {
                if (typeof required === 'boolean' && required) {
                  // 全部字段都是必填
                  return Object.values(row).every((v) => !isEmpty(v));
                } else if (Array.isArray(required)) {
                  // 数组中的字段必填
                  return required.every((key) => !isEmpty(row[key]));
                }
                // 没有字段必填全部输出
                return true;
              }) as T[],
            };
          });
          resolve(tables);
        } catch (error) {
          reject(error);
        }
      } else {
        reject();
      }
    };
    reader.readAsArrayBuffer(excelFile);
  });
};
