import vsf, { definePage } from '@vs/vsf-boot';
import { message, Section, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import DownloadIcon from '@/assets/images/icon/download_icon.png';
import FileUpload from '@/components/FileUpload';
import { usePlatformInfo } from '@/utils';

const RightExtra = styled.div`
  width: 90px;
  font-size: 14px;
  font-weight: bold;
  color: #2e5ef2;
  display: flex;
  align-items: center;
  cursor: pointer;

  .download_icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

const PrintButton = styled.div`
  width: 84px;
  height: 36px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #2e5ef2;

  font-size: 14px;
  font-weight: 500;
  color: #2e5ef2;
  cursor: pointer;
`;

const SchoolMonitorFinalListTable = (props) => {
  const { taskIdIs, extractRoundIdIs } = props;
  const [platformInfo] = usePlatformInfo();
  const [data, setData] = useState([]);

  const onFetch = useCallback(
    async (params) => {
      if (platformInfo?.platformId && extractRoundIdIs) {
        const res =
          await vsf?.services?.SampleResultDtoController_pageBySampleResultSchool_9da42c?.(
            {
              qto: {
                from: params.pagination?.from ?? 0,
                size: params.pagination?.size ?? 20,
                orderList: params.orderList, // 排序结果
                taskIdIs,
                extractRoundIdIs,
                orgIdIs: platformInfo?.platformId,
                ...params.search, // 搜索结果
                ...params.filters, // 过滤器结果
                ...(params?.extra ?? {}), // 传入的额外参数
              },
              ext: params?.ext,
            },
          );
        const _data = res?.data?.result ?? res?.data ?? [];
        setData(_data);
        return {
          data: _data,
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
    },
    [taskIdIs, platformInfo, extractRoundIdIs],
  );

  const handelDownloadClick = async () => {
    try {
      if (data?.length > 0) {
        const response =
          await vsf?.services?.SampleResultDtoController_getSampleSchoolResult_b2e42a?.(
            {
              extractRoundId: extractRoundIdIs,
              orgId: platformInfo?.platformId,
              taskId: taskIdIs,
            },
          );
        if (response && response.code === 200) {
          FileUpload.download(
            response.data?.excelUrl,
            response?.data?.excelName,
          );
          message.success('已下载');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="monitor__container">
      <Section>
        <VSPromiseTable
          editable={{ editType: 'single', columnProps: { hideInTable: true } }}
          onFetch={onFetch}
          searchConfig={{
            filterType: 'inline',
            optionButtons: 'search',
            searchButtonProps: {
              children: '查询',
            },
          }}
          pagination={{
            defaultPageSize: 10,
            showTotal: () => <></>,
          }}
          searchFormRender={(_, doms) => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                }}
              >
                {doms}

                <div className="aic">
                  <RightExtra
                    onClick={handelDownloadClick}
                    style={{
                      opacity: data?.length > 0 ? 1 : 0.5,
                    }}
                  >
                    <img src={DownloadIcon} className="download_icon" />
                    <span>下载名单</span>
                  </RightExtra>
                  {/* <PrintButton className="aic jcc">打印桌贴</PrintButton> */}
                </div>
              </div>
            );
          }}
        >
          <VSTableColumn
            dataIndex={['orgId']}
            title="学校码"
            valueType="digit"
            formItemProps={{
              rules: [
                {
                  message: '机构id的值不合法',
                  type: 'number',
                  min: 0,
                  max: null,
                },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['classes']}
            title="班级"
            valueType="text"
            search
            order={-35}
            columnKey={'classesLike'}
            formItemProps={{
              rules: [
                { message: '班级长度不合法', type: 'string', min: 0, max: 64 },
              ],
            }}
            fieldProps={{
              width: 110,
              placeholder: '请输入',
            }}
          />

          <VSTableColumn
            dataIndex={['studentMonitorId']}
            title="学生监测号"
            valueType="digit"
            // formItemProps={{
            //   rules: [
            //     {
            //       message: '监测任务id的值不合法',
            //       type: 'number',
            //       min: 0,
            //       max: null,
            //     },
            //   ],
            // }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['name']}
            title="姓名"
            valueType="text"
            search
            order={-34}
            columnKey={'nameLike'}
            formItemProps={{
              rules: [
                {
                  message: '学生姓名长度不合法',
                  type: 'string',
                  min: 0,
                  max: 64,
                },
              ],
            }}
            fieldProps={{
              width: 110,
              placeholder: '请输入',
            }}
          />

          <VSTableColumn
            dataIndex={['sex']}
            title="性别"
            valueType="select"
            dataSource={[
              { label: '男', value: 'MAN' },
              { label: '女', value: 'WOMAN' },
            ]}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['studentStatusNum']}
            title="全国学籍号"
            valueType="text"
            // formItemProps={{
            //   rules: [
            //     {
            //       message: '学籍辅号长度不合法',
            //       type: 'string',
            //       min: 0,
            //       max: 64,
            //     },
            //   ],
            // }}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['idCard']}
            title="证件号"
            valueType="text"
            // formItemProps={{
            //   rules: [
            //     {
            //       message: '学籍辅号长度不合法',
            //       type: 'string',
            //       min: 0,
            //       max: 64,
            //     },
            //   ],
            // }}
            fieldProps={{}}
          />
        </VSPromiseTable>
      </Section>
    </div>
  );
};
export default definePage(SchoolMonitorFinalListTable);
