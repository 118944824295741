import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Modal,
  Section,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { ChangeStatusEnum } from '@/config';
import { usePlatformInfo } from '@/utils';

const ShowButton = styled.div`
  width: 80px;
  height: 30px;
  background: #eaeefd;
  border-radius: 6px;
  border: 1px solid #c9d6ff;

  font-size: 14px;
  font-weight: 400;
  color: #2e5ef2;
  cursor: pointer;
`;

const RevertButton = styled.div`
  width: 80px;
  height: 30px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #dddddd;

  font-size: 14px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
`;

const UnConfirmedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #fe9908;
  margin-right: 8px;
  border-radius: 50%;
`;

const ChangeUnAuditedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #ffcf54;
  margin-right: 8px;
  border-radius: 50%;
`;

const CreateUnAuditedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #12bc82;
  margin-right: 8px;
  border-radius: 50%;
`;

const Confirmed = styled.div`
  width: 5px;
  height: 5px;
  background: #aaaaaa;
  margin-right: 8px;
  border-radius: 50%;
`;
const RejectResonView = styled.div`
  width: 452px;
  height: 160px;
  background: #fafafa;
  border-radius: 6px;
  border: 1px solid #eeeeee;
  overflow: scroll;

  font-size: 14px;
  font-weight: 400;
  color: #333333;
  box-sizing: border-box;
  padding: 8px 12px;
`;

const REJECTED = styled.div`
  width: 5px;
  height: 5px;
  background: #ff381e;
  margin-right: 8px;
  border-radius: 50%;
`;

const SchoolMonitorInfoChangeHistoryTable = (props) => {
  const { taskIdIs } = props;
  const [platformInfo] = usePlatformInfo();
  const [rejectVisible, setRejectVisible] = useState(false);
  const [rejectReason, setRejectReson] = useState('');

  const onFetch = useCallback(
    async (params) => {
      if (platformInfo) {
        const res =
          await vsf?.services?.StudentChangeAuditDtoController_pageByStudentChangeRecordSchool_8593fb?.(
            {
              qto: {
                from: params.pagination?.from ?? 0,
                size: params.pagination?.size ?? 20,
                orderList: params.orderList, // 排序结果
                taskIdIs,
                orgIdIs: platformInfo?.platformId,
                ...params.search, // 搜索结果
                ...params.filters, // 过滤器结果
                ...(params?.extra ?? {}), // 传入的额外参数
              },
              ext: params?.ext,
            },
          );
        return {
          data: res?.data?.result ?? res?.data ?? [],
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
    },
    [taskIdIs, platformInfo],
  );

  return (
    <Section className="monitorStatus">
      <VSPromiseTable
        editable={{ editType: 'single', columnProps: { hideInTable: true } }}
        searchConfig={{
          filterType: 'inline',
          optionButtons: 'search',
          searchButtonProps: {
            children: '查询',
          },
        }}
        pagination={{
          defaultPageSize: 10,
          showTotal: () => <></>,
        }}
        onFetch={onFetch}
      >
        <VSTableColumn
          dataIndex={['studentStatusNum']}
          title="学籍辅号"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '学籍辅号长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['name']}
          title="姓名"
          valueType="text"
          search
          order={-10}
          columnKey={'nameLike'}
          formItemProps={{
            rules: [
              {
                message: '学生姓名长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{
            width: 110,
            placeholder: '请输入',
          }}
        />

        <VSTableColumn
          dataIndex={['grade']}
          title="年级"
          valueType="select"
          dataSource={[
            { label: '一年级', value: 'FIRST_GRADE' },
            { label: '二年级', value: 'SECOND_GRADE' },
            { label: '三年级', value: 'THIRD_GRADE' },
            { label: '四年级', value: 'FOURTH_GRADE' },
            { label: '五年级', value: 'FIFTH_GRADE' },
            { label: '六年级', value: 'SIXTH_GRADE' },
            { label: '七年级', value: 'SEVENTH_GRADE' },
            { label: '八年级', value: 'EIGHTH_GRADE' },
            { label: '九年级', value: 'NINE_GRADE' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['classes']}
          title="班级"
          valueType="text"
          search
          order={-11}
          columnKey={'classesLike'}
          formItemProps={{
            rules: [
              { message: '班级长度不合法', type: 'string', min: 0, max: 64 },
            ],
          }}
          fieldProps={{
            width: 110,
            placeholder: '请输入',
          }}
        />

        <VSTableColumn
          dataIndex={['sex']}
          title="性别"
          valueType="select"
          dataSource={[
            { label: '男', value: 'MAN' },
            { label: '女', value: 'WOMAN' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['changeStatus']}
          title="状态"
          valueType="select"
          search
          width={140}
          order={-12}
          columnKey={'changeStatusIs'}
          dataSource={[
            { label: '信息变更已通过', value: 'CONFIRMED' },
            { label: '信息变更不通过', value: 'REJECTED' },
            { label: '信息变更待审核', value: 'CHANGE_UNAUDITED' },
            { label: '待接收学校审核', value: 'SCHOOL_UNAUDITED' },
            { label: '新增待审核', value: 'CREATE_UNAUDITED' },
            { label: '信息变更待确认', value: 'UNCONFIRMED' },
          ]}
          fieldProps={{
            placeholder: '请选择',
          }}
          render={(doms, record) => {
            return (
              <div className="aic">
                {record?.changeStatus === ChangeStatusEnum.UNCONFIRMED && (
                  <UnConfirmedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.CHANGE_UNAUDITED && (
                  <ChangeUnAuditedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.CREATE_UNAUDITED && (
                  <CreateUnAuditedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.CONFIRMED && (
                  <Confirmed />
                )}
                {record?.changeStatus === ChangeStatusEnum.REJECTED && (
                  <REJECTED />
                )}
                {record?.changeStatus === ChangeStatusEnum.SCHOOL_UNAUDITED && (
                  <ChangeUnAuditedAic />
                )}
                {doms}
              </div>
            );
          }}
        />

        <VSTableColumn title="操作" name={'action'} valueType="option">
          <VSTableAction
            disableLoading
            show={(record) => record?.changeStatus === 'CHANGE_UNAUDITED'}
            linkPage={{
              updateTable: true,
              updateType: 'reload',
              onLinkPageValidate: async (_, __, record) => {
                try {
                  const response =
                    await vsf?.services?.StudentChangeAuditBOController_revokeApplication_ebd66d?.(
                      {
                        studentId: record?.studentId,
                      },
                    );
                  if (response && response.code === 200) {
                    message.success('撤回成功');
                    return true;
                  }
                  return false;
                } catch (error) {
                  console.error(error);
                  return false;
                }
              },
            }}
          >
            <RevertButton className="aic jcc">撤回申请</RevertButton>
          </VSTableAction>
          <VSTableAction
            show={(record) => record?.changeStatus === 'REJECTED'}
            onClick={(_, record) => {
              setRejectReson(record?.rejectReason);
              setRejectVisible(true);
            }}
          >
            <ShowButton className="aic jcc">查看原因</ShowButton>
          </VSTableAction>

          <VSTableAction
            show={(record) =>
              record?.changeStatus !== ChangeStatusEnum.REJECTED &&
              record?.changeStatus !== ChangeStatusEnum.CHANGE_UNAUDITED
            }
          >
            <span>-</span>
          </VSTableAction>
        </VSTableColumn>
      </VSPromiseTable>

      <Modal
        open={rejectVisible}
        title="信息变更不通过原因"
        onCancel={() => setRejectVisible(false)}
        footer={false}
      >
        <RejectResonView>{rejectReason ?? ''}</RejectResonView>
      </Modal>
    </Section>
  );
};
export default definePage(SchoolMonitorInfoChangeHistoryTable);
