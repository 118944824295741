import React from 'react';

const ActionSplit = () => {
  return (
    <span
      style={{
        background: '#E2E1F1',
        width: 2,
        height: 14,
        display: 'flex',
      }}
    />
  );
};

export default ActionSplit;
