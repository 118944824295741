import vsf from '@vs/vsf-boot';

let ossPolicy = null;

const getOssPolicy = async (suffixName, fileName) => {
  // if (ossPolicy) return ossPolicy;
  const { data } = await vsf.services.OssController_getOssPolicys_0d5321({
    suffixName,
    fileName,
  });
  ossPolicy = data;
  return data;
};

export default getOssPolicy;
