import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Modal,
  RadioGroup,
  Section,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useMemo, useState } from 'react';

import SampleResetIcon from '@/assets/images/icon/sample_reset_icon.png';
import { labelColWidth } from '@/components/TreeMenu/utils';
import { ExtractMechanismEnum, SexEnum, UnitEnum } from '@/config';
import ChinaNum from '@/pages/RandomSampleList/ChinaNum';
import { navigateToWithPlatform, usePlatformInfo } from '@/utils';

import ReferenceGrades from './ReferenceGrades';
const DepartmentMonitorSampleConfigForm = (props) => {
  const { monitorTaskId, period, monitorTaskStatus, grade } = props;

  const [monitorTask, setMonitorTask] = useState(props.monitorTask);
  const [value, setValue] = useState();
  const [count, setCount] = useState(0);

  const [taskSubject, setTaskSubject] = useState([]);
  const [extractMechanismVisible, setExtractMechanismVisible] = useState(false);
  const [extractMechanism, setExtractMechanism] = useState(
    ExtractMechanismEnum.NOT_DUP,
  );

  const [platformInfo] = usePlatformInfo();

  const loadTaskSubject = useCallback(async () => {
    try {
      const response =
        await vsf?.services?.MonitorTaskController_getMonitorTaskSubject_02ee42?.(
          {
            monitorTaskId,
          },
        );
      if (response && response.code === 200 && response.data) {
        setTaskSubject(response.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [monitorTaskId]);

  const getDefaultExtractDimensionBtoList = useCallback(
    (_subject, sex) => {
      let arr = [];

      const _distinguishSex =
        sex !== undefined ? sex : monitorTask?.distinguishSex ?? true;
      if (_distinguishSex) {
        arr = [
          {
            sex: SexEnum.MAN,
            dimensionName: 'A',
            extractNum: '',
            backupNum: '',
            unit: UnitEnum.INDIVIDUAL,
          },
          {
            sex: SexEnum.MAN,
            dimensionName: 'B',
            extractNum: '',
            backupNum: '',
            unit: UnitEnum.INDIVIDUAL,
          },
          {
            sex: SexEnum.MAN,
            dimensionName: 'C',
            extractNum: '',
            backupNum: '',
            unit: UnitEnum.INDIVIDUAL,
          },
          {
            sex: SexEnum.MAN,
            dimensionName: 'D',
            extractNum: '',
            backupNum: '',
            unit: UnitEnum.INDIVIDUAL,
          },
          {
            sex: SexEnum.WOMAN,
            dimensionName: 'A',
            extractNum: '',
            backupNum: '',
            unit: UnitEnum.INDIVIDUAL,
          },
          {
            sex: SexEnum.WOMAN,
            dimensionName: 'B',
            extractNum: '',
            backupNum: '',
            unit: UnitEnum.INDIVIDUAL,
          },
          {
            sex: SexEnum.WOMAN,
            dimensionName: 'C',
            extractNum: '',
            backupNum: '',
            unit: UnitEnum.INDIVIDUAL,
          },
          {
            sex: SexEnum.WOMAN,
            dimensionName: 'D',
            extractNum: '',
            backupNum: '',
            unit: UnitEnum.INDIVIDUAL,
          },
        ];
      } else {
        arr = [
          {
            dimensionName: 'A',
            extractNum: '',
            backupNum: '',
            unit: UnitEnum.INDIVIDUAL,
          },
          {
            dimensionName: 'B',
            extractNum: '',
            backupNum: '',
            unit: UnitEnum.INDIVIDUAL,
          },
          {
            dimensionName: 'C',
            extractNum: '',
            backupNum: '',
            unit: UnitEnum.INDIVIDUAL,
          },
          {
            dimensionName: 'D',
            extractNum: '',
            backupNum: '',
            unit: UnitEnum.INDIVIDUAL,
          },
        ];
      }

      if (_subject === 'PHYSICAL') {
        return arr?.filter((v) => v.dimensionName !== 'D');
      }
      return arr;
    },
    [monitorTask],
  );

  const fromData = useCallback(() => {
    if (!monitorTask?.id) {
      return {
        extractType: monitorTask.extractType ?? 'STUDENT',
        extractMethod: monitorTask.extractMethod ?? 'RANDOM',
        distinguishSex: monitorTask?.distinguishSex ?? true,
        extractRoundBtoList: taskSubject?.map((item) => ({
          subject: item.subject,
          ...(monitorTask.extractMethod === 'CUSTOM'
            ? {
                unit: UnitEnum.INDIVIDUAL,
                extractDimensionBtoList: getDefaultExtractDimensionBtoList(
                  item.subject,
                ),
              }
            : {}),
        })),
      };
    }

    return {
      extractRoundBtoList: monitorTask?.extractRoundDtoList?.map((item) => ({
        ...item,
        scoreTaskId: {
          id: item?.scoreTaskId,
          name: item?.scoreTaskName,
        },
        extractDimensionBtoList: item?.extractDimensionDtoList,
      })),
      ...monitorTask,
    };
  }, [getDefaultExtractDimensionBtoList, taskSubject, monitorTask]);

  useEffect(() => {
    loadTaskSubject();
  }, [loadTaskSubject]);

  useEffect(() => {
    setValue(fromData(props.value));
  }, [fromData, props.value]);

  const handelExtractTypeChange = (_extractType) => {
    if (_extractType === 'STUDENT') {
      setValue({
        ...value,
        extractType: _extractType,
        extractMethod: 'RANDOM',
      });
      setMonitorTask({
        ...monitorTask,
        extractType: _extractType,
        extractMethod: 'RANDOM',
      });
    } else {
      setValue({
        ...value,
        extractType: _extractType,
        extractMethod: 'RANDOM_CLASSES',
      });
      setMonitorTask({
        ...monitorTask,
        extractType: _extractType,
        extractMethod: 'RANDOM_CLASSES',
      });
    }
  };

  const handelExtractMethodChange = (_extractMethod) => {
    setValue({
      ...value,
      extractMethod: _extractMethod,
    });
    setMonitorTask({
      ...monitorTask,
      extractMethod: _extractMethod,
    });

    if (_extractMethod === 'CUSTOM') {
      setMonitorTask({
        ...monitorTask,
        extractMethod: _extractMethod,
        extractRoundBtoList: value?.extractRoundBtoList?.map((item) => ({
          ...item,
          unit: UnitEnum.INDIVIDUAL,
          extractDimensionBtoList: getDefaultExtractDimensionBtoList(
            item.subject,
          ),
        })),
      });
    }
  };

  const handelSexChange = (_distinguishSex) => {
    setMonitorTask({
      ...monitorTask,
      distinguishSex: _distinguishSex,
      extractRoundBtoList: monitorTask?.extractRoundDtoList?.map((item) => ({
        ...item,
        scoreTaskId: {
          id: item?.scoreTaskId,
          name: item?.scoreTaskName,
        },
        ...(monitorTask?.id
          ? {
              extractDimensionBtoList: getDefaultExtractDimensionBtoList(
                item.subject,
                _distinguishSex,
              ),
            }
          : {
              extractDimensionBtoList: getDefaultExtractDimensionBtoList(
                item.subject,
                _distinguishSex,
              ),
            }),
      })),
    });
  };

  const toData = (value) => {
    return value;
  };

  const handelSubmit = async () => {
    try {
      const _formParam =
        await vsf?.refs?.DepartmentMonitorSampleConfigForm?.validateFieldsReturnFormatValue();
      const formParam = {
        ..._formParam,
        extractRoundBtoList: _formParam?.extractRoundBtoList?.map(
          (item, _idx) => ({
            subject: item?.subject,
            scoreTaskId: item?.scoreTaskId?.id,
            ...(monitorTask?.id
              ? {
                  id: item?.id,
                }
              : {}),
            beforeId: _idx + 1,
            unit: item?.unit ?? UnitEnum.INDIVIDUAL,
            extractMechanism:
              typeof item?.extractMechanism === 'undefined'
                ? ExtractMechanismEnum.DUP
                : item?.extractMechanism,
            classesNum: item?.classesNum,
            needConfirmedTotal: item?.needConfirmedTotal,

            extractDimensionBtoList: item?.extractDimensionBtoList?.map(
              (item) => ({
                ...item,
                extractNum: `${item?.extractNum}`?.length
                  ? item?.extractNum
                  : '0',
                backupNum: `${item?.backupNum}`?.length ? item?.backupNum : '0',
                ...(_formParam?.extractMethod === 'CUSTOM'
                  ? {
                      ...(_formParam?.distinguishSex
                        ? {}
                        : {
                            sex: 'UNKNOWN',
                          }),
                    }
                  : {}),
              }),
            ),
          }),
        ),
      };

      if (_formParam?.extractType === 'STUDENT') {
        for (let i = 0; i < formParam?.extractRoundBtoList?.length; i++) {
          if (!formParam?.extractRoundBtoList[i]?.scoreTaskId) {
            message.error(`请选择第${ChinaNum[i]}轮抽取的参考成绩`);
            return false;
          }
        }
      }

      const params = {
        monitorTaskId,
        ...formParam,
        extractMechanism,
        ...(formParam?.extractMethod === 'CUSTOM'
          ? {
              backupNum: undefined,
            }
          : {
              backupNum: formParam?.backupNum ?? 0,
            }),
      };

      console.log(`======RequestParams${params}======`);

      const response = monitorTask?.id
        ? await vsf?.services?.ExtractConfigBOController_updateExtractConfig_748c2d?.(
            {
              btoParam: {
                id: monitorTask?.id,
                ...params,
              },
            },
          )
        : await vsf?.services?.ExtractConfigBOController_createExtractConfig_9bc2cb?.(
            {
              btoParam: params,
            },
          );
      if (response && response.code === 200) {
        message.success('保存成功');
        setExtractMechanismVisible(false);
        setExtractMechanism(ExtractMechanismEnum.NOT_DUP);

        const _query = `period=${period}&grade=${grade}&monitorTaskStatus=${monitorTaskStatus}`;
        navigateToWithPlatform(
          `/${platformInfo?.platformId}/monitor/${monitorTaskId}/sampleConfigTable?${_query}`,
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelSaveClick = async () => {
    try {
      const _formParam =
        await vsf?.refs?.DepartmentMonitorSampleConfigForm?.validateFieldsReturnFormatValue();
      console.log(_formParam, '_formParam');
      const formParam = {
        ..._formParam,
        extractRoundBtoList: _formParam?.extractRoundBtoList?.map(
          (item, _idx) => ({
            subject: item?.subject,
            scoreTaskId: item?.scoreTaskId?.id,
            ...(monitorTask?.id
              ? {
                  id: item?.id,
                }
              : {}),
            beforeId: _idx + 1,
            unit: item?.unit ?? UnitEnum.INDIVIDUAL,
            extractMechanism: item?.extractMechanism,
            classesNum: item?.classesNum,
            needConfirmedTotal: item?.needConfirmedTotal,
            extractDimensionBtoList: item?.extractDimensionBtoList?.map(
              (item) => ({
                ...item,
                extractNum: item?.extractNum?.length ? item?.extractNum : '0',
                backupNum: item?.backupNum?.length ? item?.backupNum : '0',
              }),
            ),
          }),
        ),
      };

      if (_formParam?.extractType === 'STUDENT') {
        for (let i = 0; i < formParam?.extractRoundBtoList?.length; i++) {
          if (!formParam?.extractRoundBtoList[i]?.scoreTaskId) {
            message.error(`请选择第${ChinaNum[i]}轮抽取的参考成绩`);
            return false;
          }
        }
      } else {
        for (let i = 0; i < formParam?.extractRoundBtoList?.length; i++) {
          if (!formParam?.extractRoundBtoList[i]?.classesNum) {
            message.error(`请输入第${ChinaNum[i]}轮抽取的班级数`);
            return false;
          }
        }
      }

      // setExtractMechanismVisible(true);
      handelSubmit();
    } catch (error) {
      console.error(error);
    }
  };

  const handelResetClick = () => {
    vsf?.refs?.DepartmentMonitorSampleConfigForm?.resetFields?.();
    setMonitorTask({});
    setValue({
      extractType: monitorTask.extractType ?? 'STUDENT',
      extractMethod: monitorTask.extractMethod ?? 'RANDOM',
      distinguishSex: monitorTask?.distinguishSex ?? true,
      extractRoundBtoList: taskSubject?.map((item) => ({
        subject: item.subject,
        ...(monitorTask.extractMethod === 'CUSTOM'
          ? {
              unit: UnitEnum.INDIVIDUAL,
              extractDimensionBtoList: getDefaultExtractDimensionBtoList(
                item.subject,
              ),
            }
          : {}),
      })),
    });
  };

  return (
    <Section>
      <VSControlledForm
        vsid="30534"
        id="DepartmentMonitorSampleConfigForm"
        style={{
          width: value?.extractMethod === 'CUSTOM' ? 834 : 639,
        }}
        value={value}
        onChange={(_value) => {
          setValue(_value);
          const ret = toData(_value);
          props.onChange?.(ret);
        }}
      >
        <VSFormLayout key="0" columnCount={1} labelCol={labelColWidth[7]}>
          <VSFormItem
            name={['extractType']}
            label="抽样类型"
            valueType="radio"
            dataSource={[
              { label: '按学生抽', value: 'STUDENT' },
              { label: '按班级抽', value: 'CLASSES' },
            ]}
            fieldProps={{
              onChange: handelExtractTypeChange,
            }}
            required
          />
          <VSFormItem
            name={['extractMethod']}
            label="抽样方式"
            valueType="radio"
            dataSource={
              value?.extractType === 'STUDENT'
                ? [
                    { label: '随机抽样', value: 'RANDOM' },
                    { label: '等距抽样', value: 'EQUIDISTANCE' },
                    { label: '自定义', value: 'CUSTOM' },
                  ]
                : [
                    { label: '随机抽取班级', value: 'RANDOM_CLASSES' },
                    { label: '抽取中间班级', value: 'MIDDLE_CLASSES' },
                  ]
            }
            fieldProps={{
              onChange: handelExtractMethodChange,
            }}
            required
          />
          {value?.extractMethod === 'EQUIDISTANCE' &&
            value?.extractType === 'STUDENT' && (
              <div className="custom_range_digit">
                <VSFormItem
                  name={'range'}
                  label="首个样本范围"
                  valueType="digitRange"
                  rules={[
                    {
                      required: true,
                      message: '请输入首个样本范围',
                    },
                  ]}
                  fieldProps={{
                    min: 0,
                    inputNumberProps: [
                      { placeholder: '请输入最小值' },
                      { placeholder: '请输入最大值' },
                    ],
                  }}
                  required
                  style={{ width: '100%' }}
                  transform={(v) => {
                    return {
                      startRange: v?.[0],
                      endRange: v?.[1],
                    };
                  }}
                />
              </div>
            )}
          {value?.extractMethod !== 'CUSTOM' &&
            value?.extractType === 'STUDENT' && (
              <VSFormItem
                name={['extractNum']}
                label="每个维度抽取数"
                valueType="digit"
                rules={[
                  {
                    required: true,
                    message: '请输入每个维度抽取数',
                  },
                ]}
                fieldProps={{
                  placeholder: '请输入抽取数值',
                  min: 0,
                }}
                required
                style={{ width: '100%' }}
              />
            )}
          {value?.extractMethod !== 'CUSTOM' &&
            value?.extractType === 'STUDENT' && (
              <VSFormItem
                name={['backupNum']}
                label="备选数"
                valueType="digit"
                rules={[
                  {
                    message: '备选数的值不合法',
                    type: 'number',
                    min: 0,
                    max: null,
                  },
                ]}
                fieldProps={{ min: 0 }}
                transform={(_backupNum) => {
                  return {
                    backupNum: _backupNum ?? 0,
                  };
                }}
              />
            )}
          {value?.extractType === 'STUDENT' && (
            <VSFormItem
              name={['distinguishSex']}
              label="性别维度"
              valueType="radio"
              dataSource={[
                { label: '区分', value: true },
                { label: '不区分', value: false },
              ]}
              required
              fieldProps={{
                onChange: handelSexChange,
              }}
            />
          )}
          <VSFormItem
            name={['extractRoundBtoList']}
            label="抽取规则"
            valueType="randomSampleList"
            fieldProps={{
              extractTypeState: value?.extractType,
              extractMethodState: value?.extractMethod,
              distinguishSexState: value?.distinguishSex,
            }}
          />
        </VSFormLayout>
      </VSControlledForm>
      <div className="aic" style={{ width: '100%', marginLeft: 120 }}>
        <div className="sample_item__save aic jcc" onClick={handelSaveClick}>
          保存
        </div>
        <div className="sample_item__reset aic jcc" onClick={handelResetClick}>
          <img src={SampleResetIcon} className="sample_item__reset__icon" />
          <span>重置</span>
        </div>
      </div>
      <div style={{ height: 43 }} />

      <Modal
        open={extractMechanismVisible}
        title="抽取机制"
        onCancel={() => setExtractMechanismVisible(false)}
        onOk={handelSubmit}
      >
        <RadioGroup
          style={{
            display: 'flex',
            flexDirection: 'column',
          }}
          value={extractMechanism}
          dataSource={[
            {
              label: '抽取上一轮剩下的学生',
              value: ExtractMechanismEnum.NOT_DUP,
            },
            {
              label: '可抽取上一轮重复的学生',
              value: ExtractMechanismEnum.DUP,
            },
          ]}
          onChange={(e) => {
            setExtractMechanism(e);
          }}
        />
      </Modal>
    </Section>
  );
};
export default definePage(DepartmentMonitorSampleConfigForm);
