import SchoolAccountList from '@pages/SchoolAccountList';
import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React from 'react';
const SchoolPriviledgeAccount = (props) => {
  return (
    <Page title="">
      <SchoolAccountList id={props.routes.params?.platformId} />
    </Page>
  );
};
export default definePage(SchoolPriviledgeAccount);
