import SchoolMonitorInfoChangeCheckTable from '@pages/SchoolMonitorInfoChangeCheckTable';
import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React from 'react';

const SchoolMonitorInfoChangeCheck = (props) => {
  const {
    routes: {
      params: { taskIdIs },
      query: { taskOrgId },
    },
  } = props;

  return (
    <Page>
      <SchoolMonitorInfoChangeCheckTable
        orgId={taskOrgId}
        taskIdIs={taskIdIs}
      />
    </Page>
  );
};
export default definePage(SchoolMonitorInfoChangeCheck);
