import './TreeMenu.less';

import DepartmentMonitorTaskListTable from '@pages/DepartmentMonitorTaskListTable';
import vsf, { renderRoutes } from '@vs/vsf-boot';
import {
  Dropdown,
  Empty,
  Input,
  Layout,
  message,
  Modal,
  Page,
  Spin,
} from '@vs/vsf-kit';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import NewGroupIcon from '@/assets/images/icon/new_group_icon.png';
import PeopleIcon from '@/assets/images/icon/people_icon.png';
import TreeAddBlueIcon from '@/assets/images/icon/tree_add_blue_icon.png';
import WarningIcon from '@/assets/images/warning_icon.png';
import TreeMenu from '@/components/TreeMenu';
import { buildTree } from '@/components/TreeMenu/utils';
import { navigateToWithPlatform } from '@/utils';

const DeleteModalCancelButton = styled.div`
  width: 110px;
  height: 44px;
  background: #eaeefd;
  border-radius: 6px;
  border: 1px solid #c9d6ff;
  font-size: 16px;
  font-weight: 500;
  color: #2e5ef2;
  cursor: pointer;
  margin-right: 20px;
`;

const DeleteModalConfirmButton = styled.div`
  width: 110px;
  height: 44px;
  background: #2e5ef2;
  border-radius: 6px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  cursor: pointer;
  margin-left: 20px;
`;
enum TreeItemStatus {
  NEW = 'new',
  READ = 'read',
  EDIT = 'edit',
}

const { Sider } = Layout;

const dataRelationFieldNames = {
  id: 'key',
  parentId: 'parentId',
};

const DepartmentMonitorTaskList = (props) => {
  const [collapsed, setCollapsed] = useState(false);
  const [gruopDropVisible, setGruopDropVisible] = useState(false);
  const [moreDropVisible, setMoreDropVisible] = useState(false);
  const [treeData, setTreeData] = useState<any[]>([]);
  const nodeIds = useRef<number[]>([]);
  const [confirmModalVisible, setConfirmModalVisible] = useState(false);
  const [deleteText, setDeleteText] = useState<any>();

  const [activeTreeNode, setActiveTreeNode] = useState<any>();
  const [defaultSelectedKeys, setDefaultSelectedKeys] = useState<any>();
  const [expandedKeys, setExpandedKeys] = useState<any>();

  const { route } = props;

  const tempInputValue = useRef('');

  const fetchGetAllScoreMenu = useCallback(async () => {
    try {
      const response =
        await vsf.services?.MonitorMenuDtoController_getAllMonitorMenu_86b783?.(
          {
            status: '0',
          },
        );
      if (response && response.code) {
        const _tempList = (response.data ?? [])?.map((item) => ({
          key: item.id,
          title: item.name,
          parentId: !item.parentId ? null : item.parentId,
          status: TreeItemStatus.READ,
        }));
        setTreeData(_tempList);

        const _defaultSelectNode = getDefaultSelectNode(_tempList);
        setExpandedKeys([_defaultSelectNode?.key]);
        setDefaultSelectedKeys(_defaultSelectNode);
        setActiveTreeNode(_defaultSelectNode);
      }
    } catch (error) {
      console.error(error);
    }
  }, []);

  useEffect(() => {
    fetchGetAllScoreMenu();
  }, [fetchGetAllScoreMenu]);

  const generateRandomNumber = () => {
    const min = 10000; // 最小值
    const max = 99999; // 最大值
    const randomNumber = Math.floor(Math.random() * (max - min + 1)) + min;
    if (nodeIds.current?.includes(randomNumber)) {
      return generateRandomNumber();
    } else {
      return randomNumber;
    }
  };

  const handelAddGroupClick = () => {
    if (!treeData?.find((item) => item.status === TreeItemStatus.NEW)) {
      const tempList = cloneDeep(treeData);
      const id = generateRandomNumber();
      nodeIds.current.push(id);
      setTreeData([
        ...tempList,
        {
          key: 0,
          status: TreeItemStatus.NEW,
          parentId: null,
        },
      ]);
    }
  };

  const fetchCreateScoreMenu = async (name, parentId) => {
    try {
      const response =
        await vsf.services.MonitorMenuBOController_createMonitorMenu_e7b194?.({
          btoParam: {
            name,
            parentId: parentId ? parentId : 0,
            status: 0,
          },
        });
      if (response && response.code === 200) {
        return Promise.resolve(response.data?.id);
      }
      return Promise.reject();
    } catch (error) {
      console.error(error);
    }
  };

  const handelAddSubGroup = (key, parentId) => {
    const tempList = cloneDeep(treeData);
    const id = generateRandomNumber();
    nodeIds.current.push(id);
    setTreeData([
      ...tempList,
      {
        key: id,
        status: TreeItemStatus.NEW,
        parentId,
      },
    ]);
  };

  const handelInputBlur = async (value, treeId, parentId) => {
    try {
      const currentEditItem = parentId
        ? treeData
            ?.find((item) => item.key === parentId)
            ?.children?.find((item) => item.key === treeId)
        : treeData?.find((item) => item.key === treeId);
      if (currentEditItem?.status === TreeItemStatus.EDIT) {
        const response =
          await vsf?.services?.MonitorMenuBOController_updateMonitorMenu_db4be5?.(
            {
              btoParam: {
                id: currentEditItem?.key,
                name: value,
                parentId,
                status: '0',
              },
            },
          );
        if (response && response.code === 200) {
          message.success('重命名成功');
          const _treeData = treeData?.map((item) => ({
            ...item,
            ...(item.key === treeId
              ? {
                  status: TreeItemStatus.READ,
                  title: value,
                }
              : {}),
          }));
          setTreeData(_treeData);
        }
      } else {
        if (value) {
          const responseId = await fetchCreateScoreMenu(value, parentId);
          const _treeData = treeData?.map((item) => ({
            ...item,
            ...(item.key === treeId
              ? {
                  status: TreeItemStatus.READ,
                  title: value,
                  key: responseId,
                }
              : {}),
          }));
          setTreeData(_treeData);
        } else {
          message.error('请输入分组名称');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelEditItem = (treeId) => {
    setTreeData(
      treeData?.map((item) => ({
        ...item,
        ...(item.key === treeId
          ? {
              status: TreeItemStatus.EDIT,
              title: item.title,
            }
          : {}),
      })),
    );
  };

  const handelDeleteItem = async () => {
    try {
      const response =
        await vsf?.services?.MonitorMenuBOController_deleteMonitorMenu_95c22a?.(
          {
            btoParam: {
              id: deleteText?.key,
            },
          },
        );
      if (response && response.code === 200) {
        message.success('删除成功');

        const tempList = cloneDeep(treeData);
        setTreeData(tempList?.filter((item) => item.key !== deleteText?.key));
        setConfirmModalVisible(false);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelTreeSelect = (node) => {
    if (node?.key) {
      if (node.status !== 'new') {
        setActiveTreeNode(node);
      }
    }
  };

  const getDefaultSelectNode = (_treeData) => {
    try {
      const getChildren = (node) => {
        if (node?.children) {
          return getChildren(node?.children?.[0]);
        }
        return node;
      };
      const buildTreeData = buildTree(
        _treeData ?? [],
        null,
        dataRelationFieldNames,
      );
      const firstChildren = getChildren(buildTreeData?.[0]);
      if (firstChildren) {
        // handelTreeSelect(firstChildren);
        return firstChildren;
      }
      return undefined;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  };

  const renderCustomerTitle = (node) => {
    if (
      node.status === TreeItemStatus.NEW ||
      node.status === TreeItemStatus.EDIT
    ) {
      return (
        <Input
          {...(node.status === TreeItemStatus.EDIT
            ? {
                defaultValue: node.title,
              }
            : {})}
          placeholder="请输入新分组名称"
          onBlur={(e) => {
            handelInputBlur(e.target.value, node.key, node.parentId);
          }}
        />
      );
    } else if (node.status === TreeItemStatus.READ) {
      return (
        <div className="treenode-item-normal aic jcsb">
          <div className="ell" style={{ width: '80%' }}>
            {node.title}
          </div>
          <div className="aic">
            <Dropdown
              trigger={['hover']}
              placement="bottom"
              arrow={{ pointAtCenter: true }}
              menu={{
                items: [
                  {
                    label: '新建同级分组',
                    key: 'same',
                    onClick: (e) => {
                      e?.domEvent?.stopPropagation?.();
                      handelAddSubGroup(e.key, node.parentId);
                    },
                  },
                  {
                    label: '新建子级分组',
                    key: 'child',
                    onClick: (e) => {
                      setExpandedKeys([...expandedKeys, node.key]);
                      e?.domEvent?.stopPropagation?.();
                      handelAddSubGroup(e.key, node.key);
                    },
                  },
                ],
              }}
            >
              <div
                className="treenode-item-normal-iconAdd"
                onClick={() => setGruopDropVisible(!gruopDropVisible)}
              ></div>
            </Dropdown>
            <Dropdown
              trigger={['hover']}
              placement="bottom"
              arrow={{ pointAtCenter: true }}
              menu={{
                items: [
                  {
                    label: '重命名',
                    key: 'reanme',
                    onClick: (e) => {
                      e?.domEvent?.stopPropagation?.();
                      handelEditItem(node.key);
                    },
                  },
                  {
                    label: '删除',
                    key: 'delete',
                    onClick: (e: any) => {
                      e?.domEvent?.stopPropagation?.();
                      setDeleteText(node);
                      setConfirmModalVisible(true);
                      // handelDeleteItem(node);
                    },
                  },
                ],
              }}
            >
              <div
                className="treenode-item-normal-iconMore"
                onClick={() => setMoreDropVisible(!moreDropVisible)}
              ></div>
            </Dropdown>
          </div>
        </div>
      );
    }
  };

  return (
    <div className="vsf-layout-sec">
      <Sider
        className="vsf-layout-sec-sider"
        theme="light"
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        width={240}
      >
        <div className="vsf-layout-sec-sider-header aic">
          <img src={PeopleIcon} className="vsf-layout-sec-sider-header-icon" />
          <span>监测管理</span>
        </div>
        <div
          className="vsf-layout-sec-sider-new aic jcsb"
          onClick={handelAddGroupClick}
        >
          <div />
          <span>新建分组</span>
          <img src={NewGroupIcon} />
        </div>
        <TreeMenu
          treeProps={{
            titleRender: renderCustomerTitle,
            expandedKeys,
            defaultExpandedKeys: expandedKeys ? [expandedKeys?.key] : [],
            defaultSelectedKeys: defaultSelectedKeys
              ? [defaultSelectedKeys?.key]
              : [],
            onExpand: (node) => {
              setExpandedKeys(node);
            },
          }}
          search={{
            status: false,
          }}
          dataRelationFieldNames={dataRelationFieldNames}
          treeData={cloneDeep(treeData)}
          onSelect={handelTreeSelect}
        />
      </Sider>
      <div className="vsf-layout-sec-main">
        {activeTreeNode?.key ? (
          <DepartmentMonitorTaskListTable menuIdIs={activeTreeNode?.key} />
        ) : (
          <Page>
            {/* <Spin /> */}
            <Empty description="暂无数据" />
          </Page>
        )}
      </div>

      <Modal
        // confirmModalVisible
        open={confirmModalVisible}
        width={460}
        footer={false}
        onCancel={() => setConfirmModalVisible(false)}
      >
        <div style={{ height: 20 }} />
        <div className="col aic">
          <img src={WarningIcon} style={{ width: 80, height: 90 }} />
        </div>
        <div style={{ textAlign: 'center', width: '100%', fontWeight: 600 }}>
          确定删除“{deleteText?.title}”吗？
        </div>
        <div
          className="aic"
          style={{ marginTop: 30, marginBottom: 23, justifyContent: 'center' }}
        >
          <DeleteModalCancelButton
            className="aic jcc"
            onClick={() => setConfirmModalVisible(false)}
          >
            取消
          </DeleteModalCancelButton>
          <DeleteModalConfirmButton
            className="aic jcc"
            onClick={handelDeleteItem}
          >
            确定
          </DeleteModalConfirmButton>
        </div>
      </Modal>
    </div>
  );
};

export default DepartmentMonitorTaskList;
