import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React from 'react';

import SchoolMonitorInfoCheckTable from './SchoolMonitorInfoCheckTable';

const SchoolMonitorInfoCheck = (props) => {
  const {
    routes: {
      params: { taskIdIs },
      query: { type, grade, taskOrgId },
    },
  } = props;

  return (
    <Page>
      <SchoolMonitorInfoCheckTable
        type={type}
        taskIdIs={taskIdIs}
        grade={grade}
        taskOrgId={taskOrgId}
      />
    </Page>
  );
};

export default definePage(SchoolMonitorInfoCheck);
