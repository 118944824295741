import vsf, { definePage } from '@vs/vsf-boot';
import {
  Col,
  Image,
  message,
  Modal,
  Row,
  Section,
  TextArea,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import FileUpload from '@/components/FileUpload';
import {
  ChangeStatusEnum,
  ChangeStatusEnumValue,
  GradeEnumValue,
} from '@/config';

const ImageSuffix = ['.jpg', '.png', 'jpeg', '.webp'];

const PrimaryButton = styled.div`
  width: 84px;
  height: 36px;
  background: #2e5ef2;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
`;

const RejectButton = styled.div`
  width: 80px;
  height: 30px;
  background: #ffebe7;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  color: #ff3d11;
  cursor: pointer;
`;

const RevertButton = styled.div`
  width: 80px;
  height: 30px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #dddddd;

  font-size: 14px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
`;

const ConfirmButton = styled.div`
  width: 80px;
  height: 30px;
  background: #eaeefd;
  border-radius: 6px;
  border: 1px solid #c9d6ff;
  font-size: 14px;
  font-weight: 400;
  color: #2e5ef2;
  cursor: pointer;
`;
const ImageView = styled.div`
  width: 84px;
  height: 84px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  border: 1px solid #dddddd;
  margin-right: 15px;
  margin-bottom: 14px;
`;

const FileItemView = styled.div`
  width: 216px;
  height: 40px;
  background: #fafafa;
  border-radius: 22px;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  padding: 0 14px;
  margin-bottom: 10px;

  font-size: 14px;
  font-weight: 400;
  color: #333333;
`;
const CancelText = styled.div`
  width: 29px;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #2e5ef2;
  margin-right: 28px;
  cursor: pointer;
`;

const BatchRejectButton = styled.div`
  width: 84px;
  height: 36px;
  background: #ff3d11;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
`;

const UnConfirmedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #fe9908;
  margin-right: 8px;
  border-radius: 50%;
`;

const ChangeUnAuditedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #ffcf54;
  margin-right: 8px;
  border-radius: 50%;
`;

const CreateUnAuditedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #12bc82;
  margin-right: 8px;
  border-radius: 50%;
`;

const Confirmed = styled.div`
  width: 5px;
  height: 5px;
  background: #aaaaaa;
  margin-right: 8px;
  border-radius: 50%;
`;

const REJECTED = styled.div`
  width: 5px;
  height: 5px;
  background: #ff381e;
  margin-right: 8px;
  border-radius: 50%;
`;

const DepartmentMonitorInfoChangeCheckTable = (props) => {
  const { taskIdIs } = props;
  const [itemDetail, setItemDetail] = useState();
  const [isManage, setIsManage] = useState(false);
  const selectedRows = useRef([]);
  const [refresh, setRefresh] = useState(false);
  const [rejectVisible, setRejectVisible] = useState(false);
  const [currentData, setCurrentData] = useState();
  const [rejectReason, setrejectReason] = useState('');
  const [isShowRejectReason, setIsShowRejectReason] = useState(false);
  const [showResonVisible, setShowResonVisible] = useState(false);
  const [resonVisible, setResonVisible] = useState(false);

  const fakeItem = (title, children) => {
    return (
      <Row style={{ marginBottom: 24, minHeight: 32 }}>
        <Col span={4} style={{ display: 'flex', justifyContent: 'end' }}>
          {`${title}：`}
        </Col>
        <Col span={16} style={{ flex: 1 }}>
          {children}
        </Col>
      </Row>
    );
  };

  const handelBatchClick = () => {
    if (isManage) {
      console.log();
    } else {
      setIsManage(true);
    }
  };

  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf?.services?.StudentChangeAuditDtoController_pageByStudentChangeAudit_982cf0?.(
          {
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 20,
              orderList: params.orderList, // 排序结果
              taskIdIs,
              ...params.search, // 搜索结果
              ...params.filters, // 过滤器结果
              ...(params?.extra ?? {}), // 传入的额外参数
              refresh: undefined,
            },
            ext: params?.ext,
          },
        );
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [taskIdIs],
  );

  const handelSelectRowChange = (rows) => {
    selectedRows.current = rows?.filter((item) => item);
  };

  const handelConfirmClick = async (_, record) => {
    try {
      const response =
        await vsf?.services?.StudentChangeAuditBOController_passStudentChangeAudit_8b3b8b?.(
          {
            btoParam: {
              id: record?.id,
            },
          },
        );
      if (response && response.code === 200) {
        message.success('确认成功');
        setRefresh(!refresh);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelRejectSubmit = async () => {
    try {
      if (isManage && selectedRows.current?.length <= 0) {
        return message.error('请先选择学生信息！');
      }
      if (isManage) {
        const response =
          await vsf?.services?.StudentChangeAuditBOController_rejectStudentChangeAuditBatch_f4dc2e?.(
            {
              list: selectedRows.current?.map((item) => ({
                id: item.id,
                rejectReason,
              })),
            },
          );
        if (response && response.code === 200) {
          message.success('已拒绝');
          props?.onRefresh?.();
          setRefresh(!refresh);
          setRejectVisible(false);
          setIsShowRejectReason(false);
          return true;
        }
        return false;
      } else {
        const response =
          await vsf.services?.StudentChangeAuditBOController_rejectStudentChangeAuditBySchool_3702e4?.(
            {
              btoParam: {
                id: currentData?.id,
                // auditStatus: ScoreAuditStatusEnum.REJECTED,
                rejectReason,
              },
            },
          );
        if (response && response.code === 200) {
          message.success('已拒绝');
          props?.onRefresh?.();
          setRefresh(!refresh);
          setRejectVisible(false);
          setIsShowRejectReason(false);
          return true;
        }
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelRevokeClick = async (_, record) => {
    try {
      const response =
        await vsf?.services?.StudentChangeAuditBOController_revokeAudit_352013?.(
          {
            studentChangeAuditId: record?.id,
          },
        );
      if (response && response.code === 200) {
        message.success('已撤回');
        setRefresh(!refresh);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelBatchCancel = () => {
    setIsManage(false);
  };

  const handelBatchConfirmClick = async () => {
    try {
      if (isManage && selectedRows.current?.length <= 0) {
        return message.error('请先选择学生信息！');
      }
      const response =
        await vsf?.services?.StudentChangeAuditBOController_passStudentChangeAuditBatch_5eb7aa?.(
          {
            list: selectedRows.current?.map((item) => ({
              id: item?.id,
            })),
          },
        );
      if (response && response.code === 200) {
        message.success('确认成功');
        setRefresh(!refresh);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelAnnexAddress = () => {
    try {
      return (
        itemDetail?.annexAddress && JSON.parse(itemDetail?.annexAddress || '')
      );
    } catch (error) {
      return [];
    }
  };

  return (
    <Section>
      <VSPromiseTable
        scroll={{
          x: 1000,
        }}
        editable={{
          editType: 'single',
          columnProps: { hideInTable: true },
        }}
        pagination={{
          defaultPageSize: 10,
          showTotal: () => <></>,
        }}
        searchConfig={{
          filterType: 'inline',
          optionButtons: 'search',
          searchButtonProps: {
            children: '查询',
          },
        }}
        rowSelection={
          isManage
            ? {
                selectType: 'multiple',
                onSelectedRowsChange: handelSelectRowChange,
                getCheckboxProps: (row) => ({
                  disabled:
                    row?.changeStatus === 'CONFIRMED' ||
                    row?.changeStatus === 'REJECTED',
                }),
              }
            : null
        }
        extraParams={{ refresh }}
        onFetch={onFetch}
        searchFormRender={(_, doms, option) => {
          return (
            <div className="aic" style={{ alignItems: 'flex-start' }}>
              {doms}
              <div className="aic">
                {isManage && (
                  <CancelText onClick={handelBatchCancel}>取消</CancelText>
                )}
                {isManage && (
                  <PrimaryButton
                    className="aic jcc"
                    onClick={handelBatchConfirmClick}
                  >
                    批量确认
                  </PrimaryButton>
                )}
                <span style={{ width: 14 }} />
                {isManage ? (
                  <BatchRejectButton
                    className="aic jcc"
                    onClick={() => {
                      if (isManage && selectedRows.current?.length <= 0) {
                        return message.error('请先选择学生信息！');
                      }
                      setRejectVisible(true);
                    }}
                  >
                    批量拒绝
                  </BatchRejectButton>
                ) : (
                  <PrimaryButton className="aic jcc" onClick={handelBatchClick}>
                    批量管理
                  </PrimaryButton>
                )}
              </div>
            </div>
          );
        }}
      >
        <VSTableColumn
          dataIndex={['studentStatusNum']}
          title="学籍辅号"
          valueType="text"
        />
        <VSTableColumn
          dataIndex={['name']}
          title="姓名"
          valueType="text"
          search
          order={-3}
          columnKey={'nameLike'}
          fieldProps={{
            placeholder: '请输入',
            width: 110,
          }}
        />
        <VSTableColumn
          dataIndex={['taskOrgId', 'org', 'orgName']}
          title="学校名称"
          valueType="text"
          search
          order={-2}
          columnKey={'orgNameLike'}
          fieldProps={{
            placeholder: '请输入',
            width: 110,
          }}
        />
        <VSTableColumn
          dataIndex={['grade']}
          title="年级"
          valueType="select"
          dataSource={[
            { label: '一年级', value: 'FIRST_GRADE' },
            { label: '二年级', value: 'SECOND_GRADE' },
            { label: '三年级', value: 'THIRD_GRADE' },
            { label: '四年级', value: 'FOURTH_GRADE' },
            { label: '五年级', value: 'FIFTH_GRADE' },
            { label: '六年级', value: 'SIXTH_GRADE' },
            { label: '七年级', value: 'SEVENTH_GRADE' },
            { label: '八年级', value: 'EIGHTH_GRADE' },
            { label: '九年级', value: 'NINE_GRADE' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['classes']}
          title="班级"
          valueType="text"
          search
          order={-4}
          columnKey={'classesLike'}
          fieldProps={{
            placeholder: '请输入',
            width: 110,
          }}
        />
        <VSTableColumn
          dataIndex={['sex']}
          title="性别"
          valueType="select"
          dataSource={[
            { label: '男', value: 'MAN' },
            { label: '女', value: 'WOMAN' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          title="申请内容"
          render={(_, record) => {
            return (
              <a
                style={{ color: '#2E5EF2' }}
                onClick={() => {
                  setResonVisible(true);
                  setShowResonVisible(true);
                  setItemDetail(record);
                }}
              >
                {record?.operationStatus === 'REMOVE' ? '删除名单' : '变更信息'}
              </a>
            );
          }}
        />
        <VSTableColumn
          dataIndex={['changeStatus']}
          title="状态"
          valueType="select"
          fieldProps={{}}
          width={140}
          dataSource={[
            { label: '已确认', value: 'CONFIRMED' },
            { label: '已拒绝', value: 'REJECTED' },
            { label: '信息变更待审核', value: 'CHANGE_UNAUDITED' },
            { label: '待接收学校审核', value: 'SCHOOL_UNAUDITED' },
            { label: '新增待审核', value: 'CREATE_UNAUDITED' },
            { label: '待确认', value: 'UNCONFIRMED' },
          ]}
          render={(doms, record) => {
            return (
              <div className="aic">
                {record?.changeStatus === ChangeStatusEnum.UNCONFIRMED && (
                  <UnConfirmedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.CHANGE_UNAUDITED && (
                  <ChangeUnAuditedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.CREATE_UNAUDITED && (
                  <CreateUnAuditedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.SCHOOL_UNAUDITED && (
                  <ChangeUnAuditedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.CONFIRMED && (
                  <Confirmed />
                )}
                {record?.changeStatus === ChangeStatusEnum.REJECTED && (
                  <REJECTED />
                )}
                {doms}
              </div>
            );
          }}
        />
        <VSTableColumn
          valueType="option"
          title="操作"
          name={'action'}
          width={200}
        >
          <VSTableAction
            show={(record) =>
              record?.changeStatus === ChangeStatusEnum.REJECTED
            }
            onClick={(_, record) => {
              setIsShowRejectReason(true);
              setrejectReason(record?.rejectReason);
              setRejectVisible(true);
            }}
          >
            <RejectButton className="aic jcc">已拒绝</RejectButton>
          </VSTableAction>
          <VSTableAction
            show={(record) =>
              record?.changeStatus === ChangeStatusEnum.REJECTED ||
              record?.changeStatus === ChangeStatusEnum.CONFIRMED
            }
            onClick={handelRevokeClick}
          >
            <RevertButton className="aic jcc">撤回审核</RevertButton>
          </VSTableAction>
          <VSTableAction
            show={(record) =>
              record?.changeStatus === ChangeStatusEnum.CHANGE_UNAUDITED
            }
            onClick={handelConfirmClick}
          >
            <ConfirmButton className="aic jcc">确认</ConfirmButton>
          </VSTableAction>
          <VSTableAction
            show={(record) =>
              record?.changeStatus === ChangeStatusEnum.CHANGE_UNAUDITED
            }
            onClick={(_, record) => {
              setCurrentData(record);
              setRejectVisible(true);
            }}
          >
            <RejectButton className="aic jcc">拒绝</RejectButton>
          </VSTableAction>

          <VSTableAction
            disabled={isManage}
            show={(record) =>
              record?.changeStatus === ChangeStatusEnum.SCHOOL_UNAUDITED
            }
          >
            <ConfirmButton className="aic jcc" style={{ opacity: 0.5 }}>
              确认
            </ConfirmButton>
          </VSTableAction>
          <VSTableAction
            disabled={isManage}
            show={(record) =>
              record?.changeStatus === ChangeStatusEnum.SCHOOL_UNAUDITED
            }
          >
            <RejectButton className="aic jcc" style={{ opacity: 0.5 }}>
              拒绝
            </RejectButton>
          </VSTableAction>

          <VSTableAction
            disabled={isManage}
            show={(record) =>
              record?.changeStatus === ChangeStatusEnum.CREATE_UNAUDITED
            }
            onClick={handelConfirmClick}
          >
            <ConfirmButton className="aic jcc">确认</ConfirmButton>
          </VSTableAction>
          <VSTableAction
            disabled={isManage}
            show={(record) =>
              record?.changeStatus === ChangeStatusEnum.CREATE_UNAUDITED
            }
            onClick={(_, record) => {
              setCurrentData(record);
              setRejectVisible(true);
            }}
          >
            <RejectButton className="aic jcc">拒绝</RejectButton>
          </VSTableAction>
        </VSTableColumn>
      </VSPromiseTable>
      <Modal
        open={rejectVisible}
        destroyOnClose
        cancelText="取消"
        okText="确定"
        title={isShowRejectReason ? '拒绝原因' : '拒绝'}
        onOk={handelRejectSubmit}
        onCancel={() => {
          setIsShowRejectReason(false);
          setRejectVisible(false);
        }}
        {...(isShowRejectReason ? { footer: false } : {})}
      >
        <TextArea
          style={{
            width: '100%',
            minHeight: 160,
          }}
          value={rejectReason}
          maxLength={256}
          placeholder="请输入拒绝原因"
          onChange={(e) => {
            setrejectReason(e.target.value);
          }}
        ></TextArea>
      </Modal>
      <Modal
        open={showResonVisible}
        width={500}
        title={resonVisible ? '申请信息' : '"查看替换原因"'}
        onCancel={() => setShowResonVisible(false)}
        onOk={() => setShowResonVisible(false)}
      >
        {resonVisible &&
          fakeItem(
            '申请内容',
            <span style={{ cursor: 'pointer' }}>
              {itemDetail?.operationStatus === 'REMOVE' ? (
                '删除抽样名单'
              ) : (
                <>
                  <span>
                    {`"${itemDetail?.taskOrgId?.org?.orgName ?? ''}-${
                      GradeEnumValue[itemDetail?.grade] ?? ''
                    }-${itemDetail?.classes ?? ''}"`}
                  </span>
                  <div>
                    变更为
                    <span style={{ color: 'red', marginLeft: 2 }}>
                      {`"${itemDetail?.changeOrgName ?? ' '}-${
                        GradeEnumValue[itemDetail?.changeGrade] ?? ''
                      }-${itemDetail?.changeClasses ?? ''}"`}
                    </span>
                  </div>
                </>
              )}
            </span>,
          )}
        {itemDetail?.operationStatus === 'REMOVE' &&
          fakeItem('原因', <span>{itemDetail?.applicationReason}</span>)}

        {fakeItem(
          '附件',
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {handelAnnexAddress()?.map((item) => {
              const _url = item ? decodeURIComponent(item) : '';
              const _suffix = _url
                ? _url.split('?')[0].slice(_url.split('?')[0].lastIndexOf('.'))
                : '';
              const _path = _url?.split('?')[0];
              const _originFileName = _path?.slice(
                _path?.indexOf('***') + 3,
                _path?.lastIndexOf('.'),
              );
              return !ImageSuffix?.includes(_suffix) ? (
                <FileItemView className="aic">
                  <div
                    className="ell"
                    style={{ width: '100%', cursor: 'pointer' }}
                    onClick={() => {
                      FileUpload.download(item, _originFileName);
                    }}
                  >
                    {_originFileName}
                  </div>
                </FileItemView>
              ) : (
                <ImageView>
                  <Image.PreviewGroup>
                    <Image width={'100%'} height={'100%'} src={item} />
                  </Image.PreviewGroup>
                </ImageView>
              );
            })}
          </div>,
        )}
      </Modal>
    </Section>
  );
};
export default definePage(DepartmentMonitorInfoChangeCheckTable);
