import '@/pages/TreeMenu.less';
import './TreeMenu.less';

import vsf, { definePage, renderRoutes, RouteConfig } from '@vs/vsf-boot';
import { Layout, Menu, Page } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import PeopleIcon from '@/assets/images/icon/people_icon.png';
import { BreadCrumb } from '@/components';
import TreeMenu from '@/components/TreeMenu';
import { navigateToWithPlatform, usePlatformInfo } from '@/utils';

const { Sider } = Layout;

const MENU = [
  {
    key: 'infoConfirm',
    title: '信息确认',
    parentId: null,
  },
  {
    key: 'infoCheck',
    title: '学生信息确认',
    parentId: 'infoConfirm',
  },
  {
    key: 'infoChangeCheck',
    title: '学生信息变更审核',
    parentId: 'infoConfirm',
  },
  {
    key: 'infoChangeHistory',
    title: '学生信息变更记录',
    parentId: 'infoConfirm',
  },
  {
    key: 'SampleNameList',
    title: '抽样名单',
    parentId: null,
  },
  {
    key: 'studentConfirm',
    title: '抽样学生确认',
    parentId: 'SampleNameList',
  },
  {
    key: 'replacement',
    title: '替换名单',
    parentId: 'SampleNameList',
  },
  {
    key: 'replacementHistory',
    title: '抽样学生替换记录',
    parentId: 'SampleNameList',
  },
  {
    key: 'finalList',
    title: '抽样测评最终名单',
    parentId: null,
  },
];

const SchoolMonitor = (props) => {
  const {
    route,
    routes: {
      /**
       * taskIdIs  任务id
       * type  任务列表跳转确认学生type 'show' | 'confirm'
       */
      query,
      params,
    },
  } = props;

  const [defaultSelectedKey, setDefaultSelectedKey] = useState('');
  const [selected, setSelected] = useState();
  const [name, setName] = useState(query?.name);

  const [platformInfo] = usePlatformInfo();
  const [passStudentReminderSchool, setPassStudentReminderSchool] =
    useState(false);
  const [changeAuditReminderSchool, setChangeAuditReminderSchool] =
    useState(false);

  const [passSampleStudentReminderSchool, setPassSampleStudentReminderSchool] =
    useState(false);

  const [
    passChangeRosterStudentReminderSchool,
    setPassChangeRosterStudentReminderSchool,
  ] = useState(false);

  const type = useRef();
  const once = useRef(false);

  const loadPassStudentReminderSchool = useCallback(async () => {
    try {
      if (params.taskIdIs && platformInfo?.platformId) {
        const response =
          await vsf?.services?.StudentDtoController_passStudentReminderSchool_ab4611?.(
            {
              taskId: params.taskIdIs,
              orgId: platformInfo?.platformId,
            },
          );
        once.current = true;
        setPassStudentReminderSchool(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [params, platformInfo]);

  const loadChangeAuditReminderSchool = useCallback(async () => {
    try {
      if (params.taskIdIs && platformInfo?.platformId) {
        const response =
          await vsf?.services?.StudentChangeAuditDtoController_changeAuditReminderSchool_f678f7?.(
            {
              taskId: params.taskIdIs,
              orgId: platformInfo?.platformId,
            },
          );
        setChangeAuditReminderSchool(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [params, platformInfo]);

  const loadPassSampleStudentReminderSchool = useCallback(async () => {
    try {
      if (params.taskIdIs && platformInfo?.platformId) {
        const response =
          await vsf?.services?.SampleStudentDtoController_passSampleStudentReminderSchool_2674ae?.(
            {
              taskId: params.taskIdIs,
              orgId: platformInfo?.platformId,
            },
          );
        setPassSampleStudentReminderSchool(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [params, platformInfo]);

  const loadPassChangeRosterStudentReminderSchool = useCallback(async () => {
    try {
      if (params.taskIdIs && platformInfo?.platformId) {
        const response =
          await vsf?.services?.ChangeRosterStudentDtoController_passChangeRosterStudentReminderSchool_23c108?.(
            {
              taskId: params.taskIdIs,
              orgId: platformInfo?.platformId,
            },
          );
        setPassChangeRosterStudentReminderSchool(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [params, platformInfo]);

  useEffect(() => {
    if (!once.current) {
      loadPassStudentReminderSchool();
      loadChangeAuditReminderSchool();
      loadPassSampleStudentReminderSchool();
      loadPassChangeRosterStudentReminderSchool();
    }
  }, [
    loadChangeAuditReminderSchool,
    loadPassChangeRosterStudentReminderSchool,
    loadPassSampleStudentReminderSchool,
    loadPassStudentReminderSchool,
  ]);

  useEffect(() => {
    if (query.type) {
      type.current = query.type;
    }
  }, [query]);

  useEffect(() => {
    if (params.taskIdIs && !name) {
      vsf.services
        .MonitorTaskController_getMonitorTaskDetail_12984e?.({
          id: params.taskIdIs,
        })
        .then((res) => {
          if (res.data) {
            setName(res.data.name);
          }
        });
    }
  }, [params, name]);

  const renderCustomerTitle = (node) => {
    return (
      <div className="treenode-item-normal aic jcsb">
        {node.key === 'infoCheck' && passStudentReminderSchool && (
          <div
            style={{
              width: 7,
              height: 7,
              background: 'red',
              borderRadius: '50%',
              position: 'absolute',
              left: -10,
            }}
          />
        )}
        {node.key === 'infoChangeCheck' && changeAuditReminderSchool && (
          <div
            style={{
              width: 7,
              height: 7,
              background: 'red',
              borderRadius: '50%',
              position: 'absolute',
              left: -10,
            }}
          />
        )}
        {node.key === 'studentConfirm' && passSampleStudentReminderSchool && (
          <div
            style={{
              width: 7,
              height: 7,
              background: 'red',
              borderRadius: '50%',
              position: 'absolute',
              left: -10,
            }}
          />
        )}
        {node.key === 'replacement' &&
          passChangeRosterStudentReminderSchool && (
            <div
              style={{
                width: 7,
                height: 7,
                background: 'red',
                borderRadius: '50%',
                position: 'absolute',
                left: -10,
              }}
            />
          )}
        {node.title}
      </div>
    );
  };

  //   defaultExpandedKeys: defaultSelectedKeys
  //   ? [defaultSelectedKeys?.key]
  //   : [],
  // defaultSelectedKeys: defaultSelectedKeys
  //   ? [defaultSelectedKeys?.key]
  //   : [],

  useEffect(() => {
    if (props?.routes?.pathname) {
      const arr = props?.routes?.pathname?.split?.('/');
      const routeKey = arr?.[arr?.length - 1];
      setDefaultSelectedKey(routeKey);
      setSelected(routeKey);
    }
  }, [props]);

  const handelSelectClick = (node) => {
    let navigated = true;

    const _query = `type=${type.current}&taskOrgId=${query?.taskOrgId}&grade=${query?.grade}&taskIdIs=${query?.taskIdIs}&name=${query?.name}`;
    switch (node.key) {
      case 'infoCheck':
        navigateToWithPlatform(
          `/${platformInfo?.platformId}/monitor/${params.taskIdIs}/infoCheck?${_query}`,
        );
        break;
      case 'infoChangeCheck':
        navigateToWithPlatform(
          `/${platformInfo?.platformId}/monitor/${params.taskIdIs}/infoChangeCheck?${_query}`,
        );
        break;

      case 'infoChangeHistory':
        navigateToWithPlatform(
          `/${platformInfo?.platformId}/monitor/${params.taskIdIs}/infoChangeHistory?${_query}`,
        );
        break;

      case 'finalList':
        navigateToWithPlatform(
          `/${platformInfo?.platformId}/monitor/${params.taskIdIs}/finalList?${_query}`,
        );
        break;
      case 'studentConfirm':
        navigateToWithPlatform(
          `/${platformInfo?.platformId}/monitor/${params.taskIdIs}/studentConfirm?${_query}`,
        );
        break;
      case 'replacement':
        navigateToWithPlatform(
          `/${platformInfo?.platformId}/monitor/${params.taskIdIs}/replacement?${_query}`,
        );
        break;
      case 'replacementHistory':
        navigateToWithPlatform(
          `/${platformInfo?.platformId}/monitor/${params.taskIdIs}/replacementHistory?${_query}`,
        );
        break;
      default:
        navigated = false;
        break;
    }
    if (navigated) {
      setSelected(node.key);
    }
  };

  const names = [];
  if (name) {
    names.push(name);
  }
  const current = MENU.find((item) => item.key === selected);
  if (current) {
    names.push(current.title);
  }

  return (
    <>
      <BreadCrumb
        names={names}
        onBack={() =>
          navigateToWithPlatform(`/${platformInfo?.platformId}/monitorTaskList`)
        }
      />
      <div className="vsf-layout-sec">
        <Sider
          className="vsf-layout-sec-sider"
          theme="light"
          collapsible
          width={240}
        >
          <div className="vsf-layout-sec-sider-header aic">
            <img
              src={PeopleIcon}
              className="vsf-layout-sec-sider-header-icon"
            />
            <span>抽样管理</span>
          </div>
          <TreeMenu
            treeData={MENU}
            treeProps={{
              titleRender: renderCustomerTitle,
              selectedKeys: [selected],
              defaultExpandedKeys: [defaultSelectedKey],
              defaultSelectedKeys: [defaultSelectedKey],
            }}
            search={{
              status: false,
            }}
            dataRelationFieldNames={{
              id: 'key',
              parentId: 'parentId',
            }}
            onSelect={handelSelectClick}
          />
        </Sider>
        <div className="vsf-layout-sec-main">
          {renderRoutes(route.routes, route.redirect)}
        </div>
      </div>
    </>
  );
};

export default definePage(SchoolMonitor);
