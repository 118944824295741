import vsf, { definePage } from '@vs/vsf-boot';
import {
  Col,
  message,
  Row,
  Section,
  VSControlledForm,
  VSFormDependency,
  VSFormItem,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useState } from 'react';

import CustomFileUpload from '@/components/CustomFileUpload';
import { PeriodEnum } from '@/config';
import { yearFormatter } from '@/utils';
import { extractTables } from '@/utils/xlsx';
const DepartmentGradesForm = (props) => {
  const [excelList, setExcelList] = useState();
  const [formState, setFormState] = useState();
  const [periodState, setPeriodState] = useState();

  const labelSpan = 3;

  const fakeItem = (title, children) => {
    return (
      <Row style={{ marginBottom: 24, minHeight: 32 }}>
        <Col
          span={labelSpan}
          style={{ display: 'flex', justifyContent: 'end' }}
        >
          {`${title}：`}
        </Col>
        <Col>{children}</Col>
      </Row>
    );
  };

  return (
    <Section title="上传数据">
      <VSControlledForm
        id="DepartmentGradesForm"
        layout="horizontal"
        onChange={(_value) => {
          setFormState(_value);
          props.onChange?.({
            ..._value,
            list: excelList,
          });
        }}
        labelCol={{
          span: formState?.subject !== 'PHYSICAL' ? 3 : 4,
        }}
      >
        <div style={{ height: 30 }} />
        <VSFormItem
          name={['name']}
          label="名称"
          valueType="text"
          required={false}
          rules={[
            { message: '名称长度不合法', type: 'string', min: 0, max: 64 },
            { required: true },
          ]}
          fieldProps={{}}
        />

        <div className="antd-picker-width">
          <VSFormItem
            name={['year']}
            label="学年"
            valueType="dateYear"
            required={false}
            rules={[{ required: true, message: '请选择学年' }]}
            style={{ width: '100%' }}
            transform={(v) => {
              return {
                year: dayjs(v).format('YYYY'),
              };
            }}
          />
        </div>

        <VSFormItem
          name={['period']}
          label="学段"
          valueType="select"
          dataSource={[
            { label: '小学', value: 'PRIMARY_SCHOOL' },
            { label: '初中', value: 'MIDDLE_SCHOOL' },
          ]}
          required={false}
          rules={[{ required: true, message: '请选择学段' }]}
          fieldProps={{
            onChange: (_period) => {
              setPeriodState(_period);
            },
          }}
        />

        <VSFormItem
          name={['grade']}
          label="年级"
          valueType="select"
          required={false}
          rules={[{ required: true, message: '请选择年级' }]}
          dataSource={
            periodState
              ? periodState === PeriodEnum.PRIMARY_SCHOOL
                ? [
                    { label: '一年级', value: 'FIRST_GRADE' },
                    { label: '二年级', value: 'SECOND_GRADE' },
                    { label: '三年级', value: 'THIRD_GRADE' },
                    { label: '四年级', value: 'FOURTH_GRADE' },
                    { label: '五年级', value: 'FIFTH_GRADE' },
                    { label: '六年级', value: 'SIXTH_GRADE' },
                  ]
                : [
                    { label: '七年级', value: 'SEVENTH_GRADE' },
                    { label: '八年级', value: 'EIGHTH_GRADE' },
                    { label: '九年级', value: 'NINE_GRADE' },
                  ]
              : []
          }
          fieldProps={{}}
        />

        <VSFormItem
          name={['subject']}
          label="学科"
          valueType="select"
          required={false}
          rules={[{ required: true, message: '请选择学科' }]}
          dataSource={[
            { label: '语文', value: 'CHINESE' },
            { label: '数学', value: 'MATHEMATICS' },
            { label: '英语', value: 'ENGLISH' },
            { label: '体育', value: 'PHYSICAL' },
            { label: '音乐', value: 'MUSIC' },
            { label: '美术', value: 'ART' },
            { label: '科学', value: 'SCIENCE' },
            { label: '社会', value: 'SOCIOLOGY' },
          ]}
        />
        <VSFormDependency name={['subject']}>
          {(value) => {
            return value.subject === 'PHYSICAL' ? (
              <VSFormItem
                label="成绩来源"
                valueType="select"
                required={false}
                fieldProps={{
                  defaultValue: 'self',
                }}
                dataSource={[{ label: '学校自主上报', value: 'self' }]}
              />
            ) : null;
          }}
        </VSFormDependency>
      </VSControlledForm>
      {formState?.subject !== 'PHYSICAL' &&
        fakeItem(
          '文件',
          <CustomFileUpload
            type="file"
            width={395}
            template={{
              url: `/grade_empty_template_other_subject.xlsx`,
              name: '学科成绩录入模板-其他学科',
            }}
            uploadProps={{
              accept: '.xls,.xlsx',
              customRequest: async (e) => {
                try {
                  const response = await extractTables(
                    e.file,
                    {
                      schoolId: '*schoolId',
                      orgName: '*学校名称',
                      period: '*学段',
                      year: '*学年',
                      classes: '*班级',
                      studentName: '*姓名',
                      sex: '*性别',
                      studentStatusNum: '*学籍辅号',
                      score: '*总分',
                      level: '*等级',
                    },
                    true,
                  );
                  if (response) {
                    const _data = response?.[0]?.data?.map((item) => ({
                      ...item,
                      studentStatusNum:
                        item?.studentStatusNum?.indexOf('0') === 0
                          ? item?.studentStatusNum?.substr(1)
                          : item?.studentStatusNum,
                    }));

                    setExcelList(_data);
                    props?.onChange?.({
                      ...formState,
                      list: _data,
                    });
                    e.onProgress({ percent: 100 });
                    e.onSuccess(
                      {
                        name: e.file.name,
                        status: 'done',
                        path: URL.createObjectURL(e.file),
                      },
                      e.file,
                    );
                  }
                } catch (error) {
                  console.error(error);
                  message.error('解析失败，请检查文档格式');
                }
              },
            }}
          />,
        )}
    </Section>
  );
};
export default definePage(DepartmentGradesForm);
