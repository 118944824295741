import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Modal,
  Section,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import { cloneDeep } from 'lodash';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import { SubjectEnumValue } from '@/config';

const LabelView = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #333333;
`;

const ComputedView = styled.div`
  width: 180px;
  height: 40px;
  background: #fafafa;
  border-radius: 6px;
  border: 1px solid #dddddd;
`;

const ComputedItem = styled.div`
  cursor: pointer;
  width: 39px;
  height: 100%;
  font-size: 18px;
  font-weight: 500;
  color: #333333;
`;

const ComputedItemInput = styled.div`
  width: 100px;
  outline: none;
  border: none;
  text-align: center;
`;

const InputView = styled.input`
  width: 97px;
  height: 36px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #2e5ef2;
  text-align: center;
  outline: none;

  font-size: 14px;
  font-weight: 400;
  color: #333333;
`;

const DepartmentMonitorReplacementReleaseTable = (props) => {
  const {
    extractRoundIdIs,
    distinguishSex,
    subject,
    refresh,
    isTable,
    onDataChange,
    configId,
  } = props;
  const [data, setData] = useState();
  const [sexData, setSexData] = useState();
  const [visible, setVisible] = useState(false);
  const [grantNum, setGrantNum] = useState(0);
  const [refresh2, setRefresh2] = useState(false);
  const list = useRef();
  const currentRecord = useRef();

  const onFetch = useCallback(
    async (params) => {
      if (extractRoundIdIs) {
        const res =
          await vsf?.services?.ChangeStatisticsStudentDtoController_pageByChangeStatisticsStudent_dc46d0?.(
            {
              qto: {
                from: params.pagination?.from ?? 0,
                size: params.pagination?.size ?? 20,
                orderList: params.orderList, // 排序结果
                extractRoundIdIs,
                ...params.search, // 搜索结果
                ...params.filters, // 过滤器结果
                ...(params?.extra ?? {}), // 传入的额外参数
                refresh: undefined,
                refresh2: undefined,
              },
              ext: params?.ext,
            },
          );
        const ret = {
          data: res?.data?.result ?? res?.data ?? [],
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };

        const data = ret.data.reduce((ret, item) => {
          const array = ret[item.taskOrgId];
          if (array) {
            array.push(item.id);
          } else {
            ret[item.taskOrgId] = [item.id];
          }
          return ret;
        }, {});

        const sexData = ret.data.reduce((ret, item) => {
          const array = ret[item?.extractRoster?.sex];
          if (array) {
            array.push({
              id: item.id,
              taskOrgId: item?.taskOrgId,
            });
          } else {
            ret[item?.extractRoster?.sex] = [
              {
                id: item.id,
                taskOrgId: item?.taskOrgId,
              },
            ];
          }
          return ret;
        }, {});

        setData(data);
        setSexData(sexData);
        list.current = ret?.data;
        onDataChange?.(ret?.data);
        return ret;
      }
    },
    [extractRoundIdIs, onDataChange],
  );

  const handelSubmitClick = async () => {
    try {
      const response =
        await vsf?.services?.ChangeStatisticsStudentDtoController_grantExtraRoster_9fdb15?.(
          {
            rosterId: currentRecord.current?.extractRoster?.id,
            configId,
            count: grantNum,
          },
        );
      if (response && response.code === 200) {
        message.success('补发成功');
        setRefresh2(!refresh2);
        setVisible(false);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Section>
      <VSPromiseTable
        pagination={{
          defaultPageSize: 10,
          showTotal: () => <></>,
        }}
        editable={{
          editType: 'single',
          columnProps: { hideInTable: true },
        }}
        vsid="20581"
        searchConfig={{
          filterType: 'inline',
          optionButtons: ['search'],
          searchText: '查询',
        }}
        extraParams={{ refresh, refresh2 }}
        onFetch={onFetch}
        scroll={{
          x: 1000,
        }}
      >
        <VSTableColumn
          dataIndex={['extractRoster', 'taskOrg', 'org', 'id']}
          title="学校识别码"
          valueType="digit"
          fieldProps={{}}
          onCell={(record) => {
            const array = data[record.taskOrgId];
            if (array) {
              if (array.indexOf(record.id) === 0) {
                return {
                  rowSpan: array.length,
                };
              } else {
                return {
                  rowSpan: 0,
                };
              }
            }
            return {};
          }}
        />
        <VSTableColumn
          dataIndex={['extractRoster', 'taskOrg', 'org', 'orgName']}
          title="学校名称"
          valueType="text"
          search
          order={-25}
          columnKey={'orgNameLike'}
          formItemProps={{
            rules: [
              {
                message: '机构名称长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{
            width: 140,
          }}
          onCell={(record) => {
            const array = data[record.taskOrgId];
            if (array) {
              if (array.indexOf(record.id) === 0) {
                return {
                  rowSpan: array.length,
                };
              } else {
                return {
                  rowSpan: 0,
                };
              }
            }
            return {};
          }}
        />

        <VSTableColumn title="替换学生" rowSpan={2}>
          {distinguishSex && (
            <VSTableColumn
              rowSpan={0}
              dataIndex={['extractRoster', 'sex']}
              title="性别"
              valueType="text"
              fieldProps={{}}
              onCell={(record) => {
                const array = sexData[record.extractRoster?.sex]
                  ?.filter((item) => item?.taskOrgId === record?.taskOrgId)
                  ?.map((item) => item.id);
                if (array) {
                  if (array.indexOf(record.id) === 0) {
                    return {
                      rowSpan: array.length,
                    };
                  } else {
                    return {
                      rowSpan: 0,
                    };
                  }
                }
                return {};
              }}
              render={(_, record) =>
                record?.extractRoster?.sex === 'MAN' ? '男生' : '女生'
              }
            />
          )}
          <VSTableColumn
            rowSpan={0}
            dataIndex={['extractRoster', 'level']}
            valueType="text"
            fieldProps={{}}
            render={(_, record) => {
              return `${SubjectEnumValue[subject]}${record?.extractRoster.level}`;
            }}
          />
          <VSTableColumn
            rowSpan={0}
            dataIndex={['applicationNum']}
            valueType="text"
            fieldProps={{}}
          />
        </VSTableColumn>

        <VSTableColumn
          title="审核通过"
          dataIndex={['passNum']}
          valueType="text"
          fieldProps={{}}
        />
        <VSTableColumn
          title="审核拒绝"
          dataIndex={['rejectNum']}
          valueType="text"
          fieldProps={{}}
        />
        <VSTableColumn
          title="未审核"
          dataIndex={['unauditedNum']}
          valueType="text"
          fieldProps={{}}
        />
        {!isTable && (
          <VSTableColumn
            width={120}
            title="名额已发放数"
            dataIndex={['unauditedNum']}
            valueType="text"
            fieldProps={{}}
            render={(_, record) => record?.grantNum ?? '未发放'}
          />
        )}
        <VSTableColumn
          title="实际发放数"
          name={'action'}
          valueType="option"
          width={120}
        >
          {isTable ? (
            <VSTableAction>
              {(record) => {
                return (
                  <InputView
                    type="number"
                    defaultValue={parseInt(
                      list.current?.find((_v) => _v.id === record?.id)
                        ?.grantNum ?? 0,
                    )}
                    onChange={(e) => {
                      const _list = cloneDeep(list.current)?.map((_v) => ({
                        ..._v,
                        grantNum: parseInt(
                          (_v.id === record?.id
                            ? e.target.value
                            : _v.grantNum) ?? 0,
                        ),
                      }));
                      list.current = _list;
                      // setList(_list);
                      onDataChange?.(_list);
                    }}
                  />
                );
              }}
            </VSTableAction>
          ) : (
            <VSTableAction
              onClick={(_, record) => {
                // setGrantNum(record?.grantNum);
                currentRecord.current = record;
                setVisible(true);
              }}
            >
              补发名额
            </VSTableAction>
          )}
        </VSTableColumn>
      </VSPromiseTable>
      <Modal
        open={visible}
        width={460}
        title="名额补发设置"
        onCancel={() => setVisible(false)}
        onOk={handelSubmitClick}
      >
        <div
          style={{
            width: '100%',
            height: 146,
          }}
          className="aic jcc"
        >
          <div className="aic">
            <LabelView>增加名额：</LabelView>
            <ComputedView className="aic">
              <ComputedItem
                className="aic jcc"
                style={{
                  borderRight: '1px solid #dddddd',
                }}
                onClick={() => {
                  setGrantNum(grantNum - 1 <= 0 ? 0 : grantNum - 1);
                }}
              >
                -
              </ComputedItem>
              <ComputedItemInput>{grantNum ?? 0}</ComputedItemInput>
              <ComputedItem
                className="aic jcc"
                style={{
                  borderLeft: '1px solid #dddddd',
                }}
                onClick={() => {
                  setGrantNum(grantNum + 1);
                }}
              >
                +
              </ComputedItem>
            </ComputedView>
          </div>
        </div>
      </Modal>
    </Section>
  );
};
export default definePage(DepartmentMonitorReplacementReleaseTable);
