import vsf, { definePage } from '@vs/vsf-boot';
import { Modal, Page } from '@vs/vsf-kit';
import { Button } from 'antd';
import React from 'react';
import styled from 'styled-components';

import Empty from '@/assets/images/empty_bg.png';

import DepartmentMonitorAddStudentForm from './DepartmentMonitorAddStudentForm';
import DepartmentMonitorInfoCheckSchoolTable from './DepartmentMonitorInfoCheckSchoolTable';

const EmptyBg = styled.img`
  width: 250px;
  height: 194px;
`;

const EmptyLabel = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #999999;
  margin-top: 10px;
`;

const EmptyButton = styled.button`
  width: 140px;
  height: 44px;
  background: #2e5ef2;
  border-radius: 6px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  border: none;
  outline: none;
  cursor: pointer;
  margin-top: 24px;
`;

const DepartmentMonitorInfoCheckSchool = (props) => {
  const {
    routes: {
      params: { taskIdIs, taskOrgIdIs },
      query: { title, isEmpty, period, grade, monitorTaskStatus },
    },
  } = props;

  return (
    <Page title={isEmpty === 'true' ? '' : title}>
      {isEmpty === 'true' ? (
        <div style={{ width: '100%', height: '100%', marginTop: '164px' }}>
          <div className="col aic">
            <EmptyBg src={Empty} />
            <EmptyLabel>暂无学生数据，请先“添加学生”</EmptyLabel>
          </div>
        </div>
      ) : (
        <DepartmentMonitorInfoCheckSchoolTable
          taskOrgIdIs={taskOrgIdIs}
          taskIdIs={taskIdIs}
          period={period}
          grade={grade}
          monitorTaskStatus={monitorTaskStatus}
        />
      )}
    </Page>
  );
};

export default definePage(DepartmentMonitorInfoCheckSchool);
