import vsf, { definePage } from '@vs/vsf-boot';
import { message, Modal, Page, RadioGroup } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { GrantByEnum, SubjectEnumValue } from '@/config';

import DepartmentMonitorReplacementReleaseModalTable from './DepartmentMonitorReplacementReleaseModalTable';
import DepartmentMonitorReplacementReleaseTable from './DepartmentMonitorReplacementReleaseTable';
import DepartmentMonitorSampleStudentReplacementTable from './DepartmentMonitorSampleStudentReplacementTable';
import ChinaNum from './RandomSampleList/ChinaNum';

const SubjectTabsView = styled.div`
  width: 100%;
  height: 56px;
  border-bottom: 1px solid #eeeeee;
`;

const SubjectTabsViewItem = styled.div`
  min-width: 94px;
  height: 22px;
  font-size: 16px;
  font-weight: bold;
  color: #2e5ef2;
  position: relative;
  cursor: pointer;
  z-index: 99;
  margin-right: 30px;
`;

const SubjectTabsViewItemLine = styled.div`
  width: 94px;
  height: 6px;
  background: linear-gradient(90deg, #ffc736 0%, #ffffff 100%);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
`;

const PublishButton = styled.div`
  width: 84px;
  height: 36px;
  background: #2e5ef2;
  border-radius: 6px;

  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
`;

const PublishModalTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #666666;
`;

const PublishModalLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #999999;
`;

const InputNormal = styled.input`
  width: 80px;
  height: 34px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #2e5ef2;
  text-align: center;
  outline: none;
  position: absolute;
  left: 150px;
  top: 116px;
  transition: all 0.2s linear;
`;

const InputDisable = styled.input`
  width: 80px;
  height: 34px;
  background: #fafafa;
  border-radius: 6px;
  border: 1px solid #dddddd;
  text-align: center;
  outline: none;
  position: absolute;
  left: 150px;
  top: 165px;
  transition: all 0.3s linear;
`;

const DepartmentMonitorReplacementRelease = (props) => {
  const {
    routes: {
      params: { taskIdIs },
    },
  } = props;

  const [monitorTask, setMonitorTask] = useState();
  const [activeId, setActiveId] = useState();
  const [publishVisible, setPublishVisible] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [byEnum, setByEnum] = useState(GrantByEnum.APPLICATION_NUM);
  const [count1, setCount1] = useState(0);
  const [count2, setCount2] = useState(0);
  const [modalTableVisible, setModalTableVisible] = useState(false);

  const customList = useRef([]);

  const loadMonitorTask = useCallback(async () => {
    try {
      const response =
        await vsf?.services?.ExtractConfigDtoController_getByMonitorTaskId_553a8d?.(
          {
            monitorTaskId: taskIdIs,
          },
        );
      if (response.data) {
        setMonitorTask(response.data);
        setActiveId(response.data?.extractRoundDtoList?.[0]?.id);
      }
    } catch (error) {
      console.error(error);
    }
  }, [taskIdIs]);

  useEffect(() => {
    loadMonitorTask();
  }, [loadMonitorTask]);

  const handelRadioChange = (_value) => {
    setByEnum(_value);
  };

  const handelSubmitClick = async () => {
    try {
      const response =
        await vsf?.services?.ChangeStatisticsStudentDtoController_setGrantNum_2547c3?.(
          {
            byEnum,
            configId: monitorTask?.id,
            count: byEnum === GrantByEnum.APPLICATION_NUM ? count1 : count2,
          },
        );
      if (response && response.code === 200) {
        message.success('设置成功');
        setPublishVisible(false);
        setRefresh(!refresh);

        loadMonitorTask();

        setModalTableVisible(true);

        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  };

  const handelDataChange = (list) => {
    customList.current = list;
  };

  const handelCustomSubmit = async () => {
    try {
      const response =
        await vsf?.services?.ChangeStatisticsStudentDtoController_customGrantNum_5728cb?.(
          {
            btoParam: customList.current?.map((item) => ({
              id: item?.id,
              grantNum: item?.grantNum,
            })),
          },
        );
      if (response && response.code === 200) {
        await vsf?.services?.ChangeStatisticsStudentDtoController_grantChangeStudent_96d88f?.(
          {
            configId: monitorTask?.id,
          },
        );

        message.success('发放成功');
        setRefresh(!refresh);
        setModalTableVisible(false);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Page>
      <div className="aic">
        <SubjectTabsView className="aic">
          {monitorTask?.extractRoundDtoList?.map((item, _idx) => (
            <SubjectTabsViewItem
              key={item.id}
              style={{
                color: item?.id === activeId ? '#2E5EF2' : '#666666',
              }}
              onClick={() => setActiveId(item?.id)}
            >
              <span>
                第{ChinaNum[_idx]}轮（{SubjectEnumValue[item?.subject]}）
              </span>
              {item?.id === activeId && <SubjectTabsViewItemLine />}
            </SubjectTabsViewItem>
          ))}
        </SubjectTabsView>

        <PublishButton
          className="aic jcc"
          onClick={() => {
            if (monitorTask?.replaceRosterGrantStatus !== 'REPLACE_ISSUED') {
              setPublishVisible(true);
            }
          }}
          style={{
            opacity:
              monitorTask?.replaceRosterGrantStatus &&
              monitorTask?.replaceRosterGrantStatus === 'REPLACE_ISSUED'
                ? 0.5
                : 1,
          }}
        >
          发放名额
        </PublishButton>
      </div>
      <DepartmentMonitorReplacementReleaseTable
        refresh={refresh}
        subject={
          monitorTask?.extractRoundDtoList?.find((item) => item.id === activeId)
            ?.subject
        }
        configId={monitorTask?.id}
        extractRoundIdIs={activeId}
        distinguishSex={monitorTask?.distinguishSex}
      />
      <Modal
        open={publishVisible}
        width={460}
        title="名额发放规则"
        onCancel={() => setPublishVisible(false)}
        onOk={handelSubmitClick}
        okText="确定"
        cancelText="取消"
      >
        <div className="aic">
          <PublishModalTitle>选择发放的数量标准</PublishModalTitle>
          <PublishModalLabel>
            （填写统一增加或减少的数值，如1；-1）
          </PublishModalLabel>
        </div>
        <RadioGroup
          value={byEnum}
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 20,
            height: 70,
            justifyContent: 'space-between',
          }}
          dataSource={[
            {
              label: '按申请数额外增加',
              value: GrantByEnum.APPLICATION_NUM,
            },
            {
              label: '按审核通过数额外增加',
              value: GrantByEnum.PASS_NUM,
            },
          ]}
          onChange={handelRadioChange}
        />
        <InputNormal
          type="number"
          value={count1}
          disabled={byEnum === GrantByEnum.PASS_NUM}
          min={0}
          style={{
            border:
              byEnum === GrantByEnum.APPLICATION_NUM
                ? '1px solid #2e5ef2'
                : ' 1px solid #dddddd',
            marginLeft: 50,
          }}
          onInput={(e) => {
            setCount1(e.target.value);
          }}
        />
        <InputDisable
          type="number"
          value={count2}
          min={0}
          style={{
            border:
              byEnum === GrantByEnum.PASS_NUM
                ? '1px solid #2e5ef2'
                : ' 1px solid #dddddd',
            marginLeft: 50,
          }}
          disabled={byEnum === GrantByEnum.APPLICATION_NUM}
          onInput={(e) => {
            setCount2(e.target.value);
          }}
        />
      </Modal>
      <Modal
        open={modalTableVisible}
        width={950}
        title="名额发放规则"
        onCancel={() => setModalTableVisible(false)}
        style={{
          padding: 0,
        }}
        onOk={handelCustomSubmit}
        okText="确定"
        cancelText="取消"
      >
        <DepartmentMonitorReplacementReleaseModalTable
          taskIdIs={taskIdIs}
          onDataChange={handelDataChange}
        />
      </Modal>
    </Page>
  );
};

export default definePage(DepartmentMonitorReplacementRelease);
