import SchoolMonitorInfoChangeHistoryTable from '@pages/SchoolMonitorInfoChangeHistoryTable';
import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React from 'react';
const SchoolMonitorInfoChangeHistory = (props) => {
  const {
    routes: {
      params: { taskIdIs },
    },
  } = props;

  return (
    <Page>
      <SchoolMonitorInfoChangeHistoryTable taskIdIs={taskIdIs} />
    </Page>
  );
};
export default definePage(SchoolMonitorInfoChangeHistory);
