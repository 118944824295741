import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Page,
  Section,
  Tooltip,
  VSPromiseTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';

import {
  navigateToWithPlatform,
  usePlatformInfo,
  yearDataFormatter,
} from '@/utils';

import ActionSplit from './ActionSplit';
import DepartmentGradesForm from './DepartmentGradesForm';
const DepartmentGradesList = (props) => {
  const {
    routes: {
      params: { scoreMenuIdIs },
    },
  } = props;

  const [platformInfo] = usePlatformInfo();

  const handelDetailClick = (currentItem) => {
    navigateToWithPlatform(
      `/${platformInfo?.platformId}/grades/gradesDetail/${currentItem?.id}?name=${currentItem?.name}`,
    );
  };

  const handelCheckClick = (scoreTaskId) => {
    navigateToWithPlatform(
      `/${platformInfo?.platformId}/gradesCheck/${scoreTaskId}`,
    );
  };

  const onFetch = useCallback(
    async (params) => {
      if (scoreMenuIdIs && scoreMenuIdIs !== 'undefined') {
        const res =
          await vsf?.services?.ScoreTaskDtoController_pageScoreTask_09fa6d?.({
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 20,
              orderList: params.orderList, // 排序结果
              scoreMenuIdIs: scoreMenuIdIs,
              ...params.search, // 搜索结果
              ...params.filters, // 过滤器结果
              ...(params?.extra ?? {}), // 传入的额外参数
            },
          });
        return {
          data: res?.data?.result ?? res?.data ?? [],
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
    },
    [scoreMenuIdIs],
  );

  return (
    <Page>
      <Section>
        <VSPromiseTable
          onRemove={async (params) => {
            const res =
              await vsf?.services?.ScoreTaskBOController_deleteScoreTask_db388b?.(
                {
                  btoParam: params,
                },
              );
            return res?.code === 200;
          }}
          editable={{
            editType: 'single',
            columnProps: {
              width: 180,
            },
            onCanEdit: () => false,
            deleteConfirmProps: { title: '确定删除该账号吗？', icon: <></> },
            extraOperations: [
              {
                order: 0,
                children: '查看',
                onClick: (e, record) => handelDetailClick(record),
              },
              {
                order: 1,
                show: (record) => record?.subject === 'PHYSICAL',
                children: '成绩审核',
                onClick: (e, record) => handelCheckClick(record?.id),
              },
            ],
          }}
          actionSpaceProps={{
            split: <ActionSplit />,
          }}
          searchConfig={{
            optionButtons: 'search',
            filterType: 'inline',
            searchText: '查询',
            extraAdditionButton: true,
            addition: {
              children: '新增',
              position: 'top',
            },
          }}
          pagination={{
            defaultPageSize: 10,
            showTotal: () => <></>,
          }}
          addLinkPage={{
            linkPage: () => <DepartmentGradesForm />,
            modalProps: { width: 500 },
            onLinkPageValidate: async (params) => {
              try {
                await vsf.refs.DepartmentGradesForm?.validateFieldsReturnFormatValue();
                if (params?.subject !== 'PHYSICAL' && !params?.list) {
                  vsf.showToast('请上传成绩数据', 'error');
                  return false;
                }
                const res =
                  await vsf?.services?.ScoreTaskBOController_createScoreTask_919277?.(
                    {
                      btoParam: {
                        ...params,
                        scoreMenuId: scoreMenuIdIs,
                        list: undefined,
                      },
                      list: params?.list,
                    },
                  );
                return res?.data;
              } catch (e) {
                return false;
              }
            },
          }}
          onFetch={onFetch}
        >
          <VSTableColumn
            dataIndex={['name']}
            title="监测名称"
            ellipsis
            valueType="text"
            search
            order={0}
            columnKey={'nameLike'}
            formItemProps={{
              rules: [
                {
                  message: '名称长度不合法',
                  type: 'string',
                  min: 0,
                  max: 64,
                },
              ],
            }}
            fieldProps={{
              placeholder: '请输入',
              width: 100,
            }}
          />

          <VSTableColumn
            dataIndex={['year']}
            title="学年"
            valueType="dateYear"
          />

          <VSTableColumn
            dataIndex={['period']}
            title="学段"
            valueType="select"
            dataSource={[
              { label: '小学', value: 'PRIMARY_SCHOOL' },
              { label: '初中', value: 'MIDDLE_SCHOOL' },
            ]}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['grade']}
            title="年级"
            valueType="select"
            search
            order={-16}
            columnKey={'gradeIs'}
            dataSource={[
              { label: '一年级', value: 'FIRST_GRADE' },
              { label: '二年级', value: 'SECOND_GRADE' },
              { label: '三年级', value: 'THIRD_GRADE' },
              { label: '四年级', value: 'FOURTH_GRADE' },
              { label: '五年级', value: 'FIFTH_GRADE' },
              { label: '六年级', value: 'SIXTH_GRADE' },
              { label: '七年级', value: 'SEVENTH_GRADE' },
              { label: '八年级', value: 'EIGHTH_GRADE' },
              { label: '九年级', value: 'NINE_GRADE' },
            ]}
            fieldProps={{
              width: 110,
              placeholder: '请选择',
            }}
          />

          <VSTableColumn
            dataIndex={['subject']}
            title="学科"
            valueType="select"
            search
            order={-14}
            columnKey={'subjectIs'}
            dataSource={[
              { label: '语文', value: 'CHINESE' },
              { label: '数学', value: 'MATHEMATICS' },
              { label: '英语', value: 'ENGLISH' },
              { label: '体育', value: 'PHYSICAL' },
              { label: '音乐', value: 'MUSIC' },
              { label: '美术', value: 'ART' },
              { label: '科学', value: 'SCIENCE' },
              { label: '社会', value: 'SOCIOLOGY' },
            ]}
            fieldProps={{
              width: 110,
              placeholder: '请选择',
            }}
          />

          <VSTableColumn
            dataIndex={['createdAt']}
            title="创建时间"
            valueType="date"
            width={160}
            fieldProps={{
              format: 'YYYY-MM-DD HH:mm',
            }}
          />
        </VSPromiseTable>
      </Section>
    </Page>
  );
};
export default definePage(DepartmentGradesList);
