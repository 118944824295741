import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React from 'react';

import DepartmentMonitorInfoChangeCheckTable from './DepartmentMonitorInfoChangeCheckTable';

const DepartmentMonitorInfoChangeCheck = (props) => {
  const {
    routes: {
      params: { taskIdIs },
    },
  } = props;

  return (
    <Page>
      <DepartmentMonitorInfoChangeCheckTable taskIdIs={taskIdIs} />
    </Page>
  );
};

export default definePage(DepartmentMonitorInfoChangeCheck);
