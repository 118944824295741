import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useEffect, useState } from 'react';
import { useCallback } from 'react';
import styled from 'styled-components';

import { MonitorTaskStatusEnum } from '@/config';
import { navigateToWithPlatform, usePlatformInfo } from '@/utils';

const ConfirmButton = styled.div`
  width: 80px;
  height: 30px;
  background: #eaeefd;
  border-radius: 6px;
  border: 1px solid #c9d6ff;

  font-size: 14px;
  font-weight: 400;
  color: #2e5ef2;
`;

const ShowButton = styled.div`
  width: 80px;
  height: 30px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #dddddd;

  font-size: 14px;
  font-weight: 400;
  color: #333333;
`;

const EndAic = styled.div`
  width: 5px;
  height: 5px;
  background: #aaaaaa;
  border-radius: 50%;
  margin-right: 8px;
`;

const NotStartAic = styled.div`
  width: 5px;
  height: 5px;
  background: #fe9908;
  border-radius: 50%;
  margin-right: 8px;
`;

const ProgressAic = styled.div`
  width: 5px;
  height: 5px;
  background: #2e5ef2;
  border-radius: 50%;
  margin-right: 8px;
`;

const SchoolMonitorTaskListTable = (props) => {
  const [platformInfo] = usePlatformInfo();
  const [refresh, setRefresh] = useState();

  // useEffect(() => {
  //   try {
  //     console.log('================');
  //     const _platformInfo = JSON.parse(
  //       localStorage.getItem('platformInfo') ?? {},
  //     );

  //     if (_platformInfo?.platformId !== platformInfo?.platformId) {
  //       setRefresh(!refresh);
  //     }
  //     console.log('12312312312', _platformInfo);
  //   } catch (error) {
  //     console.error(error);
  //   }
  // }, [platformInfo.platformId, refresh]);

  const onFetch = useCallback(
    async (params) => {
      if (platformInfo?.platformId) {
        const res =
          await vsf?.services?.MonitorStudentController_pageMonitorTaskSchool_aebbfa?.(
            {
              qto: {
                from: params.pagination?.from ?? 0,
                size: params.pagination?.size ?? 20,
                orderList: params.orderList, // 排序结果
                orgIdIs: platformInfo?.platformId,
                ...params.search, // 搜索结果
                ...params.filters, // 过滤器结果
                ...(params?.extra ?? {}), // 传入的额外参数
                refresh: undefined,
              },
            },
          );
        return {
          data: res?.data?.result ?? res?.data ?? [],
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
    },
    [platformInfo],
  );

  const handelShowClick = (_, record) => {
    navigateToWithPlatform(
      `/${platformInfo?.platformId}/monitor/${record?.task?.id}/infoCheck?type=show&name=${record?.task?.name}&taskIdIs=${record?.task?.id}&taskOrgId=${record?.id}`,
    );
  };

  const handelConfirmClick = (_, record) => {
    navigateToWithPlatform(
      `/${platformInfo?.platformId}/monitor/${record?.task?.id}/infoCheck?type=confirm&name=${record?.task?.name}&taskIdIs=${record?.task?.id}&grade=${record?.task?.grade}&taskOrgId=${record?.id}`,
    );
  };

  return (
    <Section>
      <VSPromiseTable
        editable={{ editType: 'single', columnProps: { hideInTable: true } }}
        pagination={{
          defaultPageSize: 9,
          showTotal: () => <></>,
        }}
        searchConfig={{
          filterType: 'inline',
          optionButtons: 'search',
          searchButtonProps: {
            children: '查询',
          },
        }}
        extraParams={{ refresh }}
        onFetch={onFetch}
      >
        <VSTableColumn
          dataIndex={['task', 'name']}
          title="监测名称"
          ellipsis
          valueType="text"
          search
          order={-1}
          columnKey={'nameLike'}
          formItemProps={{
            rules: [
              {
                message: '监测名称长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{
            width: 110,
            placeholder: '请输入',
          }}
        />

        <VSTableColumn
          dataIndex={['task', 'year']}
          title="学年"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '学年长度不合法', type: 'string', min: 0, max: 64 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['task', 'period']}
          title="学段"
          valueType="select"
          dataSource={[
            { label: '小学', value: 'PRIMARY_SCHOOL' },
            { label: '初中', value: 'MIDDLE_SCHOOL' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['task', 'grade']}
          title="年级"
          valueType="select"
          search
          order={-4}
          columnKey={'gradeIs'}
          dataSource={[
            { label: '一年级', value: 'FIRST_GRADE' },
            { label: '二年级', value: 'SECOND_GRADE' },
            { label: '三年级', value: 'THIRD_GRADE' },
            { label: '四年级', value: 'FOURTH_GRADE' },
            { label: '五年级', value: 'FIFTH_GRADE' },
            { label: '六年级', value: 'SIXTH_GRADE' },
            { label: '七年级', value: 'SEVENTH_GRADE' },
            { label: '八年级', value: 'EIGHTH_GRADE' },
            { label: '九年级', value: 'NINE_GRADE' },
          ]}
          fieldProps={{
            width: 110,
            placeholder: '请选择',
          }}
        />

        <VSTableColumn
          dataIndex={['task', 'monitorTaskStatus']}
          title="状态"
          valueType="select"
          search
          order={-2}
          columnKey={'monitorTaskStatusIs'}
          dataSource={[
            { label: '待开始', value: 'NOT_START' },
            { label: '进行中', value: 'PROGRESS' },
            { label: '已结束', value: 'END' },
          ]}
          formItemProps={{
            label: '监测状态',
          }}
          fieldProps={{
            width: 110,
            placeholder: '请选择',
          }}
          render={(dom, record) => {
            return (
              <div className="aic">
                {record?.monitorTaskStatus ===
                  MonitorTaskStatusEnum.NOT_START && <NotStartAic />}
                {record?.monitorTaskStatus ===
                  MonitorTaskStatusEnum.PROGRESS && <ProgressAic />}
                {record?.monitorTaskStatus === MonitorTaskStatusEnum.END && (
                  <EndAic />
                )}
                {dom}
              </div>
            );
          }}
        />

        <VSTableColumn
          dataIndex={['createdAt']}
          title="创建时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />

        <VSTableColumn
          dataIndex={['monitorTaskSubjectDtoList', 'subject']}
          title="学科"
          hideInTable={true}
          valueType="select"
          search
          order={-3}
          columnKey={'subjectIn'}
          // searchFieldProps={{ mode: 'multiple' }}
          searchConfig={{
            transform: (values) => ({
              subjectIn: !values?.length ? null : [values],
            }),
          }}
          dataSource={[
            { label: '语文', value: 'CHINESE' },
            { label: '数学', value: 'MATHEMATICS' },
            { label: '英语', value: 'ENGLISH' },
            { label: '体育', value: 'PHYSICAL' },
            { label: '音乐', value: 'MUSIC' },
            { label: '美术', value: 'ART' },
            { label: '科学', value: 'SCIENCE' },
            { label: '社会', value: 'SOCIOLOGY' },
          ]}
          fieldProps={{
            width: 110,
            placeholder: '请选择',
          }}
        />

        <VSTableColumn dataIndex={['action']} title="操作" valueType="option">
          <VSTableAction
            show={(record) =>
              record?.task?.monitorTaskStatus === MonitorTaskStatusEnum.PROGRESS
            }
            onClick={handelConfirmClick}
          >
            <ConfirmButton className="aic jcc">前往确认</ConfirmButton>
          </VSTableAction>

          <VSTableAction
            show={(record) =>
              record?.task?.monitorTaskStatus === MonitorTaskStatusEnum.END
            }
            onClick={handelShowClick}
          >
            <ShowButton className="aic jcc">查看</ShowButton>
          </VSTableAction>
          <VSTableAction
            show={(record) =>
              record?.task?.monitorTaskStatus ===
              MonitorTaskStatusEnum.NOT_START
            }
          >
            一
          </VSTableAction>
        </VSTableColumn>
      </VSPromiseTable>
    </Section>
  );
};
export default definePage(SchoolMonitorTaskListTable);
