import { defineStore } from '@vs/vsf-boot';

const user = defineStore({
  userInfo: {},

  platformInfo: {
    platform: 'school',
  },

  activeTabsKey: 'Sampling',

  schoolGradeBreadCrumb: {
    show: false,
    label: '',
  },

  orgList: [],

  updateSchoolGradeBreadCrumb: (_schoolGradeBreadCrumb) => {
    user.schoolGradeBreadCrumb = _schoolGradeBreadCrumb;
  },

  setUserInfo: (userInfo) => {
    user.userInfo = userInfo;
  },

  setPlatformInfo: (platformInfo) => {
    user.platformInfo = platformInfo;
  },

  setActiveTabsKey: (currentKey) => {
    user.activeTabsKey = currentKey;
  },

  setOrgList: (list) => {
    user.orgList = list;
  },
});

export default user;
