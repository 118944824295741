/* eslint-disable prettier/prettier */
import './index.less';

import {
  compose,
  Image,
  message,
  Upload,
  UploadFile,
  UploadProps,
  useControllableState,
  withField,
  withPreview,
} from '@vs/vsf-kit';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import DeleteIcon from '@/assets/images/icon/image_delete_icon.png'
import { convertToHttps } from '@/utils';

import CloseIcon from './close_icon.png'
import { portalize } from './portal';
import UploadIcon from './upload_icon.png';

const ImageSuffix = ['.jpg', '.png', 'jpeg', '.webp']

const ImageView = styled.div`
width: 84px;
height: 84px;
border-radius: 8px;
overflow: hidden;
position: relative;
border: 1px solid #DDDDDD;
margin-right: 15px;
margin-bottom: 14px;
`

const FileItemView = styled.div`
width: 216px;
height: 40px;
background: #FAFAFA;
border-radius: 22px;
border: 1px solid #EEEEEE;
box-sizing: border-box;
padding: 0 14px;
margin-bottom: 10px;

font-size: 14px;
font-weight: 400;
color: #333333;
`

export type UploadOssParams = {
  /** 自定义类型字段 */
  dir: string;
  expire: string;
  host: string;
  accessKeyId: string;
  policy: string;
  signature: string;
  url: string;
  fileName: string
};

export type FileUploadValue = UploadFile & {
  /** 自定义类型字段 */
  url?: string;
  name?: string;
};

export type getOssPolicyType = (suffixName?: string, fileName?: string) => Promise<UploadOssParams>;

export type FileUploadProps = {
  /**
   * 默认值
   */
  defaultValue?: FileUploadValue[];
  /**
   * 值
   */
  value?: FileUploadValue[];
  /**
   * 值变化回调
   */
  onChange?: (value?: FileUploadValue[]) => void;
  /**
   * 上传参数
   */
  ossParams?: UploadOssParams;
  /**
   *  获取上传参数服务
   */
  getOssPolicy?: getOssPolicyType;
  /**
   * 上传文件类型
   */
  type: 'picture' | 'file';
  /**
   * upload属性
   */
  uploadProps?: UploadProps;
  /**
   * 自定义上传内容
   */
  children?: React.ReactNode;

  isCustomFileUpload?: boolean

  disabledForm?: boolean
};

const VpUploadDiv = styled.div`
  .ant-upload {
    width: auto !important;
    height: auto !important;
    min-width: 104px;
    min-height: 104px;
  }
`;

const AppectLabel = styled.div`
  font-size: 12px;
  font-weight: 400;
  color: #999999;
  margin-left: 10px;
`;

const DeleteIconView = styled.div`
width:15px;
height:15px;
position: absolute;
right: 0;
top: -5px;
`

const aTagDownload = (url, filename) => {
  const a = document.createElement('a'); // 创建 a 标签
  a.href = url; // 下载路径
  a.download = filename; // 下载属性，文件名
  a.style.display = 'none'; // 不可见
  document.body.appendChild(a); // 挂载
  a.click(); // 触发点击事件
  document.body.removeChild(a); // 移除
};

const download = (path, fileName) => {
  const _path = convertToHttps(path)
  fetch(_path)
    .then((res) => res.blob())
    .then((blob) => {
      const objectUrl = URL.createObjectURL(blob); // 创建 url 对象
      aTagDownload(objectUrl, fileName); // 调用 上面 [2] 动态方式
    })
    .catch((err) => {
      console.error();
    });
};

/**
 * 上传
 */
const FileUpload = (props: FileUploadProps) => {
  const {
    defaultValue,
    value: valueProp,
    onChange,
    ossParams,
    uploadProps,
    type,
    getOssPolicy,
    children,
    disabledForm = false,
    isCustomFileUpload = false,
    ...rest
  } = props;
  const [value, setValue] = useControllableState({
    defaultValue,
    value: valueProp,
    onChange,
  });
  const [OSSData, setOSSData] = useState<UploadOssParams>();
  const otherFile = useRef<any>([])
  const portalRef = useRef<any>();
  const loadOssPolicy = useCallback(async (suffixName, fileName) => {
    try {
      const _data = await getOssPolicy?.(suffixName, `***${fileName}`);
      setOSSData(_data);
      return _data;
    } catch (err) {
      // message.error(`获取ossParams错误: ${err}`);
      // console.error();
    }
  }, [getOssPolicy]);

  // useEffect(() => {
  //   if (getOssPolicy && !uploadProps?.customRequest) {
  //     loadOssPolicy();
  //   } else {
  //     setOSSData(ossParams);
  //   }
  // }, [getOssPolicy, loadOssPolicy, ossParams, uploadProps]);

  const handleChange = async ({ fileList }) => {
    const reg = /(http|https):\/\/\S*/;
    const _value = fileList.map((item) => {
      return {
        ...item,
        url: reg.test(item.url) ? item.url : `${OSSData?.host}/${item.url}`,
      };
    });
    setValue([..._value]);
  };

  const onRemove = (file: UploadFile) => {
    const files = (value || []).filter((v) => v.url !== file.url);
    setValue([...files]);
  };

  const getExtraData = async (file) => {
    return {
      key: OSSData?.fileName,
      OSSAccessKeyId: OSSData?.accessKeyId,
      policy: OSSData?.policy,
      Signature: OSSData?.signature,
    };
  };

  const beforeUpload = async (file) => {
    const _fileName = file?.name?.slice(0, file?.name?.lastIndexOf('.'))
    const suffix = file.name.slice(file.name.lastIndexOf('.'));

    const _OSSData = await loadOssPolicy(suffix, _fileName)

    if (!_OSSData) {
      return;
    }
    // const filename = file.name?.slice(0, file.name.lastIndexOf('.')) + Date.now() + suffix;
    file.url = _OSSData.url;
    return file;
  };

  const handlePreviewClose = (visible) => {
    if (!visible) {
      portalRef?.current?.close();
    }
  };

  const handlePreview = async (file: UploadFile) => {
    if (type === 'picture') {
      portalRef.current = portalize({
        component: (
          <Image
            style={{
              opacity: 0,
            }}
            width={200}
            src={file?.url || (file.preview as string)}
            preview={{
              visible: true,
              onVisibleChange: handlePreviewClose,
            }}
          />
        ),
      });
    } else if (type === 'file') {
      download(`${file.url}`, file.name);
    }
  };

  const retryImage = (domId) => {
    const img = document.getElementById(domId) as HTMLImageElement;
    if (img) {
      img.src = img.getAttribute("src") ?? '';
    }
  }

  // 上传参数
  const uploadParams: UploadProps = {
    name: 'file',
    fileList: value,
    defaultFileList: value,
    action: OSSData?.host,
    onChange: handleChange,
    onRemove,
    data: getExtraData,
    beforeUpload,
    listType: type === 'picture' ? 'picture-card' : 'text',
    onPreview: handlePreview,
    itemRender: (originNode, file, fileList, { download, preview, remove }) => {
      let _url = file.url
      let suffix = file.url ? file?.url.split('?')[0].slice(file?.url.split('?')[0].lastIndexOf('.')) : ''
      // 源文件对象才会有lastModified
      if (file?.lastModified) {
        suffix = file?.name?.slice(file?.name?.lastIndexOf('.'))
      }
      if (file?.thumbUrl) {
        _url = file?.thumbUrl
      }


      if (ImageSuffix?.includes(suffix)) {
        return (
          <ImageView>
            <img src={_url}
              id={file?.uid}
              style={{ width: '100%', height: '100%', objectFit: 'cover', cursor: 'pointer' }}
              onClick={preview}
              onError={e => {
                retryImage(file?.uid)
              }}
            />
            {
              !disabledForm && (
                <DeleteIconView>
                  <img src={DeleteIcon} style={{ width: '100%', height: '100%', cursor: 'pointer' }}
                    onClick={remove}
                  />
                </DeleteIconView>
              )
            }
          </ImageView>
        )
      }
      otherFile.current = cloneDeep(fileList)?.filter(item => {
        const _suffix = item?.url ? item?.url.split('?')[0].slice(item?.url.split('?')[0].lastIndexOf('.')) : ''
        return !ImageSuffix?.includes(_suffix)
      })?.map(item => {
        const decodeUrl = decodeURIComponent(item.url ?? '')
        const _path = decodeUrl?.split('?')[0]
        const _originFileName = _path?.slice(_path?.indexOf('***') + 3, _path?.lastIndexOf('.'))
        return {
          ...item,
          originFileName: _originFileName,
          download,
          remove
        }
      })

      return <div style={{ width: 0, height: 0 }} />
    },
    ...uploadProps,
  };

  console.log(disabledForm, 'disabledForm========')

  /** 编写组件逻辑 */
  return (
    <>
      {/* 这里必须把rest传递给根节点，不要删除 */}
      <VpUploadDiv {...rest}>
        {/* <>展示值：</> */}
        {/* @ts-ignore */}
        <Upload {...uploadParams}>
          {children ? (
            children
          ) : (
            !disabledForm && <>
              {type && type === 'picture' ? (
                <div className='aic' style={{ marginBottom: 10 }}>
                  <div className='custom_upload_btn aic jcc'>
                    <img src={UploadIcon} className='custom_upload_btn__icon' />
                    <span>上传附件</span>
                  </div>
                  <AppectLabel>上传格式要求：JPG、JPEG、PNG、PDF</AppectLabel>
                </div>
              ) : (
                // @ts-ignore
                <div className='aic' style={{ marginBottom: 14 }}>
                  <div className='custom_upload_btn aic jcc'>
                    <img src={UploadIcon} className='custom_upload_btn__icon' />
                    <span>上传附件</span>
                  </div>
                  <AppectLabel>上传格式要求：JPG、JPEG、PNG、PDF</AppectLabel>
                </div>
              )}
            </>
          )}
        </Upload>
        {
          !isCustomFileUpload && (
            <div className='col'>
              {
                otherFile.current?.map((item, _idx) => {
                  return (
                    <FileItemView key={_idx} className='aic'>
                      <div className='ell' style={{ width: '90%' }}
                        onClick={item?.download}
                      >
                        {item?.originFileName}
                      </div>
                      {
                        !disabledForm &&
                        (
                          <img src={CloseIcon}
                            style={{
                              width: 16,
                              height: 16,
                              marginLeft: 8
                            }}
                            onClick={item?.remove}
                          />
                        )
                      }
                    </FileItemView>
                  )
                })
              }
            </div>
          )
        }
      </VpUploadDiv>
    </>
  );
};

FileUpload.download = download;

export default compose(
  withField<FileUploadValue>({
    name: 'FileUpload',
  }),
  withPreview<FileUploadProps>({
    renderPreview: (props) => {
      const { value, type } = props;
      const aTagDownload = (url, filename) => {
        const a = document.createElement('a'); // 创建 a 标签
        a.href = url; // 下载路径
        a.download = filename; // 下载属性，文件名
        a.style.display = 'none'; // 不可见
        document.body.appendChild(a); // 挂载
        a.click(); // 触发点击事件
        document.body.removeChild(a); // 移除
      };

      const download = (path, fileName) => {
        fetch(path)
          .then((res) => res.blob())
          .then((blob) => {
            const objectUrl = URL.createObjectURL(blob); // 创建 url 对象
            aTagDownload(objectUrl, fileName); // 调用 上面 [2] 动态方式
          })
          .catch((err) => {
            console.error();
          });
      };

      /** 返回预览模式下的dom */
      return (
        <>
          {/* 预览值： */}
          {value?.map((item) => (
            <div key={item.url}>
              {type === 'picture' && (
                <>
                  <Image width={100} src={item.url || ''} />
                  <div>{item?.name}</div>
                </>
              )}
              {type === 'file' && (
                <a
                  onClick={() => {
                    download(item.url, item?.name);
                  }}
                  href={item.url}
                >
                  {item?.name}
                </a>
              )}
            </div>
          ))}
        </>
      );
    },
  }),
)(FileUpload);
