import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  Col,
  message,
  Modal,
  Row,
  Section,
  TextArea,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import classNames from 'classnames';
import React, { useCallback, useRef, useState } from 'react';

import { ScoreAuditStatusEnum } from '@/config';
import { birdayFormatter } from '@/utils';

const DepartmentGradesCheckTable = (props) => {
  const { scoreTaskIdIs, activeSchool } = props;

  const [rejectReason, setrejectReason] = useState('');

  const [rejectVisible, setRejectVisible] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const handelPassClick = async () => {
    try {
      if (activeSchool?.auditStatus === ScoreAuditStatusEnum.UNAUDITED) {
        const response =
          await vsf.services?.ScoreAuditBOController_updateScoreAudit_23e3e8?.({
            btoParam: {
              id: activeSchool?.id,
              auditStatus: ScoreAuditStatusEnum.PASSED,
            },
          });
        if (response && response.code === 200) {
          message.success('已通过');
          props?.onRefresh?.();
          setRefresh(!refresh);
          return true;
        }
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelRejectClick = () => {
    if (activeSchool?.auditStatus === ScoreAuditStatusEnum.UNAUDITED) {
      setRejectVisible(true);
    }
  };

  const handelRejectSubmit = async () => {
    try {
      const response =
        await vsf.services?.ScoreAuditBOController_updateScoreAudit_23e3e8?.({
          btoParam: {
            id: activeSchool?.id,
            auditStatus: ScoreAuditStatusEnum.REJECTED,
            rejectReason,
          },
        });
      if (response && response.code === 200) {
        message.success('已拒绝');
        props?.onRefresh?.();
        setRefresh(!refresh);
        setRejectVisible(false);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  };
  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf?.services?.ScoreInfoPubDtoController_pageOrgScoreInfo_a0e81c?.(
          {
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 20,
              orderList: params.orderList, // 排序结果
              scoreTaskIdIs: scoreTaskIdIs,
              orgIdIs: activeSchool?.org?.id,
              ...params.search, // 搜索结果
              ...params.filters, // 过滤器结果
              ...(params?.extra ?? {}), // 传入的额外参数
              refresh: undefined,
            },
            ext: params?.ext,
          },
        );
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [scoreTaskIdIs, activeSchool],
  );

  console.log(activeSchool, 'activeSchool====');

  return (
    <Section>
      <VSPromiseTable
        pagination={{
          defaultPageSize: 10,
          showTotal: () => <></>,
        }}
        searchConfig={{
          filterType: 'inline',
          optionButtons: 'search',
          searchText: '查询',
        }}
        scroll={{
          x: 1000,
        }}
        extraParams={{ refresh }}
        searchFormRender={(_, doms, option) => {
          return (
            <div className="aic">
              {doms}
              <div className={classNames(option.containerClassName, 'aic')}>
                <Button
                  size="large"
                  type="primary"
                  onClick={handelPassClick}
                  style={{
                    opacity:
                      activeSchool?.auditStatus ===
                      ScoreAuditStatusEnum.UNAUDITED
                        ? 1
                        : 0.5,
                  }}
                >
                  通过
                </Button>
                <div style={{ width: 14 }} />
                <Button
                  size="large"
                  type="primary"
                  danger
                  onClick={handelRejectClick}
                  style={{
                    opacity:
                      activeSchool?.auditStatus ===
                      ScoreAuditStatusEnum.UNAUDITED
                        ? 1
                        : 0.5,
                  }}
                >
                  拒绝
                </Button>
              </div>
            </div>
          );
        }}
        onFetch={onFetch}
      >
        <VSTableColumn
          dataIndex={['orgName']}
          title="学校名称"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '学校名称长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
        />

        <VSTableColumn
          dataIndex={['period']}
          title="学段"
          valueType="select"
          dataSource={[
            { label: '小学', value: 'PRIMARY_SCHOOL' },
            { label: '初中', value: 'MIDDLE_SCHOOL' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['scoreTaskId', 'year']}
          title="学年"
          valueType="text"
        />

        <VSTableColumn
          dataIndex={['classes']}
          title="班级"
          valueType="text"
          search
          order={-2}
          columnKey={'classesLike'}
          formItemProps={{
            rules: [
              { message: '班级长度不合法', type: 'string', min: 0, max: 64 },
            ],
          }}
          fieldProps={{
            width: 110,
            placeholder: '请输入',
          }}
        />

        <VSTableColumn
          dataIndex={['studentName']}
          title="姓名"
          valueType="text"
          search
          order={-1}
          columnKey={'studentNameLike'}
          formItemProps={{
            rules: [
              {
                message: '学生姓名长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{
            width: 110,
            placeholder: '请输入',
          }}
        />

        <VSTableColumn
          dataIndex={['sex']}
          title="性别"
          valueType="select"
          search
          order={-3}
          columnKey={'sexIs'}
          dataSource={[
            { label: '男', value: 'MAN' },
            { label: '女', value: 'WOMAN' },
          ]}
          fieldProps={{
            width: 110,
            placeholder: '请选择',
          }}
        />

        <VSTableColumn
          dataIndex={['idCard']}
          title="身份证"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                pattern:
                  /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['birthday']}
          title="出生日期"
          valueType="date"
          fieldProps={{
            format: 'yyyy-MM-dd',
          }}
          render={(_, record) => {
            return birdayFormatter(record?.birthday);
          }}
        />

        <VSTableColumn
          dataIndex={['nation']}
          title="民族"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '民族长度不合法', type: 'string', min: 0, max: 64 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['nativePlace']}
          title="籍贯"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '籍贯长度不合法', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['studentStatusNum']}
          title="学籍辅号"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '学籍辅号长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn dataIndex={['score']} title="总分" valueType="text" />

        {/* <VSTableColumn dataIndex={['action']} title="操作" valueType="option">
          <VSTableAction>删除</VSTableAction>
          <VSTableAction>删除</VSTableAction>
        </VSTableColumn> */}
      </VSPromiseTable>
      <Modal
        open={rejectVisible}
        destroyOnClose
        title="拒绝"
        onOk={handelRejectSubmit}
        onCancel={() => setRejectVisible(false)}
      >
        <TextArea
          style={{
            width: '100%',
            height: 160,
          }}
          value={rejectReason}
          placeholder="请输入拒绝原因"
          onChange={(e) => {
            setrejectReason(e.target.value);
          }}
        ></TextArea>
      </Modal>
    </Section>
  );
};
export default definePage(DepartmentGradesCheckTable);
