import vsf, { definePage } from '@vs/vsf-boot';
import { Section, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';

import { birdayFormatter, usePlatformInfo } from '@/utils';
const SchoolGradesDetailTable = (props) => {
  const [platformInfo] = usePlatformInfo();

  const onFetch = useCallback(
    async (params) => {
      if (platformInfo?.platformId) {
        const res =
          await vsf?.services?.ScoreInfoPubDtoController_pageOrgScoreInfo_a0e81c?.(
            {
              qto: {
                from: params.pagination?.from ?? 0,
                size: params.pagination?.size ?? 20,
                orderList: params.orderList, // 排序结果
                scoreTaskIdIs: props.scoreTaskIdIs,
                orgIdIs: platformInfo?.platformId,
                ...params.search, // 搜索结果
                ...params.filters, // 过滤器结果
                ...(params?.extra ?? {}), // 传入的额外参数
              },
              ext: params?.ext,
            },
          );
        return {
          data: res?.data?.result ?? res?.data ?? [],
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
    },
    [props.scoreTaskIdIs, platformInfo],
  );

  return (
    <Section>
      <VSPromiseTable
        editable={{ editType: 'single', columnProps: { hideInTable: true } }}
        searchConfig={{
          filterType: 'inline',
          optionButtons: 'search',
          searchText: '查询',
        }}
        pagination={{
          defaultPageSize: 10,
          showTotal: () => <></>,
        }}
        onFetch={onFetch}
        scroll={{
          x: 800,
        }}
      >
        <VSTableColumn
          dataIndex={['orgName']}
          title="学校名称"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '学校名称长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['scoreTaskId', 'year']}
          title="学年"
          valueType="text"
        />
        <VSTableColumn
          dataIndex={['period']}
          title="学段"
          valueType="select"
          dataSource={[
            { label: '小学', value: 'PRIMARY_SCHOOL' },
            { label: '初中', value: 'MIDDLE_SCHOOL' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['classes']}
          title="班级"
          valueType="text"
          search
          order={-7}
          columnKey={'classesLike'}
          formItemProps={{
            rules: [
              { message: '班级长度不合法', type: 'string', min: 0, max: 64 },
            ],
          }}
          fieldProps={{
            placeholder: '请输入',
            width: 110,
          }}
        />
        <VSTableColumn
          dataIndex={['studentName']}
          title="姓名"
          valueType="text"
          search
          order={-6}
          columnKey={'studentNameLike'}
          formItemProps={{
            rules: [
              {
                message: '学生姓名长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{
            placeholder: '请输入',
            width: 110,
          }}
        />
        <VSTableColumn
          dataIndex={['idCard']}
          title="身份证号"
          valueType="text"
          width={180}
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                pattern:
                  /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['sex']}
          title="性别"
          valueType="select"
          search
          order={-8}
          columnKey={'sexIs'}
          dataSource={[
            { label: '男', value: 'MAN' },
            { label: '女', value: 'WOMAN' },
          ]}
          fieldProps={{
            placeholder: '请选择',
            width: 110,
          }}
        />
        <VSTableColumn
          dataIndex={['birthday']}
          title="出生日期"
          valueType="date"
          width={120}
          fieldProps={{ format: 'yyyy-MM-dd' }}
          render={(_, record) => {
            return birdayFormatter(record?.birthday);
          }}
        />
        <VSTableColumn
          dataIndex={['nation']}
          title="民族"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '民族长度不合法', type: 'string', min: 0, max: 64 },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['nativePlace']}
          title="籍贯"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '籍贯长度不合法', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['studentStatusNum']}
          title="学籍辅号"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '学籍辅号长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn dataIndex={['score']} title="总分" valueType="text" />
      </VSPromiseTable>
    </Section>
  );
};
export default definePage(SchoolGradesDetailTable);
