import DepartmentGrades from '@pages/DepartmentGrades';
import DepartmentGradesCheck from '@pages/DepartmentGradesCheck';
import DepartmentGradesDetail from '@pages/DepartmentGradesDetail';
import DepartmentMonitor from '@pages/DepartmentMonitor';
import DepartmentMonitorFinalList from '@pages/DepartmentMonitorFinalList';
import DepartmentMonitorInfoChangeCheck from '@pages/DepartmentMonitorInfoChangeCheck';
import DepartmentMonitorInfoCheck from '@pages/DepartmentMonitorInfoCheck';
import DepartmentMonitorInfoCheckSchool from '@pages/DepartmentMonitorInfoCheckSchool';
import DepartmentMonitorReplacementRelease from '@pages/DepartmentMonitorReplacementRelease';
import DepartmentMonitorSampleStudent from '@pages/DepartmentMonitorSampleStudent';
import DepartmentMonitorSampleStudentReplacement from '@pages/DepartmentMonitorSampleStudentReplacement';
import DepartmentPriviledge from '@pages/DepartmentPriviledge';
import SchoolGrades from '@pages/SchoolGrades';
import SchoolGradesDetail from '@pages/SchoolGradesDetail';
import SchoolMonitor from '@pages/SchoolMonitor';
import SchoolMonitorFinalList from '@pages/SchoolMonitorFinalList';
import SchoolMonitorInfoChangeCheck from '@pages/SchoolMonitorInfoChangeCheck';
import SchoolMonitorInfoChangeHistory from '@pages/SchoolMonitorInfoChangeHistory';
import SchoolMonitorInfoCheck from '@pages/SchoolMonitorInfoCheck';
import SchoolMonitorReplacement from '@pages/SchoolMonitorReplacement';
import SchoolMonitorReplacementHistory from '@pages/SchoolMonitorReplacementHistory';
import SchoolMonitorStudentConfirm from '@pages/SchoolMonitorStudentConfirm';
import SchoolPriviledgeAccount from '@pages/SchoolPriviledgeAccount';

import DepartmentGradesList from '@/pages/DepartmentGradesList';
import DepartmentMonitorSampleConfig from '@/pages/DepartmentMonitorSampleConfig';
import DepartmentMonitorSampleConfigTable from '@/pages/DepartmentMonitorSampleConfigTable';
import DepartmentMonitorSampleSchool from '@/pages/DepartmentMonitorSampleSchool';
import DepartmentMonitorTaskList from '@/pages/DepartmentMonitorTaskList';
import SchoolGradesList from '@/pages/SchoolGradesList';
import SchoolMonitorTaskList from '@/pages/SchoolMonitorTaskList';

const routes = [
  {
    // 局端-成绩管理
    path: '/department/:platformId/grades',
    component: DepartmentGrades,
    routes: [
      {
        // 局端-成绩管理 - 任务列表
        path: '/department/:platformId/grades/gradeslist/:scoreMenuIdIs',
        component: DepartmentGradesList,
        exact: true,
      },
      {
        // 局端-成绩管理 - 查看
        path: '/department/:platformId/grades/gradesDetail/:recordId',
        component: DepartmentGradesDetail,
        exact: true,
      },
    ],
  },
  {
    // 局端-成绩管理 - 成绩审核
    path: '/department/:platformId/gradesCheck/:scoreTaskId',
    component: DepartmentGradesCheck,
    exact: true,
  },
  {
    // 局端-监测任务列表
    path: '/department/:platformId/monitorTaskList',
    component: DepartmentMonitorTaskList,
    exact: true,
  },
  {
    // 局端-监测列表
    path: '/department/:platformId/monitor/:taskIdIs',
    component: DepartmentMonitor,
    routes: [
      {
        // 局端-监测任务详情-抽样测评最终名单
        path: '/department/:platformId/monitor/:taskIdIs/finalList',
        component: DepartmentMonitorFinalList,
        exact: true,
      },
      {
        // 局端-监测任务详情-学生信息变更审核
        path: '/department/:platformId/monitor/:taskIdIs/infoChangeCheck',
        component: DepartmentMonitorInfoChangeCheck,
        exact: true,
      },
      {
        // 局端-任务详情-学生信息确认
        path: '/department/:platformId/monitor/:taskIdIs/infoCheck',
        component: DepartmentMonitorInfoCheck,
        exact: true,
      },
      {
        // 局端-监测任务详情-学生信息确认-查看学生
        path: '/department/:platformId/monitor/:taskIdIs/school/:taskOrgIdIs',
        component: DepartmentMonitorInfoCheckSchool,
        exact: true,
      },
      {
        // 局端-监测任务详情-样本名单-替换名额发放
        path: '/department/:platformId/monitor/:taskIdIs/replacementRelease',
        component: DepartmentMonitorReplacementRelease,
        exact: true,
      },
      {
        // 局端-监测任务详情-样本名单-样本学生
        path: '/department/:platformId/monitor/:taskIdIs/sampleStudent',
        component: DepartmentMonitorSampleStudent,
        exact: true,
      },
      {
        // 局端-监测任务详情-样本名单-样本学校
        path: '/department/:platformId/monitor/:monitorTaskId/sampleSchool',
        component: DepartmentMonitorSampleSchool,
        exact: true,
      },
      {
        // 局端-监测任务详情-样本名单-样本学生替换审核
        path: '/department/:platformId/monitor/:taskIdIs/sampleStudentReplacement',
        component: DepartmentMonitorSampleStudentReplacement,
        exact: true,
      },
      {
        // 局端-监测任务详情-抽样配置-学校抽取参数
        path: '/department/:platformId/monitor/:monitorTaskId/sampleConfigDetail',
        component: DepartmentMonitorSampleConfig,
        exact: true,
      },
      {
        // 局端-监测任务详情-抽样配置-学校抽取参数
        path: '/department/:platformId/monitor/:monitorTaskId/sampleConfigTable',
        component: DepartmentMonitorSampleConfigTable,
        exact: true,
      },
    ],
  },
  {
    // 局端-权限管理
    path: '/department/:platformId/priviledge',
    component: DepartmentPriviledge,
    exact: true,
  },
  {
    // 校端-成绩管理
    path: '/school/:platformId/grades',
    component: SchoolGrades,
    redirect: '/school/:platformId/grades/gradesList',
    routes: [
      {
        // 校端-成绩管理 - 列表
        path: '/school/:platformId/grades/gradesList',
        component: SchoolGradesList,
        exact: true,
      },
      {
        // 校端-成绩管理 - 查看
        path: '/school/:platformId/grades/gradesDetail/:scoreTaskIdIs',
        component: SchoolGradesDetail,
        exact: true,
      },
    ],
  },
  {
    // 校端-监测任务列表
    path: '/school/:platformId/monitorTaskList',
    component: SchoolMonitorTaskList,
    exact: true,
  },
  {
    // 校端-监测列表
    path: '/school/:platformId/monitor/:taskIdIs',
    component: SchoolMonitor,
    routes: [
      {
        // 校端-监测任务详情-最终名单
        path: '/school/:platformId/monitor/:taskIdIs/finalList',
        component: SchoolMonitorFinalList,
        exact: true,
      },
      {
        // 校端-监测任务详情-学生信息变更审核
        path: '/school/:platformId/monitor/:taskIdIs/infoChangeCheck',
        component: SchoolMonitorInfoChangeCheck,
        exact: true,
      },
      {
        // 校端-监测任务详情-学生信息变更记录
        path: '/school/:platformId/monitor/:taskIdIs/infoChangeHistory',
        component: SchoolMonitorInfoChangeHistory,
        exact: true,
      },
      {
        // 校端-监测任务详情-学生信息确认
        path: '/school/:platformId/monitor/:taskIdIs/infoCheck',
        component: SchoolMonitorInfoCheck,
        exact: true,
      },
      {
        // 校端-监测任务详情-替换名单
        path: '/school/:platformId/monitor/:taskIdIs/replacement',
        component: SchoolMonitorReplacement,
        exact: true,
      },
      {
        // 校端-监测任务详情-替换记录
        path: '/school/:platformId/monitor/:taskIdIs/replacementHistory',
        component: SchoolMonitorReplacementHistory,
        exact: true,
      },
      {
        // 校端-监测任务详情-抽样学生确认
        path: '/school/:platformId/monitor/:taskIdIs/studentConfirm',
        component: SchoolMonitorStudentConfirm,
        exact: true,
      },
    ],
  },
  {
    // 校端-权限管理
    path: '/school/:platformId/priviledge',
    component: SchoolPriviledgeAccount,
    exact: true,
  },
];

export default routes;
