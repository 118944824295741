import vsf, { definePage } from '@vs/vsf-boot';
import {
  Col,
  RadioGroup,
  Row,
  Section,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';
import { useRef } from 'react';

import getOssPolicy from '@/utils/getOssPolicy';

const SchoolMonitorStudentConfirmChangeForm = (props) => {
  const formData = useRef();

  const { taskIdIs } = props;

  return (
    <Section>
      <div className="custom_ant_upload">
        <VSControlledForm
          vsid="72789"
          id="SchoolMonitorStudentConfirmChangeForm"
          value={props.value}
          onChange={(_value) => {
            formData.current = _value;
            props?.onChange?.({
              ..._value,
            });
          }}
        >
          <VSFormLayout key="0" columnCount={1}>
            <div className="antd-formitem-contnent-border">
              <VSFormItem
                name={['applicationReason']}
                label="申请原因"
                valueType="textarea"
                required={false}
                rules={[
                  {
                    required: true,
                  },
                  {
                    message: '拒绝原因：长度不合法',
                    type: 'string',
                    min: 0,
                    max: 256,
                  },
                ]}
                fieldProps={{
                  style: {
                    minHeight: 100,
                  },
                }}
              />
            </div>
            <VSFormItem
              name={['annexAddress']}
              label="附件地址"
              valueType="fileUpload"
              rules={[]}
              convertValue={(v) => {
                try {
                  if (typeof v === 'string') {
                    const _v = JSON.parse(v);
                    return _v?.map((vv) => {
                      if (typeof vv === 'string') {
                        return { url: vv };
                      }
                      return vv;
                    });
                  }
                  if (!v) {
                    return v;
                  }
                  return v?.map((vv) => {
                    if (typeof vv === 'string') {
                      return { url: vv };
                    }
                    return vv;
                  });
                } catch (error) {
                  console.log(error);
                }
              }}
              transform={(v) => {
                if (typeof v === 'string') {
                  return {
                    annexAddress: v,
                  };
                }
                return {
                  annexAddress: JSON.stringify(
                    v?.map((vv) => {
                      return vv.url;
                    }),
                  ),
                };
              }}
              fieldProps={{
                getOssPolicy: getOssPolicy,
                uploadProps: {
                  multiple: true,
                  accept: '.jpg,.png,.jpeg,.pdf',
                  listType: 'picture',
                  className: 'upload-list-inline',
                  // itemRender: (_, file) => {
                  //   return (
                  //     <div
                  //       style={{
                  //         width: 84,
                  //         height: 84,
                  //         borderRadius: 4,
                  //         overflow: 'hidden',
                  //         position: 'relative',
                  //       }}
                  //     >
                  //       <img
                  //         src={file?.url}
                  //         style={{ width: '100%', height: '100%' }}
                  //       />
                  //     </div>
                  //   );
                  // },
                },
              }}
            />
          </VSFormLayout>
        </VSControlledForm>
      </div>
    </Section>
  );
};
export default definePage(SchoolMonitorStudentConfirmChangeForm);
