import SchoolMonitorTaskListTable from '@pages/SchoolMonitorTaskListTable';
import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React from 'react';
const SchoolMonitorTaskList = (props) => {
  return (
    <Page>
      <SchoolMonitorTaskListTable />
    </Page>
  );
};
export default definePage(SchoolMonitorTaskList);
