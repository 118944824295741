import vsf, { definePage } from '@vs/vsf-boot';
import { Section, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useCallback } from 'react';

import { birdayFormatter } from '@/utils';
const DepartmentGradesDetailTable = (props) => {
  const { handelSetDataLength } = props;
  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf?.services?.ScoreInfoPubDtoController_pageScoreInfo_a2ea04?.({
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList, // 排序结果
            scoreTaskIdIs: props.scoreTaskIdIs,
            ...params.search, // 搜索结果
            ...params.filters, // 过滤器结果
            ...(params?.extra ?? {}), // 传入的额外参数
          },
          ext: params?.ext,
        });
      const _data = res?.data?.result ?? res?.data ?? [];
      handelSetDataLength?.(_data?.length);
      return {
        data: _data,
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [props.scoreTaskIdIs, handelSetDataLength],
  );
  return (
    <Section
      style={{
        height: '95%',
        flex: 'none',
      }}
    >
      <VSPromiseTable
        pagination={{
          defaultPageSize: 10,
          showTotal: () => <></>,
        }}
        onFetch={onFetch}
        scroll={{
          x: 1000,
        }}
      >
        <VSTableColumn
          dataIndex={['orgName']}
          title="学校名称"
          width={120}
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '学校名称长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['period']}
          title="学段"
          valueType="select"
          width={100}
          dataSource={[
            { label: '小学', value: 'PRIMARY_SCHOOL' },
            { label: '初中', value: 'MIDDLE_SCHOOL' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['scoreTaskId', 'year']}
          title="学年"
          valueType="text"
          width={100}
        />

        <VSTableColumn
          dataIndex={['classes']}
          title="班级"
          width={100}
          valueType="text"
          formItemProps={{
            rules: [
              { message: '班级长度不合法', type: 'string', min: 0, max: 64 },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['studentName']}
          title="姓名"
          width={100}
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '学生姓名长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['idCard']}
          title="身份证号"
          valueType="text"
          width={180}
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                pattern:
                  /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['sex']}
          title="性别"
          valueType="select"
          width={80}
          dataSource={[
            { label: '男', value: 'MAN' },
            { label: '女', value: 'WOMAN' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['birthday']}
          width={100}
          title="出生日期"
          valueType="date"
          fieldProps={{ format: 'yyyy-MM-dd' }}
          render={(_, record) => {
            return birdayFormatter(record?.birthday);
          }}
        />
        <VSTableColumn
          dataIndex={['nation']}
          title="民族"
          width={100}
          valueType="text"
          formItemProps={{
            rules: [
              { message: '民族长度不合法', type: 'string', min: 0, max: 64 },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['nativePlace']}
          title="籍贯"
          width={120}
          valueType="text"
          formItemProps={{
            rules: [
              { message: '籍贯长度不合法', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['studentStatusNum']}
          title="学籍辅号"
          width={80}
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '学籍辅号长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['score']}
          title="总分"
          valueType="text"
          fieldProps={{}}
          width={80}
        />
        <VSTableColumn
          dataIndex={['level']}
          title="等级"
          valueType="text"
          width={80}
          formItemProps={{
            rules: [
              { message: '级别长度不合法', type: 'string', min: 0, max: 20 },
            ],
          }}
          fieldProps={{}}
        />
      </VSPromiseTable>
    </Section>
  );
};
export default definePage(DepartmentGradesDetailTable);
