import vsf, { definePage, renderRoutes } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React from 'react';

import { BreadCrumb } from '@/components';
import { navigateToWithPlatform, usePlatformInfo } from '@/utils';

const SchoolGrades = (props) => {
  const { route } = props;

  const [platformInfo] = usePlatformInfo();
  return (
    <div style={{ width: '100%', height: '100%' }} className="col">
      {vsf?.stores?.user?.schoolGradeBreadCrumb?.show && (
        <BreadCrumb
          names={['成绩管理', vsf?.stores?.user?.schoolGradeBreadCrumb?.label]}
          onBack={() => {
            vsf?.stores?.user?.updateSchoolGradeBreadCrumb({
              show: false,
              label: '',
            });
            navigateToWithPlatform(`/${platformInfo?.platformId}/grades`);
          }}
        />
      )}
      <Page>{renderRoutes(route.routes, route.redirect)}</Page>
    </div>
  );
};

export default definePage(SchoolGrades);
