import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback } from 'react';
import styled from 'styled-components';

import { SampleStudentEnum } from '@/config';
import { navigateToWithPlatform, usePlatformInfo } from '@/utils';

import SchoolMonitorStudentConfirmAskForLeaveForm from './SchoolMonitorStudentConfirmAskForLeaveForm';

const GoCheckButton = styled.div`
  width: 80px;
  height: 30px;
  background: #eaeefd;
  border-radius: 6px;
  border: 1px solid #c9d6ff;
  font-size: 14px;
  font-weight: 400;
  color: #2e5ef2;
`;

const UnConfirmedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #fe9908;
  margin-right: 8px;
  border-radius: 50%;
`;

const UNAUDITEDAic = styled.div`
  width: 5px;
  height: 5px;
  background: #fe9908;
  margin-right: 8px;
  border-radius: 50%;
`;

const PASSEDAic = styled.div`
  width: 5px;
  height: 5px;
  background: #12bc82;
  margin-right: 8px;
  border-radius: 50%;
`;
const REJECTEDdAic = styled.div`
  width: 5px;
  height: 5px;
  background: #ff3322;
  margin-right: 8px;
  border-radius: 50%;
`;

const Confirmed = styled.div`
  width: 5px;
  height: 5px;
  background: #aaaaaa;
  margin-right: 8px;
  border-radius: 50%;
`;

const ConfirmButton = styled.div`
  width: 80px;
  height: 30px;
  background: #eaeefd;
  border-radius: 6px;
  border: 1px solid #c9d6ff;
  font-size: 14px;
  font-weight: 400;
  color: #2e5ef2;
  cursor: pointer;
`;
const ChangeButton = styled.div`
  width: 80px;
  height: 30px;
  background: #ffebe7;
  border-radius: 6px;
  border: 1px solid #ffd4cc;

  font-size: 14px;
  font-weight: 400;
  color: #ff3d11;
  cursor: pointer;
`;

const DepartmentMonitorSampleStudentTable = (props) => {
  const {
    extractRoundIdIs,
    monitorTaskStatus,
    grade,
    period,
    taskIdIs,
    monitorTask,
  } = props;

  const [platformInfo] = usePlatformInfo();

  const onFetch = useCallback(
    async (params) => {
      if (extractRoundIdIs) {
        const res =
          await vsf?.services?.SampleStudentDtoController_pageBySampleStudent_5aa199?.(
            {
              qto: {
                from: params.pagination?.from ?? 0,
                size: params.pagination?.size ?? 20,
                orderList: params.orderList, // 排序结果
                extractRoundIdIs,
                ...params.search, // 搜索结果
                ...params.filters, // 过滤器结果
                ...(params?.extra ?? {}), // 传入的额外参数
              },
              ext: params?.ext,
            },
          );
        return {
          data: res?.data?.result ?? res?.data ?? [],
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
    },
    [extractRoundIdIs],
  );

  return (
    <Section>
      <VSPromiseTable
        pagination={{
          defaultPageSize: 10,
          showTotal: () => <></>,
        }}
        editable={{ editType: 'single', columnProps: { hideInTable: true } }}
        vsid="54705"
        onFetch={onFetch}
        searchConfig={{
          filterType: 'inline',
          collapsed: false,
          collapseRender: () => null,
          searchText: '查询',
          optionButtons: ['search'],
        }}
      >
        <VSTableColumn
          dataIndex={['studentId', 'studentStatusNum']}
          title="学籍辅号"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '学籍辅号长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['studentId', 'name']}
          title="姓名"
          valueType="text"
          search
          order={-2}
          columnKey={'nameLike'}
          formItemProps={{
            rules: [
              {
                message: '学生姓名长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{
            width: 110,
          }}
        />
        <VSTableColumn
          dataIndex={['studentId', 'org', 'orgName']}
          title="学校名称"
          valueType="text"
          search
          order={-1}
          columnKey={'orgNameLike'}
          formItemProps={{
            rules: [
              {
                message: '机构名称长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{ width: 140 }}
        />
        <VSTableColumn
          dataIndex={['studentId', 'grade']}
          title="年级"
          valueType="select"
          dataSource={[
            { label: '一年级', value: 'FIRST_GRADE' },
            { label: '二年级', value: 'SECOND_GRADE' },
            { label: '三年级', value: 'THIRD_GRADE' },
            { label: '四年级', value: 'FOURTH_GRADE' },
            { label: '五年级', value: 'FIFTH_GRADE' },
            { label: '六年级', value: 'SIXTH_GRADE' },
            { label: '七年级', value: 'SEVENTH_GRADE' },
            { label: '八年级', value: 'EIGHTH_GRADE' },
            { label: '九年级', value: 'NINE_GRADE' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['studentId', 'classes']}
          title="班级"
          valueType="text"
          search
          order={-3}
          columnKey={'classesLike'}
          formItemProps={{
            rules: [
              { message: '班级长度不合法', type: 'string', min: 0, max: 64 },
            ],
          }}
          fieldProps={{ width: 110 }}
        />
        <VSTableColumn
          dataIndex={['studentId', 'sex']}
          title="性别"
          valueType="select"
          dataSource={[
            { label: '男', value: 'MAN' },
            { label: '女', value: 'WOMAN' },
          ]}
          fieldProps={{}}
        />
        {monitorTask?.extractType === 'STUDENT' && (
          <VSTableColumn
            dataIndex={['extractRoster', 'level']}
            title="维度"
            valueType="text"
            formItemProps={{
              rules: [
                { message: '级别长度不合法', type: 'string', min: 0, max: 64 },
              ],
            }}
            fieldProps={{}}
          />
        )}
        <VSTableColumn
          dataIndex={['status']}
          title="状态"
          valueType="select"
          search
          order={-4}
          columnKey={'statusIs'}
          dataSource={
            monitorTask?.extractType === 'STUDENT'
              ? [
                  { label: '待确认', value: 'UNCONFIRMED' },
                  { label: '已确认', value: 'CONFIRMED' },
                  { label: '替换待审核', value: 'UNAUDITED' },
                  { label: '审核通过', value: 'PASSED' },
                  { label: '审核拒绝', value: 'REJECTED' },
                ]
              : [
                  { label: '待确认', value: 'UNCONFIRMED' },
                  { label: '已确认', value: 'CONFIRMED' },
                  { label: '请假', value: 'ASK_FOR_LEAVE' },
                ]
          }
          fieldProps={{}}
          render={(doms, record) => {
            return (
              <div className="aic">
                {(record?.status === SampleStudentEnum.UNCONFIRMED ||
                  record?.status === SampleStudentEnum.ASK_FOR_LEAVE) && (
                  <UnConfirmedAic />
                )}
                {record?.status === SampleStudentEnum.UNAUDITED && (
                  <UNAUDITEDAic />
                )}
                {record?.status === SampleStudentEnum.PASSED && <PASSEDAic />}
                {record?.status === SampleStudentEnum.REJECTED && (
                  <REJECTEDdAic />
                )}
                {record?.status === SampleStudentEnum.CONFIRMED && (
                  <Confirmed />
                )}
                {doms}
              </div>
            );
          }}
        />
        <VSTableColumn title="操作" name={'action'} valueType="option">
          <VSTableAction
            show={(record) => record?.status === 'UNAUDITED'}
            onClick={(_, record) => {
              const _query = `period=${period}&grade=${grade}&monitorTaskStatus=${monitorTaskStatus}`;
              navigateToWithPlatform(
                `/${platformInfo?.platformId}/monitor/${taskIdIs}/sampleStudentReplacement?${_query}`,
              );
            }}
          >
            <GoCheckButton className="aic jcc">前往审核</GoCheckButton>
          </VSTableAction>
          <VSTableAction
            disableLoading
            show={(record) =>
              record?.status === 'ASK_FOR_LEAVE' &&
              monitorTask?.extractType === 'CLASSES'
            }
            linkPage={{
              updateTable: false,
              modalProps: {
                width: 500,
                title: '查看请假原因',
                hideFooter: true,
              },
              linkPage: () => (
                <SchoolMonitorStudentConfirmAskForLeaveForm disabledForm />
              ),
            }}
            style={{ color: '#fe9908' }}
          >
            <ChangeButton className="aic jcc">请假原因</ChangeButton>
          </VSTableAction>
          <VSTableAction
            show={(record) =>
              record?.status !== 'UNAUDITED' &&
              record?.status !== 'ASK_FOR_LEAVE'
            }
          >
            <span>-</span>
          </VSTableAction>
        </VSTableColumn>
      </VSPromiseTable>
    </Section>
  );
};
export default definePage(DepartmentMonitorSampleStudentTable);
