import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React from 'react';

import { BreadCrumb } from '@/components';
import { navigateToWithPlatform, usePlatformInfo } from '@/utils';

import SchoolGradesDetailTable from './SchoolGradesDetailTable';

const SchoolGradesDetail = (props) => {
  const {
    routes: {
      params: { scoreTaskIdIs },
      query: { name },
    },
  } = props;
  const [platformInfo] = usePlatformInfo();
  return (
    <div>
      {/* <BreadCrumb
        names={['成绩管理', name]}
        onBack={() =>
          navigateToWithPlatform(`/${platformInfo?.platformId}/grades`)
        }
      /> */}
      <SchoolGradesDetailTable scoreTaskIdIs={scoreTaskIdIs} />
    </div>
  );
};

export default definePage(SchoolGradesDetail);
