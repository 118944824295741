import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';

import { labelColWidth } from '@/components/TreeMenu/utils';
import { usePlatformInfo } from '@/utils';
import getOssPolicy from '@/utils/getOssPolicy';

const SchoolMonitorInfoCheckAddStudentFrom = (props) => {
  const [orgList, setOrgList] = useState([]);
  const [platformInfo] = usePlatformInfo();

  const { taskIdIs, grade } = props;

  const loadOrgList = useCallback(async () => {
    try {
      const response =
        await vsf?.services?.TaskOrgDtoController_queryAllByTaskOrgList_788d4b?.(
          {
            qto: {
              taskIdIs,
            },
          },
        );
      if (response && response.code === 200) {
        setOrgList(
          (response?.data?.result ?? []).map((item) => ({
            label: item.org?.orgName ?? '',
            value: item?.id,
            orgId: item?.org?.id,
          })),
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [taskIdIs]);

  useEffect(() => {
    loadOrgList();
  }, [loadOrgList]);

  console.log(props.value, '=');

  return (
    <Section title="添加学生">
      <div className="custom_ant_upload">
        <VSControlledForm
          id="SchoolMonitorInfoCheckAddStudentFrom"
          vsid="55728"
          value={{
            ...props.value,
            grade,
            taskOrgId: orgList?.find(
              (item) => item?.orgId === platformInfo?.platformId,
            )?.value,
            changeTaskOrgId: orgList?.find(
              (item) => item?.orgId === platformInfo?.platformId,
            )?.value,
          }}
          onSubmit={async (params) => {
            const res =
              await vsf?.services?.StudentChangeAuditBOController_createStudentChangeAudit_f507e3?.(
                { btoParam: params, extApiParam: params?.extApiParam },
              );
            return params; // 直接返回提交的数据
          }}
        >
          <div style={{ height: 30 }} />
          <VSFormLayout key="0" columnCount={1} labelCol={labelColWidth[4]}>
            <VSFormItem
              name={['taskOrgId']}
              label="学校名称"
              valueType="select"
              dataSource={orgList}
              readonly
            />
            <VSFormItem
              readonly
              name={['grade']}
              label="年级"
              valueType="select"
              rules={[
                { message: '班级长度不合法', type: 'string', min: 0, max: 64 },
              ]}
              dataSource={[
                { label: '一年级', value: 'FIRST_GRADE' },
                { label: '二年级', value: 'SECOND_GRADE' },
                { label: '三年级', value: 'THIRD_GRADE' },
                { label: '四年级', value: 'FOURTH_GRADE' },
                { label: '五年级', value: 'FIFTH_GRADE' },
                { label: '六年级', value: 'SIXTH_GRADE' },
                { label: '七年级', value: 'SEVENTH_GRADE' },
                { label: '八年级', value: 'EIGHTH_GRADE' },
                { label: '九年级', value: 'NINE_GRADE' },
              ]}
            />
            <VSFormItem
              name={['classes']}
              label="班级"
              valueType="text"
              required={false}
              rules={[
                { required: true },
                {
                  message: '班级长度不合法',
                  type: 'string',
                  min: 0,
                  max: 64,
                },
              ]}
              fieldProps={{}}
            />

            <VSFormItem
              name={['name']}
              label="姓名"
              valueType="text"
              required={false}
              rules={[
                { required: true },
                {
                  message: '学生姓名长度不合法',
                  type: 'string',
                  min: 0,
                  max: 64,
                },
              ]}
              fieldProps={{}}
            />

            <VSFormItem
              name={['sex']}
              label="性别"
              valueType="select"
              required={false}
              dataSource={[
                { label: '男', value: 'MAN' },
                { label: '女', value: 'WOMAN' },
              ]}
              rules={[
                { required: true },
                {
                  message: '请选择性别',
                },
              ]}
              fieldProps={{}}
            />

            <VSFormItem
              name={['studentStatusNum']}
              label="学籍辅号"
              valueType="text"
              required={false}
              rules={[
                { required: true },
                {
                  message: '学籍辅号长度不合法',
                  type: 'string',
                  min: 0,
                  max: 64,
                },
              ]}
              fieldProps={{}}
            />
            <div className="antd-formitem-contnent-border">
              <VSFormItem
                name={['applicationReason']}
                label="添加原因"
                valueType="textarea"
                required={false}
                rules={[{ required: true }]}
                fieldProps={{
                  style: {
                    minHeight: 100,
                  },
                }}
              />
            </div>

            <VSFormItem
              name={['annexAddress']}
              label="附件地址"
              required={false}
              valueType="fileUpload"
              rules={[{ required: true, message: '请上传附件地址' }]}
              convertValue={(v) => {
                if (!v) {
                  return v;
                }
                return v?.map((vv) => {
                  if (typeof vv === 'string') {
                    return { url: vv };
                  }
                  return vv;
                });
              }}
              transform={(v) => {
                return {
                  annexAddress: JSON.stringify(
                    v?.map((vv) => {
                      return vv.url;
                    }),
                  ),
                };
              }}
              fieldProps={{
                type: 'picture',
                getOssPolicy: getOssPolicy,
                uploadProps: {
                  accept: '.jpg,.png,.jpeg,.pdf',
                  listType: 'picture',
                  className: 'upload-list-inline',
                },
              }}
            />
          </VSFormLayout>
        </VSControlledForm>
      </div>
    </Section>
  );
};
export default definePage(SchoolMonitorInfoCheckAddStudentFrom);
