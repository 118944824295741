import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Modal,
  Section,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import AddIcon from '@/assets/images/icon/add_icon.png';
import WarningIcon from '@/assets/images/warning_icon.png';
import { ChangeStatusEnum } from '@/config';
import { usePlatformInfo } from '@/utils';

import SchoolMonitorInfoCheckAddStudentFrom from './SchoolMonitorInfoCheckAddStudentFrom';
import SchoolMonitorInfoCheckChangeForm from './SchoolMonitorInfoCheckChangeForm';

const AddIconImg = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 6px;
`;

const AddStudentText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #ff3d11;
`;

const CancelText = styled.div`
  width: 29px;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #2e5ef2;
  margin-right: 28px;
  cursor: pointer;
`;

const PrimaryButton = styled.div`
  width: 84px;
  height: 36px;
  background: #2e5ef2;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
`;

const AllConfirmButton = styled.div`
  width: 84px;
  height: 36px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #2e5ef2;
  font-size: 14px;
  font-weight: 500;
  color: #2e5ef2;
  cursor: pointer;
`;

const BatchChangeButton = styled.div`
  width: 84px;
  height: 36px;
  background: #ff3d11;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
`;

const ColumnConfirmButton = styled.div`
  width: 80px;
  height: 30px;
  background: #eaeefd;
  border-radius: 6px;
  border: 1px solid #c9d6ff;

  font-size: 14px;
  font-weight: 400;
  color: #2e5ef2;
`;

const ColumnChangeButton = styled.div`
  width: 80px;
  height: 30px;
  background: #ffebe7;
  border-radius: 6px;
  border: 1px solid #ffd4cc;

  font-size: 14px;
  font-weight: 400;
  color: #ff3d11;
`;

const ColumnRevertButton = styled.div`
  width: 80px;
  height: 30px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #dddddd;

  font-size: 14px;
  font-weight: 400;
  color: #333333;
`;

const ConfirmModalTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
  color: #333333;
  margin-top: 15px;
`;

const ConfirmModalLabel = styled.div`
  font-size: 14px;
  font-weight: 400;
  color: #666666;
`;

const ConfirmModalConfirmButton = styled.div`
  width: 110px;
  height: 44px;
  border-radius: 6px;
  border: 1px solid #2e5ef2;
  margin-right: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #2e5ef2;
  cursor: pointer;
`;

const ConfirmModalCancelButton = styled.div`
  width: 110px;
  height: 44px;
  background: #2e5ef2;
  border-radius: 6px;
  margin-left: 20px;
  font-size: 16px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
`;

const UnConfirmedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #fe9908;
  margin-right: 8px;
  border-radius: 50%;
`;

const ChangeUnAuditedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #ffcf54;
  margin-right: 8px;
  border-radius: 50%;
`;

const CreateUnAuditedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #12bc82;
  margin-right: 8px;
  border-radius: 50%;
`;

const Confirmed = styled.div`
  width: 5px;
  height: 5px;
  background: #aaaaaa;
  margin-right: 8px;
  border-radius: 50%;
`;

const REJECTED = styled.div`
  width: 5px;
  height: 5px;
  background: #ff381e;
  margin-right: 8px;
  border-radius: 50%;
`;

const SchoolMonitorInfoCheckTable = (props) => {
  const { taskIdIs, type, grade, taskOrgId } = props;

  const [isManage, setIsManage] = useState(false);

  const [formVisible, setFormVisible] = useState(false);
  const selectedRows = useRef([]);

  const [confirmModalVisible, setConfirmModalVisible] = useState(false);

  const [refresh, setRefresh] = useState(false);

  const [batchChangeVisible, setBatchChangeVisible] = useState(false);

  const optionType = useRef('delete');

  const [platformInfo] = usePlatformInfo();

  const [isAllConfirm, setIsAllConfirm] = useState(false);

  const onFetch = useCallback(
    async (params) => {
      if (platformInfo) {
        const res =
          await vsf?.services?.StudentDtoController_pageByStudentSchool_2f24a8?.(
            {
              qto: {
                from: params.pagination?.from ?? 0,
                size: params.pagination?.size ?? 20,
                orderList: params.orderList, // 排序结果
                taskIdIs,
                orgIdIs: platformInfo?.platformId,
                ...params.search, // 搜索结果
                ...params.filters, // 过滤器结果
                ...(params?.extra ?? {}), // 传入的额外参数
                refresh: undefined,
              },
              ext: params?.ext,
            },
          );
        return {
          data: res?.data?.result ?? res?.data ?? [{}],
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
    },
    [taskIdIs, platformInfo],
  );

  const handelBatchCancel = () => {
    setIsManage(false);
  };

  const handelBatchClick = () => {
    if (isManage) {
      console.log();
    } else {
      setIsManage(true);
    }
  };

  const handelBatchChangeClick = async () => {
    setBatchChangeVisible(true);
  };

  const handelBatchChangeSubmit = async () => {
    try {
      const formParam =
        await vsf.refs.SchoolMonitorInfoCheckChangeForm?.validateFieldsReturnFormatValue();
      if (formParam) {
        if (optionType.current === 'delete') {
          const response =
            await vsf?.services?.StudentChangeAuditBOController_deleteStudentAuditBatch_3384bc?.(
              {
                list: selectedRows.current?.map((record) => ({
                  taskOrgId: record?.taskOrg?.id,
                  studentId: record?.id,
                  ...formParam,
                })),
              },
            );
          if (response && response.code === 200) {
            message.success('申请成功');
            setRefresh(!refresh);
            setBatchChangeVisible(false);
            return true;
          }
        } else {
          const response =
            await vsf?.services?.StudentChangeAuditBOController_applicationStudentChangeAuditBatch_081337?.(
              {
                list: selectedRows.current.map((record) => ({
                  taskOrgId: record?.taskOrg?.id,
                  ...formParam,
                  studentId: record?.id,
                  name: record?.name,
                  sex: record?.sex,
                  grade: record?.grade,
                  studentStatusNum: record?.studentStatusNum,
                })),
              },
            );
          if (response && response.code === 200) {
            message.success('申请成功');
            setRefresh(!refresh);
            setBatchChangeVisible(false);
            return true;
          }
        }
      } else {
        vsf.showToast('请上传附件', 'error');
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelSelectRowChange = (rows) => {
    selectedRows.current = rows;
  };

  const handelConfirmModalClick = async () => {
    if (isManage) {
      const response =
        await vsf?.services?.StudentBOController_passStudentList_2d818c?.({
          btoParam: selectedRows.current?.map((item) => ({
            id: item?.id,
          })),
        });
      if (response && response.code === 200) {
        message.success('确认成功');
        setRefresh(!refresh);
        setConfirmModalVisible(false);
        return;
      }
    } else {
      const response =
        await vsf?.services?.StudentBOController_passAllStudent_da3f03?.({
          taskId: taskIdIs,
          orgId: platformInfo?.platformId,
        });
      if (response && response.code === 200) {
        message.success('一键确认成功');
        setIsAllConfirm(true);
        setRefresh(!refresh);
        setConfirmModalVisible(false);
        return;
      }
    }
  };

  const handelItemConfirmClick = async (_, record) => {
    try {
      const response =
        await vsf?.services?.StudentBOController_passStudent_14b964?.({
          btoParam: {
            id: record?.id,
            // orgId: record?.taskOrg?.org?.id,
          },
        });
      if (response && response.code === 200) {
        message.success('确认成功');
        setRefresh(!refresh);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelAddStudentConfirm = async () => {
    try {
      const formParam =
        await vsf.refs.SchoolMonitorInfoCheckAddStudentFrom?.validateFieldsReturnFormatValue();
      const response =
        await vsf?.services?.StudentChangeAuditBOController_createStudentChangeAudit_f507e3(
          {
            btoParam: {
              taskOrgId,
              changeTaskOrgId: taskOrgId,
              changeClasses: formParam?.classes,
              changeGrade: formParam?.grade,
              ...formParam,
            },
          },
        );
      if (response && response.code === 200) {
        message.success('添加成功');
        setFormVisible(false);
        setTimeout(() => {
          setRefresh(!refresh);
        }, 1000);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelRevokeClick = async (_, record) => {
    try {
      const response =
        await vsf?.services?.StudentChangeAuditBOController_revokeApplication_ebd66d?.(
          {
            studentId: record?.id,
          },
        );
      if (response && response.code === 200) {
        message.success('已撤回');
        setRefresh(!refresh);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelTypeChange = (_type) => {
    optionType.current = _type;
  };

  return (
    <Section className="monitorStatus">
      <VSPromiseTable
        editable={{ editType: 'single', columnProps: { hideInTable: true } }}
        searchConfig={{
          filterType: 'inline',
          optionButtons: 'search',
          searchButtonProps: {
            children: '查询',
          },
        }}
        pagination={{
          defaultPageSize: 8,
          showTotal: () => <></>,
        }}
        rowSelection={
          isManage
            ? {
                selectType: 'multiple',
                onSelectedRowsChange: handelSelectRowChange,
                getCheckboxProps: (row) => ({
                  disabled:
                    row?.changeStatus === 'CONFIRMED' ||
                    row?.changeStatus === 'REJECTED',
                }),
              }
            : null
        }
        onFetch={onFetch}
        extraParams={{ refresh }}
        searchFormRender={(_, doms, option) => {
          return (
            <div className="aic" style={{ alignItems: 'flex-start' }}>
              {doms}
              {type !== 'show' && (
                <div className="aic">
                  {isManage ? (
                    <CancelText onClick={handelBatchCancel}>取消</CancelText>
                  ) : (
                    <div
                      className="aic"
                      style={{ width: 90, cursor: 'pointer' }}
                    >
                      <AddIconImg src={AddIcon} />
                      <AddStudentText onClick={() => setFormVisible(true)}>
                        添加学生
                      </AddStudentText>
                    </div>
                  )}
                  {isManage ? (
                    <PrimaryButton
                      className="aic jcc"
                      onClick={() => setConfirmModalVisible(true)}
                    >
                      批量确认
                    </PrimaryButton>
                  ) : (
                    <AllConfirmButton
                      className="aic jcc"
                      onClick={() => {
                        if (!isAllConfirm) setConfirmModalVisible(true);
                      }}
                      style={{ opacity: isAllConfirm ? 0.5 : 1 }}
                    >
                      一键确认
                    </AllConfirmButton>
                  )}
                  <span style={{ width: 14 }} />
                  {isManage ? (
                    <BatchChangeButton
                      className="aic jcc"
                      onClick={handelBatchChangeClick}
                    >
                      批量变更
                    </BatchChangeButton>
                  ) : (
                    <PrimaryButton
                      className="aic jcc"
                      onClick={handelBatchClick}
                    >
                      批量管理
                    </PrimaryButton>
                  )}
                </div>
              )}
            </div>
          );
        }}
      >
        <VSTableColumn
          dataIndex={['studentStatusNum']}
          title="学籍辅号"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '学籍辅号长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['name']}
          title="姓名"
          valueType="text"
          search
          order={-1}
          columnKey={'nameLike'}
          formItemProps={{
            rules: [
              {
                message: '学生姓名长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{
            width: 110,
            placeholder: '请输入',
          }}
        />
        <VSTableColumn
          dataIndex={['grade']}
          title="年级"
          valueType="select"
          dataSource={[
            { label: '一年级', value: 'FIRST_GRADE' },
            { label: '二年级', value: 'SECOND_GRADE' },
            { label: '三年级', value: 'THIRD_GRADE' },
            { label: '四年级', value: 'FOURTH_GRADE' },
            { label: '五年级', value: 'FIFTH_GRADE' },
            { label: '六年级', value: 'SIXTH_GRADE' },
            { label: '七年级', value: 'SEVENTH_GRADE' },
            { label: '八年级', value: 'EIGHTH_GRADE' },
            { label: '九年级', value: 'NINE_GRADE' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['classes']}
          title="班级"
          valueType="text"
          search
          order={-2}
          columnKey={'classesLike'}
          formItemProps={{
            rules: [
              { message: '班级长度不合法', type: 'string', min: 0, max: 64 },
            ],
          }}
          fieldProps={{
            width: 110,
            placeholder: '请输入',
          }}
        />
        <VSTableColumn
          dataIndex={['sex']}
          title="性别"
          valueType="select"
          dataSource={[
            { label: '男', value: 'MAN' },
            { label: '女', value: 'WOMAN' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['changeStatus']}
          title="状态"
          valueType="select"
          search
          width={140}
          order={-3}
          columnKey={'changeStatusIs'}
          dataSource={[
            { label: '已确认', value: 'CONFIRMED' },
            { label: '已拒绝', value: 'REJECTED' },
            { label: '信息变更待审核', value: 'CHANGE_UNAUDITED' },
            { label: '待接收学校审核', value: 'SCHOOL_UNAUDITED' },
            { label: '新增待审核', value: 'CREATE_UNAUDITED' },
            { label: '待确认', value: 'UNCONFIRMED' },
          ]}
          fieldProps={{
            placeholder: '请选择',
          }}
          render={(doms, record) => {
            return (
              <div className="aic">
                {record?.changeStatus === ChangeStatusEnum.UNCONFIRMED && (
                  <UnConfirmedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.REJECTED && (
                  <REJECTED />
                )}
                {record?.changeStatus === ChangeStatusEnum.CHANGE_UNAUDITED && (
                  <ChangeUnAuditedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.CREATE_UNAUDITED && (
                  <CreateUnAuditedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.CONFIRMED && (
                  <Confirmed />
                )}
                {doms}
              </div>
            );
          }}
        />
        {type !== 'show' && (
          <VSTableColumn
            name={'action'}
            valueType="option"
            title="操作"
            width={200}
          >
            <VSTableAction
              disabled={isManage}
              show={(record) =>
                record.changeStatus === ChangeStatusEnum.UNCONFIRMED
              }
              onClick={handelItemConfirmClick}
            >
              <ColumnConfirmButton
                className="aic jcc"
                style={{ opacity: isManage ? 0.5 : 1 }}
              >
                确认
              </ColumnConfirmButton>
            </VSTableAction>
            <VSTableAction
              show={(record) =>
                record.changeStatus === ChangeStatusEnum.UNCONFIRMED
              }
              linkPage={{
                modalProps: {
                  width: 500,
                },
                updateTable: true,
                updateType: 'reload',
                linkPage: () => (
                  <SchoolMonitorInfoCheckChangeForm
                    taskIdIs={taskIdIs}
                    grade={grade}
                  />
                ),
                onLinkPageValidate: async (_params, _, record) => {
                  try {
                    const { type = 'delete' } = _params;
                    const formParam =
                      await vsf.refs.SchoolMonitorInfoCheckChangeForm?.validateFieldsReturnFormatValue();

                    if (type === 'change') {
                      const response =
                        await vsf?.services?.StudentChangeAuditBOController_applicationStudentChangeAudit_8f3c87?.(
                          {
                            btoParam: {
                              taskOrgId: record?.taskOrg?.id,
                              ...formParam,
                              studentId: record?.id,
                              name: record?.name,
                              sex: record?.sex,
                              grade: record?.grade,
                              studentStatusNum: record?.studentStatusNum,
                            },
                          },
                        );
                      if (response && response.code === 200) {
                        message.success('申请成功');
                        setRefresh(!refresh);
                        return true;
                      }
                      return false;
                    } else {
                      const response =
                        await vsf?.services?.StudentChangeAuditBOController_deleteStudentAudit_dfd4d9?.(
                          {
                            btoParam: {
                              taskOrgId: record?.taskOrg?.id,
                              studentId: record?.id,
                              ...formParam,
                            },
                          },
                        );
                      if (response && response.code === 200) {
                        message.success('申请成功');
                        setRefresh(!refresh);
                        return true;
                      }
                      return false;
                    }
                  } catch (e) {
                    return false;
                  }
                },
              }}
            >
              <ColumnChangeButton
                disabled={isManage}
                className="aic jcc"
                style={{ opacity: isManage ? 0.5 : 1 }}
              >
                申请变更
              </ColumnChangeButton>
            </VSTableAction>
            <VSTableAction
              show={(record) =>
                record.changeStatus === ChangeStatusEnum.CHANGE_UNAUDITED ||
                record.changeStatus === ChangeStatusEnum.SCHOOL_UNAUDITED ||
                record.changeStatus === ChangeStatusEnum.CREATE_UNAUDITED
              }
              onClick={handelRevokeClick}
            >
              <ColumnRevertButton
                className="aic jcc"
                style={{ opacity: isManage ? 0.5 : 1 }}
              >
                撤回审核
              </ColumnRevertButton>
            </VSTableAction>

            <VSTableAction
              show={(record) =>
                record?.changeStatus === ChangeStatusEnum.CONFIRMED
              }
            >
              <span className="actionSpan">一</span>
            </VSTableAction>
          </VSTableColumn>
        )}
      </VSPromiseTable>
      <Modal
        open={formVisible}
        onCancel={() => setFormVisible(false)}
        cancelText="取消"
        okText="提交审核"
        onOk={handelAddStudentConfirm}
      >
        <SchoolMonitorInfoCheckAddStudentFrom
          grade={grade}
          taskIdIs={taskIdIs}
        />
      </Modal>
      <Modal
        open={confirmModalVisible}
        width={460}
        footer={false}
        onCancel={() => setConfirmModalVisible(false)}
      >
        <div style={{ height: 20 }} />
        <div className="col aic">
          <img src={WarningIcon} style={{ width: 80, height: 90 }} />
          <ConfirmModalTitle>
            即将把{isManage ? '已选中' : '所有'}“
            <span style={{ color: '#FF3D11' }}>待确认</span>
            ”的信息将全部变为“<span style={{ color: '#FF3D11' }}>已确认</span>”
          </ConfirmModalTitle>
          <div style={{ height: 14 }} />
          <ConfirmModalLabel>确认后的信息不允许撤回或修改</ConfirmModalLabel>
          <ConfirmModalLabel>
            请将需要变更的信息提交申请后再点击“一键确认“
          </ConfirmModalLabel>
          <div className="aic" style={{ marginTop: 30, marginBottom: 23 }}>
            <ConfirmModalConfirmButton
              className="aic jcc"
              onClick={handelConfirmModalClick}
            >
              {isManage ? '批量确认' : '一键确认'}
            </ConfirmModalConfirmButton>
            <ConfirmModalCancelButton
              className="aic jcc"
              onClick={() => setConfirmModalVisible(false)}
            >
              再看看
            </ConfirmModalCancelButton>
          </div>
        </div>
      </Modal>

      <Modal
        open={batchChangeVisible}
        width={500}
        okText="确定"
        cancelText="取消"
        onCancel={() => setBatchChangeVisible(false)}
        onOk={handelBatchChangeSubmit}
      >
        <SchoolMonitorInfoCheckChangeForm
          taskIdIs={taskIdIs}
          grade={grade}
          onTypeChange={handelTypeChange}
        />
      </Modal>
    </Section>
  );
};
export default definePage(SchoolMonitorInfoCheckTable);
