import { CloseCircleOutlined } from '@vs/vsf-icons';
import {
  compose,
  Image,
  message,
  Progress,
  withField,
  withPreview,
  withSize,
} from '@vs/vsf-kit';
import React, { useCallback, useMemo, useState } from 'react';
import styled from 'styled-components';

import FileUpload, { FileUploadProps, FileUploadValue } from '../FileUpload';
import Bg from './bg.png';
import downIcon from './download_icon.png';

const ContainerDiv = styled.div`
  .ant-upload-list {
    width: 450px;
    height: 150px;
    top: 0;
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    z-index: -1;
  }
`;

const CustomDiv = styled.div`
  width: 450px;
  height: 150px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 1px dashed #d9d9d9;
  border-radius: 4px;
  .custom_icon {
    position: absolute;
    left: 75%;
    top: 13%;
    .ant-image {
      width: 16px;
      height: 16px;
    }
  }
  .ant-image {
    width: 76px;
    height: 76px;
  }
`;

const CustomDivTitle = styled.div`
  color: #333;
  font-weight: 600;
  font-size: 16px;
`;

const CustomDivDesc = styled.div`
  color: #999;
  font-weight: 400;
  font-size: 15px;
`;

const CustomDivExtra = styled.div`
  color: #2e5ef2;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  position: absolute;
  top: 20px;
  right: 20px;
`;

const CustomDivFile = styled.div`
  width: 350px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  position: relative;
  font-weight: bold;
  .customDivFile_bottom {
  }
`;

const CustomDivFileFinish = styled.div`
  width: 350px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;
  font-weight: bold;
  padding: 12px 21px;
  border: 1px solid #dee5ff;
  border-radius: 20px;
  cursor: pointer;
  color: #000;
  .anticon {
    color: #ff3d11;
    cursor: pointer;
  }
`;

export type CustomFileUploadProps = FileUploadProps & {
  template?: FileUploadValue;
  style?: React.CSSProperties;
};

/**
 * 自定义文件上传
 */
const CustomFileUpload = (props: CustomFileUploadProps) => {
  const {
    defaultValue,
    value,
    onChange,
    children,
    template,
    style: styleProp,
    ...rest
  } = props;
  const style = useMemo(() => ({ width: 450, ...styleProp }), [styleProp]);
  const [item, setItem] = useState<FileUploadValue[]>();

  const handleDownloadTpl = useCallback(
    (e) => {
      e.stopPropagation();
      if (template) {
        FileUpload.download(template.url, template.name);
        message.success('已下载');
      }
    },
    [template],
  );

  const handleRemoveFile = useCallback(
    (e) => {
      e.stopPropagation();
      setItem(undefined);
      onChange?.(undefined);
    },
    [onChange],
  );

  const download = (file) => {
    if (!file) {
      return;
    }
    FileUpload.download(file.url, file.name);
  };

  const renderChildren = useCallback(() => {
    return children ? (
      children
    ) : (
      <CustomDiv style={style}>
        <div className="custom_icon">
          <Image src={downIcon} preview={false} />
        </div>
        <CustomDivExtra onClick={handleDownloadTpl}>下载模板</CustomDivExtra>
        {!item || item?.length === 0 ? (
          <>
            <Image src={Bg} preview={false} />
            <CustomDivTitle>请点击或拖拽文件到此处导入名单</CustomDivTitle>
            {rest?.uploadProps?.accept && (
              <CustomDivDesc>
                支持后缀为{rest?.uploadProps?.accept}
              </CustomDivDesc>
            )}
          </>
        ) : (
          <>
            {item?.map((item) => {
              if (!item?.percent || item?.percent < 100) {
                return (
                  <CustomDivFile key={item?.uid}>
                    <div className="name">
                      {item?.name}
                      {!item?.percent ||
                        (item?.percent > -1 && (
                          <Progress percent={item?.percent} />
                        ))}
                      {/* {item?.percent === -1 && (
  
                      )} */}

                      <CloseCircleOutlined
                        onClick={handleRemoveFile}
                        style={{ marginLeft: '10px' }}
                      />
                    </div>
                  </CustomDivFile>
                );
              } else {
                return (
                  <CustomDivFileFinish
                    key={item?.uid}
                    onClick={() => {
                      download(item);
                    }}
                  >
                    <div className="name">{item?.name}</div>
                    {item?.percent === 100 && (
                      <CloseCircleOutlined onClick={handleRemoveFile} />
                    )}
                  </CustomDivFileFinish>
                );
              }
            })}
          </>
        )}
      </CustomDiv>
    );
  }, [
    children,
    style,
    handleDownloadTpl,
    item,
    rest?.uploadProps?.accept,
    handleRemoveFile,
  ]);

  return (
    <ContainerDiv style={style}>
      <FileUpload
        isCustomFileUpload
        {...rest}
        value={value}
        defaultValue={defaultValue}
        onChange={(v) => {
          setItem(v);
          onChange?.(v);
        }}
        uploadProps={{
          ...(rest?.uploadProps || {}),
          maxCount: 1,
          disabled: (value ?? [])?.length > 0,
          openFileDialogOnClick: !value || value?.length === 0,
        }}
      >
        {renderChildren()}
      </FileUpload>
    </ContainerDiv>
  );
};

export default compose(
  withField<FileUploadValue>({
    name: 'CustomFileUpload',
  }),
  withPreview<CustomFileUploadProps>({
    renderPreview: (props) => {
      return <FileUpload {...props} isPreview />;
    },
  }),
  withSize(),
)(CustomFileUpload);
