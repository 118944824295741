import vsf, { definePage } from '@vs/vsf-boot';
import { Section, VSControlledForm, VSFormItem } from '@vs/vsf-kit';
import React from 'react';
const DepartmentAccountEdit = (props) => {
  return (
    <Section title="">
      <VSControlledForm
        id="DepartmentAccountForm"
        value={props.value}
        onChange={(_value) => {
          props.onChange?.(_value);
        }}
        layout="horizontal"
        labelCol={{
          span: 4,
        }}
      >
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
        />

        <VSFormItem
          name={[props.nameIndex || 'userName']}
          label="姓名"
          valueType="text"
          required={false}
          rules={[
            {
              message: '管理员姓名长度不合法',
              type: 'string',
              min: 0,
              max: 64,
            },
            { required: true },
          ]}
          fieldProps={{
            placeholder: '请输入姓名',
          }}
        />

        <VSFormItem
          name={['phone']}
          label="手机号"
          valueType="text"
          required={false}
          rules={[
            {
              message: '手机号格式错误',
              type: 'string',
              pattern: /^(?:\+?86)?1[3-9]\d{9}$/,
            },
            { required: true },
          ]}
          fieldProps={{
            placeholder: '请输入手机号',
          }}
        />
      </VSControlledForm>
    </Section>
  );
};
export default definePage(DepartmentAccountEdit);
