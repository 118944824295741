import vsf, { definePage } from '@vs/vsf-boot';
import {
  Col,
  Image,
  message,
  Modal,
  Row,
  Section,
  TextArea,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import FileUpload from '@/components/FileUpload';
import { SampleStudentEnum, SubjectEnumValue } from '@/config';

const ImageSuffix = ['.jpg', '.png', 'jpeg', '.webp'];
const ConfirmButton = styled.div`
  width: 80px;
  height: 30px;
  background: #eaeefd;
  border-radius: 6px;
  border: 1px solid #c9d6ff;
  font-size: 14px;
  font-weight: 400;
  color: #2e5ef2;
  cursor: pointer;
`;

const RejectButton = styled.div`
  width: 80px;
  height: 30px;
  background: #ffebe7;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  color: #ff3d11;
`;

const ImageView = styled.div`
  width: 84px;
  height: 84px;
  border-radius: 8px;
  overflow: hidden;
  position: relative;
  border: 1px solid #dddddd;
  margin-right: 15px;
  margin-bottom: 14px;
`;

const FileItemView = styled.div`
  width: 216px;
  height: 40px;
  background: #fafafa;
  border-radius: 22px;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  padding: 0 14px;
  margin-bottom: 10px;

  font-size: 14px;
  font-weight: 400;
  color: #333333;
`;

const UnConfirmedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #fe9908;
  margin-right: 8px;
  border-radius: 50%;
`;

const UNAUDITEDAic = styled.div`
  width: 5px;
  height: 5px;
  background: #fe9908;
  margin-right: 8px;
  border-radius: 50%;
`;

const PASSEDAic = styled.div`
  width: 5px;
  height: 5px;
  background: #12bc82;
  margin-right: 8px;
  border-radius: 50%;
`;
const REJECTEDdAic = styled.div`
  width: 5px;
  height: 5px;
  background: #ff3322;
  margin-right: 8px;
  border-radius: 50%;
`;

const Confirmed = styled.div`
  width: 5px;
  height: 5px;
  background: #aaaaaa;
  margin-right: 8px;
  border-radius: 50%;
`;

const DepartmentMonitorSampleStudentReplacementTable = (props) => {
  const { extractRoundIdIs, subject } = props;

  const [rejectVisible, setRejectVisible] = useState(false);
  const [rejectReason, setrejectReason] = useState('');
  const [refresh, setRefresh] = useState(false);
  const [showRejectResonVisible, setShowRejectResonVisible] = useState(false);
  const [showResonVisible, setShowResonVisible] = useState(false);
  const [itemDetail, setItemDetail] = useState();
  const currentRecord = useRef();

  const handelAnnexAddress = () => {
    try {
      return (
        itemDetail?.annexAddress && JSON.parse(itemDetail?.annexAddress || '')
      );
    } catch (error) {
      return [];
    }
  };

  const onFetch = useCallback(
    async (params) => {
      if (extractRoundIdIs) {
        const res =
          await vsf?.services?.ChangeSampleStudentPageDtoController_pageByChangeSampleStudent_f7c5b9?.(
            {
              qto: {
                from: params.pagination?.from ?? 0,
                size: params.pagination?.size ?? 20,
                orderList: params.orderList, // 排序结果
                extractRoundIdIs,
                ...params.search, // 搜索结果
                ...params.filters, // 过滤器结果
                ...(params?.extra ?? {}), // 传入的额外参数
                refresh: undefined,
              },
              ext: params?.ext,
            },
          );
        return {
          data: res?.data?.result ?? res?.data ?? [],
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
    },
    [extractRoundIdIs],
  );

  const handelRejectSubmit = async () => {
    try {
      const response =
        await vsf.services?.SampleStudentController_rejectChangeSampleStudent_949cad?.(
          {
            btoParam: {
              id: currentRecord.current?.id,
              rejectReason,
            },
          },
        );
      if (response && response.code === 200) {
        message.success('已拒绝');
        setrejectReason('');
        props?.onRefresh?.();
        setRefresh(!refresh);
        setRejectVisible(false);
        return true;
      }
      return false;
    } catch (error) {
      console.error(error);
    }
  };

  const fakeItem = (title, children) => {
    return (
      <Row style={{ marginBottom: 24, minHeight: 32 }}>
        <Col span={3} style={{ display: 'flex', justifyContent: 'end' }}>
          {`${title}：`}
        </Col>
        <Col style={{ flex: 1 }}>{children}</Col>
      </Row>
    );
  };

  return (
    <Section className="monitorStatus">
      <VSPromiseTable
        pagination={{
          defaultPageSize: 10,
          showTotal: () => <></>,
        }}
        scroll={{
          x: 1000,
        }}
        extraParams={{ refresh }}
        editable={{ editType: 'single', columnProps: { hideInTable: true } }}
        searchConfig={{
          filterType: 'inline',
          collapsed: false,
          collapseRender: () => null,
          optionButtons: ['search'],
          searchText: '查询',
        }}
        vsid="15599"
        onFetch={onFetch}
      >
        <VSTableColumn
          dataIndex={['sampleStudent', 'studentId', 'studentStatusNum']}
          title="学籍辅号"
          valueType="text"
          width={120}
          formItemProps={{
            rules: [
              {
                message: '学籍辅号长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['sampleStudent', 'studentId', 'name']}
          title="姓名"
          valueType="text"
          search
          order={-40}
          columnKey={'nameLike'}
          formItemProps={{
            rules: [
              {
                message: '学生姓名长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{ width: 110 }}
        />
        <VSTableColumn
          dataIndex={['sampleStudent', 'studentId', 'org', 'orgName']}
          title="学校名称"
          valueType="text"
          ellipsis
          search
          width={120}
          columnKey={'orgNameLike'}
          // order={-40}
          formItemProps={{
            rules: [
              {
                message: '机构名称长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{ width: 140 }}
        />
        <VSTableColumn
          dataIndex={['sampleStudent', 'studentId', 'grade']}
          title="年级"
          valueType="select"
          dataSource={[
            { label: '一年级', value: 'FIRST_GRADE' },
            { label: '二年级', value: 'SECOND_GRADE' },
            { label: '三年级', value: 'THIRD_GRADE' },
            { label: '四年级', value: 'FOURTH_GRADE' },
            { label: '五年级', value: 'FIFTH_GRADE' },
            { label: '六年级', value: 'SIXTH_GRADE' },
            { label: '七年级', value: 'SEVENTH_GRADE' },
            { label: '八年级', value: 'EIGHTH_GRADE' },
            { label: '九年级', value: 'NINE_GRADE' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['sampleStudent', 'studentId', 'classes']}
          title="班级"
          valueType="text"
          search
          order={-41}
          columnKey={'classesLike'}
          formItemProps={{
            rules: [
              { message: '班级长度不合法', type: 'string', min: 0, max: 64 },
            ],
          }}
          fieldProps={{ width: 110 }}
        />
        <VSTableColumn
          dataIndex={['sampleStudent', 'studentId', 'sex']}
          title="性别"
          valueType="select"
          dataSource={[
            { label: '男', value: 'MAN' },
            { label: '女', value: 'WOMAN' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['sampleStudent', 'extractRoster', 'level']}
          title="维度"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '级别长度不合法', type: 'string', min: 0, max: 64 },
            ],
          }}
          fieldProps={{}}
          render={(_, record) =>
            `${SubjectEnumValue[subject]}${record?.sampleStudent?.extractRoster?.level}`
          }
        />
        <VSTableColumn
          dataIndex={['applicationReason']}
          title="变更原因"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '申请原因长度不合法',
                type: 'string',
                min: 0,
                max: 256,
              },
            ],
          }}
          fieldProps={{}}
          render={(dom, record) => {
            return (
              <a
                onClick={() => {
                  setItemDetail(record);
                  setShowResonVisible(true);
                }}
              >
                查看原因
              </a>
            );
          }}
        />
        <VSTableColumn
          dataIndex={['changeStatus']}
          title="状态"
          search
          width={120}
          order={-42}
          valueType="select"
          columnKey={'changeStatusIs'}
          dataSource={[
            { label: '待确认', value: 'UNCONFIRMED' },
            { label: '已确认', value: 'CONFIRMED' },
            { label: '替换待审核', value: 'UNAUDITED' },
            { label: '已通过', value: 'PASSED' },
            { label: '已拒绝', value: 'REJECTED' },
          ]}
          render={(doms, record) => {
            return (
              <div className="aic">
                {record?.changeStatus === SampleStudentEnum.UNCONFIRMED && (
                  <UnConfirmedAic />
                )}
                {record?.changeStatus === SampleStudentEnum.UNAUDITED && (
                  <UNAUDITEDAic />
                )}
                {record?.changeStatus === SampleStudentEnum.PASSED && (
                  <PASSEDAic />
                )}
                {record?.changeStatus === SampleStudentEnum.REJECTED && (
                  <REJECTEDdAic />
                )}
                {record?.changeStatus === SampleStudentEnum.CONFIRMED && (
                  <Confirmed />
                )}
                {doms}
              </div>
            );
          }}
        />
        <VSTableColumn
          title="操作"
          name={'action'}
          valueType="option"
          width={200}
        >
          <VSTableAction
            disableLoading
            show={(record) => record?.changeStatus === 'UNAUDITED'}
            linkPage={{
              updateTable: true,
              updateType: 'reload',
              onLinkPageValidate: async (_, __, record) => {
                try {
                  const response =
                    await vsf?.services?.SampleStudentController_passChangeSampleStudent_1b05a8?.(
                      {
                        btoParam: {
                          id: record?.id,
                        },
                      },
                    );
                  if (response && response.code === 200) {
                    message.success('确认成功');
                    return true;
                  }
                  return false;
                } catch (error) {
                  console.error(error);
                }
              },
            }}
          >
            <ConfirmButton className="aic jcc">确认</ConfirmButton>
          </VSTableAction>
          <VSTableAction
            disableLoading
            show={(record) => record?.changeStatus === 'UNAUDITED'}
            onClick={(_, record) => {
              currentRecord.current = record;
              setRejectVisible(true);
            }}
          >
            <RejectButton className="aic jcc">拒绝</RejectButton>
          </VSTableAction>
          <VSTableAction
            show={(record) => record?.changeStatus === 'REJECTED'}
            onClick={(_, record) => {
              setrejectReason(record?.rejectReason);
              setShowRejectResonVisible(true);
            }}
          >
            <RejectButton className="aic jcc">已拒绝</RejectButton>
          </VSTableAction>
          <VSTableAction
            show={(record) =>
              record?.changeStatus !== 'UNAUDITED' &&
              record?.changeStatus !== 'REJECTED'
            }
          >
            <span>-</span>
          </VSTableAction>
        </VSTableColumn>
      </VSPromiseTable>

      <Modal
        open={rejectVisible}
        destroyOnClose
        title="拒绝原因"
        onOk={handelRejectSubmit}
        onCancel={() => {
          setrejectReason('');
          setRejectVisible(false);
        }}
      >
        <TextArea
          style={{
            width: '100%',
            height: 160,
          }}
          value={rejectReason}
          placeholder="请输入拒绝原因"
          onChange={(e) => {
            setrejectReason(e.target.value);
          }}
        ></TextArea>
      </Modal>

      <Modal
        open={showRejectResonVisible}
        destroyOnClose
        title="拒绝原因"
        footer={false}
        onCancel={() => {
          setrejectReason('');
          setShowRejectResonVisible(false);
        }}
      >
        <TextArea
          style={{
            width: '100%',
            height: 160,
          }}
          readOnly
          value={rejectReason}
          placeholder="请输入拒绝原因"
        ></TextArea>
      </Modal>
      <Modal
        open={showResonVisible}
        width={500}
        title="查看替换原因"
        onCancel={() => setShowResonVisible(false)}
        onOk={() => setShowResonVisible(false)}
      >
        {fakeItem('原因', <span>{itemDetail?.applicationReason}</span>)}
        {fakeItem(
          '附件',
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'row',
              flexWrap: 'wrap',
              justifyContent: 'space-between',
            }}
          >
            {handelAnnexAddress()?.map((item) => {
              const _url = item ? decodeURIComponent(item) : '';
              const _suffix = _url
                ? _url.split('?')[0].slice(_url.split('?')[0].lastIndexOf('.'))
                : '';
              const _path = _url?.split('?')[0];
              const _originFileName = _path?.slice(
                _path?.indexOf('***') + 3,
                _path?.lastIndexOf('.'),
              );
              return !ImageSuffix?.includes(_suffix) ? (
                <FileItemView className="aic">
                  <div
                    className="ell"
                    style={{ width: '100%', cursor: 'pointer' }}
                    onClick={() => {
                      FileUpload.download(item, _originFileName);
                    }}
                  >
                    {_originFileName}
                  </div>
                </FileItemView>
              ) : (
                <ImageView>
                  <Image.PreviewGroup>
                    <Image width={'100%'} height={'100%'} src={item} />
                  </Image.PreviewGroup>
                </ImageView>
              );
            })}
          </div>,
        )}
      </Modal>
    </Section>
  );
};
export default definePage(DepartmentMonitorSampleStudentReplacementTable);
