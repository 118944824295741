import vsf, { definePage } from '@vs/vsf-boot';
import {
  Col,
  Input,
  message,
  Modal,
  Row,
  Section,
  TextArea,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback, useRef, useState } from 'react';
import styled from 'styled-components';

import { ChangeStatusEnum } from '@/config';
import { usePlatformInfo } from '@/utils';

const CancelText = styled.div`
  width: 29px;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #2e5ef2;
  margin-right: 28px;
  cursor: pointer;
`;

const PrimaryButton = styled.div`
  width: 84px;
  height: 36px;
  background: #2e5ef2;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
`;

const BatchRejectButton = styled.div`
  width: 84px;
  height: 36px;
  background: #ff3d11;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
`;

const RejectButton = styled.div`
  width: 80px;
  height: 30px;
  background: #ffebe7;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  color: #ff3d11;
  cursor: pointer;
`;

const RevertButton = styled.div`
  width: 80px;
  height: 30px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #dddddd;

  font-size: 14px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
`;

const ConfirmButton = styled.div`
  width: 80px;
  height: 30px;
  background: #eaeefd;
  border-radius: 6px;
  border: 1px solid #c9d6ff;
  font-size: 14px;
  font-weight: 400;
  color: #2e5ef2;
  cursor: pointer;
`;

const UnConfirmedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #fe9908;
  margin-right: 8px;
  border-radius: 50%;
`;

const ChangeUnAuditedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #ffcf54;
  margin-right: 8px;
  border-radius: 50%;
`;

const CreateUnAuditedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #12bc82;
  margin-right: 8px;
  border-radius: 50%;
`;

const Confirmed = styled.div`
  width: 5px;
  height: 5px;
  background: #aaaaaa;
  margin-right: 8px;
  border-radius: 50%;
`;

const Reject = styled.div`
  width: 5px;
  height: 5px;
  background: #ff3d11;
  margin-right: 8px;
  border-radius: 50%;
`;

const SchoolMonitorInfoChangeCheckTable = (props) => {
  const { taskIdIs, orgId } = props;

  const [isManage, setIsManage] = useState(false);
  const selectedRows = useRef([]);
  const [refresh, setRefresh] = useState(false);
  const [rejectVisible, setRejectVisible] = useState(false);
  const [rejectReason, setrejectReason] = useState('');
  const [currentData, setCurrentData] = useState();
  const [platformInfo] = usePlatformInfo();
  const [confirmVisible, setConfirmVisible] = useState(false);
  const classRef = useRef('');
  const currentRef = useRef();

  const onFetch = useCallback(
    async (params) => {
      if (platformInfo) {
        const res =
          await vsf?.services?.StudentChangeAuditDtoController_pageByStudentChangeAuditSchool_106733?.(
            {
              qto: {
                from: params.pagination?.from ?? 0,
                size: params.pagination?.size ?? 20,
                orderList: params.orderList, // 排序结果
                taskIdIs,
                orgIdIs: platformInfo?.platformId,
                ...params.search, // 搜索结果
                ...params.filters, // 过滤器结果
                ...(params?.extra ?? {}), // 传入的额外参数
                refresh: undefined,
              },
              ext: params?.ext,
            },
          );
        return {
          data: res?.data?.result ?? res?.data ?? [{}],
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
    },
    [taskIdIs, platformInfo],
  );

  const handelBatchCancel = () => {
    setIsManage(false);
  };

  const handelBatchClick = () => {
    if (isManage) {
      console.log();
    } else {
      setIsManage(true);
    }
  };

  const handelSelectRowChange = (rows) => {
    selectedRows.current = rows;
  };

  const handelConfirmClick = async (_, record) => {
    setConfirmVisible(true);
    currentRef.current = record;
  };

  const handelRejectSubmit = async () => {
    try {
      if (isManage) {
        const response =
          await vsf?.services?.StudentChangeAuditBOController_rejectStudentChangeAuditBySchoolBatch_b6acd1?.(
            {
              list: selectedRows.current?.map((item) => ({
                id: item.id,
                rejectReason,
              })),
            },
          );
        if (response && response.code === 200) {
          message.success('已拒绝');
          props?.onRefresh?.();
          setRefresh(!refresh);
          setRejectVisible(false);
          setIsManage(false);
          return true;
        }
        return false;
      } else {
        const response =
          await vsf.services?.StudentChangeAuditBOController_rejectStudentChangeAuditBySchool_3702e4?.(
            {
              btoParam: {
                id: currentData?.id,
                // auditStatus: ScoreAuditStatusEnum.REJECTED,
                rejectReason,
              },
            },
          );
        if (response && response.code === 200) {
          message.success('已拒绝');
          props?.onRefresh?.();
          setRefresh(!refresh);
          setRejectVisible(false);
          return true;
        }
        return false;
      }
    } catch (error) {
      console.error(error);
    }
  };
  const handelBatchConfirmClick = async () => {
    try {
      const response =
        await vsf?.services?.StudentChangeAuditBOController_passStudentChangeAuditBySchoolBatch_a5eb7e?.(
          {
            list: selectedRows.current?.map((item) => ({
              id: item?.id,
            })),
          },
        );
      if (response && response.code === 200) {
        message.success('确认成功');
        setRefresh(!refresh);
        setIsManage(false);
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  const fakeItem = (title, children) => {
    return (
      <Row style={{ marginBottom: 24, minHeight: 32, alignItems: 'center' }}>
        <Col span={5} style={{ display: 'flex', justifyContent: 'end' }}>
          {`${title}：`}
        </Col>
        <Col span={16} style={{ flex: 1 }}>
          {children}
        </Col>
      </Row>
    );
  };

  return (
    <Section className="monitorStatus">
      <VSPromiseTable
        editable={{ editType: 'single', columnProps: { hideInTable: true } }}
        onFetch={onFetch}
        searchConfig={{
          filterType: 'inline',
          optionButtons: 'search',
          searchButtonProps: {
            children: '查询',
          },
        }}
        pagination={{
          defaultPageSize: 10,
          showTotal: () => <></>,
        }}
        rowSelection={
          isManage
            ? {
                selectType: 'multiple',
                onSelectedRowsChange: handelSelectRowChange,
                getCheckboxProps: (row) => ({
                  disabled:
                    row?.changeStatus === 'CONFIRMED' ||
                    row?.changeStatus === 'REJECTED',
                }),
              }
            : null
        }
        extraParams={{ refresh }}
        searchFormRender={(_, doms, option) => {
          return (
            <div className="aic" style={{ alignItems: 'flex-start' }}>
              {doms}
              <div className="aic">
                {isManage && (
                  <CancelText onClick={handelBatchCancel}>取消</CancelText>
                )}
                {isManage && (
                  <PrimaryButton
                    className="aic jcc"
                    onClick={handelBatchConfirmClick}
                  >
                    批量确认
                  </PrimaryButton>
                )}
                <span style={{ width: 14 }} />
                {isManage ? (
                  <BatchRejectButton
                    className="aic jcc"
                    onClick={() => {
                      setRejectVisible(true);
                    }}
                  >
                    批量拒绝
                  </BatchRejectButton>
                ) : (
                  <PrimaryButton className="aic jcc" onClick={handelBatchClick}>
                    批量管理
                  </PrimaryButton>
                )}
              </div>
            </div>
          );
        }}
      >
        <VSTableColumn
          dataIndex={['studentStatusNum']}
          title="学籍辅号"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '学籍辅号长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['name']}
          title="姓名"
          valueType="text"
          search
          order={-11}
          columnKey={'nameLike'}
          formItemProps={{
            rules: [
              {
                message: '学生姓名长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
          }}
          fieldProps={{
            width: 110,
            placeholder: '请输入',
          }}
        />
        <VSTableColumn
          dataIndex={['grade']}
          title="年级"
          valueType="select"
          dataSource={[
            { label: '一年级', value: 'FIRST_GRADE' },
            { label: '二年级', value: 'SECOND_GRADE' },
            { label: '三年级', value: 'THIRD_GRADE' },
            { label: '四年级', value: 'FOURTH_GRADE' },
            { label: '五年级', value: 'FIFTH_GRADE' },
            { label: '六年级', value: 'SIXTH_GRADE' },
            { label: '七年级', value: 'SEVENTH_GRADE' },
            { label: '八年级', value: 'EIGHTH_GRADE' },
            { label: '九年级', value: 'NINE_GRADE' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['classes']}
          title="班级"
          valueType="text"
          search
          order={-12}
          columnKey={'classesLike'}
          formItemProps={{
            rules: [
              { message: '班级长度不合法', type: 'string', min: 0, max: 64 },
            ],
          }}
          fieldProps={{
            width: 110,
            placeholder: '请输入',
          }}
        />
        <VSTableColumn
          dataIndex={['changeStatus']}
          title="状态"
          valueType="select"
          search
          order={-13}
          width={140}
          columnKey={'changeStatusIs'}
          dataSource={[
            { label: '已确认', value: 'CONFIRMED' },
            { label: '已拒绝', value: 'REJECTED' },
            { label: '信息变更待审核', value: 'CHANGE_UNAUDITED' },
            { label: '待接收学校审核', value: 'SCHOOL_UNAUDITED' },
            { label: '新增待审核', value: 'CREATE_UNAUDITED' },
            { label: '待确认', value: 'UNCONFIRMED' },
            { label: '待局端审核', value: 'BUREAU_UNAUDITED' },
          ]}
          fieldProps={{
            placeholder: '请选择',
          }}
          render={(doms, record) => {
            return (
              <div className="aic">
                {record?.changeStatus === ChangeStatusEnum.UNCONFIRMED && (
                  <UnConfirmedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.CHANGE_UNAUDITED && (
                  <ChangeUnAuditedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.SCHOOL_UNAUDITED && (
                  <ChangeUnAuditedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.BUREAU_UNAUDITED && (
                  <ChangeUnAuditedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.CREATE_UNAUDITED && (
                  <CreateUnAuditedAic />
                )}
                {record?.changeStatus === ChangeStatusEnum.CONFIRMED && (
                  <Confirmed />
                )}
                {record?.changeStatus === ChangeStatusEnum.REJECTED && (
                  <Reject />
                )}
                {doms}
              </div>
            );
          }}
        />

        <VSTableColumn
          name={'action'}
          valueType="option"
          title="操作"
          width={180}
        >
          <VSTableAction
            disableLoading
            show={(record) =>
              record?.changeStatus === ChangeStatusEnum.REJECTED ||
              record?.changeStatus === ChangeStatusEnum.BUREAU_UNAUDITED
            }
            linkPage={{
              updateTable: true,
              updateType: 'reload',
              onLinkPageValidate: async (_, __, record) => {
                try {
                  const response =
                    await vsf?.services?.StudentChangeAuditBOController_revokeAuditSchool_aac950?.(
                      {
                        orgId,
                        studentChangeAuditId: record?.id,
                      },
                    );
                  if (response && response.code === 200) {
                    message.success('撤回成功');
                    return true;
                  }
                  return false;
                } catch (error) {
                  console.error(error);
                  return false;
                }
              },
            }}
          >
            <RevertButton className="aic jcc">撤回审核</RevertButton>
          </VSTableAction>
          <VSTableAction
            disabled={isManage}
            show={(record) =>
              record?.changeStatus === ChangeStatusEnum.CHANGE_UNAUDITED
            }
            onClick={handelConfirmClick}
          >
            <ConfirmButton className="aic jcc">确认</ConfirmButton>
          </VSTableAction>
          <VSTableAction
            disabled={isManage}
            show={(record) =>
              record?.changeStatus === ChangeStatusEnum.CHANGE_UNAUDITED
            }
            onClick={(_, record) => {
              setCurrentData(record);
              setRejectVisible(true);
            }}
          >
            <RejectButton className="aic jcc">拒绝</RejectButton>
          </VSTableAction>

          <VSTableAction
            disabled={isManage}
            show={(record) =>
              record?.changeStatus === ChangeStatusEnum.SCHOOL_UNAUDITED
            }
          >
            <ConfirmButton className="aic jcc" style={{ opacity: 0.5 }}>
              确认
            </ConfirmButton>
          </VSTableAction>
          <VSTableAction
            disabled={isManage}
            show={(record) =>
              record?.changeStatus === ChangeStatusEnum.SCHOOL_UNAUDITED
            }
          >
            <RejectButton className="aic jcc" style={{ opacity: 0.5 }}>
              拒绝
            </RejectButton>
          </VSTableAction>

          <VSTableAction
            disabled={isManage}
            show={(record) =>
              record?.changeStatus === ChangeStatusEnum.CREATE_UNAUDITED
            }
            onClick={handelConfirmClick}
          >
            <ConfirmButton className="aic jcc">确认</ConfirmButton>
          </VSTableAction>
          <VSTableAction
            disabled={isManage}
            show={(record) =>
              record?.changeStatus === ChangeStatusEnum.CREATE_UNAUDITED
            }
            onClick={(_, record) => {
              setCurrentData(record);
              setRejectVisible(true);
            }}
          >
            <RejectButton className="aic jcc">拒绝</RejectButton>
          </VSTableAction>
          <VSTableAction
            show={(record) =>
              record?.changeStatus !== ChangeStatusEnum.CHANGE_UNAUDITED &&
              record?.changeStatus !== ChangeStatusEnum.REJECTED &&
              record?.changeStatus !== ChangeStatusEnum.BUREAU_UNAUDITED
            }
          >
            <span>-</span>
          </VSTableAction>
        </VSTableColumn>
      </VSPromiseTable>

      <Modal
        open={confirmVisible}
        title="学生变更通过"
        onCancel={() => setConfirmVisible(false)}
        onOk={async () => {
          try {
            const response =
              await vsf?.services?.StudentChangeAuditBOController_passStudentChangeAuditBySchool_260cc7?.(
                {
                  btoParam: {
                    id: currentRef.current?.id,
                    changeClasses: classRef.current,
                  },
                },
              );
            if (response && response.code === 200) {
              message.success('确认成功');
              setConfirmVisible(false);
              setRefresh(!refresh);
              return;
            }
          } catch (error) {
            setConfirmVisible(false);
            console.error(error);
          }
        }}
      >
        {fakeItem(
          '调整后班级',
          <Input
            onChange={(e) => {
              classRef.current = e.target?.value;
            }}
          />,
        )}
      </Modal>

      <Modal
        open={rejectVisible}
        destroyOnClose
        title="拒绝"
        onOk={handelRejectSubmit}
        onCancel={() => setRejectVisible(false)}
        okText="确定"
        cancelText="取消"
      >
        <TextArea
          style={{
            width: '100%',
            height: 160,
          }}
          value={rejectReason}
          maxLength={256}
          placeholder="请输入拒绝原因"
          onChange={(e) => {
            setrejectReason(e.target.value);
          }}
        ></TextArea>
      </Modal>
    </Section>
  );
};
export default definePage(SchoolMonitorInfoChangeCheckTable);
