import DepartmentOrgAdd from '@pages/DepartmentOrgAdd';
import DepartmentOrgEdit from '@pages/DepartmentOrgEdit';
import DepartmentOrgManage from '@pages/DepartmentOrgManage';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  Icon,
  message,
  Section,
  Todo,
  VSPromiseTable,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';

import ActionSplit from './ActionSplit';

const periods = [
  { period: 'PRIMARY_SCHOOL', name: '小学' },
  { period: 'MIDDLE_SCHOOL', name: '初中' },
];

const DepartmentOrgList = (props) => {
  const [refresh, setRefresh] = useState(false);
  /**
   * orgPeriodDtoList转到orgPeriodBtoList
   * @param {OrgVoOrganizationInfoEntranceWebVo} data
   * @returns {object}
   */
  const dto2bto = (data) => {
    if (!data) {
      return;
    }
    const { orgPeriodDtoList, ...rest } = data;
    return { orgPeriodBtoList: orgPeriodDtoList, ...rest };
  };
  /**
   * orgPeriodBtoList转到orgPeriodDtoList
   * @param {object} data
   * @returns {object}
   */
  const bto2dto = (data) => {
    if (!data) {
      return;
    }
    const { orgPeriodBtoList, ...rest } = data;
    return { orgPeriodDtoList: orgPeriodBtoList, ...rest };
  };

  const onFetch = useCallback(async (params) => {
    const res = await vsf?.services?.OrgDtoController_pageOrg_b9b838?.({
      qto: {
        from: params.pagination?.from ?? 0,
        size: params.pagination?.size ?? 20,
        orderList: params.orderList, // 排序结果
        ...params.search, // 搜索结果
        ...params.filters, // 过滤器结果
        ...(params?.extra ?? {}),
      },
    });
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  return (
    <Section title="" className="department_account_list">
      <VSPromiseTable
        extraParams={{ refresh }}
        editable={{
          editType: 'single',
          onCanDelete: () => false,
          extraOperations: [
            {
              order: 0,
              children: '管理人员',
              linkPage: {
                linkPage: () => <DepartmentOrgManage />,
                modalProps: {
                  title: (data) => `管理人员-${data.orgName}`,
                  width: 500,
                  hideFooter: true,
                },
              },
            },
          ],
          columnProps: {
            width: 200,
          },
        }}
        form={{
          style: {
            justifyContent: 'end',
          },
        }}
        searchConfig={{
          filterType: 'inline',
          // autoSearch: true,
          optionButtons: [],
          extraAdditionButton: true,
          addition: { children: '添加机构', position: 'top' },
        }}
        debounceTime={200}
        actionSpaceProps={{
          split: <ActionSplit />,
        }}
        pagination={{
          defaultPageSize: 10,
          showTotal: () => <></>,
        }}
        onFetch={onFetch}
        updateLinkPage={{
          linkPage: () => <DepartmentOrgEdit />,
          onLinkPageValidate: async (params) => {
            try {
              console.log(
                await vsf.refs.DepartmentOrgForm?.validateFieldsReturnFormatValue(),
                '====',
              );
              await vsf.refs.DepartmentOrgForm?.validateFieldsReturnFormatValue();

              const res =
                await vsf?.services?.OrgBOController_updateOrg_c5d949?.({
                  btoParam: { ...(dto2bto(params) ?? {}) },
                });
              return bto2dto(res?.data);
            } catch (e) {
              console.log(e);
              return false;
            }
          },
          modalProps: { title: '编辑机构', width: 500 },
        }}
        addLinkPage={{
          linkPage: () => <DepartmentOrgAdd />,
          onLinkPageValidate: async (params) => {
            try {
              await vsf.refs.DepartmentOrgAddForm?.validateFieldsReturnFormatValue();
              if (params) {
                if (Array.isArray(params)) {
                  const res =
                    await vsf?.services?.OrgBOController_excelOrg_ad492b?.({
                      orgEoList: params,
                    });
                  return res?.data;
                } else {
                  const data = dto2bto(params);
                  const { orgUserList, ...rest } = data;
                  const res =
                    await vsf?.services?.OrgBOController_createOrg_05bcd2?.({
                      btoParam: rest ?? {},
                      orgUserEoList: orgUserList,
                    });
                  return bto2dto(res?.data);
                }
              } else {
                vsf.showToast('请导入机构数据', 'error');
              }
            } catch (e) {
              return false;
            }
          },
          modalProps: { width: 600, title: '添加机构' },
        }}
      >
        <VSTableColumn
          dataIndex={['orgName']}
          title="机构名称"
          valueType="text"
          search
          ellipsis
          columnKey={'orgNameLike'}
          formItemProps={{
            rules: [
              {
                message: '机构名称长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
            label: '',
          }}
          fieldProps={{
            // addonAfter: <Icon type="SearchOutlined" />,
            placeholder: '搜索机构名称、ID',
            suffix: (
              <div
                onClick={() => {
                  setRefresh(!refresh);
                }}
              >
                <Icon type="SearchOutlined" />
              </div>
            ),
          }}
        />

        <VSTableColumn
          dataIndex={['id']}
          title="机构ID"
          valueType="digit"
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['orgLevel']}
          title="机构层级"
          valueType="select"
          dataSource={[
            { label: '集团校', value: 'GROUP_SCHOOL' },
            { label: '主校', value: 'MAIN_CAMPUS' },
            { label: '分校', value: 'BRANCH_CAMPUS' },
            { label: '省级', value: 'PROVINCE_SCHOOL' },
            { label: '市级', value: 'CITY_SCHOOL' },
            { label: '区级', value: 'COUNTY_SCHOOL' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['orgPeriodDtoList']}
          title="学段"
          valueType="checkbox"
          fieldProps={{
            dataSource: periods,
            fieldNames: { value: 'period', label: 'name' },
          }}
          render={(_, data) => {
            return data.orgPeriodDtoList
              ?.sort((a, b) => (a.period < b.period ? 1 : -1))
              ?.map((d) => periods.find((p) => p.period === d.period)?.name)
              .join('、');
          }}
        />

        <VSTableColumn
          dataIndex={['province']}
          title="所属省份"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '所属省份长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
            label: '',
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['city']}
          title="所属市级"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '所属市级长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
            label: '',
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['county']}
          title="所属区域"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '所属区域长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
            label: '',
          }}
          fieldProps={{}}
        />
      </VSPromiseTable>
    </Section>
  );
};
export default definePage(DepartmentOrgList);
