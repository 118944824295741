import vsf, { definePage } from '@vs/vsf-boot';
import { message, Page } from '@vs/vsf-kit';
import React, { useState } from 'react';
import styled from 'styled-components';

import DownloadIcon from '@/assets/images/icon/download_icon.png';
import FileUpload from '@/components/FileUpload';

import DepartmentGradesDetailTable from './DepartmentGradesDetailTable';

const RightExtra = styled.div`
  font-size: 14px;
  font-weight: bold;
  color: #2e5ef2;
  display: flex;
  align-items: center;
  cursor: pointer;

  .download_icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

const TitleSpan = styled.span`
  font-size: 18px;
  font-weight: bold;
  color: #333333;
`;

const DepartmentGradesDetail = (props) => {
  const {
    routes: {
      params: { recordId },
      query: { name },
    },
  } = props;

  const [dataLength, setDataLength] = useState();

  const handelDownloadClick = async () => {
    try {
      if (dataLength > 0) {
        const response =
          await vsf?.services?.ScoreInfoPubDtoController_getScoreInfoPub_702bf9?.(
            {
              scoreTaskIdI: recordId,
            },
          );
        if (response && response.code === 200) {
          FileUpload.download(
            response.data?.excelUrl,
            response?.data?.excelName,
          );
          message.success('已下载');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelSetDataLength = (_length) => {
    setDataLength(_length);
  };

  return (
    <div
      className="col"
      style={{
        width: '100%',
        boxSizing: 'border-box',
        padding: '0 20px',
        // background: '#fff',
      }}
    >
      <div
        style={{
          width: '100%',
          height: '100%',
          background: '#fff',
          boxSizing: 'border-box',
          padding: '0 20px',
        }}
        className="col"
      >
        <div
          className="aic jcsb"
          style={{
            margin: '17px 0',
          }}
        >
          <TitleSpan>{name}</TitleSpan>
          <RightExtra
            onClick={handelDownloadClick}
            style={{
              opacity: dataLength > 0 ? 1 : 0.5,
            }}
          >
            <img src={DownloadIcon} className="download_icon" />
            <span>下载</span>
          </RightExtra>
        </div>
        <DepartmentGradesDetailTable
          scoreTaskIdIs={recordId}
          handelSetDataLength={handelSetDataLength}
        />
      </div>
    </div>
  );
};

export default definePage(DepartmentGradesDetail);
