import '@/pages/TreeMenu.less';
import './TreeMenu.less';

import vsf, { definePage, renderRoutes, RouteConfig } from '@vs/vsf-boot';
import { Layout, Menu, Page } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import PeopleIcon from '@/assets/images/icon/people_icon.png';
import { BreadCrumb } from '@/components';
import TreeMenu from '@/components/TreeMenu';
import { navigateToWithPlatform, usePlatformInfo } from '@/utils';

const { Sider } = Layout;

const MENU = [
  {
    key: 'infoConfirm',
    title: '信息确认',
    parentId: null,
  },
  {
    key: 'infoCheck',
    title: '学生信息核对',
    parentId: 'infoConfirm',
  },
  {
    key: 'infoChangeCheck',
    title: '学生信息变更审核',
    parentId: 'infoConfirm',
  },
  {
    key: 'sampleConfig',
    title: '抽样配置',
    parentId: null,
  },
  {
    key: 'sampleConfigForm',
    title: '学校抽取参数',
    parentId: 'sampleConfig',
  },
  {
    key: 'SampleList',
    title: '样本名单',
    parentId: null,
  },
  {
    key: 'sampleSchool',
    title: '样本学校',
    parentId: 'SampleList',
  },
  {
    key: 'sampleStudent',
    title: '样本学生',
    parentId: 'SampleList',
  },
  {
    key: 'sampleStudentReplacement',
    title: '样本学生替换审核',
    parentId: 'SampleList',
  },
  {
    key: 'replacementRelease',
    title: '替换名额发放',
    parentId: 'SampleList',
  },
  {
    key: 'finalList',
    title: '抽样测评最终名单',
    parentId: null,
  },
];

const secondary = [
  {
    key: 'school',
    titleKey: 'title',
    title: '查看学生',
    parentId: 'infoCheck',
  },
];

const DepartmentMonitor = (props) => {
  const {
    route,
    routes: { query, params, pathname },
  } = props;
  const [defaultSelectedKey, setDefaultSelectedKey] = useState('');
  const [selected, setSelected] = useState();
  const [name, setName] = useState(query?.name);
  const [platformInfo] = usePlatformInfo();
  const [message, setMessage] = useState(false);
  const [changeSampleReminder, setChangeSampleReminder] = useState(false);
  const once = useRef(false);

  const loadMessage = useCallback(async () => {
    try {
      if (params.taskIdIs) {
        const response =
          await vsf?.services?.StudentChangeAuditDtoController_changeAuditReminder_5d32f0?.(
            {
              taskId: params.taskIdIs,
            },
          );
        once.current = true;
        setMessage(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [params]);

  const loadChangeSampleReminder = useCallback(async () => {
    try {
      if (params.taskIdIs) {
        const response =
          await vsf?.services?.ChangeSampleStudentPageDtoController_ChangeSampleReminder_255d82?.(
            {
              taskId: params.taskIdIs,
            },
          );
        setChangeSampleReminder(response?.data);
      }
    } catch (error) {
      console.error(error);
    }
  }, [params]);

  useEffect(() => {
    if (!once.current && params.taskIdIs) {
      loadMessage();
      loadChangeSampleReminder();
    }
  }, [loadChangeSampleReminder, loadMessage, params.taskIdIs]);

  useEffect(() => {
    if (params.taskIdIs && !name) {
      vsf.services
        .MonitorTaskController_getMonitorTaskDetail_12984e?.({
          id: params.taskIdIs,
        })
        .then((res) => {
          if (res.data) {
            setName(res.data.name);
          }
        });
    }
  }, [params, name, loadMessage, loadChangeSampleReminder]);

  useEffect(() => {
    if (props?.routes?.pathname) {
      const arr = props?.routes?.pathname?.split?.('/');
      const routeKey = arr?.[arr?.length - 1];
      const item = MENU.find((item) => item.key === routeKey);
      if (item) {
        setDefaultSelectedKey(routeKey);
        setSelected(routeKey);
      }
    }
  }, [props]);

  const renderCustomerTitle = (node) => {
    return (
      <div className="treenode-item-normal aic jcsb">
        {node.key === 'infoChangeCheck' && message && (
          <div
            style={{
              width: 7,
              height: 7,
              background: 'red',
              borderRadius: '50%',
              position: 'absolute',
              left: -10,
            }}
          />
        )}
        {node.key === 'sampleStudentReplacement' && changeSampleReminder && (
          <div
            style={{
              width: 7,
              height: 7,
              background: 'red',
              borderRadius: '50%',
              position: 'absolute',
              left: -10,
            }}
          />
        )}
        {node.title}
      </div>
    );
  };

  const navigaSampleConfig = async (_query) => {
    try {
      const response =
        await vsf?.services?.ExtractConfigDtoController_getByMonitorTaskId_553a8d?.(
          {
            monitorTaskId: params.taskIdIs,
          },
        );
      if (response.data) {
        navigateToWithPlatform(
          `/${platformInfo?.platformId}/monitor/${params.taskIdIs}/sampleConfigTable?${_query}`,
        );
      } else {
        navigateToWithPlatform(
          `/${platformInfo?.platformId}/monitor/${params.taskIdIs}/sampleConfigDetail?${_query}`,
        );
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelSelectClick = (node) => {
    let navigated = true;
    const _query = `period=${query?.period}&grade=${query?.grade}&monitorTaskStatus=${query?.monitorTaskStatus}&createStatus=${query?.createStatus}`;
    switch (node.key) {
      case 'infoCheck':
        navigateToWithPlatform(
          `/${platformInfo?.platformId}/monitor/${params.taskIdIs}/infoCheck?${_query}`,
        );
        break;

      case 'infoChangeCheck':
        navigateToWithPlatform(
          `/${platformInfo?.platformId}/monitor/${params.taskIdIs}/infoChangeCheck?${_query}`,
        );
        break;

      case 'infoChangeHistory':
        navigateToWithPlatform(
          `/${platformInfo?.platformId}/monitor/${params.taskIdIs}/infoChangeHistory?${_query}`,
        );
        break;

      case 'sampleConfigForm':
        navigaSampleConfig(_query);
        break;

      case 'sampleStudentReplacement':
        navigateToWithPlatform(
          `/${platformInfo?.platformId}/monitor/${params.taskIdIs}/sampleStudentReplacement?${_query}`,
        );
        break;

      case 'sampleSchool':
        navigateToWithPlatform(
          `/${platformInfo?.platformId}/monitor/${params.taskIdIs}/sampleSchool?${_query}`,
        );
        break;

      case 'sampleStudent':
        navigateToWithPlatform(
          `/${platformInfo?.platformId}/monitor/${params.taskIdIs}/sampleStudent?${_query}`,
        );
        break;

      case 'replacementRelease':
        navigateToWithPlatform(
          `/${platformInfo?.platformId}/monitor/${params.taskIdIs}/replacementRelease?${_query}`,
        );
        break;

      case 'finalList':
        navigateToWithPlatform(
          `/${platformInfo?.platformId}/monitor/${params.taskIdIs}/finalList?${_query}`,
        );
        break;
      default:
        navigated = false;
        break;
    }
    if (navigated) {
      setSelected(node.key);
    }
  };

  const names = [];
  if (name) {
    names.push(name);
  }
  const current = MENU.find((item) => item.key === selected);
  if (current) {
    names.push(current.title);
  }
  const second = secondary.find((item) => {
    const parts = pathname.split('/').filter((part) => !!part);
    // /department/:platformId/monitor/:monitor/xxx
    const key = parts[4];
    if (key === item.key) {
      return true;
    }
    return false;
  });
  if (second) {
    names.push(query[second.titleKey] ?? second.title);
  }

  return (
    <>
      <BreadCrumb
        names={names}
        onBack={() => {
          const array = pathname?.split('/');
          if (array && array.length > 0) {
            const key = array[array.length - 1];
            const item = MENU.find((item) => item.key === key);
            if (item || key === 'sampleConfigTable') {
              navigateToWithPlatform(
                `/${platformInfo?.platformId}/monitorTaskList`,
              );
            } else {
              vsf.goBack();
            }
          }
        }}
      />
      <div className="vsf-layout-sec">
        <Sider
          className="vsf-layout-sec-sider"
          theme="light"
          collapsible
          width={240}
        >
          <div className="vsf-layout-sec-sider-header aic">
            <img
              src={PeopleIcon}
              className="vsf-layout-sec-sider-header-icon"
            />
            <span>抽样管理</span>
          </div>
          <TreeMenu
            treeData={MENU}
            treeProps={{
              titleRender: renderCustomerTitle,
              selectedKeys: [selected],
              defaultExpandedKeys: [defaultSelectedKey],
              defaultSelectedKeys: [defaultSelectedKey],
            }}
            search={{
              status: false,
            }}
            dataRelationFieldNames={{
              id: 'key',
              parentId: 'parentId',
            }}
            onSelect={handelSelectClick}
          />
        </Sider>
        <div className="vsf-layout-sec-main">
          {renderRoutes(route.routes, route.redirect)}
        </div>
      </div>
    </>
  );
};

export default definePage(DepartmentMonitor);
