import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { SubjectEnumValue } from '@/config';
import { usePlatformInfo } from '@/utils';

import ChinaNum from './RandomSampleList/ChinaNum';
import SchoolMonitorFinalListTable from './SchoolMonitorFinalListTable';

const SubjectView = styled.div`
  width: 100%;
  height: 56px;
  background: #ecedf0;
  border-radius: 6px 6px 0px 0px;
`;

const SubjectViewItem = styled.div`
  width: 146px;
  height: 56px;
  background: #ffffff;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  cursor: pointer;
`;

const Line = styled.div`
  width: 100%;
  height: 3px;
  background: #2e5ef2;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px 12px 0 0;
`;

const SchoolMonitorFinalList = (props) => {
  const {
    routes: {
      params: { taskIdIs },
      query: { taskOrgId },
    },
  } = props;

  const [monitorTask, setMonitorTask] = useState();
  const [extractRoundIdIs, setExtractRoundIdIs] = useState();
  const [platformInfo] = usePlatformInfo();

  const loadByMonitorTask = useCallback(async () => {
    try {
      if (platformInfo?.platformId) {
        const response =
          await vsf?.services?.SampleRuleController_getConfigRosterByMonitorTaskId_c29dad?.(
            {
              monitorTaskId: taskIdIs,
              orgId: platformInfo?.platformId,
            },
          );
        if (response && response.code === 200) {
          if (response.data && response.data?.extractRoundDtoList?.[0]) {
            setExtractRoundIdIs(
              response.data && response.data?.extractRoundDtoList?.[0]?.id,
            );
          }
          setMonitorTask(response.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [taskIdIs, platformInfo]);

  useEffect(() => {
    loadByMonitorTask();
  }, [loadByMonitorTask]);

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        borderRadius: 8,
        overflowY: 'scroll',
        boxSizing: 'border-box',
        paddingLeft: 24,
      }}
      className="col"
    >
      {monitorTask?.extractRoundDtoList && (
        <SubjectView className="aic">
          {monitorTask?.extractRoundDtoList?.map((item, _idx) => (
            <SubjectViewItem
              key={item?.id}
              className="aic jcc"
              style={{
                background:
                  extractRoundIdIs === item?.id ? '#FFFFFF' : '#ECEDF0',
              }}
              onClick={() => {
                setExtractRoundIdIs(item?.id);
              }}
            >
              {extractRoundIdIs === item?.id && <Line />}
              <span
                style={{
                  color: extractRoundIdIs === item?.id ? '#2E5EF2' : '#999999',
                }}
              >
                第{ChinaNum[_idx]}轮（{SubjectEnumValue[item?.subject]}）
              </span>
            </SubjectViewItem>
          ))}
        </SubjectView>
      )}

      <div
        // style={{ background: '#fff' }}
        style={{ width: '100%', height: '100%' }}
        className="col"
      >
        <SchoolMonitorFinalListTable
          taskIdIs={taskIdIs}
          orgIdIs={taskOrgId}
          extractRoundIdIs={extractRoundIdIs}
        />
      </div>
    </div>
  );
};

export default definePage(SchoolMonitorFinalList);
