import SchoolMonitorStudentConfirmTable from '@pages/SchoolMonitorStudentConfirmTable';
import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { SubjectEnumValue } from '@/config';
import { usePlatformInfo } from '@/utils';

import ChinaNum from './RandomSampleList/ChinaNum';
import SchoolMonitorReplacementHistoryTable from './SchoolMonitorReplacementHistoryTable';

const SubjectView = styled.div`
  width: 100%;
  height: 56px;
  background: #ecedf0;
  border-radius: 6px 6px 0px 0px;
`;

const SubjectViewItem = styled.div`
  width: 146px;
  height: 56px;
  background: #ffffff;
  font-size: 16px;
  font-weight: bold;
  position: relative;
  cursor: pointer;
`;

const Line = styled.div`
  width: 100%;
  height: 3px;
  background: #2e5ef2;
  position: absolute;
  top: 0;
  left: 0;
  border-radius: 12px 12px 0 0;
`;

const SubjectTabsView = styled.div`
  flex: 1;
  height: 63px;
  box-sizing: border-box;
  padding-top: 14px;
  box-sizing: border-box;
  padding-left: 20px;
`;

const SubjectTabsViewItem = styled.div`
  height: 100%;
  font-size: 16px;
  font-weight: 500;
  color: #999999;
  position: relative;
  margin-right: 30px;
`;

const SubjectTabsViewItemLine = styled.div`
  width: 24px;
  height: 3px;
  background: #2e5ef2;
  border-radius: 2px;
  position: absolute;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
`;

const CountLabel = styled.div`
  font-size: 14px;
  font-family:
    PingFangSC-Regular,
    PingFang SC;
  font-weight: 400;
  color: #333333;
  margin-right: 19px;
`;

const SchoolMonitorReplacementHistory = (props) => {
  const {
    routes: {
      params: { taskIdIs },
      query: { taskOrgId },
    },
  } = props;

  const [monitorTask, setMonitorTask] = useState();
  const [extractRoundIdIs, setExtractRoundIdIs] = useState();
  const [tabsId, setTabsId] = useState();
  const [studentNumData, setStudentNumData] = useState();
  const configIdRef = useRef();
  const [platformInfo] = usePlatformInfo();

  const getStudentNum = useCallback(async () => {
    const currentTabs = monitorTask?.extractRoundDtoList
      ?.find((item) => item.id === extractRoundIdIs)
      ?.extractRosterOpenDtoList?.find((item) => item?.id === tabsId);

    try {
      if (platformInfo?.platformId) {
        const response =
          await vsf?.services?.ExtractRosterBOController_studentExtractPercentage_7efd94?.(
            {
              queryEo: {
                configId: monitorTask?.id,
                roundId: extractRoundIdIs,
                level: currentTabs?.level,
                orgId: platformInfo?.platformId,
                ...(monitorTask?.extractType === 'CLASSES'
                  ? {
                      rosterId: currentTabs?.id,
                    }
                  : {}),
              },
            },
          );
        if (response && response.code === 200) {
          setStudentNumData(response?.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [monitorTask, extractRoundIdIs, tabsId, platformInfo]);

  useEffect(() => {
    if (monitorTask?.id && extractRoundIdIs && tabsId) {
      getStudentNum();
    }
  }, [monitorTask, extractRoundIdIs, tabsId, getStudentNum]);
  const loadByMonitorTask = useCallback(async () => {
    try {
      if (platformInfo?.platformId) {
        const response =
          await vsf?.services?.SampleRuleController_getConfigRosterByMonitorTaskId_c29dad?.(
            {
              monitorTaskId: taskIdIs,
              orgId: platformInfo?.platformId,
            },
          );
        if (response && response.code === 200) {
          if (response.data && response.data?.extractRoundDtoList?.[0]) {
            setExtractRoundIdIs(
              response.data && response.data?.extractRoundDtoList?.[0]?.id,
            );

            if (response.data?.distinguishSex) {
              setTabsId(
                (
                  response.data?.extractRoundDtoList?.[0]
                    ?.extractRosterOpenDtoList ?? []
                )?.filter((item) => item.sex === 'MAN')?.[0]?.id,
              );
            } else {
              setTabsId(
                (response.data?.extractRoundDtoList?.[0]
                  ?.extractRosterOpenDtoList ?? [])?.[0]?.id,
              );
            }
          }
          setMonitorTask(response.data);
        }
      }
    } catch (error) {
      console.error(error);
    }
  }, [taskIdIs, platformInfo]);

  useEffect(() => {
    loadByMonitorTask();
  }, [loadByMonitorTask]);

  const getTabsList = () => {
    const arr = [];
    monitorTask?.extractRoundDtoList
      ?.find((item) => item.id === extractRoundIdIs)
      ?.extractRosterOpenDtoList?.forEach((item) => {
        if (!arr.find((_v) => _v.level === item.level)) {
          arr?.push(item);
        }
      });

    return arr;
  };

  const getExtractRosterIdIn = () => {
    const currentTabs = monitorTask?.extractRoundDtoList
      ?.find((item) => item.id === extractRoundIdIs)
      ?.extractRosterOpenDtoList?.find((item) => item?.id === tabsId);

    const ids = monitorTask?.extractRoundDtoList
      ?.find((item) => item.id === extractRoundIdIs)
      ?.extractRosterOpenDtoList?.filter(
        (item) => item.level === currentTabs?.level,
      )
      ?.map((item) => item.id);
    return ids;
  };

  return (
    <div
      style={{
        width: '100%',
        height: '100%',
        borderRadius: 8,
        overflowY: 'scroll',
        boxSizing: 'border-box',
        paddingLeft: 24,
      }}
      className="col"
    >
      {monitorTask?.extractRoundDtoList && (
        <SubjectView className="aic">
          {monitorTask?.extractRoundDtoList?.map((item, _idx) => (
            <SubjectViewItem
              key={item?.id}
              className="aic jcc"
              style={{
                background:
                  extractRoundIdIs === item?.id ? '#FFFFFF' : '#ECEDF0',
              }}
              onClick={() => {
                setExtractRoundIdIs(item?.id);
                setTabsId(item?.extractRosterOpenDtoList?.[0]?.id);
              }}
            >
              {extractRoundIdIs === item?.id && <Line />}
              <span
                style={{
                  color: extractRoundIdIs === item?.id ? '#2E5EF2' : '#999999',
                }}
              >
                第{ChinaNum[_idx]}轮（{SubjectEnumValue[item?.subject]}）
              </span>
            </SubjectViewItem>
          ))}
        </SubjectView>
      )}

      <div
        className="aic jcsb"
        style={{ background: '#fff', borderBottom: '1px solid #e9e9e9' }}
      >
        <SubjectTabsView className="aic">
          {getTabsList()?.map((item) => (
            <SubjectTabsViewItem
              key={item?.id}
              className="col aic"
              onClick={() => setTabsId(item?.id)}
            >
              <span>
                {
                  SubjectEnumValue[
                    monitorTask?.extractRoundDtoList?.find(
                      (item) => item.id === extractRoundIdIs,
                    )?.subject
                  ]
                }{' '}
                {item?.level}
              </span>
              {tabsId === item?.id && <SubjectTabsViewItemLine />}
            </SubjectTabsViewItem>
          ))}
        </SubjectTabsView>
        {monitorTask?.distinguishSex ? (
          <>
            男生:
            <CountLabel>
              已确认{' '}
              <span style={{ color: '#FF3D11' }}>
                {studentNumData?.manData?.replaceConfirmedTotal ?? 0}
              </span>
              /{studentNumData?.manData?.replaceTotal ?? 0} 替换学生
            </CountLabel>
            女生:
            <CountLabel>
              已确认{' '}
              <span style={{ color: '#FF3D11' }}>
                {studentNumData?.womanData?.replaceConfirmedTotal ?? 0}
              </span>
              /{studentNumData?.womanData?.replaceTotal ?? 0} 替换学生
            </CountLabel>
          </>
        ) : (
          <>
            <CountLabel>
              已确认{' '}
              <span style={{ color: '#FF3D11' }}>
                {studentNumData?.studentData?.replaceConfirmedTotal ?? 0}
              </span>
              /{studentNumData?.studentData?.replaceTotal ?? 0} 替换学生
            </CountLabel>
          </>
        )}
      </div>
      <SchoolMonitorReplacementHistoryTable
        extractRoundIdIs={extractRoundIdIs}
        rosterIdIn={getExtractRosterIdIn()}
        taskOrgIdIs={taskOrgId}
      />
    </div>
  );
};
export default definePage(SchoolMonitorReplacementHistory);
