export const STORAGE_KEY_PREFIX = 'xuetong-admin';

export enum TABS_MENU_KEY {
  Sampling = 'Sampling',
  AchievementManage = 'AchievementManage',
  PermissionManage = 'PermissionManage',
}

export enum PLATFORM {
  department = 'department',
  school = 'school',
}

/**
 * 成绩审核状态枚举
 */
export enum ScoreAuditStatusEnum {
  UNAUDITED = 'UNAUDITED',
  REJECTED = 'REJECTED',
  PASSED = 'PASSED',
  NOT_SUBMIT = 'NOT_SUBMIT',
}

export enum ScoreAuditStatusEnumValue {
  UNAUDITED = '待审核',
  REJECTED = '已拒绝',
  PASSED = '已审核',
  NOT_SUBMIT = '待提交',
}

export enum ScoreAuditStatusEnumBackgroundColor {
  UNAUDITED = 'rgba(241,135,0,0.5)',
  REJECTED = 'rgba(255,111,78,0.5)',
  PASSED = 'rgba(153,153,153,0.5)',
  NOT_SUBMIT = 'transparent',
}

export enum ScoreAuditStatusEnumTextColor {
  UNAUDITED = '#F18700',
  REJECTED = '#FF6F4E',
  PASSED = '#999999',
  NOT_SUBMIT = '',
}
/**
 * 抽样学生审核状态
 */
export enum SampleStudentEnum {
  UNCONFIRMED = 'UNCONFIRMED',
  CONFIRMED = 'CONFIRMED',
  UNAUDITED = 'UNAUDITED',
  PASSED = 'PASSED',
  REJECTED = 'REJECTED',
  ASK_FOR_LEAVE = 'ASK_FOR_LEAVE',
}

export enum SampleStudentEnumValue {
  UNCONFIRMED = '待确认',
  CONFIRMED = '已确认',
  UNAUDITED = '替换待审核',
  PASSED = '已通过',
  REJECTED = '已拒绝',
  ASK_FOR_LEAVE = '请假',
}
/**
 * 监测任务状态枚举类
 */
export enum MonitorTaskStatusEnum {
  NOT_START = 'NOT_START',
  PROGRESS = 'PROGRESS',
  END = 'END',
}

/**
 * 学生变更状态
 */
export enum ChangeStatusEnum {
  CONFIRMED = 'CONFIRMED',
  REJECTED = 'REJECTED',
  CHANGE_UNAUDITED = 'CHANGE_UNAUDITED',
  SCHOOL_UNAUDITED = 'SCHOOL_UNAUDITED',
  CREATE_UNAUDITED = 'CREATE_UNAUDITED',
  UNCONFIRMED = 'UNCONFIRMED',
  BUREAU_UNAUDITED = 'BUREAU_UNAUDITED',
}

export enum ChangeStatusEnumValue {
  CONFIRMED = '已确认',
  REJECTED = '已拒绝',
  CHANGE_UNAUDITED = '信息变更待审核',
  SCHOOL_UNAUDITED = '待接收学校审核',
  CREATE_UNAUDITED = '新增待审核',
  UNCONFIRMED = '待确认',
  BUREAU_UNAUDITED = '待局端审核',
}

/**
 * 学段
 */
export enum PeriodEnum {
  PRIMARY_SCHOOL = 'PRIMARY_SCHOOL',
  MIDDLE_SCHOOL = 'MIDDLE_SCHOOL',
}

export enum PeriodEnumValue {
  PRIMARY_SCHOOL = '小学',
  MIDDLE_SCHOOL = '初中',
}

export enum GradeEnum {
  FIRST_GRADE = 'FIRST_GRADE',
  SECOND_GRADE = 'SECOND_GRADE',
  THIRD_GRADE = 'THIRD_GRADE',
  FOURTH_GRADE = 'FOURTH_GRADE',
  FIFTH_GRADE = 'FIFTH_GRADE',
  SIXTH_GRADE = 'SIXTH_GRADE',
  SEVENTH_GRADE = 'SEVENTH_GRADE',
  EIGHTH_GRADE = 'EIGHTH_GRADE',
  NINE_GRADE = 'NINE_GRADE',
}

export enum GradeEnumValue {
  FIRST_GRADE = '一年级',
  SECOND_GRADE = '二年级',
  THIRD_GRADE = '三年级',
  FOURTH_GRADE = '四年级',
  FIFTH_GRADE = '五年级',
  SIXTH_GRADE = '六年级',
  SEVENTH_GRADE = '七年级',
  EIGHTH_GRADE = '八年级',
  NINE_GRADE = '九年级',
}

/**
 * 学科枚举
 */
export enum SubjectEnum {
  CHINESE = 'CHINESE',
  MATHEMATICS = 'MATHEMATICS',
  ENGLISH = 'ENGLISH',
  PHYSICAL = 'PHYSICAL',
  MUSIC = 'MUSIC',
  ART = 'ART',
  SCIENCE = 'SCIENCE',
  SOCIOLOGY = 'SOCIOLOGY',
}
export enum SubjectEnumValue {
  CHINESE = '语文',
  MATHEMATICS = '数学',
  ENGLISH = '英语',
  PHYSICAL = '体育',
  MUSIC = '音乐',
  ART = '美术',
  SCIENCE = '科学',
  SOCIOLOGY = '社会',
}

/**
 * 抽取单位枚举
 */
export enum UnitEnum {
  INDIVIDUAL = 'INDIVIDUAL',
  PERCENTAGE = 'PERCENTAGE',
}

/**
 * 性别枚举
 */
export enum SexEnum {
  MAN = 'MAN',
  WOMAN = 'WOMAN',
}

/**
 * 抽取机制
 */
export enum ExtractMechanismEnum {
  DUP = 'DUP',
  NOT_DUP = 'NOT_DUP',
}

/**
 * 发放依据枚举
 */
export enum GrantByEnum {
  APPLICATION_NUM = 'APPLICATION_NUM',
  PASS_NUM = 'PASS_NUM',
}

/**
 * 监测任务机构状态
 */
export enum TaskOrgStatusEnum {
  UNCONFIRMED = 'UNCONFIRMED',
  CONFIRMED = 'CONFIRMED',
  UNCHANGED = 'UNCHANGED',
}
export enum TaskOrgStatusEnumValue {
  UNCONFIRMED = '待确认',
  CONFIRMED = '已确认',
  UNCHANGED = '未变更',
}

export default {};
