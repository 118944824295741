import vsf, { definePage, renderRoutes } from '@vs/vsf-boot';
import { Dropdown, Input, Layout, message, Page } from '@vs/vsf-kit';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import PeopleIcon from '@/assets/images/icon/people_icon.png';
import { BreadCrumb } from '@/components';
import {
  ScoreAuditStatusEnumBackgroundColor,
  ScoreAuditStatusEnumColor,
  ScoreAuditStatusEnumTextColor,
  ScoreAuditStatusEnumValue,
} from '@/config';
import { navigateToWithPlatform, usePlatformInfo } from '@/utils';

import DepartmentGradesCheckTable from './DepartmentGradesCheckTable';

const { Sider } = Layout;

const ScoreAuditListItemView = styled.div`
  width: 100%;
  height: 40px;
  border-radius: 6px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  padding-left: 11px;
  position: relative;
  margin-bottom: 14px;
  font-size: 14px;
  overflow: hidden;
  transition: all 0.2s linear;
`;

const ScoreAuditListItemViewStatus = styled.div`
  width: 90px;
  display: flex;
  align-items: center;
  display: flex;
  align-items: center;
  font-size: 9px;
  font-weight: 500;
  position: absolute;
  right: -33px;
  top: 10px;
  transform: rotate(37deg);
  text-indent: 19px;
`;

const DepartmentGradesCheck = (props) => {
  const [scoreAuditList, setScoreAuditList] = useState();
  const [activeSchool, setActiveSchool] = useState();
  const [platformInfo] = usePlatformInfo();

  const {
    routes: {
      params: { scoreTaskId },
    },
  } = props;

  const loadScoreAudit = useCallback(
    async (isRefresh = false) => {
      try {
        const response =
          await vsf.services?.ScoreAuditPubDtoController_getScoreAudit_fe74f7?.(
            {
              scoreTaskId,
            },
          );
        if (response && response.code === 200) {
          if (!isRefresh) {
            setActiveSchool(response.data?.[0]);
          }
          setScoreAuditList(response.data);
          return;
        }
        setScoreAuditList([]);
      } catch (error) {
        console.error(error);
      }
    },
    [scoreTaskId],
  );

  useEffect(() => {
    loadScoreAudit();
  }, [loadScoreAudit]);

  return (
    <>
      <BreadCrumb
        names={['成绩管理', '成绩审核']}
        onBack={() =>
          navigateToWithPlatform(`/${platformInfo?.platformId}/grades`)
        }
      />

      <div className="vsf-layout-sec">
        <Sider
          className="vsf-layout-sec-sider"
          theme="light"
          collapsible
          width={240}
        >
          <div className="vsf-layout-sec-sider-header aic">
            <img
              src={PeopleIcon}
              className="vsf-layout-sec-sider-header-icon"
            />
            <span>成绩审核</span>
          </div>
          <div style={{ height: 20 }} />
          {(scoreAuditList ?? []).map((item) => (
            <ScoreAuditListItemView
              key={item.id}
              style={
                activeSchool.id === item?.id
                  ? {
                      background: '#F3F6FF',
                      color: '#2E5EF2',
                      fontWeight: 'bold',
                    }
                  : { color: '#333333', background: '#FAFAFA' }
              }
              onClick={() => setActiveSchool(item)}
            >
              <div style={{ width: '90%' }} className="ell">
                {item?.org?.orgName}
              </div>
              <ScoreAuditListItemViewStatus
                style={{
                  background:
                    ScoreAuditStatusEnumBackgroundColor[item.auditStatus],
                  color: ScoreAuditStatusEnumTextColor[item.auditStatus],
                }}
              >
                {ScoreAuditStatusEnumValue[item.auditStatus]}
              </ScoreAuditListItemViewStatus>
            </ScoreAuditListItemView>
          ))}
        </Sider>
        <div className="vsf-layout-sec-main">
          <Page>
            <DepartmentGradesCheckTable
              scoreTaskIdIs={scoreTaskId}
              activeSchool={activeSchool}
              onRefresh={() => {
                loadScoreAudit(true);
              }}
            />
          </Page>
          {/* {renderRoutes(route.routes, route.redirect)} */}
        </div>
      </div>
    </>
  );
};

export default definePage(DepartmentGradesCheck);
