import vsf, { definePage } from '@vs/vsf-boot';
import {
  Col,
  RadioGroup,
  Row,
  Section,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';
import { useRef } from 'react';

import getOssPolicy from '@/utils/getOssPolicy';

const SchoolMonitorInfoCheckChangeForm = (props) => {
  const [type, setType] = useState('delete');
  const [orgList, setOrgList] = useState([]);
  const formData = useRef();

  const { taskIdIs, grade } = props;

  const loadOrgList = useCallback(async () => {
    try {
      const response =
        await vsf?.services?.TaskOrgDtoController_queryAllByTaskOrgList_788d4b?.(
          {
            qto: {
              taskIdIs,
            },
          },
        );
      if (response && response.code === 200) {
        setOrgList(
          (response?.data?.result ?? []).map((item) => ({
            label: item.org?.orgName ?? '',
            value: item?.id,
          })),
        );
      }
    } catch (error) {
      console.error(error);
    }
  }, [taskIdIs]);

  useEffect(() => {
    loadOrgList();
  }, [loadOrgList]);

  const fakeItem = (title, children) => {
    return (
      <Row style={{ marginBottom: 24, minHeight: 32 }}>
        <Col span={4} style={{ display: 'flex', justifyContent: 'end' }}>
          {`${title}：`}
        </Col>
        <Col>{children}</Col>
      </Row>
    );
  };

  return (
    <Section title="申请变更">
      <div className="custom_ant_upload">
        {fakeItem(
          '添加方式',
          <RadioGroup
            dataSource={[
              { label: '申请删除', value: 'delete' },
              { label: '申请调整', value: 'change' },
            ]}
            value={type}
            onChange={(_value) => {
              setType(_value);
              props?.onChange?.({
                ...formData.current,
                type: _value,
              });
              props?.onTypeChange?.(_value);
            }}
          />,
        )}
        <VSControlledForm
          vsid="72789"
          id="SchoolMonitorInfoCheckChangeForm"
          value={{
            ...props.value,
            changeGrade: grade,
          }}
          onChange={(_value) => {
            formData.current = _value;
            props?.onChange?.({
              ..._value,
              type,
            });
          }}
        >
          {type === 'delete' ? (
            <VSFormLayout key="0" columnCount={1}>
              <div className="antd-formitem-contnent-border">
                <VSFormItem
                  name={['applicationReason']}
                  label="删除原因"
                  valueType="textarea"
                  required={false}
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  fieldProps={{
                    style: {
                      minHeight: 100,
                    },
                    maxLength: 256,
                  }}
                />
              </div>

              <VSFormItem
                name={['annexAddress']}
                label="附件地址"
                valueType="fileUpload"
                rules={[]}
                convertValue={(v) => {
                  try {
                    if (typeof v === 'string') {
                      const _v = JSON.parse(v);
                      return _v?.map((vv) => {
                        if (typeof vv === 'string') {
                          return { url: vv };
                        }
                        return vv;
                      });
                    }
                    if (!v) {
                      return v;
                    }
                    return v?.map((vv) => {
                      if (typeof vv === 'string') {
                        return { url: vv };
                      }
                      return vv;
                    });
                  } catch (error) {
                    console.log(error);
                  }
                }}
                transform={(v) => {
                  if (typeof v === 'string') {
                    return {
                      annexAddress: v,
                    };
                  }
                  return {
                    annexAddress: JSON.stringify(
                      v?.map((vv) => {
                        return vv.url;
                      }),
                    ),
                  };
                }}
                fieldProps={{
                  type: 'picture',
                  getOssPolicy: getOssPolicy,
                  uploadProps: {
                    accept: '.jpg,.png,.jpeg,.pdf',
                    listType: 'picture',
                    className: 'upload-list-inline',
                  },
                }}
              />
            </VSFormLayout>
          ) : (
            <VSFormLayout key="0" columnCount={1}>
              <VSFormItem
                name={['changeTaskOrgId']}
                label="学校调整为"
                valueType="select"
                required={false}
                rules={[
                  {
                    required: true,
                  },
                  {
                    message: '原机构id的值不合法',
                    type: 'number',
                    min: 0,
                    max: null,
                  },
                ]}
                dataSource={orgList}
                fieldProps={{}}
              />

              <VSFormItem
                name={['changeGrade']}
                label="年级调整为"
                valueType="select"
                readonly
                dataSource={[
                  { label: '一年级', value: 'FIRST_GRADE' },
                  { label: '二年级', value: 'SECOND_GRADE' },
                  { label: '三年级', value: 'THIRD_GRADE' },
                  { label: '四年级', value: 'FOURTH_GRADE' },
                  { label: '五年级', value: 'FIFTH_GRADE' },
                  { label: '六年级', value: 'SIXTH_GRADE' },
                  { label: '七年级', value: 'SEVENTH_GRADE' },
                  { label: '八年级', value: 'EIGHTH_GRADE' },
                  { label: '九年级', value: 'NINE_GRADE' },
                ]}
                fieldProps={{}}
              />

              <VSFormItem
                name={['changeClasses']}
                label="班级调整为"
                valueType="text"
                // rules={[
                //   {
                //     required: true,
                //   },
                //   {
                //     message: '变更班级长度不合法',
                //     type: 'string',
                //     min: 0,
                //     max: 64,
                //   },
                // ]}
                fieldProps={{}}
              />
            </VSFormLayout>
          )}
        </VSControlledForm>
      </div>
    </Section>
  );
};
export default definePage(SchoolMonitorInfoCheckChangeForm);
