import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';
const ReferenceGradesTable = (props) => {
  const { scoreMenuIdIs, subject } = props;
  const [selectedRow, setSelectedRow] = useState();

  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf?.services?.ScoreTaskDtoController_pageScoreTaskBySubject_835f98?.(
          {
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 8,
              orderList: params.orderList, // 排序结果
              scoreMenuIdIs,
              subjectIs: subject,
              ...params.search, // 搜索结果
              ...params.filters, // 过滤器结果
              ...(params?.extra ?? {}), // 传入的额外参数
            },
            ext: params?.ext,
          },
        );
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [subject, scoreMenuIdIs],
  );

  const handelUseClick = (_, record) => {
    setSelectedRow(record);
    props?.onUseGrade?.(record);
  };

  return (
    <Section>
      <VSPromiseTable
        editable={{
          editType: 'single',
          columnProps: {
            hideInTable: true,
          },
        }}
        pagination={{
          defaultPageSize: 8,
          showTotal: () => <></>,
        }}
        onFetch={onFetch}
        rowSelection={{
          hideColumn: true,
          selectType: 'single',
          selectedRows: selectedRow ? [selectedRow] : [],
          onSelectedRowsChange: (rows) => setSelectedRow(rows?.[0]),
        }}
      >
        <VSTableColumn
          dataIndex={['name']}
          title="监测名称"
          ellipsis
          valueType="text"
          formItemProps={{
            rules: [
              { message: '名称长度不合法', type: 'string', min: 0, max: 64 },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['year']}
          title="学年"
          valueType="text"
          formItemProps={{
            rules: [
              { message: '学年长度不合法', type: 'string', min: 0, max: 64 },
            ],
          }}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['period']}
          title="学段"
          valueType="select"
          dataSource={[
            { label: '小学', value: 'PRIMARY_SCHOOL' },
            { label: '初中', value: 'MIDDLE_SCHOOL' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['grade']}
          title="年级"
          valueType="select"
          dataSource={[
            { label: '一年级', value: 'FIRST_GRADE' },
            { label: '二年级', value: 'SECOND_GRADE' },
            { label: '三年级', value: 'THIRD_GRADE' },
            { label: '四年级', value: 'FOURTH_GRADE' },
            { label: '五年级', value: 'FIFTH_GRADE' },
            { label: '六年级', value: 'SIXTH_GRADE' },
            { label: '七年级', value: 'SEVENTH_GRADE' },
            { label: '八年级', value: 'EIGHTH_GRADE' },
            { label: '九年级', value: 'NINE_GRADE' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['subject']}
          title="学科"
          valueType="select"
          dataSource={[
            { label: '语文', value: 'CHINESE' },
            { label: '数学', value: 'MATHEMATICS' },
            { label: '英语', value: 'ENGLISH' },
            { label: '体育', value: 'PHYSICAL' },
            { label: '音乐', value: 'MUSIC' },
            { label: '美术', value: 'ART' },
            { label: '科学', value: 'SCIENCE' },
            { label: '社会', value: 'SOCIOLOGY' },
          ]}
          fieldProps={{}}
        />
        <VSTableColumn
          dataIndex={['createdAt']}
          title="创建时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />
        <VSTableColumn title="操作" name={'action'} valueType="option">
          <VSTableAction onClick={handelUseClick}>选择</VSTableAction>
        </VSTableColumn>
      </VSPromiseTable>
    </Section>
  );
};
export default definePage(ReferenceGradesTable);
