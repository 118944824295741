import vsf, { definePage } from '@vs/vsf-boot';
import { message, Modal, Page, RadioGroup } from '@vs/vsf-kit';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import { GrantByEnum, SubjectEnumValue } from '@/config';

import DepartmentMonitorReplacementReleaseTable from './DepartmentMonitorReplacementReleaseTable';
import DepartmentMonitorSampleStudentReplacementTable from './DepartmentMonitorSampleStudentReplacementTable';
import ChinaNum from './RandomSampleList/ChinaNum';

const SubjectTabsView = styled.div`
  width: 100%;
  height: 56px;
  border-bottom: 1px solid #eeeeee;
`;

const SubjectTabsViewItem = styled.div`
  min-width: 94px;
  height: 22px;
  font-size: 16px;
  font-weight: bold;
  color: #2e5ef2;
  position: relative;
  cursor: pointer;
  z-index: 99;
  margin-right: 30px;
`;

const SubjectTabsViewItemLine = styled.div`
  width: 94px;
  height: 6px;
  background: linear-gradient(90deg, #ffc736 0%, #ffffff 100%);
  position: absolute;
  left: 0;
  bottom: 0;
  z-index: -1;
`;

const DepartmentMonitorReplacementReleaseModal = (props) => {
  const { taskIdIs, onDataChange } = props;

  const [monitorTask, setMonitorTask] = useState();
  const [activeId, setActiveId] = useState();

  const loadMonitorTask = useCallback(async () => {
    try {
      const response =
        await vsf?.services?.ExtractConfigDtoController_getByMonitorTaskId_553a8d?.(
          {
            monitorTaskId: taskIdIs,
          },
        );
      if (response.data) {
        setMonitorTask(response.data);
        setActiveId(response.data?.extractRoundDtoList?.[0]?.id);
      }
    } catch (error) {
      console.error(error);
    }
  }, [taskIdIs]);

  useEffect(() => {
    loadMonitorTask();
  }, [loadMonitorTask]);

  return (
    <div>
      <div className="aic">
        {monitorTask?.extractRoundDtoList && (
          <SubjectTabsView className="aic">
            {monitorTask?.extractRoundDtoList?.map((item, _idx) => (
              <SubjectTabsViewItem
                key={item.id}
                style={{
                  color: item?.id === activeId ? '#2E5EF2' : '#666666',
                }}
                onClick={() => setActiveId(item?.id)}
              >
                <span>
                  第{ChinaNum[_idx]}轮（{SubjectEnumValue[item?.subject]}）
                </span>
                {item?.id === activeId && <SubjectTabsViewItemLine />}
              </SubjectTabsViewItem>
            ))}
          </SubjectTabsView>
        )}
      </div>
      <DepartmentMonitorReplacementReleaseTable
        isTable
        onDataChange={onDataChange}
        subject={
          monitorTask?.extractRoundDtoList?.find((item) => item.id === activeId)
            ?.subject
        }
        extractRoundIdIs={activeId}
        distinguishSex={monitorTask?.distinguishSex}
      />
    </div>
  );
};

export default definePage(DepartmentMonitorReplacementReleaseModal);
