import vsf, { definePage } from '@vs/vsf-boot';
import {
  DatePicker,
  message,
  Page,
  Section,
  TimePicker,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import { Col, Modal, Row } from 'antd';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';

import DownloadIcon from '@/assets/images/icon/download_icon.png';
import SettingIcon from '@/assets/images/icon/setting_orange_icon.png';
import FileUpload from '@/components/FileUpload';
import { SubjectEnumValue } from '@/config';
import ChinaNum from '@/pages/RandomSampleList/ChinaNum';
import { navigateToWithPlatform, usePlatformInfo } from '@/utils';

const TabsView = styled.div`
  width: 100%;
  height: 54px;
  border-top: 1px solid #eeeeee;
`;

const TabsViewItem = styled.div`
  font-size: 16px;
  font-weight: bold;
  margin-right: 30px;
  position: relative;
  cursor: pointer;
`;

const TabsViewItemLine = styled.div`
  width: 94px;
  height: 6px;
  background: linear-gradient(90deg, #ffc736 0%, #ffffff 100%);
  position: absolute;
  left: 0;
  bottom: 0;
`;

const RightExtra = styled.div`
  width: 90px;
  font-size: 14px;
  font-weight: bold;
  color: #2e5ef2;
  display: flex;
  align-items: center;
  cursor: pointer;

  .download_icon {
    width: 16px;
    height: 16px;
    margin-right: 4px;
  }
`;

const SettingIconImg = styled.img`
  width: 16px;
  height: 16px;
  margin-right: 6px;
`;
const AddStudentText = styled.div`
  font-size: 14px;
  font-weight: 500;
  color: #ff3d11;
`;

const CancelText = styled.div`
  width: 29px;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #2e5ef2;
  margin-right: 28px;
  cursor: pointer;
`;

const PublishButton = styled.div`
  width: 84px;
  height: 36px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #2e5ef2;
  font-size: 14px;
  font-weight: 500;
  color: #2e5ef2;
  cursor: pointer;
`;

const AllManageButton = styled.div`
  width: 84px;
  height: 36px;
  background: #2e5ef2;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  color: #ffffff;
  cursor: pointer;
`;

const TimeTextView = styled.div`
  width: 87px;
  height: 20px;
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  position: relative;
`;

const TimeTextViewLine = styled.div`
  width: 86px;
  height: 6px;
  background: linear-gradient(90deg, #ffc736 0%, #ffffff 100%);
  position: absolute;
  left: 0;
  bottom: 0;
`;

const ModalItemView = styled.div`
  width: 452px;
  height: 180px;
  background: #fafafa;
  border-radius: 6px;
  border: 1px solid #eeeeee;
  box-sizing: border-box;
  padding: 16px;
  margin-bottom: 20px;
`;

const ModalItemViewTitle = styled.div`
  font-size: 16px;
  font-weight: 500;
  color: #333333;
`;

const ModalItemViewTitleAic = styled.div`
  width: 6px;
  height: 6px;
  background: #2e5ef2;
  margin-right: 8px;
  border-radius: 50%;
`;

const subjects = [
  { name: '语文', subject: 'CHINESE' },
  { name: '数学', subject: 'MATHEMATICS' },
  { name: '英语', subject: 'ENGLISH' },
  { name: '体育', subject: 'PHYSICAL' },
  { name: '音乐', subject: 'MUSIC' },
  { name: '美术', subject: 'ART' },
  { name: '科学', subject: 'SCIENCE' },
  { name: '社会', subject: 'SOCIOLOGY' },
];

const DepartmentMonitorSampleSchool = (props) => {
  const {
    routes: {
      params: { monitorTaskId },
      query: { period, grade, monitorTaskStatus },
    },
  } = props;
  const [monitorTask, setMonitorTask] = useState();
  const [extractRoundIdIs, setExtractRoundIdIs] = useState();
  const [subject, setSubject] = useState();
  const [isManage, setIsManage] = useState(false);
  const [date, setDate] = useState();
  const [time, setTime] = useState();
  const [refresh, setRefresh] = useState(false);
  const [data, setData] = useState([]);

  const [publishModalVisible, setPublishModalVisible] = useState(false);

  const [platformInfo] = usePlatformInfo();

  const loadByMonitorTask = useCallback(async () => {
    try {
      const response =
        await vsf?.services?.ExtractConfigDtoController_getByMonitorTaskId_553a8d?.(
          {
            monitorTaskId,
          },
        );
      if (response.data && response.data?.extractRoundDtoList?.[0]) {
        setSubject(
          response.data && response.data?.extractRoundDtoList?.[0]?.subject,
        );
        setExtractRoundIdIs(
          response.data && response.data?.extractRoundDtoList?.[0]?.id,
        );
      }
      setMonitorTask(response.data);
    } catch (error) {
      console.error(error);
    }
  }, [monitorTaskId]);

  useEffect(() => {
    loadByMonitorTask();
  }, [loadByMonitorTask]);

  const fromData = (data) => {
    return data?.map((row) => {
      const { extractRosterOpenDtoList, ...rest } = row;
      const map = extractRosterOpenDtoList?.reduce((map, item) => {
        const { sex, level, ...other } = item;
        const key = sex || 'ALL';
        const sexData = map[key];
        if (monitorTask?.extractType === 'STUDENT') {
          if (sexData) {
            sexData[level] = other;
          } else {
            map[key] = {
              [level]: other,
            };
          }
        } else {
          map[key] = {
            CLASSES: other,
          };
        }
        return map;
      }, {});
      return {
        ...rest,
        ...map,
      };
    });
  };

  const onFetch = useCallback(
    async (params) => {
      if (extractRoundIdIs) {
        const res =
          await vsf?.services?.TaskOrgOpenDtoController_pageTaskExtractRoster_265837?.(
            {
              qto: {
                from: params.pagination?.from ?? 0,
                size: params.pagination?.size ?? 20,
                orderList: params.orderList, // 排序结果
                taskIdIs: monitorTaskId,
                ...params.search, // 搜索结果
                ...params.filters, // 过滤器结果
                ...(params?.extra ?? {}), // 传入的额外参数
                refresh: undefined,
              },
              ext: params?.ext,
            },
          );
        const _data = fromData(res?.data?.result ?? res?.data ?? []);
        setData(_data);
        return {
          data: _data,
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [extractRoundIdIs, monitorTaskId],
  );

  const handelExtractRoundClick = (record) => {
    setExtractRoundIdIs(record?.id);
  };

  const handelDownloadClick = async () => {
    // 缺下载接口
    try {
      if (data?.length > 0) {
        const response =
          await vsf?.services?.TaskOrgOpenDtoController_getSampleSchool_20bd00?.(
            {
              taskExtractRosterOssQtoSimple: {
                extractRoundIdIs: extractRoundIdIs,
                taskIdIs: monitorTaskId,
              },
            },
          );
        if (response && response.code === 200) {
          FileUpload.download(
            response.data?.excelUrl,
            response?.data?.excelName,
          );
          message.success('已下载');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handelShowClick = () => {
    const _query = `period=${period}&grade=${grade}&monitorTaskStatus=${monitorTaskStatus}`;
    navigateToWithPlatform(
      `/${platformInfo?.platformId}/monitor/${monitorTaskId}/sampleStudent?${_query}`,
    );
  };

  const getClassesColumns = () => {
    return [
      <VSTableColumn
        width={70}
        key={`studentTotal`}
        dataIndex={['ALL', 'CLASSES', 'studentTotal']}
        title={`学生数`}
        valueType="digit"
        fieldProps={{
          min: 0,
        }}
        align="center"
        preview
        className={'man_th'}
      />,
      <VSTableColumn
        width={70}
        key={`extractTotal`}
        dataIndex={['ALL', 'CLASSES', 'extractTotal']}
        title={`抽样数`}
        valueType="digit"
        fieldProps={{
          min: 0,
        }}
        align="center"
        preview
        className={'man_th'}
      />,
      <VSTableColumn
        width={70}
        key={`extractPercentage`}
        dataIndex={['ALL', 'CLASSES', 'extractPercentage']}
        title={`占比`}
        valueType="digit"
        fieldProps={{
          min: 0,
        }}
        align="center"
        preview
        className={'man_th'}
      />,
    ];
  };

  const getDynamicColumns = () => {
    if (!monitorTask) {
      return [];
    }
    const subjectItem = subjects.find((s) => s.subject === subject);
    if (!subjectItem) {
      return [];
    }
    const distinguishSex = monitorTask.distinguishSex;
    const sexes = distinguishSex ? ['MAN', 'WOMAN'] : ['ALL'];
    const levels =
      subjectItem.subject === 'PHYSICAL'
        ? ['A', 'B', 'C']
        : ['A', 'B', 'C', 'D'];

    const sexData = sexes.reduce((data, sex) => {
      const subColumns = levels.reduce((columns, level) => {
        columns.push(
          <VSTableColumn
            width={140}
            key={`${sex}-${level}-studentTotal`}
            dataIndex={[sex, level, 'extractTotal']}
            title={`${subjectItem.name}${level}抽样数`}
            valueType="digit"
            align="center"
            className={sex === 'MAN' ? 'man_th' : 'woman_th'}
          />,
        );
        columns.push(
          <VSTableColumn
            width={70}
            key={`${sex}-${level}-confirmedTotal`}
            dataIndex={[sex, level, 'confirmedTotal']}
            title="确认数"
            valueType="text"
            align="center"
            className={sex === 'MAN' ? 'man_th' : 'woman_th'}
            render={(dom) => {
              return (
                <div
                  style={{
                    background: sex === 'MAN' ? '#EAEEFD' : '#FFEBE7',
                  }}
                  className="custom_table_cell_background aic jcc"
                >
                  {dom}
                </div>
              );
            }}
          />,
        );
        return columns;
      }, []);
      data[sex] = subColumns;
      return data;
    }, {});

    if (distinguishSex) {
      return [
        <VSTableColumn title="男生" key="MAN" className="man_th">
          {sexData.MAN}
        </VSTableColumn>,
        <VSTableColumn title="女生" key="WOMAN" className="woman_th">
          {sexData.WOMAN}
        </VSTableColumn>,
      ];
    }
    return sexData.ALL;
  };

  return (
    <Page>
      <Section>
        <VSPromiseTable
          pagination={{
            defaultPageSize: 10,
            showTotal: () => <></>,
          }}
          editable={{
            editType: 'single',
            onCanEdit: () => false,
            onCanDelete: () => false,
            extraOperations: [
              {
                order: -1,
                children: '查看学生',
                onClick: handelShowClick,
              },
            ],
            columnProps: {
              fixed: 'right',
              width: 78,
              // hideInTable: true,
            },
          }}
          vsid="88369"
          extraParams={{ extractRoundIdIs, refresh }}
          onFetch={onFetch}
          scroll={{
            x: 1000,
          }}
          searchConfig={{
            filterType: 'inline',
            optionButtons: 'search',
            searchText: '查询',
          }}
          tableExtraRender={() => {
            return monitorTask?.extractRoundDtoList?.length > 1 ? (
              <TabsView className="aic">
                {monitorTask?.extractRoundDtoList?.map((item, _idx) => (
                  <TabsViewItem
                    key={item.id}
                    className="aic"
                    style={{
                      color:
                        item.id === extractRoundIdIs ? '#2E5EF2' : '#666666',
                    }}
                    onClick={() => handelExtractRoundClick(item)}
                  >
                    <span style={{ position: 'relative', zIndex: 99 }}>
                      第{ChinaNum[_idx]}轮({SubjectEnumValue[item?.subject]})
                    </span>
                    <TabsViewItemLine />
                  </TabsViewItem>
                ))}
              </TabsView>
            ) : null;
          }}
          onUpdate={() => {
            console.log('1231231');
          }}
        >
          <VSTableColumn
            width={104}
            dataIndex={['org', 'id']}
            title="学校识别码"
            valueType="digit"
            fieldProps={{}}
          />
          <VSTableColumn
            width={164}
            dataIndex={['org', 'orgName']}
            title="学校名称"
            valueType="text"
            search
            order={-7}
            columnKey={'orgNameLike'}
            fieldProps={{
              width: 110,
              placeholder: '请输入',
            }}
          />
          {/* //TODO: 字段需变更 */}
          <VSTableColumn
            width={80}
            dataIndex={['extractTotal']}
            title="总抽样数"
            valueType="text"
            order={-7}
          />
          {/* //TODO: 字段需变更 */}
          <VSTableColumn
            width={80}
            dataIndex={['extractPercentage']}
            title="抽样占比"
            valueType="text"
            order={-7}
          />
          {monitorTask?.extractType === 'STUDENT'
            ? getDynamicColumns()
            : getClassesColumns()}
          {/* <VSTableColumn
            title="操作"
            name={'action'}
            valueType="option"
            width={80}
          >
            <VSTableAction onClick={handelShowClick}>查看学生</VSTableAction>
          </VSTableColumn> */}
        </VSPromiseTable>
      </Section>
    </Page>
  );
};
export default definePage(DepartmentMonitorSampleSchool);
