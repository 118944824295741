import DepartmentMonitorTaskListTableForm from '@pages/DepartmentMonitorTaskListTableForm';
import vsf, { definePage } from '@vs/vsf-boot';
import {
  message,
  Page,
  Section,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { MonitorTaskStatusEnum } from '@/config';
import { navigateToWithPlatform, usePlatformInfo } from '@/utils';

import ActionSplit from './ActionSplit';

const UnConfirmedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #fe9908;
  margin-right: 8px;
  border-radius: 50%;
`;

const PROGRESSAic = styled.div`
  width: 5px;
  height: 5px;
  background: #f2d05e;
  margin-right: 8px;
  border-radius: 50%;
`;

const Confirmed = styled.div`
  width: 5px;
  height: 5px;
  background: #aaaaaa;
  margin-right: 8px;
  border-radius: 50%;
`;
const DepartmentMonitorTaskListTable = (props) => {
  const { menuIdIs } = props;

  const [platformInfo] = usePlatformInfo();

  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf?.services?.MonitorTaskDtoController_pageMonitorTask_0d681d?.({
          qto: {
            from: params.pagination?.from ?? 0,
            size: params.pagination?.size ?? 20,
            orderList: params.orderList, // 排序结果
            menuIdIs,
            ...params.search, // 搜索结果
            ...params.filters, // 过滤器结果
            ...(params?.extra ?? {}), // 传入的额外参数
          },
          ext: params?.ext,
        });
      return {
        data: res?.data?.result ?? res?.data ?? [],
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [menuIdIs],
  );

  const handelMonitorManageClick = (_, record) => {
    console.log(record, '=====');
    navigateToWithPlatform(
      `/${platformInfo?.platformId}/monitor/${record?.id}/infoCheck?name=${record?.name}&period=${record?.period}&grade=${record?.grade}&createStatus=${record?.createStatus}`,
    );
  };

  const handelShowClick = (_, record) => {
    navigateToWithPlatform(
      `/${platformInfo?.platformId}/monitor/${record?.id}/infoCheck?name=${record?.name}&monitorTaskStatus=${record?.monitorTaskStatus}&createStatus=${record?.createStatus}`,
    );
  };

  return (
    <Page>
      <Section>
        <VSPromiseTable
          editable={{ editType: 'single', columnProps: { hideInTable: true } }}
          searchConfig={{
            optionButtons: 'search',
            filterType: 'inline',
            searchButtonProps: {
              children: '查询',
            },
            addition: {
              children: '新增',
              position: 'top',
            },
            extraAdditionButton: true,
          }}
          actionSpaceProps={{
            split: <ActionSplit />,
          }}
          pagination={{
            defaultPageSize: 9,
            showTotal: () => <></>,
          }}
          onFetch={onFetch}
          addLinkPage={{
            linkPage: () => <DepartmentMonitorTaskListTableForm />,
            onLinkPageValidate: async (params) => {
              try {
                await vsf.refs.DepartmentMonitorTaskListTableForm?.validateFieldsReturnFormatValue();
                const res =
                  await vsf?.services?.MonitorTaskBOController_createMonitorTask_47dafc?.(
                    {
                      btoParam: { ...params, menuId: menuIdIs },
                      extApiParam: params?.extApiParam,
                    },
                  );
                return res?.data;
              } catch (e) {
                return false;
              }
            },
            modalProps: { width: 500, title: '新增任务' },
          }}
        >
          <VSTableColumn
            dataIndex={['name']}
            title="监测名称"
            ellipsis
            valueType="text"
            search
            order={-1}
            columnKey={'nameLike'}
            formItemProps={{
              rules: [
                {
                  message: '监测名称长度不合法',
                  type: 'string',
                  min: 0,
                  max: 64,
                },
              ],
            }}
            fieldProps={{
              width: 110,
              placeholder: '请输入',
            }}
          />

          <VSTableColumn
            dataIndex={['year']}
            title="学年"
            valueType="text"
            formItemProps={{
              rules: [
                { message: '学年长度不合法', type: 'string', min: 0, max: 64 },
              ],
            }}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['period']}
            title="学段"
            valueType="select"
            dataSource={[
              { label: '小学', value: 'PRIMARY_SCHOOL' },
              { label: '初中', value: 'MIDDLE_SCHOOL' },
            ]}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['grade']}
            title="年级"
            valueType="select"
            dataSource={[
              { label: '一年级', value: 'FIRST_GRADE' },
              { label: '二年级', value: 'SECOND_GRADE' },
              { label: '三年级', value: 'THIRD_GRADE' },
              { label: '四年级', value: 'FOURTH_GRADE' },
              { label: '五年级', value: 'FIFTH_GRADE' },
              { label: '六年级', value: 'SIXTH_GRADE' },
              { label: '七年级', value: 'SEVENTH_GRADE' },
              { label: '八年级', value: 'EIGHTH_GRADE' },
              { label: '九年级', value: 'NINE_GRADE' },
            ]}
            fieldProps={{}}
          />

          <VSTableColumn
            dataIndex={['monitorTaskStatus']}
            title="状态"
            valueType="select"
            search
            order={-2}
            columnKey={'monitorTaskStatusIs'}
            dataSource={[
              { label: '待开始', value: 'NOT_START' },
              { label: '进行中', value: 'PROGRESS' },
              { label: '已结束', value: 'END' },
            ]}
            formItemProps={{
              label: '监测状态',
            }}
            fieldProps={{
              width: 110,
              placeholder: '请选择',
            }}
            render={(doms, record) => {
              return (
                <div className="aic">
                  {record?.monitorTaskStatus ===
                    MonitorTaskStatusEnum.NOT_START && <UnConfirmedAic />}
                  {record?.monitorTaskStatus ===
                    MonitorTaskStatusEnum.PROGRESS && <PROGRESSAic />}
                  {record?.monitorTaskStatus === MonitorTaskStatusEnum.END && (
                    <Confirmed />
                  )}
                  {doms}
                </div>
              );
            }}
          />

          <VSTableColumn
            width={170}
            dataIndex={['createdAt']}
            title="创建时间"
            valueType="date"
            fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
          />
          <VSTableColumn dataIndex={['action']} title="操作" valueType="option">
            <VSTableAction
              show={(record) =>
                record?.monitorTaskStatus === MonitorTaskStatusEnum.NOT_START
              }
              linkPage={{
                updateTable: true,
                updateType: 'reload',
                onLinkPageValidate: async (_, __, record) => {
                  try {
                    const response =
                      await vsf?.services?.MonitorTaskBOController_startMonitorTask_b804f7(
                        {
                          btoParam: {
                            id: record?.id,
                          },
                        },
                      );
                    if (response && response.code === 200) {
                      message.success('已开启');
                      return true;
                    }
                    return false;
                  } catch (error) {
                    console.error(error);
                  }
                  return false;
                },
              }}
            >
              开启
            </VSTableAction>
            <VSTableAction
              show={(record) =>
                record?.monitorTaskStatus === MonitorTaskStatusEnum.NOT_START
              }
              linkPage={{
                updateTable: true,
                linkPage: () => <DepartmentMonitorTaskListTableForm />,
                onLinkPageValidate: async (params) => {
                  try {
                    await vsf.refs.DepartmentMonitorTaskListTableForm?.validateFieldsReturnFormatValue();
                    const res =
                      await vsf?.services?.MonitorTaskBOController_updateMonitorTask_12ea30?.(
                        {
                          btoParam: { ...params, menuId: menuIdIs },
                          extApiParam: params?.extApiParam,
                        },
                      );
                    return res?.data;
                  } catch (e) {
                    return false;
                  }
                },
                modalProps: { width: 500, title: '修改任务' },
              }}
            >
              修改
            </VSTableAction>
            <VSTableAction
              show={(record) =>
                record?.monitorTaskStatus === MonitorTaskStatusEnum.PROGRESS
              }
              linkPage={{
                updateTable: true,
                updateType: 'reload',
                onLinkPageValidate: async (_, __, record) => {
                  try {
                    const response =
                      await vsf?.services?.MonitorTaskBOController_endMonitorTask_1b03c3(
                        {
                          btoParam: {
                            id: record?.id,
                          },
                        },
                      );
                    if (response && response.code === 200) {
                      message.success('已结束');
                      return true;
                    }
                    return false;
                  } catch (error) {
                    console.error(error);
                  }
                  return false;
                },
              }}
              popConfirm={{
                title: '确定结束该任务吗',
                icon: false,
              }}
            >
              结束
            </VSTableAction>
            <VSTableAction
              show={(record) =>
                record?.monitorTaskStatus === MonitorTaskStatusEnum.PROGRESS
              }
              onClick={handelMonitorManageClick}
            >
              抽样管理
            </VSTableAction>
            <VSTableAction
              show={(record) =>
                record?.monitorTaskStatus === MonitorTaskStatusEnum.END
              }
              onClick={handelShowClick}
            >
              查看
            </VSTableAction>
          </VSTableColumn>
        </VSPromiseTable>
      </Section>
    </Page>
  );
};
export default definePage(DepartmentMonitorTaskListTable);
