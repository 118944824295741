import vsf, { definePage } from '@vs/vsf-boot';
import { Page } from '@vs/vsf-kit';
import React from 'react';

import DepartmentMonitorInfoCheckTable from './DepartmentMonitorInfoCheckTable';

const DepartmentMonitorInfoCheck = (props) => {
  const {
    routes: {
      params: { taskIdIs },
      query: { monitorTaskStatus, period, grade, createStatus },
    },
  } = props;

  return (
    <Page>
      <DepartmentMonitorInfoCheckTable
        taskIdIs={taskIdIs}
        monitorTaskStatus={monitorTaskStatus}
        period={period}
        grade={grade}
        createStatus={createStatus}
      />
    </Page>
  );
};

export default definePage(DepartmentMonitorInfoCheck);
