import vsf, { definePage } from '@vs/vsf-boot';
import {
  Section,
  VSControlledForm,
  VSFormItem,
  VSFormLayout,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useState } from 'react';

const subjects = [
  { name: '语文', subject: 'CHINESE' },
  { name: '数学', subject: 'MATHEMATICS' },
  { name: '英语', subject: 'ENGLISH' },
  { name: '体育', subject: 'PHYSICAL' },
  { name: '音乐', subject: 'MUSIC' },
  { name: '美术', subject: 'ART' },
  { name: '科学', subject: 'SCIENCE' },
  { name: '社会', subject: 'SOCIOLOGY' },
];
const subjectMap = {};
subjects.forEach((subject) => {
  subjectMap[subject.subject] = subject;
});

import { labelColWidth } from '@/components/TreeMenu/utils';
import { PeriodEnum } from '@/config';

const DepartmentMonitorTaskListTableForm = (props) => {
  const { monitorTaskSubjectDtoList, ...rest } = props.value || {};
  const [periodState, setPeriodState] = useState();
  const _value = {
    ...rest,
    monitorTaskSubjectBtoList:
      rest.monitorTaskSubjectBtoList ?? monitorTaskSubjectDtoList,
  };
  return (
    <Section>
      <VSControlledForm
        id="DepartmentMonitorTaskListTableForm"
        vsid="01893"
        layout="horizontal"
        value={_value}
        onChange={(_value) => {
          props.onChange?.(_value);
        }}
      >
        <VSFormItem
          name={['id']}
          label="主键"
          style={{ display: 'none' }}
          valueType="digit"
        />
        <VSFormLayout key="0" columnCount={1} labelCol={labelColWidth[4]}>
          <VSFormItem
            name={['name']}
            label="监测名称"
            ellipsis
            required={false}
            valueType="text"
            rules={[
              {
                required: true,
              },
              {
                message: '监测名称长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ]}
            fieldProps={{}}
          />
          <VSFormItem
            name={['year']}
            label="学年"
            required={false}
            rules={[
              {
                required: true,
                message: '请选择学年',
              },
            ]}
            valueType="dateYear"
            transform={(v) => {
              return {
                year: dayjs(v).format('YYYY'),
              };
            }}
            // fieldProps={{
            //   mode: ['year', 'year'],
            // }}
            // rules={[
            //   { message: '学年长度不合法', type: 'string', min: 0, max: 64 },
            // ]}
          />

          <VSFormItem
            name={['period']}
            label="学段"
            required={false}
            rules={[
              {
                required: true,
                message: '请选择学段',
              },
            ]}
            valueType="select"
            dataSource={[
              { label: '小学', value: 'PRIMARY_SCHOOL' },
              { label: '初中', value: 'MIDDLE_SCHOOL' },
            ]}
            fieldProps={{
              onChange: (_period) => {
                setPeriodState(_period);
              },
            }}
          />

          <VSFormItem
            name={['grade']}
            label="年级"
            required={false}
            rules={[
              {
                required: true,
                message: '请选择年级',
              },
            ]}
            valueType="select"
            dataSource={
              periodState
                ? periodState === PeriodEnum.PRIMARY_SCHOOL
                  ? [
                      { label: '一年级', value: 'FIRST_GRADE' },
                      { label: '二年级', value: 'SECOND_GRADE' },
                      { label: '三年级', value: 'THIRD_GRADE' },
                      { label: '四年级', value: 'FOURTH_GRADE' },
                      { label: '五年级', value: 'FIFTH_GRADE' },
                      { label: '六年级', value: 'SIXTH_GRADE' },
                    ]
                  : [
                      { label: '七年级', value: 'SEVENTH_GRADE' },
                      { label: '八年级', value: 'EIGHTH_GRADE' },
                      { label: '九年级', value: 'NINE_GRADE' },
                    ]
                : []
            }
            fieldProps={{}}
          />

          <VSFormItem
            name={['monitorTaskSubjectBtoList']}
            label="学科"
            required={false}
            rules={[
              {
                required: true,
                message: '请选择学科',
              },
            ]}
            valueType="select"
            dataSource={subjects}
            fieldNames={{
              value: 'subject',
              label: 'name',
            }}
            fieldProps={{
              mode: 'multiple',
              placeholder: '请选择学科（可多选）',
            }}
            convertValue={(value) => {
              if (!Array.isArray(value)) {
                return value;
              }
              const ret = value.map((v) => ({
                ...v,
                name: subjectMap[v.subject]?.name,
              }));
              return ret;
            }}
          />
        </VSFormLayout>
      </VSControlledForm>
    </Section>
  );
};
export default definePage(DepartmentMonitorTaskListTableForm);
