import vsf, { definePage } from '@vs/vsf-boot';
import {
  Button,
  message,
  Modal,
  Section,
  VSPromiseTable,
  VSTableAction,
  VSTableColumn,
} from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import { ScoreAuditStatusEnum } from '@/config';
import {
  navigateToWithPlatform,
  usePlatformInfo,
  yearDataFormatter,
} from '@/utils';

import SchoolGradesSubmitForm from './SchoolGradesSubmitForm';

const SubmitButton = styled.div`
  width: 80px;
  height: 30px;
  background: #eaeefd;
  border-radius: 6px;
  border: 1px solid #c9d6ff;
  font-size: 14px;
  font-weight: 400;
  color: #2e5ef2;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const DefaultButton = styled.div`
  width: 80px;
  height: 30px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #dddddd;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RejectButton = styled.div`
  width: 80px;
  height: 30px;
  background: #ffebe7;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 400;
  color: #ff3d11;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const RejectResonView = styled.div`
  width: 452px;
  height: 160px;
  background: #fafafa;
  border-radius: 6px;
  border: 1px solid #eeeeee;
  overflow: scroll;

  font-size: 14px;
  font-weight: 400;
  color: #333333;
  box-sizing: border-box;
  padding: 8px 12px;
`;
const UnConfirmedAic = styled.div`
  width: 5px;
  height: 5px;
  background: #fe9908;
  margin-right: 8px;
  border-radius: 50%;
`;

const PROGRESSAic = styled.div`
  width: 5px;
  height: 5px;
  background: #f2d05e;
  margin-right: 8px;
  border-radius: 50%;
`;

const REJECTEDAic = styled.div`
  width: 5px;
  height: 5px;
  background: #ff381e;
  margin-right: 8px;
  border-radius: 50%;
`;

const Confirmed = styled.div`
  width: 5px;
  height: 5px;
  background: #aaaaaa;
  margin-right: 8px;
  border-radius: 50%;
`;
const SchoolGradesList = (props) => {
  const [rejectVisible, setRejectVisible] = useState(false);
  const [platformInfo] = usePlatformInfo();
  const [rejectReason, setRejectReson] = useState('');
  const [refresh, setRefresh] = useState(false);

  const onFetch = useCallback(
    async (params) => {
      if (platformInfo) {
        const res =
          await vsf?.services?.ScoreAuditSchoolDtoController_pageScoreAuditSchool_a1d7d6?.(
            {
              qto: {
                from: params.pagination?.from ?? 0,
                size: params.pagination?.size ?? 20,
                orderList: params.orderList, // 排序结果
                orgIdIs: platformInfo?.platformId,
                ...params.search, // 搜索结果
                ...params.filters, // 过滤器结果
                ...(params?.extra ?? {}), // 传入的额外参数
                refresh: undefined,
              },
              ext: params?.ext,
            },
          );
        return {
          data: res?.data?.result ?? res?.data ?? [],
          total: res?.data?.count ?? res?.data?.length ?? 0,
        };
      }
    },
    [platformInfo],
  );

  const handelShowClick = async (_, record) => {
    try {
      vsf?.stores?.user?.updateSchoolGradeBreadCrumb({
        show: true,
        label: record?.scoreTask?.name ?? '',
      });
      navigateToWithPlatform(
        `/${platformInfo?.platformId}/grades/gradesDetail/${record?.scoreTask?.id}?name=${record?.scoreTask?.name}`,
      );
    } catch (error) {
      console.error(error);
    }
  };

  const renderRejectReson = () => {
    return (
      <Modal
        open={rejectVisible}
        title="拒绝原因"
        footer={false}
        onCancel={() => setRejectVisible(false)}
        width={500}
      >
        <RejectResonView>{rejectReason ?? ''}</RejectResonView>
      </Modal>
    );
  };

  return (
    <Section>
      <VSPromiseTable
        editable={{
          editType: 'single',
          columnProps: {
            hideInTable: true,
          },
        }}
        searchConfig={{
          filterType: 'inline',
          optionButtons: 'search',
          searchText: '查询',
          // extraAdditionButton: true,
          // addition: {
          //   children: '新增',
          // },
        }}
        pagination={{
          defaultPageSize: 9,
          showTotal: () => <></>,
        }}
        extraParams={{ refresh }}
        onFetch={onFetch}
      >
        <VSTableColumn
          dataIndex={['scoreTask', 'name']}
          title="监测名称"
          ellipsis
          valueType="text"
          search
          order={-1}
          columnKey={'taskNameLike'}
          formItemProps={{
            rules: [
              { message: '名称长度不合法', type: 'string', min: 0, max: 64 },
            ],
          }}
          fieldProps={{
            placeholder: '请输入',
            width: 110,
          }}
        />
        <VSTableColumn
          dataIndex={['scoreTask', 'year']}
          title="学年"
          valueType="dateYear"
        />
        <VSTableColumn
          dataIndex={['scoreTask', 'period']}
          title="学段"
          valueType="select"
          dataSource={[
            { label: '小学', value: 'PRIMARY_SCHOOL' },
            { label: '初中', value: 'MIDDLE_SCHOOL' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['scoreTask', 'grade']}
          title="年级"
          valueType="select"
          search
          order={-2}
          columnKey={'gradeIs'}
          dataSource={[
            { label: '一年级', value: 'FIRST_GRADE' },
            { label: '二年级', value: 'SECOND_GRADE' },
            { label: '三年级', value: 'THIRD_GRADE' },
            { label: '四年级', value: 'FOURTH_GRADE' },
            { label: '五年级', value: 'FIFTH_GRADE' },
            { label: '六年级', value: 'SIXTH_GRADE' },
            { label: '七年级', value: 'SEVENTH_GRADE' },
            { label: '八年级', value: 'EIGHTH_GRADE' },
            { label: '九年级', value: 'NINE_GRADE' },
          ]}
          fieldProps={{
            placeholder: '请选择',
            width: 110,
          }}
        />

        <VSTableColumn
          dataIndex={['scoreTask', 'subject']}
          title="学科"
          valueType="select"
          dataSource={[
            { label: '语文', value: 'CHINESE' },
            { label: '数学', value: 'MATHEMATICS' },
            { label: '英语', value: 'ENGLISH' },
            { label: '体育', value: 'PHYSICAL' },
            { label: '音乐', value: 'MUSIC' },
            { label: '美术', value: 'ART' },
            { label: '科学', value: 'SCIENCE' },
            { label: '社会', value: 'SOCIOLOGY' },
          ]}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['auditStatus']}
          title="状态"
          valueType="select"
          search
          order={-1}
          columnKey={'auditStatusIs'}
          dataSource={[
            { label: '待审核', value: 'UNAUDITED' },
            { label: '已拒绝', value: 'REJECTED' },
            { label: '已通过', value: 'PASSED' },
            { label: '待提交', value: 'NOT_SUBMIT' },
          ]}
          formItemProps={{
            label: '监测状态',
          }}
          fieldProps={{
            placeholder: '请选择',
            width: 110,
          }}
          render={(doms, record) => {
            return (
              <div className="aic">
                {record?.auditStatus === ScoreAuditStatusEnum.UNAUDITED && (
                  <PROGRESSAic />
                )}
                {record?.auditStatus === ScoreAuditStatusEnum.REJECTED && (
                  <REJECTEDAic />
                )}
                {record?.auditStatus === ScoreAuditStatusEnum.NOT_SUBMIT && (
                  <UnConfirmedAic />
                )}
                {record?.auditStatus === ScoreAuditStatusEnum.PASSED && (
                  <Confirmed />
                )}
                {doms}
              </div>
            );
          }}
        />
        <VSTableColumn
          dataIndex={['createdAt']}
          title="创建时间"
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm' }}
          width={160}
        />
        <VSTableColumn
          dataIndex={['action']}
          title="操作"
          valueType="option"
          width={200}
        >
          <VSTableAction
            linkPage={{
              updateTable: true,
              updateType: 'reload',
              modalProps: {
                width: 500,
              },
              linkPage: () => <SchoolGradesSubmitForm />,
              onLinkPageValidate: async (params, _, record) => {
                try {
                  const response =
                    await vsf.services?.ScoreInfoBOController_submitScoreInfo_c2f086?.(
                      {
                        list: params.list,
                        scoreTaskId: record?.scoreTask?.id,
                        orgId: record?.orgId,
                      },
                    );
                  if (response && response.code === 200) {
                    return true;
                  }
                  return false;
                } catch (error) {
                  console.error(error);
                  return false;
                }
              },
            }}
            show={(record) => record?.auditStatus === 'NOT_SUBMIT'}
          >
            {(record) => <SubmitButton>提交</SubmitButton>}
          </VSTableAction>
          <VSTableAction show={(record) => record?.auditStatus === 'UNAUDITED'}>
            {(record) => (
              <div className="aic">
                <DefaultButton
                  onClick={() => {
                    vsf?.stores?.user?.updateSchoolGradeBreadCrumb({
                      show: true,
                      label: record?.scoreTask?.name ?? '',
                    });
                    navigateToWithPlatform(
                      `/${platformInfo?.platformId}/grades/gradesDetail/${record?.scoreTask?.id}?name=${record?.scoreTask?.name}`,
                    );
                  }}
                >
                  查看
                </DefaultButton>
                <div style={{ width: 14 }} />
                <DefaultButton
                  onClick={async () => {
                    try {
                      const response =
                        await vsf?.services?.ScoreAuditBOController_revokeScoreAudit_396d2f?.(
                          {
                            btoParam: {
                              id: record?.id,
                            },
                          },
                        );
                      if (response && response.code === 200) {
                        message.success('已撤回');
                        setRefresh(!refresh);
                        return true;
                      }
                      return false;
                    } catch (error) {
                      console.log(error);
                    }
                  }}
                >
                  撤回
                </DefaultButton>
              </div>
            )}
          </VSTableAction>
          <VSTableAction
            show={(record) => record?.auditStatus === 'REJECTED'}
            onClick={(_, record) => {
              setRejectReson(record?.rejectReason);
              setRejectVisible(true);
            }}
          >
            <RejectButton>已拒绝</RejectButton>
          </VSTableAction>
          <VSTableAction
            show={(record) => record?.auditStatus === 'REJECTED'}
            linkPage={{
              updateTable: true,
              updateType: 'reload',
              modalProps: {
                width: 500,
              },
              linkPage: () => <SchoolGradesSubmitForm />,
              onLinkPageValidate: async (params, _, record) => {
                try {
                  const response =
                    await vsf.services?.ScoreInfoBOController_dupSubmitScoreInfo_e5b03a?.(
                      {
                        list: params.list,
                        scoreTaskId: record?.scoreTask?.id,
                        orgId: record?.orgId,
                      },
                    );
                  if (response && response.code === 200) {
                    return true;
                  }
                  return false;
                } catch (error) {
                  console.error(error);
                  return false;
                }
              },
            }}
          >
            <SubmitButton>重新提交</SubmitButton>
          </VSTableAction>
          <VSTableAction
            show={(record) => record?.auditStatus === 'PASSED'}
            onClick={handelShowClick}
          >
            <DefaultButton>查看</DefaultButton>
          </VSTableAction>
        </VSTableColumn>
      </VSPromiseTable>
      {renderRejectReson()}
    </Section>
  );
};
export default definePage(SchoolGradesList);
