import vsf, { definePage } from '@vs/vsf-boot';
import { message, Section, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';
import styled from 'styled-components';

import FileUpload from '@/components/FileUpload';

const ExportButton = styled.div`
  width: 84px;
  height: 36px;
  background: #ffffff;
  border-radius: 6px;
  border: 1px solid #2e5ef2;
  font-size: 14px;
  font-weight: 500;
  color: #2e5ef2;
  cursor: pointer;
`;

const DepartmentMonitorFinalListTable = (props) => {
  const { taskIdIs, extractRoundIdIs } = props;
  const [data, setData] = useState();

  const onFetch = useCallback(
    async (params) => {
      const res =
        await vsf?.services?.SampleResultDtoController_pageBySampleResult_ab4e7d?.(
          {
            qto: {
              from: params.pagination?.from ?? 0,
              size: params.pagination?.size ?? 20,
              orderList: params.orderList, // 排序结果
              taskIdIs,
              extractRoundIdIs,
              ...params.search, // 搜索结果
              ...params.filters, // 过滤器结果
              ...(params?.extra ?? {}), // 传入的额外参数
            },
            ext: params?.ext,
          },
        );
      const _data = res?.data?.result ?? res?.data ?? [];
      setData(_data);
      return {
        data: _data,
        total: res?.data?.count ?? res?.data?.length ?? 0,
      };
    },
    [taskIdIs, extractRoundIdIs],
  );

  const handelExportClick = async () => {
    try {
      if (data?.length > 0) {
        const response =
          await vsf?.services?.SampleResultDtoController_getSampleResult_11277b?.(
            {
              extractRoundId: extractRoundIdIs,
              taskId: taskIdIs,
            },
          );
        if (response && response.code === 200) {
          FileUpload.download(
            response.data?.excelUrl,
            response?.data?.excelName,
          );
          message.success('导出成功');
        }
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="monitor__container">
      <Section>
        <VSPromiseTable
          pagination={{
            defaultPageSize: 10,
            showTotal: () => <></>,
          }}
          editable={{ editType: 'single', columnProps: { hideInTable: true } }}
          vsid="20509"
          searchConfig={{
            filterType: 'inline',
            collapsed: false,
            collapseRender: () => null,
            optionButtons: ['search'],
            searchText: '查询',
          }}
          searchFormRender={(_, doms) => {
            return (
              <div className="aic">
                {doms}
                <ExportButton
                  className="aic jcc"
                  onClick={handelExportClick}
                  style={{
                    marginTop: '-25px',
                    opacity: data?.length ? 1 : 0.5,
                  }}
                >
                  导出名单
                </ExportButton>
              </div>
            );
          }}
          onFetch={onFetch}
        >
          <VSTableColumn
            dataIndex={['orgId']}
            title="学校码"
            valueType="digit"
            formItemProps={{
              rules: [
                {
                  message: '机构id的值不合法',
                  type: 'number',
                  min: 0,
                  max: null,
                },
              ],
            }}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['orgName']}
            title="学校名称"
            valueType="text"
            search
            order={-13}
            columnKey={'orgNameLike'}
            formItemProps={{
              rules: [
                {
                  message: '机构名称长度不合法',
                  type: 'string',
                  min: 0,
                  max: 64,
                },
              ],
            }}
            fieldProps={{
              width: 140,
            }}
          />
          <VSTableColumn
            dataIndex={['classes']}
            title="班级"
            valueType="text"
            search
            order={-15}
            columnKey={'classesLike'}
            formItemProps={{
              rules: [
                { message: '班级长度不合法', type: 'string', min: 0, max: 64 },
              ],
            }}
            fieldProps={{
              width: 110,
            }}
          />
          <VSTableColumn
            dataIndex={['studentMonitorId']}
            title="学生监测号"
            valueType="digit"
            formItemProps={{
              rules: [
                {
                  message: '学生监测号的值不合法',
                  type: 'number',
                  min: 0,
                  max: null,
                },
              ],
            }}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['name']}
            title="姓名"
            valueType="text"
            search
            order={-14}
            columnKey={'nameLike'}
            formItemProps={{
              rules: [
                {
                  message: '学生姓名长度不合法',
                  type: 'string',
                  min: 0,
                  max: 64,
                },
              ],
            }}
            fieldProps={{ width: 110 }}
          />
          <VSTableColumn
            dataIndex={['sex']}
            title="性别"
            valueType="select"
            search
            order={-16}
            columnKey={'sexIs'}
            dataSource={[
              { label: '男', value: 'MAN' },
              { label: '女', value: 'WOMAN' },
            ]}
            fieldProps={{
              width: 110,
            }}
          />
          <VSTableColumn
            dataIndex={['studentStatusNum']}
            title="全国学籍号"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '学籍辅号长度不合法',
                  type: 'string',
                  min: 0,
                  max: 64,
                },
              ],
            }}
            fieldProps={{}}
          />
          <VSTableColumn
            dataIndex={['idCard']}
            title="证件号"
            valueType="text"
            formItemProps={{
              rules: [
                {
                  message: '数据格式错误',
                  type: 'string',
                  pattern:
                    /^[1-9]\d{5}(18|19|20|(3\d))\d{2}((0[1-9])|(1[0-2]))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$/,
                },
              ],
            }}
            fieldProps={{}}
          />
        </VSPromiseTable>
      </Section>
    </div>
  );
};
export default definePage(DepartmentMonitorFinalListTable);
