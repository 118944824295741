import DepartmentAccountList from '@pages/DepartmentAccountList';
import DepartmentOrgList from '@pages/DepartmentOrgList';
import vsf, { definePage } from '@vs/vsf-boot';
import { Page, TabPane, Tabs } from '@vs/vsf-kit';
import React, { useState } from 'react';
const DepartmentPriviledge = (props) => {
  /**
   * 当前Tab
   * @type {string}
   */
  const [tab, setTab] = useState('user');
  return (
    <Page title="">
      <Tabs
        activeKey={tab}
        onChange={(_value) => {
          setTab(_value);
        }}
      >
        <TabPane tabKey="1" tab="账号管理" key="user">
          <DepartmentAccountList />
        </TabPane>
        <TabPane tabKey="2" tab="下属机构" key="org">
          <DepartmentOrgList />
        </TabPane>
      </Tabs>
    </Page>
  );
};
export default definePage(DepartmentPriviledge);
