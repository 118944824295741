import { Breadcrumb as AntBreadcrumb } from '@vs/vsf-kit';
import React from 'react';

const { Item, Separator } = AntBreadcrumb;

export type BreadCrumbProps = {
  onBack?: () => void;
  names?: string[];
};

const BreadCrumb: React.FC<BreadCrumbProps> = (props) => {
  const { onBack, names } = props;
  return (
    <div className="vsf-layout-breadcrumb">
      <Item separator="|" onClick={onBack}>
        {'< 返回'}
      </Item>
      <AntBreadcrumb separator=">">
        {names?.map((name) => {
          if (!name) {
            return <></>;
          }
          return <Item key={name}>{name}</Item>;
        })}
      </AntBreadcrumb>
    </div>
  );
};

export default BreadCrumb;
