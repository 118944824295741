import vsf from '@vs/vsf-boot';
import { message } from '@vs/vsf-kit';
import dayjs from 'dayjs';
import React, { useEffect, useState } from 'react';

import { PLATFORM } from '@/config';
// source from https://www.npmjs.com/package/use-force-update
export function useForceUpdate(): () => void {
  const [, dispatch] = React.useState<Record<string, any>>(Object.create(null));

  // Turn dispatch(required_parameter) into dispatch().
  const memoizedDispatch = React.useCallback((): void => {
    dispatch(Object.create(null));
  }, [dispatch]);
  return memoizedDispatch;
}

export const useHorizontalScroll = (ref) => {
  useEffect(() => {
    const handleWheel = (event) => {
      if (ref) {
        ref.current.scrollLeft += event.deltaY;
      }
    };

    ref?.current?.addEventListener('wheel', handleWheel);
  }, [ref]);
};

export const navigateToWithPlatform = (path) => {
  try {
    const platformInfo = JSON.parse(localStorage.getItem('platformInfo') ?? '');
    if (!platformInfo?.platform) {
      vsf.navigateTo(`/useInstitution`);
    }
    if (platformInfo?.platform === 'school' && !platformInfo?.platformId) {
      vsf.navigateTo(`/useInstitution`);
    }
    vsf.navigateTo(`/${PLATFORM[platformInfo?.platform]}${path}`);
  } catch (error) {
    console.error(error);
    message.error('机构ID不存在');
    vsf.navigateTo(`/useInstitution`);
  }
};

/**
 * 学年格式化
 */
export const yearDataFormatter = (input?: number | string) => {
  if (!input) {
    return null;
  }
  const year = typeof input === 'number' ? input : Number(input);
  return yearFormatter(dayjs(year));
};

export const yearFormatter = (year?: dayjs.Dayjs) => {
  if (!year) {
    return null;
  }
  const nextYear = year.add(1, 'year');
  return `${year.format('YYYY')}～${nextYear.format('YYYY')}`;
};
// excel日期解析
export const birdayFormatter = (value) => {
  if (!value) {
    return '-';
  }
  const baseDate = new Date(1900, 0, 1); // 基准日期为1900年1月1日
  const date = new Date(baseDate.getTime() + value * 24 * 60 * 60 * 1000);
  const lastData = new Date(date);
  const year = lastData.getFullYear();
  const month = (lastData.getMonth() + 1).toString().padStart(2, '0'); // 注意，月份是从0开始的，所以需要加1
  const day = (lastData.getDate() - 1).toString().padStart(2, '0');
  const formattedDate = year + '-' + month + '-' + day;
  return formattedDate;
};

type PlatformInfoType = {
  platform: string;
  platformId: number | null | undefined;
};

export const usePlatformInfo = () => {
  const [value, setValue] = useState<PlatformInfoType>();

  useEffect(() => {
    try {
      const platformInfo = JSON.parse(
        localStorage.getItem('platformInfo') ?? '',
      );
      if (!platformInfo?.platform) {
        vsf.navigateTo(`/useInstitution`);
      }
      if (platformInfo?.platform === 'school' && !platformInfo?.platformId) {
        vsf.navigateTo(`/useInstitution`);
      }
      setValue(platformInfo);
    } catch (error) {
      console.error(error);
      message.error('机构ID不存在');
      vsf.navigateTo(`/useInstitution`);
    }
  }, []);

  return [value, setValue] as [PlatformInfoType, any];
};

export const convertToHttps = (url) => {
  if (url.startsWith('http://')) {
    return 'https://' + url.slice(7);
  }
  return url;
};
