import { Empty, Input, Tree, TreeProps } from '@vs/vsf-kit';
import { cloneDeep } from 'lodash';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { DomKey } from './constants';
import ContextMenu, { ContextMenuConfigType } from './contextMenu';
import { portalize } from './portal';
import { buildTree, filterTreeData, highlight } from './utils';

type loadDataType = () => Promise<any>;

type FieldNames = {
  title?: string;
  /** @private Internal usage for `rc-tree-select`, safe to remove if no need */
  _title?: string[];
  key?: string;
  children?: string;
};

type TreeMenuProps = Omit<TreeProps, 'treeData' | 'fieldNames' | 'onSelect'> & {
  /**
   * 数据源
   */
  treeData?: any[];
  /**
   * 动态加载数据
   */
  loadData?: loadDataType;
  /**
   * 自定义节点 title、key、children 的字段
   * @field [title, key, children]
   */
  fieldNames?: FieldNames;
  /**
   * tree 关系映射
   */
  dataRelationFieldNames?: {
    id: string;
    parentId: string;
  };
  /**
   * tree 关系映射
   */
  onSelect: (node: any) => void;
  /**
   * 右键菜单
   * @expand
   */
  context?: {
    /**
     * 单行右键菜单
     */
    rowContextMenu: ContextMenuConfigType;
    /**
     * 空白处右键菜单
     */
    defaultContextMenu: ContextMenuConfigType;
  };
  /**
   * 搜索
   * @expand
   */
  search: {
    /**
     * 是否需要搜索过滤
     */
    status?: boolean;
    /**
     * 搜索过滤类型, 静态过滤 | 请求接口
     */
    searchType?: 'static' | 'request';
    /**
     * 自定义搜索关键字
     */
    searchKeyWord?: string | string[];
    /**
     * 自定义搜索函数
     */
    searchCallback?: (node: any, keyword: string) => boolean;
  };
  /**
   * tree 组件 props
   * @component Tree
   * @componentProp omit [treeData, fieldNames]
   */
  treeProps?: TreeProps;
};

/**
 * 树形菜单
 */
const TreeMenu = (props: TreeMenuProps) => {
  const {
    treeData,
    loadData,
    fieldNames,
    dataRelationFieldNames,
    search,
    onSelect,
    context,
    treeProps,
    ...rest
  } = props;
  const { rowContextMenu, defaultContextMenu } = context || {};
  const {
    status,
    searchType = 'static',
    searchKeyWord,
    searchCallback,
  } = search || {};
  const portalRef = useRef();
  const [dataSource, setDataSource] = useState<any[]>([]);
  const [data, setData] = useState<any[]>([]);
  const searchValue = useRef();

  const [expandedKeys, setExpandedKeys] = useState<any[]>();
  let extraProps = {};

  const onRightClick = ({ event, node }) => {
    event.stopPropagation();
    portalRef.current = portalize({
      event,
      destoryClickOutside: true,
      component: <ContextMenu config={rowContextMenu} record={node} />,
      key: DomKey,
    });
  };

  const onBgRightClick = (event) => {
    event.preventDefault();
    portalRef.current = portalize({
      event,
      destoryClickOutside: true,
      component: <ContextMenu config={defaultContextMenu} />,
      key: DomKey,
    });
  };

  const handleSearch = (e) => {
    if (searchType === 'static') {
      const target = filterTreeData(dataSource, e?.target?.value, {
        fieldNames: fieldNames,
        keywords: searchKeyWord,
        callback: searchCallback,
      });
      searchValue.current = e?.target?.value;
      setData(target?.data);
      setExpandedKeys(target?.expandKeys);
    } else {
      // todo request
    }
  };

  const getData = useCallback(async () => {
    let resData = treeData;
    if (loadData) {
      const target = await loadData?.();
      resData = target?.data;
    }
    const targetData = buildTree(resData ?? [], null, dataRelationFieldNames);
    setDataSource(targetData);
    setData(targetData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [treeData]);

  const handleExpand = (expandedKeys) => {
    setExpandedKeys(expandedKeys);
  };

  const handleSelect = (
    selectedKeys,
    { selected, selectedNodes, node, event },
  ) => {
    onSelect?.(selectedNodes?.[0] ?? {});
  };

  useEffect(() => {
    getData();
  }, [getData]);

  if (rowContextMenu) {
    extraProps = {
      onRightClick: onRightClick,
    };
  }

  return (
    <div
      {...(defaultContextMenu ? { onContextMenu: onBgRightClick } : {})}
      className="vsf-treemenu-container"
    >
      {status && (
        <Input className="vsf-treemenu-search" onChange={handleSearch} />
      )}
      <div className="vsf-treemenu-content">
        {data && data?.length > 0 ? (
          <Tree
            {...extraProps}
            treeData={data}
            fieldNames={fieldNames}
            onExpand={handleExpand}
            // expandedKeys={expandedKeys}
            onSelect={handleSelect}
            {...treeProps}
            // titleRender={(node) => {
            //   return highlight(
            //     node,
            //     searchValue.current,
            //     searchKeyWord,
            //     fieldNames,
            //   );
            // }}
          />
        ) : (
          <Empty description="暂无数据" />
        )}
      </div>
    </div>
  );
};
TreeMenu.displayName = 'TreeMenu';

export default TreeMenu;
