import DepartmentAccountEdit from '@pages/DepartmentAccountEdit';
import vsf, { definePage } from '@vs/vsf-boot';
import { Icon, Section, VSPromiseTable, VSTableColumn } from '@vs/vsf-kit';
import React, { useCallback, useState } from 'react';

import ActionSplit from './ActionSplit';
const DepartmentAccountList = (props) => {
  const [refresh, setRefresh] = useState(false);

  const onFetch = useCallback(async (params) => {
    const res =
      await vsf?.services?.AdminUserDtoController_pageAdminUser_8a4210?.({
        qto: {
          from: params.pagination?.from ?? 0,
          size: params.pagination?.size ?? 20,
          orderList: params.orderList, // 排序结果
          ...params.search, // 搜索结果
          ...params.filters, // 过滤器结果
          ...(params?.extra ?? {}),
          refresh: undefined,
        },
      });
    return {
      data: res?.data?.result ?? res?.data ?? [],
      total: res?.data?.count ?? res?.data?.length ?? 0,
    };
  }, []);

  return (
    <Section title="" className="department_account_list">
      <VSPromiseTable
        extraParams={{ refresh }}
        onFetch={onFetch}
        onRemove={async (params) => {
          const res =
            await vsf?.services?.AdminUserBOController_deleteAdminUser_a526a2?.(
              { btoParam: { ...(params ?? {}) } },
            );
          if (res && res?.code === 200) {
            vsf.showToast('删除成功', 'success');
          }
          return res?.code === 200;
        }}
        addLinkPage={{
          linkPage: () => <DepartmentAccountEdit />,
          onLinkPageValidate: async (params) => {
            try {
              await vsf.refs.DepartmentAccountForm?.validateFieldsReturnFormatValue();
              const res =
                await vsf?.services?.AdminUserBOController_createAdminUser_66b7e3?.(
                  { btoParam: { ...(params ?? {}) } },
                );
              if (res && res?.code === 200) {
                vsf.showToast('添加成功', 'success');
              }
              return res?.data;
            } catch (e) {
              return false;
            }
          },
          modalProps: { title: '添加账号', width: 500 },
        }}
        updateLinkPage={{
          linkPage: () => <DepartmentAccountEdit />,
          onLinkPageValidate: async (params) => {
            try {
              await vsf.refs.DepartmentAccountForm?.validateFieldsReturnFormatValue();
              const res =
                await vsf?.services?.AdminUserBOController_updateAdminUser_d08f40?.(
                  { btoParam: { ...(params ?? {}) } },
                );
              if (res && res?.code === 200) {
                vsf.showToast('编辑成功', 'success');
              }
              return res?.data;
            } catch (e) {
              return false;
            }
          },
          modalProps: { title: '编辑账号', width: 500 },
        }}
        editable={{
          editType: 'single',
          deleteConfirmProps: { title: '确定删除该账号吗？', icon: <></> },
          columnProps: {
            width: 150,
          },
        }}
        actionSpaceProps={{
          split: <ActionSplit />,
        }}
        pagination={{
          defaultPageSize: 10,
          showTotal: () => <></>,
        }}
        form={{
          style: {
            justifyContent: 'end',
          },
        }}
        searchConfig={{
          filterType: 'inline',
          // autoSearch: true,
          optionButtons: [],
          searchButtonProps: {
            type: 'default',
          },
          extraAdditionButton: true,
          addition: { children: '添加账号', position: 'top' },
        }}
        debounceTime={200}
      >
        <VSTableColumn
          dataIndex={['phone']}
          title="账号"
          valueType="text"
          formItemProps={{
            rules: [
              {
                message: '数据格式错误',
                type: 'string',
                pattern: /^(?:\+?86)?1[3-9]\d{9}$/,
              },
            ],
          }}
          fieldProps={{}}
        />

        <VSTableColumn
          dataIndex={['userName']}
          title="姓名"
          valueType="text"
          search
          columnKey={'userNameLike'}
          formItemProps={{
            rules: [
              {
                message: '管理员姓名长度不合法',
                type: 'string',
                min: 0,
                max: 64,
              },
            ],
            label: '',
          }}
          fieldProps={{
            // addonAfter: <Icon type="SearchOutlined" />,
            placeholder: '搜索姓名、手机号或角色名称',
            suffix: (
              <div
                onClick={() => {
                  setRefresh(!refresh);
                }}
              >
                <Icon type="SearchOutlined" />
              </div>
            ),
          }}
        />

        <VSTableColumn
          dataIndex={['createdAt']}
          title="创建时间"
          editable={false}
          valueType="date"
          fieldProps={{ format: 'YYYY-MM-DD HH:mm:ss' }}
        />
      </VSPromiseTable>
    </Section>
  );
};
export default definePage(DepartmentAccountList);
