import './index.less';

import vsf, { definePage } from '@vs/vsf-boot';
import { message } from '@vs/vsf-kit';
import cn from 'classnames';
import dd from 'dingtalk-jsapi';
import React, { useCallback, useEffect, useState } from 'react';

import Bg from '@/assets/images/use_institution_bg.png';
import { PLATFORM } from '@/config';
import { navigateToWithPlatform } from '@/utils';

const menu = [
  {
    label: '局端',
    value: PLATFORM.department,
  },
  {
    label: '校端',
    value: PLATFORM.school,
  },
];

const UseInstitution: React.FC<React.Component> = (props: any) => {
  const [currentId, setCurrentId] = useState('');
  const [orgList, setOrgList] = useState<any>([]);
  const {
    routes: {
      query: { corpId },
    },
  } = props;

  const loadDD = useCallback(async () => {
    try {
      const ret = await dd.runtime.permission.requestAuthCode({
        corpId, // 企业id
      });
      if (ret && ret.code) {
        const response =
          await vsf?.services?.DingtalkLoginController_dingLogin_9108fe?.({
            code: ret?.code,
          });

        const _tempList =
          response.data?.orgUserInfo?.map((item) => ({
            label: item.orgName,
            value: item?.orgId,
          })) ?? [];

        if (response && response?.code === 200) {
          const userName = response.data?.userName;
          localStorage.setItem('userName', userName);
          if (response?.data?.isAdmin) {
            setOrgList([
              {
                label: '西湖区教育局',
                value: -1,
              },
              ..._tempList,
            ]);
            localStorage.setItem(
              'sample-org-list',
              JSON.stringify([
                {
                  label: '西湖区教育局',
                  value: -1,
                },
                ..._tempList,
              ]),
            );
          } else {
            vsf?.stores?.user?.setOrgList(_tempList);
            localStorage.setItem('sample-org-list', JSON.stringify(_tempList));
            setOrgList(_tempList);
          }
        }
      }
      //  else {
      //   fetchAllOrg();
      // }
    } catch (error) {
      // if (!corpId) {
      //   fetchAllOrg();
      // }
      console.error(error);
      // message.error('免登失败');
    }
  }, [corpId]);

  const loadLocalOrgList = useCallback(() => {
    try {
      if (!corpId) {
        const _data = JSON.parse(
          localStorage.getItem('sample-org-list') ?? '[]',
        );
        if (_data) {
          setOrgList(_data);
        } else {
          loadDD();
        }
      }
    } catch (error) {
      console.log(error);
    }
  }, [corpId, loadDD]);

  useEffect(() => {
    loadLocalOrgList();
  }, [loadLocalOrgList]);

  useEffect(() => {
    loadDD();
  }, [loadDD]);

  const handelItemClick = (value) => {
    setCurrentId(value);
    if (value && value !== -1) {
      localStorage.setItem(
        'platformInfo',
        JSON.stringify({
          platform: 'school',
          platformId: value,
        }),
      );
    } else {
      localStorage.setItem(
        'platformInfo',
        JSON.stringify({
          platform: 'department',
          platformId: -1,
        }),
      );
    }
    navigateToWithPlatform(`/${value}/monitorTaskList`);
  };

  const fetchAllOrg = async () => {
    try {
      const response =
        await vsf?.services?.OrgDtoController_listAllOrg_84e432?.({
          qto: {},
        });
      if (response && response.code === 200) {
        const _tempList = [
          {
            label: '西湖区教育局',
            value: -1,
          },
          ...(response.data?.result?.map((item) => ({
            label: item.orgName,
            value: item.id,
          })) ?? []),
        ];
        setOrgList(_tempList);
        localStorage.setItem('sample-org-list', JSON.stringify(_tempList));
        return;
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className="use_institution_container col aic">
      <img src={Bg} />
      <div className="use_institution_container__title">
        - 中小学生质量监测抽样平台 -
      </div>
      <div className="use_institution_container__content col aic">
        <div className="use_institution_container__content__title">
          请选择登录的机构
        </div>
        <div className="use_institution_container__content__list">
          {orgList?.map((item) => (
            <div
              key={item.value}
              className={cn(
                'use_institution_container__content__list__item aic jcc',
                {
                  ['use_institution_container__content__list__item-active']:
                    currentId === item.value,
                  ['use_institution_container__content__list__item-normal']:
                    currentId !== item.value,
                },
              )}
              onClick={() => handelItemClick(item.value)}
            >
              {item.label}
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default definePage(UseInstitution);
