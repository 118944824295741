import React from 'react';

const labelStyle = (v) => {
  return {
    style: {
      width: 15 * (v - 1) + 30,
    },
  };
};

export const labelColWidth = {
  2: labelStyle(2),
  3: labelStyle(3),
  4: labelStyle(4),
  5: labelStyle(5),
  6: labelStyle(6),
  7: labelStyle(7),
  8: labelStyle(8),
  9: labelStyle(9),
};

const containsIgnoreCase = (str, searchStr) => {
  const regex = new RegExp(searchStr, 'i');
  return regex.test(str);
};

export const isValidData = (node, searchString, keywords) => {
  if (typeof keywords === 'string') {
    return containsIgnoreCase(node?.[keywords], searchString);
  }
  if (Array.isArray(keywords)) {
    let res = false;
    keywords.forEach((item: string) => {
      if (containsIgnoreCase(node?.[item], searchString)) {
        res = true;
      }
    });
    return res;
  }
  return false;
};

const findStringIndices = (str, searchStr) => {
  if (!searchStr) return [];
  const regex = new RegExp(searchStr, 'ig');
  let match;
  const indices: number[] = [];

  while ((match = regex.exec(str)) !== null) {
    indices.push(match.index);
  }

  return indices;
};

const splitStringByIndexes = (str, indexes, length) => {
  const result: any[] = [];
  let start = 0;
  let end = 0;
  let flag = false;

  for (let i = 0; i < indexes.length; i++) {
    if (!flag && indexes[i] !== 0) {
      end = indexes[i];
      result.push({
        highlight: false,
        str: str.substring(start, end),
      });
    }
    start = indexes[i];
    end = indexes[i] + length;
    result.push({
      highlight: true,
      str: str.substring(start, end),
    });
    if (i < indexes.length - 1 && indexes[i] + length < indexes[i + 1]) {
      result.push({
        highlight: false,
        str: str.substring(end, indexes[i + 1]),
      });
    } else {
      result.push({
        highlight: false,
        str: str.substring(end, str?.length),
      });
    }
    flag = true;
  }
  return result;
};

const getHighlightIndex = (node, searchString, keywords) => {
  let target = keywords;
  if (!Array.isArray(keywords)) {
    target = [keywords];
  }
  const IndexArr = target
    .map((item: string) => {
      return findStringIndices(node?.[item], searchString);
    })
    ?.filter((item) => item?.length > 0);
  return IndexArr?.[0];
};

export const highlight = (node, searchString, keywords, fieldNames) => {
  const showLabel = node?.[fieldNames?.title ?? 'title'];
  const index = getHighlightIndex(
    node,
    searchString,
    keywords ?? [fieldNames?.title ?? 'title'],
  );
  const formatStrList = splitStringByIndexes(
    showLabel,
    index ?? [],
    searchString?.length,
  );
  if (formatStrList?.filter((v) => v)?.length > 0) {
    return (
      <span>
        {(formatStrList || [])?.map((item) => {
          return (
            <span
              key={item?.str}
              style={
                item?.highlight
                  ? {
                      color: 'red',
                    }
                  : {}
              }
            >
              {item?.str}
            </span>
          );
        })}
      </span>
    );
  } else {
    return showLabel;
  }
};

/**
 * search 过滤数据
 * @param treeData
 * @param searchString
 * @param fc
 * @returns
 */
export const filterTreeData = (treeData, searchString, extra) => {
  const arr: any[] = [];
  let expandKeys: any[] = [];
  treeData.forEach((node) => {
    const { fieldNames, keywords, callback } = extra;
    // 检查当前节点是否包含搜索字符串
    let containsSearchString;

    if (callback) {
      containsSearchString = callback(node, searchString);
    } else if (keywords) {
      containsSearchString = isValidData(node, searchString, keywords);
    } else {
      containsSearchString = isValidData(
        node,
        searchString,
        fieldNames?.title ?? 'title',
      );
    }

    // 如果当前节点是叶子节点并且满足条件，则保留该节点
    if (containsSearchString) {
      arr.push(node);
    } else {
      // 递归过滤子节点
      const filteredChildren = node?.children
        ? filterTreeData(node?.children, searchString, extra)
        : { data: [], expandKeys: [] };

      // 如果当前节点满足条件或其子节点有满足条件的节点，则保留该节点
      if (containsSearchString || filteredChildren?.data?.length > 0) {
        expandKeys = [
          ...expandKeys,
          node[fieldNames?.key ?? 'key'],
          // eslint-disable-next-line no-unsafe-optional-chaining
          ...filteredChildren?.expandKeys,
        ];
        arr.push({
          ...node,
          children: filteredChildren?.data,
        });
      }
    }
  });
  return {
    data: arr ?? [],
    expandKeys: expandKeys ?? [],
  };
};

export const isNil = (v) => {
  return v === undefined || v === null;
};

/**
 * 一维对象数组 => 树状结构
 * @param arr
 * @param parentId
 * @param keyMap id parentId 映射
 * @returns
 */
export const buildTree = (
  arr,
  parentId = null,
  keyMap = {
    id: 'id',
    parentId: 'parentId',
  },
) => {
  const tree: any[] = [];

  for (const item of arr) {
    if (item[keyMap['parentId']] === parentId) {
      const children = buildTree(arr, item[keyMap['id']], keyMap);

      if (children.length > 0) {
        item.children = children;
      }
      tree.push(item);
    }
  }

  return tree;
};
