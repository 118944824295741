import { RouteConfig } from '@vs/vsf-boot';

import Root from '@/layouts';
// import Index from '@/pages';
import UseInstitution from '@/pages/use_institution';

import pageRoutes from './routes';

const routes: RouteConfig[] = [
  {
    path: '/useInstitution',
    exact: true,
    component: UseInstitution,
  },
  {
    path: '/',
    component: Root,
    routes: pageRoutes,
  },
];

export default routes;
